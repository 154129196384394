import React from 'react'
import { useDispatch } from 'react-redux'

import DetailsPopup from '../../../../Scenes/Components/DetailsPopup'
import WithFeatureToggleHOC from '../../../../Services/HOC/WithFeatureToggleHOC'
import {
	Feature,
	FeatureComponentId
} from '../../../../Services/models/Features'
import { ProjectClusterStatus } from '../../../../Services/models/IProject'
import {
	PART_CONSOLIDATION_OPPORTUNITIES,
	PART_CONSOLIDATION_OPPORTUNITIES_HELPER,
	SEARCH_AGAIN,
	SEARCH_AGAIN_FOR_RESULT,
	WITH_ANY_MATERIAL,
	WITH_SAME_MATERIAL
} from '../../../../Services/Strings'
import { getString } from '../../../../Services/Strings/StringService'
import FeatureSection from '../../../Components/FeatureSection'
import InfoBox from '../../../Components/InfoBox'
import IconFactory from '../../../Components/StarIcon/IconFactory'
import Button from '../../../Components/thirdParty/CreativeTim/components/CustomButtons/Button.jsx'
import PrintableClustersOverview from '../../PrintableClusters/PrintableClustersOverview'
import AwaitingClusterAnalysis from './AwaitingClusterAnalysis'
import GenerateMoreResultsAlert from './GenerateMoreResultsAlert'
import { toggleGenerateMoreResultsAlert } from 'global actions'
import Flexbox from 'Scenes/Components/FlexBox'

import '../ProjectAnalysis.scss'

export const RequestMultiplePartsIntoOneCard = ({
	materialCountInfo,
	requested,
	requestText,
	projectClusterStatus,
	setupClustersOverview,
	clusterItemsWithPictures,
	showResults,
	project,
	confirmConsolidationAnalysis
}) => {
	const dispatch = useDispatch()

	const renderCountExplanation = () => {
		return (
			<div>
				<div className="text-dark">
					{materialCountInfo?.sameMaterialCount} {WITH_SAME_MATERIAL}
				</div>
				<div>
					{materialCountInfo?.anyMaterialCount} {WITH_ANY_MATERIAL}
				</div>
			</div>
		)
	}

	const renderSubHeaderSection = () => {
		const displayPopup =
			materialCountInfo?.totalMaterialCount &&
			projectClusterStatus === ProjectClusterStatus.complete

		const displayText =
			projectClusterStatus !== ProjectClusterStatus.awaitingRequest

		if (!requested) {
			return <div />
		}

		return (
			<Flexbox alignItems="center">
				{projectClusterStatus !== ProjectClusterStatus.failed && (
					<IconFactory
						iconName={projectClusterStatus}
						className="cluster-section--subheader--icon"
					/>
				)}
				{displayText && (
					<div className="cluster-section--subheader--text">
						{requestText ||
							getString('COMBINE_MULTIPLE_INTO_ONE_CARD_COMPLETED_TXT').format(
								materialCountInfo?.totalMaterialCount || 0
							)}
					</div>
				)}
				{!!displayPopup && (
					<InfoBox
						inPortal={true}
						boxContact={renderCountExplanation()}
						iconClassName="admin-form-field__info_icon"
						boxDirection="right-start"
					/>
				)}
			</Flexbox>
		)
	}

	const renderSectionContent = () => {
		if (showResults) {
			return (
				<>
					<PrintableClustersOverview
						clusterItemsWithPictures={clusterItemsWithPictures}
						setupClustersOverview={setupClustersOverview}
						projectId={project.id}
						projectIdentifier={project.id}
					/>
					<GenerateMoreResultsAlert />
				</>
			)
		}

		if (requested) {
			return <div />
		}

		return requestText
	}

	const renderSectionAction = () => {
		const isAwaitingAnalysis =
			projectClusterStatus === ProjectClusterStatus.awaitingAnalysis
		const isAwaitingRequest =
			projectClusterStatus === ProjectClusterStatus.awaitingRequest
		const isNotRecalculate = !project?.recalculateClusters
		const noClustersFound =
			projectClusterStatus === ProjectClusterStatus.noClustersFound &&
			isNotRecalculate
		const isFeatureOn = Feature.isFeatureOn(FeatureComponentId.UNIFICATION)

		const disableButton = !Feature.isFeatureActive(
			FeatureComponentId.UNIFICATION
		)
		const customizePartConsolidationResults = Feature.isFeatureOn(
			FeatureComponentId.CUSTOMIZE_PART_CONSOLIDATION_RESULTS
		)

		const showGenerateMoreButton =
			customizePartConsolidationResults &&
			showResults &&
			isNotRecalculate &&
			!isAwaitingAnalysis

		if (isAwaitingRequest) {
			return (
				<>
					<div className="search-again-info"></div>
					<DetailsPopup
						isHover={false}
						data={getString('LIGHT_USER_BUTTONS_INFORMATION')}
						popperDirection="bottom"
						popperClassName="info-box-wrapper details-popup--contact-us"
						popperContactClassName="info-box-data"
					>
						<Button
							disabled={disableButton}
							className="cluster-section--button"
							color="primary"
							onClick={() =>
								confirmConsolidationAnalysis(
									project.id,
									materialCountInfo?.totalMaterialCount,
									undefined,
									true
								)
							}
						>
							{getString('UNIFICATIONS_REQUEST')}
						</Button>
					</DetailsPopup>
				</>
			)
		}

		if (showGenerateMoreButton) {
			return (
				<Button
					disabled={disableButton}
					className="cluster-section--button"
					color="primary"
					onClick={() => dispatch(toggleGenerateMoreResultsAlert(true))}
				>
					{getString('GENERATE_MORE_RESULTS')}
				</Button>
			)
		}

		if (isAwaitingAnalysis) {
			return <AwaitingClusterAnalysis />
		}

		if (
			isAwaitingAnalysis ||
			noClustersFound ||
			isNotRecalculate ||
			!projectClusterStatus ||
			!isFeatureOn
		) {
			return
		}

		return (
			<>
				<div className="search-again-info">
					<IconFactory
						iconName="warning"
						className="filter-fields--widget--remove--icon"
					/>
					{SEARCH_AGAIN_FOR_RESULT}
				</div>
				<DetailsPopup
					isHover={false}
					data={getString('LIGHT_USER_BUTTONS_INFORMATION')}
					popperDirection="bottom"
					popperClassName="info-box-wrapper details-popup--contact-us"
					popperContactClassName="info-box-data"
				>
					<Button
						disabled={disableButton}
						className="cluster-section--button"
						color="primary"
						onClick={() =>
							confirmConsolidationAnalysis(
								project.id,
								materialCountInfo?.totalMaterialCount
							)
						}
					>
						{SEARCH_AGAIN}
					</Button>
				</DetailsPopup>
			</>
		)
	}

	const sectionHeader = () => {
		return (
			<div className="feature-section--header__with-icon">
				<span>{PART_CONSOLIDATION_OPPORTUNITIES}</span>
				<div>
					<InfoBox
						boxContact={PART_CONSOLIDATION_OPPORTUNITIES_HELPER}
						iconClassName="admin-form-field__info_icon"
						boxDirection="right-start"
					/>
				</div>
			</div>
		)
	}

	return (
		<div id={FeatureComponentId.UNIFICATION}>
			<FeatureSection
				sectionHeader={sectionHeader()}
				sectionSubHeader={renderSubHeaderSection()}
				sectionAction={renderSectionAction()}
				sectionContent={renderSectionContent()}
			/>
		</div>
	)
}

export default WithFeatureToggleHOC(
	RequestMultiplePartsIntoOneCard,
	FeatureComponentId.UNIFICATION
)
