import React, { FC, memo } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'

import { isUndefined } from 'lodash'

import PartAnalysisTab from './index'
import { History, Location } from 'history'
import ErrorBoundary from 'Scenes/Components/ErrorBoundary/ErrorBoundary'
import MainPartAnalysis from 'Scenes/Home/NewPartAnalysis/MainPartAnalysis/MainPartAnalysis'
import { Feature, FeatureComponentId } from 'Services/models/Features'

import './index.scss'

const MainPartAnalysisTSX: any = MainPartAnalysis

interface PartAnalysisTabProps {
	location: Location
	match: any
	history: History
}

const PartAnalysisView: FC<PartAnalysisTabProps> = props => {
	const partAnalysisTabOn = Feature.isFeatureOn(
		FeatureComponentId.PART_ANALYSIS_TAB
	)
	const { errorMessage, errorClass } = useSelector(
		(state: RootStateOrAny) => state.ErrorBoundaryReducer
	)

	if (isUndefined(partAnalysisTabOn)) return null

	return (
		<ErrorBoundary
			extra="PartAnalysisView"
			errorClass={errorClass}
			errorMessage={errorMessage}
		>
			{partAnalysisTabOn ? (
				<PartAnalysisTab {...props} />
			) : (
				<MainPartAnalysisTSX {...props} />
			)}
		</ErrorBoundary>
	)
}

export default memo(PartAnalysisView)
