import { getTheme } from '../themes/getTheme'
import {
	amColor,
	benefitsBackground,
	benefitsBackgroundOpac,
	costSavingColor,
	methodColor,
	pieBenefitsCo2,
	pieBenefitsInventory,
	pieBorderlineColor,
	pieFailedColor,
	pieMissingInformationColor,
	pieNotCostEffectiveColor,
	piePassedColor,
	piePassedGradientStartColor,
	successColor
} from './colors'
import {
	EXTERNAL,
	OAUTH2,
	ONBOARDING_WIZARD_ROUTE,
	UPLOAD_SCRIPT_ROUTE
} from './Constants/RoutesConstants'
import { CADAnalysisResult } from './models/CADAnalysisResult'
import { SolutionConfigurationTypes } from './models/ISolutionConfiguration'
import {
	COST_AND_LEAD_LABELS,
	DRAWING_COST_PACKING_DENSITY,
	DRAWING_COST_SUPPORT_VOLUME,
	PART_ANALYSIS_NEW_SOLUTION_NAME,
	PART_RESULTS_OPTIONS
} from './Strings'
import { getString } from './Strings/StringService'
import { UserRole } from 'Scenes/Home/UserRole.enum'

const { fontStyle, chartHeadFontSize, colors } = getTheme()

export const loginProvider = {
	azure: 'azure',
	ping: 'ping'
}

export const localesAlowed = ['en', 'ja']

export const holesColor = [0, 0.5569, 0.9176]
export const overhangingColor = [0.5882, 0.1804, 0.8627]
export const wtColor = [0.851, 0.1333, 0.3137]
export const cncColor = [0.85, 0.51, 0.34]
export const threadsColor = [0.57, 0.57, 0.0277]

export const defaultSettingScenario = {
	lowVolume: '3'
}

export const NETWORK_ERROR_CODE = 600,
	ENTER_KEY_CODE = 13,
	ESC_KEY_CODE = 27,
	PRINTER_NAME_ALREADY_EXIST_ERROR = 129345,
	PRINTER_MATERIAL_NAME_ALREADY_EXIST_ERROR = 235434,
	USER_MATERIAL_NAME_ALREADY_EXIST_ERROR = 235433,
	USER_HAS_NO_PERMISSION = 89335,
	USER_COUNTRY_NOT_ALLOWED = 82320,
	USER_CREDIT_PARTS_END_CODE = 54875,
	USER_TRIAL_SUBSCRIPTION_UN_VALID = 54876,
	USER_SUBSCRIPTION_UN_VALID = 54877,
	MIN_NUMBER_FIELD_VALUE = 1,
	MIN_PROPERTIES_CONFIGURATIONS_FOR_EDITABLE_FIELDS = 1,
	MAX_NUMBER_FIELD_VALUE = 1000000,
	ZERO_NUMBER_FIELD_VALUE = 0,
	DECIMAL_MIN_NUMBER_FIELD_VALUE = 0.1,
	MAX_PERCENTAGE_FIELD_VALUE = 100,
	EMPTY_FIELD_VALUE = '',
	MAX_QUANTITY_VALUE = 1000000,
	MIN_BATCH = 10,
	MIN_YEARS = 5

export const partResults = {
	printable: 'printable',
	borderline: 'borderline',
	notPrintable: 'notPrintable',
	offTheShelf: 'offTheShelf',
	failed: 'failed',
	notCostEffective: 'notCostEffective',
	costSavingPrintable: 'costSavingPrintable',
	dependencyAnalysisFailed: 'dependencyAnalysisFailed',
	missingInformation: 'missingInformation',
	sheetMetal: 'sheetMetal',
	castingCNC: 'castingCNC',
	latheCNC: 'latheCNC',
	optimized: 'optimized'
}

export const PART_RESULTS_LED_WITH_TITLE_INDEXES = [
	{
		title: PART_RESULTS_OPTIONS.find(
			partResultOption => partResultOption.value === partResults.printable
		)?.title,
		result: partResults.printable,
		color: piePassedColor,
		stroke: piePassedColor
	},
	{
		title: PART_RESULTS_OPTIONS.find(
			partResultOption => partResultOption.value === partResults.borderline
		)?.title,
		result: partResults.borderline,
		color: pieBorderlineColor,
		stroke: pieBorderlineColor
	},
	{
		title: PART_RESULTS_OPTIONS.find(
			partResultOption => partResultOption.value === partResults.notPrintable
		)?.title,
		result: partResults.notPrintable,
		color: pieFailedColor,
		stroke: pieFailedColor
	},
	{
		title: PART_RESULTS_OPTIONS.find(
			partResultOption =>
				partResultOption.value === partResults.notCostEffective
		)?.title,
		result: partResults.notCostEffective,
		color: pieNotCostEffectiveColor,
		stroke: pieNotCostEffectiveColor
	},
	{
		title: PART_RESULTS_OPTIONS.find(
			partResultOption =>
				partResultOption.value === partResults.missingInformation
		)?.title,
		result: partResults.missingInformation,
		color: pieMissingInformationColor,
		stroke: pieMissingInformationColor
	}
]

export const PART_RESULTS_OPTIMIZED_WITH_TITLE_INDEXES = [
	{
		title: PART_RESULTS_OPTIONS.find(
			partResultOption => partResultOption.value === partResults.optimized
		)?.title,
		result: partResults.optimized,
		color: piePassedColor,
		stroke: piePassedColor
	},
	{
		title: PART_RESULTS_OPTIONS.find(
			partResultOption => partResultOption.value === partResults.castingCNC
		)?.title,
		result: partResults.castingCNC,
		color: pieFailedColor,
		stroke: pieFailedColor
	},
	{
		title: PART_RESULTS_OPTIONS.find(
			partResultOption => partResultOption.value === partResults.latheCNC
		)?.title,
		result: partResults.latheCNC,
		color: pieNotCostEffectiveColor,
		stroke: pieNotCostEffectiveColor
	},
	{
		title: PART_RESULTS_OPTIONS.find(
			partResultOption => partResultOption.value === partResults.sheetMetal
		)?.title,
		result: partResults.sheetMetal,
		color: pieMissingInformationColor,
		stroke: pieMissingInformationColor
	}
]

export const PART_BENEFITS_RESULTS_LED_WITH_TITLE_INDEXES = [
	{
		title: PART_RESULTS_OPTIONS.find(
			partResultOption => partResultOption.value === partResults.printable
		)?.title,
		gradientStartColor: piePassedGradientStartColor,
		color: piePassedColor,
		stroke: piePassedColor
	},
	{
		title: PART_RESULTS_OPTIONS.find(
			partResultOption =>
				partResultOption.value === partResults.costSavingPrintable
		)?.title,
		result: 'cost-saving',
		color: costSavingColor,
		stroke: pieBorderlineColor
	}
]

export const USER_PANEL_PART_RESULTS_INDEXES = [
	{
		title: getString('USER_PANEL_PART_RESULTS_PRINTABLE_INDEX_TITLE'),
		result: CADAnalysisResult.printable
	},
	{
		title: getString('USER_PANEL_PART_RESULTS_NOT_PRINTABLE_INDEX_TITLE'),
		result: CADAnalysisResult.notPrintable
	},
	{
		title: getString('USER_PANEL_PART_RESULTS_NOT_COST_EFFECTIVE_INDEX_TITLE'),
		result: CADAnalysisResult.notCostEffective
	},
	{
		title: getString('USER_PANEL_PART_RESULTS_NOT_PRINT_SUITABLE_INDEX_TITLE'),
		result: CADAnalysisResult.notPrintSuitable
	}
]

export const USER_PANEL_PRINTING_SAVING_INDEXES = [
	{
		name: 'upfront',
		title: getString('USER_PANEL_PRINTING_SAVING_UPFRONT_INDEX_TITLE'),
		color: benefitsBackground
	},
	{
		name: 'manufacturing',
		title: getString('USER_PANEL_PRINTING_SAVING_MAN_INDEX_TITLE'),
		color: benefitsBackgroundOpac
	},
	{
		name: 'inventory',
		title: getString('USER_PANEL_PRINTING_SAVING_INV_INDEX_TITLE'),
		color: pieBenefitsInventory
	},
	{
		name: 'co2Saving',
		title: getString('USER_PANEL_PRINTING_SAVING_CO2_INDEX_TITLE'),
		color: pieBenefitsCo2
	}
]

var Chartist = require('chartist')

var delays = 80,
	durations = 500

export const POLLER_COUNTER_TIMEOUT = 30
export const POLLER_COUNTER_WARNING_TIMEOUT = 8
export const POLLER_COUNTER_CHANGE_UI_TIMEOUT = 4
export const POLLER_DELAY = 500

export const MAX_MATERIAL_ADDED = 10

export const defaultSolutionColor = '#64c882'

export const defaultSolution = {
	id: 0,
	name: PART_ANALYSIS_NEW_SOLUTION_NAME,
	leadTime: 0,
	cost: 0,
	material: {
		name: ''
	},
	printerMaterial: {
		name: ''
	},
	printer: {
		name: ''
	},
	dontShow: true,
	metrics: {
		data: {
			metrics: {
				cost: 2,
				ultimateTensileStrength: 4,
				density: 2
			},
			filters: {}
		}
	}
}

export const defaultConfigurations = [
	{
		id: -1,
		name: PART_ANALYSIS_NEW_SOLUTION_NAME,
		material: {
			name: ''
		},
		dontShow: true,
		priorities: {},
		filters: {},
		solution: defaultSolution,
		type: SolutionConfigurationTypes.simple
	},
	{
		id: 0,
		name: PART_ANALYSIS_NEW_SOLUTION_NAME,
		material: {
			name: ''
		},
		dontShow: true,
		priorities: {
			cost: 2,
			ultimateTensileStrength: 4,
			density: 2
		},
		filters: {},
		solution: defaultSolution
	}
]

export const chartGraphDataOptions = {
	chart: {
		zoom: {
			enabled: false
		},
		toolbar: {
			show: false
		},
		animations: {
			enabled: true,
			easing: 'easeinout',
			speed: 500,
			animateGradually: {
				enabled: false
			},
			dynamicAnimation: {
				enabled: false
			}
		}
	},
	title: {
		text: '',
		align: 'center',
		style: {
			fontSize: chartHeadFontSize
		}
	},
	colors: [methodColor, amColor],
	dataLabels: {
		enabled: false,
		formatter: (value, obj) => value,
		textAnchor: 'start',
		offsetY: -11,
		offsetX: -10,
		style: {
			colors: [successColor],
			fontSize: '16px'
		},
		background: {
			enabled: false
		}
	},
	stroke: {
		width: 3,
		curve: 'smooth'
	},
	tooltip: {
		x: {
			show: false
		},
		y: {
			formatter: undefined,
			title: {
				formatter: seriesName => ''
			}
		},
		marker: {
			show: false
		}
	},
	grid: {
		borderColor: 'transparent'
	},
	markers: {
		size: 3,
		discrete: [
			{
				seriesIndex: 0,
				dataPointIndex: -1,
				fillColor: successColor,
				strokeColor: successColor,
				size: 4
			},
			{
				seriesIndex: 1,
				dataPointIndex: 1,
				fillColor: successColor,
				strokeColor: successColor,
				size: 4
			}
		]
	},
	xaxis: {
		categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
		title: {
			text: 'Month',
			offsetY: 10,
			style: {
				fontWeight: 100
			}
		},
		tickPlacement: 'between',
		tickAmount: 'dataPoints',
		type: 'category'
	},
	yaxis: {
		title: {
			text: 'Temperature',
			style: {
				fontWeight: 100
			}
		}
	}
}

export const partFinancialAnalysis = {
		data: {
			labels: [],
			series: []
		},
		options: {
			lineSmooth: Chartist.Interpolation.cardinal({
				tension: 10
			}),
			axisX: {
				showGrid: false
				// labelInterpolationFnc: function(value, index) {
				//     return index % 2 === 0 ? value : null;
				// }
			},
			axisY: {
				showGrid: false
				// labelInterpolationFnc: function(value, index) {
				//     return index % 2 === 0 ? value : null;
				// }
			},
			low: 0,
			high: 10, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
			chartPadding: {
				top: 0,
				right: 0,
				bottom: 0,
				left: 0
			},
			showPoint: true,
			scales: {
				yAxes: [
					{
						scaleLabel: {
							display: true,
							labelString: 'Y text'
						}
					}
				],
				xAxes: [
					{
						scaleLabel: {
							display: true,
							labelString: 'X text'
						}
					}
				]
			}
		},
		animation: {
			draw: function (data) {
				animationDrawFunc(data)
			}
		}
	},
	defaultMetal = {
		name: '6061 Alloy',
		type: 'metal',
		id: 66,
		category: 'Aluminum Alloys'
	},
	defaultPrinterMetal = {
		name: 'AlSi10Mg',
		type: 'metal',
		id: 181,
		category: 'Aluminum Alloys',
		subCategory: 'AlSi10Mg'
	},
	materialTypes = { plastic: 'plastic', metal: 'metal' },
	manufacturingMethodTypes = {
		mold: 'Molding',
		cnc: 'CNC',
		cast: 'Casting',
		investmentCast: 'Investment casting',
		standardCost: 'Standard cost'
	},
	manufacturingMethodStrings = {
		mold: 'PART_FINANCIAL_ANALYSIS_INJECTION_MOLDING',
		cnc: 'CNC',
		cast: 'CASTING',
		investmentCast: 'INVESTMENT_CASTING',
		standardCost: 'STANDARD_COST'
	},
	manufactureTypeByCosting = {
		COSTING_CO2_DIE_CASTING: 'cast',
		COSTING_CO2_INVESTMENT_CASTING: 'investmentCast',
		COSTING_CO2_INJECTION_MOLDING: 'mold',
		COSTING_CO2_CNC: 'cnc'
	},
	defaultPrinterConfiguration = {
		type: 'bestMatch',
		custom: 'custom',
		eligible: 'eligible',
		inHouse: 'inHouse',
		configuration: {
			name: 'Configuration'
		}
	},
	defaultNamingPrinterConfiguration = {
		bestMatch: 'Best Match',
		eligible: 'Eligible Printers',
		inHouse: 'In house Printers',
		custom: 'Custom',
		polymerAlternative: 'Polymer Alternative',
		weightReduction: 'Weight reduction'
	},
	configurationProperties = [
		{
			name: 'Cost',
			id: 'cost',
			value: 2,
			display: true
		},
		{
			name: 'Strength',
			id: 'ultimateTensileStrength',
			value: 4,
			display: true
		},
		{
			name: 'Weight',
			id: 'density',
			value: 2,
			display: true
		},
		{
			name: 'Stiffness',
			id: 'youngsModulus',
			withCheckbox: true,
			active: true,
			value: 1,
			display: false
		},
		{
			name: 'Elongation',
			id: 'percentElongationAtBreak',
			withCheckbox: true,
			active: true,
			value: 1,
			display: false
		},
		{
			name: 'Heat Resistance',
			id: 'maximumServiceTemperature',
			withCheckbox: true,
			active: false,
			value: 1,
			display: false
		}
	],
	defaultProperties = {
		cost: 2,
		ultimateTensileStrength: 4,
		density: 2
	},
	advancedProperties = {
		youngsModulus: 1,
		percentElongationAtBreak: 1,
		maximumServiceTemperature: 1
	},
	defaultConfigurationProperties = {
		...defaultProperties,
		...advancedProperties
	},
	defaultPropertiesOptions = {
		basic: 'Basic',
		advanced: 'Advanced'
	}

export const materialForToleranceCheckBox = [
	'aluminum alloys',
	'stainless steels'
]
// defaultMetal = {
//   name: '6061 Alloy',
//   type: 'metal',
//   id: 66,
//   category: 'Aluminum Alloys'
// },

export const defaultConstantsMetalCostValues = {
	camExistence: false,
	accuracy: false,
	complexity: true,
	cncProgrammingPricePerHour: 50,
	operationCostPerHour: 50
}

export const animationDrawFunc = data => {
	if (
		data.type.toLowerCase() === 'line' ||
		data.type.toLowerCase() === 'area'
	) {
		data.element.animate({
			d: {
				begin: 600,
				dur: 300,
				from: data.path
					.clone()
					.scale(1, 0)
					.translate(0, data.chartRect.height())
					.stringify(),
				to: data.path.clone().stringify(),
				easing: Chartist.Svg.Easing.easeOutQuint
			}
		})
	} else if (data.type === 'point') {
		data.element.animate({
			opacity: {
				begin: (data.index + 1) * delays,
				dur: durations,
				from: 0,
				to: 1,
				easing: 'ease'
			}
		})
	}
}

export const muiTheme = {
	zIndex: {
		modal: 6500
	},
	palette: {
		primary: {
			light: '#757ce8',
			main: colors.primaryColor || '#3f50b5',
			dark: '#002884',
			contrastText: '#fff'
		},
		secondary: {
			light: '#757ce8',
			main: '#3f50b5',
			dark: '#002884',
			contrastText: '#fff'
		}
	},
	typography: fontStyle || {
		fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
		fontWeight: '300',
		lineHeight: '1.5em'
	},
	overrides: {
		MuiCheckbox: {
			root: {
				'&$disabled': {
					color: 'rgba(0, 0, 0, 0.38) !important'
				}
			}
		},
		MuiSwitch: {
			switchBase: {
				'&$disabled': {
					color: 'rgba(0, 0, 0, 0.38) !important'
				}
			}
		},
		MuiRadio: {
			root: {
				'&&': {
					padding: '0'
				}
			}
		},
		MuiListItemIcon: {
			root: {
				'&&': {
					minWidth: '0',
					display: 'inherit'
				}
			}
		},
		MuiOutlinedInput: {
			input: {
				'&&': {
					padding: '6px 0 7px'
				}
			}
		},
		MuiFilledInput: {
			root: {
				'&&': {
					background: '#ffffff'
				}
			}
		},
		MuiSelect: {
			selectMenu: {
				'&&': {
					fontWeight: '300'
				}
			}
		},
		MuiTypography: {
			body1: {
				fontSize: '0.875rem'
			},
			h6: {
				'&&': {
					fontSize: '1.3125rem',
					textTransform: 'inherit',
					color: 'inherit'
				}
			}
		}
	}
}

//upload page
export const defaultAllowedFileTypes = ['.step', '.stp', '.x_t', '.stl']
export const defaultPartsFileSizeLimit = 0
export const conversionFactorBytesToMB = 1000000
export const defaultUploadFileName = 'Mounting_lug.sldprt'
export const pdfAllowedFileTypes = ['.pdf']
export const metaDataAllowedFileTypes = ['.xls', '.xlsx', '.csv']
export const defaultNotSupportedUnitTypeFormats = ['.stl']
export const defaultMaxAllowedUploadParts = 100
export const defaultMaxAllowedUploadProjects = 100000000
export const defaultUploadUnitType = ''
export const BOMRadioValue = '1'
export const chosenTmMaterialRadioValue = '2'
export const chosenAmMaterialRadioValue = '3'
export const drawingFiles = '4'
export const defaultMetrics = { price: 0.5, strength: 0.5, sameMaterial: 1 }
export const benefitTypes = {
	buyToFly: 'buy-to-fly',
	complexityScore: 'complexity-score',
	costSaving: 'cost-saving',
	timeSaving: 'time-saving',
	weightReduction: 'weight-reduction',
	co2Saving: 'co2-saving'
}

export const SUBSCRIPTION_FULL_VERSION_RADIO_VALUE = 1
export const EXTEND_SUBSCRIPTION_RADIO_VALUE = 2
export const OTHER_RADIO_VALUE = 3

export const PUBLISHED = 'published'
export const FAILED = 'failed'

export const json = 'JSON'
//in house printers
export const OTHER = 'Other...'

//custom user material
export const temperatureUnits = { C: 'C', F: 'F' }

//cost and lead
export const COST_AND_LEAD_NAMES = {
	machineLifetime: {
		text: getString('COST_LEAD_MACHINE_LIFE').TEXT,
		title: getString('COST_LEAD_MACHINE_LIFE').TITLE,
		label: COST_AND_LEAD_LABELS.YEARS,
		edit: false,
		type: 'text',
		validation: {
			type: 'range',
			value: [1, 30]
		},
		alert: getString('COST_LEAD_MACHINE_LIFE').ALERT
	},
	hoursPerDay: {
		text: getString('COST_LEAD_HOURS_PER_DAY').TEXT,
		title: getString('COST_LEAD_HOURS_PER_DAY').TITLE,
		label: COST_AND_LEAD_LABELS.HOURS,
		edit: false,
		type: 'text',
		validation: {
			type: 'range',
			value: [1, 24]
		},
		alert: getString('COST_LEAD_HOURS_PER_DAY').ALERT
	},
	daysPerWeek: {
		text: getString('COST_LEAD_DAYS_PER_WEEK').TEXT,
		title: getString('COST_LEAD_DAYS_PER_WEEK').TITLE,
		label: COST_AND_LEAD_LABELS.DAYS,
		edit: false,
		type: 'text',
		validation: {
			type: 'range',
			value: [1, 7]
		},
		alert: getString('COST_LEAD_DAYS_PER_WEEK').ALERT
	},
	cncInitialTechnologySetupTime: {
		text: getString('COST_LEAD_INITIAL_CNC').TEXT,
		title: '',
		label: COST_AND_LEAD_LABELS.DAYS,
		edit: false,
		type: 'number',
		validation: {
			type: 'range',
			value: [1, 999]
		},
		alert: getString('COST_LEAD_INITIAL_CNC').ALERT
	},
	moldInitialTechnologySetupTime: {
		text: getString('COST_LEAD_INITIAL_CASTING').TEXT,
		title: '',
		label: COST_AND_LEAD_LABELS.DAYS,
		edit: false,
		type: 'number',
		validation: {
			type: 'range',
			value: [1, 999]
		},
		alert: getString('COST_LEAD_INITIAL_CASTING').ALERT
	},
	engineerHourCost: {
		text: getString('COST_LEAD_ENGINEER_HOUR').TEXT,
		title: getString('COST_LEAD_ENGINEER_HOUR').TITLE,
		label: COST_AND_LEAD_LABELS.RATE,
		edit: false,
		type: 'number',
		validation: {
			type: 'range',
			value: [1, 999]
		},
		alert: getString('COST_LEAD_ENGINEER_HOUR').ALERT
	},

	technicianHourCost: {
		text: getString('COST_LEAD_TECHNICAL_HOURS_COST').TEXT,
		title: getString('COST_LEAD_TECHNICAL_HOURS_COST').TITLE,
		label: COST_AND_LEAD_LABELS.RATE,
		edit: false,
		type: 'number',
		validation: {
			type: 'range',
			value: [1, 999]
		},
		alert: getString('COST_LEAD_TECHNICAL_HOURS_COST').ALERT
	},
	operatorHourCost: {
		text: getString('COST_LEAD_OPERATOR_HOURS_COST').TEXT,
		title: getString('COST_LEAD_OPERATOR_HOURS_COST').TITLE,
		label: COST_AND_LEAD_LABELS.RATE,
		edit: false,
		type: 'number',
		validation: {
			type: 'range',
			value: [1, 999]
		},
		alert: getString('COST_LEAD_OPERATOR_HOURS_COST').ALERT
	},
	cncMachineOperationHourCost: {
		text:
			(getString('CNC_MACHINE_OPERATION_COST_PER_HOUR').TEXT &&
				getString('CNC_MACHINE_OPERATION_COST_PER_HOUR').TEXT.replace(
					'{0}',
					'$'
				)) ||
			'',
		title: getString('CNC_MACHINE_OPERATION_COST_PER_HOUR').TITLE,
		label: COST_AND_LEAD_LABELS.RATE,
		edit: false,
		type: 'number',
		validation: {
			type: 'range',
			value: [1, 999]
		},
		alert: getString('CNC_MACHINE_OPERATION_COST_PER_HOUR').ALERT
	},
	engineeringOperationHourCost: {
		text:
			(getString('ENGINEERING_COST_PER_HOUR').TEXT &&
				getString('ENGINEERING_COST_PER_HOUR').TEXT.replace('{0}', '$')) ||
			'',
		title: getString('ENGINEERING_COST_PER_HOUR').TITLE,
		label: COST_AND_LEAD_LABELS.RATE,
		edit: false,
		type: 'number',
		validation: {
			type: 'range',
			value: [0, 999]
		},
		alert: getString('ENGINEERING_COST_PER_HOUR').ALERT
	},
	assemblyTimePerPartPair: {
		text: getString('ASSEMBLY_TIME_PER_PART_PAIR').TEXT,
		title: getString('ASSEMBLY_TIME_PER_PART_PAIR').TITLE,
		label: COST_AND_LEAD_LABELS.MINUTES,
		edit: false,
		type: 'number',
		validation: {
			type: 'range',
			value: [0, 999]
		},
		alert: getString('ASSEMBLY_TIME_PER_PART_PAIR').ALERT
	},
	customsTaxes: {
		text: getString('CUSTOM_TAXES').TEXT,
		title: getString('CUSTOM_TAXES').TITLE,
		label: COST_AND_LEAD_LABELS.PERCENTAGE,
		edit: false,
		type: 'number',
		validation: {
			type: 'min',
			value: [0]
		},
		alert: getString('CUSTOM_TAXES').ALERT
	},
	domesticShipment: {
		text: getString('SERVICE_BUREAU_PROFIT').TEXT,
		title: getString('SERVICE_BUREAU_PROFIT').TITLE,
		label: COST_AND_LEAD_LABELS.RATE,
		edit: false,
		type: 'number',
		validation: {
			type: 'min',
			value: [0]
		},
		alert: getString('SERVICE_BUREAU_PROFIT').ALERT
	},
	profitMargin: {
		text: getString('PROFIT_MARGIN').TEXT,
		title: getString('PROFIT_MARGIN').TITLE,
		label: COST_AND_LEAD_LABELS.PERCENTAGE,
		edit: false,
		type: 'number',
		validation: {
			type: 'min',
			value: [0]
		},
		alert: getString('PROFIT_MARGIN').ALERT
	}
}

//2D Drawing Cost cost
export const DRAWING_COSTS_NAMES = {
	drawingCostAssumedSupportVolume: {
		value: 0,
		text: DRAWING_COST_SUPPORT_VOLUME.TEXT,
		title: DRAWING_COST_SUPPORT_VOLUME.TITLE,
		label: COST_AND_LEAD_LABELS.PERCENTAGE,
		edit: false,
		apply: false,
		editInput: '',
		type: 'text',
		validation: {
			type: 'range',
			minValue: 0,
			maxValue: 100
		},
		alert: DRAWING_COST_SUPPORT_VOLUME.ALERT
	},
	drawingCostPackingDensity: {
		value: 0,
		text: DRAWING_COST_PACKING_DENSITY.TEXT,
		title: DRAWING_COST_PACKING_DENSITY.TITLE,
		label: COST_AND_LEAD_LABELS.PERCENTAGE,
		edit: false,
		apply: false,
		editInput: '',
		type: 'text',
		validation: {
			type: 'range',
			minValue: 1,
			maxValue: 100
		},
		alert: DRAWING_COST_PACKING_DENSITY.ALERT
	}
}

//Metadata
export const METADATA_COSTS_NAMES = {
	metadataCostAssumedSupportVolume: {
		value: 0,
		text: DRAWING_COST_SUPPORT_VOLUME.TEXT,
		title: DRAWING_COST_SUPPORT_VOLUME.TITLE,
		label: COST_AND_LEAD_LABELS.PERCENTAGE,
		edit: false,
		apply: false,
		editInput: '',
		type: 'text',
		validation: {
			type: 'range',
			minValue: 0,
			maxValue: 100
		},
		alert: DRAWING_COST_SUPPORT_VOLUME.ALERT
	},
	metadataCostPackingDensity: {
		value: 0,
		text: DRAWING_COST_PACKING_DENSITY.TEXT,
		title: DRAWING_COST_PACKING_DENSITY.TITLE,
		label: COST_AND_LEAD_LABELS.PERCENTAGE,
		edit: false,
		apply: false,
		editInput: '',
		type: 'text',
		validation: {
			type: 'range',
			minValue: 1,
			maxValue: 100
		},
		alert: DRAWING_COST_PACKING_DENSITY.ALERT
	}
}

export const PRINTING_COST_PARAMETERS = [
	getString('PRINTER_HOURLY_COST'),
	getString('PRINTER_PURCHASING_COST')
]

export const MACHINING_ID = 4
export const ORIENTATION_COUNT = 6
export const ORIENTATION = 'OrientationStability'

export const CUSTOMIZE_MINIMUM_PRINTER_PRICE = 100
export const CUSTOMIZE_MAXIMUM_PRINTER_PRICE = 10000000

export const CUSTOMIZE_MINIMUM_PRINTER_HOURLY_PRICE = 1
export const CUSTOMIZE_MAXIMUM_PRINTER_HOURLY_PRICE = 10000

export const CUSTOMIZE_MAXIMUM_LAYER_THICKNESS = 1000
export const CUSTOMIZE_MINIMUM_LAYER_THICKNESS = 10
export const CASTOR_LINK = 'https://www.3dcastor.com/'
export const PART_IMAGES_TABS_INDEX = {
	STRUCTURAL_LIMITATION_TAB_INDEX: 0,
	ORIENTATION_TAB_INDEX: 1
}

export const NA = 'N/A'
export const materialCategories = {
	aluminumAlloys: 'Aluminum Alloys',
	stainlessSteels: 'Stainless Steels'
}

export const printStatusScore = {
	passed: 100,
	failed: 0
}

export const InapplicableStatus = 2
export const INAPPLICABLE = 'inapplicable'
export const pdfBuggyVersionOfChrome = 117
export const Chrome = 'Chrome'
export const supportedBrowsers = [Chrome, 'Edge']

export const analysisTabLSKey = ({ partId, configurationId }) =>
	`analysis-tab-${partId}-${configurationId}`

export const immediateFeaLSKey = ({ partId, configurationId }) =>
	`immediate_FEA_analysis_${configurationId}_${partId}`

export const viewFeaLSKey = ({ partId, configurationId }) =>
	`view_FEA_analysis_${configurationId}_${partId}`

export const leadingPopupLSKey = projectId => `leading-popup-${projectId}`

export const cypressFeaUrl = 'cypress_FEA_Url'

export const EMAIL = 'email'

export const ADMIN_ROLES = [
	UserRole.SUPER_ADMIN,
	UserRole.ADMIN,
	UserRole.USER_MANAGER,
	UserRole.DB_MANAGER,
	UserRole.ORGANIZATIONS_ADMIN
]

export const STEP = 'STEP'

export const STP = 'STP'

export const GA_TAB_SORTING_COLUMN_INDEX = 0
export const GA_REVIEW_SORTING_COLUMN_INDEX = 1
export const GA_ACTION_COLUMN_INDEX = 3

export const UNDEFINED_STRING = 'undefined'

export const TRUE_STRING = 'true'

export const DEFAULT_QUANTITY = 50

export const partsPageLimit = 15
export const LOADING = 'loading'

export const onboardingPermittedRoutes = [
	ONBOARDING_WIZARD_ROUTE,
	UPLOAD_SCRIPT_ROUTE
]

export const IN_HOUSE_PRINTERS = 'inHousePrinters'
export const DEFAULT_VALUE = '(Default)'

export const PROJECT_REDUCER = {
	BUNDLE: 'ProjectBundleReducer',
	ANALYSIS: 'ProjectAnalysisReducer'
}

export const FORBIDDEN_ERROR_CODE = 403
export const ORGANIZATION_NAME_ERROR_CODE = 5112

export const COMMUNICATION_TOOL_LS_KEY = 'communicationTool'
export const COMMUNICATION_TOOL_PARAMS_LS_KEY = 'comsToolParams'
export const SKIP_LICENSE_LS_KEY = 'skipLicense'
export const LOGIN_LS_FIELDS = 'loginFields'
export const LOGIN_LS_ERRORS = 'loginErrors'
export const SAVE_BEFORE_LOGOUT_LS_KEYS_LIST = [SKIP_LICENSE_LS_KEY]

export const stopRedirectUrls = [
	'/resetPassword',
	'/login-provider',
	'/usage-agreement',
	'/license',
	'/license/sso'
]

export const notRequirePermissionUrls = [
	'/login',
	'/login-provider',
	'/register',
	'/license',
	'/license/sso',
	'/isLoggedIn',
	'/refresh_token',
	'/requestVerify',
	'/home/castorLight',
	'/usage-agreement',
	'/resetPassword',
	'/provider/login',
	'/publicOrganizations'
]

export const disableErrorRoutes = [
	'/',
	'/home/castorLight',
	'/home/comsTool',
	'/authorize',
	'/api/integration-consumer/provider-auth',
	OAUTH2,
	EXTERNAL
]

export const redirectMessage = 'user has cancelled'
export const FOLDER = 'folder'
export const SELECTED_ORGANIZATION_LS_KEY = 'selectedOrganizationId'
export const ORGANIZATION_ID = 'organizationId'
export const NAME = 'name'
export const ActionType = {
	LOGIN: 'login',
	LOGOUT: 'logout',
	REFRESH_TOKEN: 'refreshToken',
	FAILED_LOGIN: 'failedLogin',
	TWO_FA: 'twoFactorSetupConfirm',
	DELETE_PROJECT: 'deleteProject',
	DELETE_PART: 'deletePart',
	DELETE_USER: 'deleteUser'
}
export const ACCESS_LOG_SUCCESS = 1
export const ACCESS_LOG_FAILED = 0
