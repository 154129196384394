export enum GenerateMoreResultOption {
	setUnlimitedSize = 'setUnlimitedSize',
	setSpecificSize = 'setSpecificSize',
	generateMoreResults = 'generateMoreResults'
}
export enum DimensionType {
	trayWidth = 'trayWidth',
	trayHeight = 'trayHeight',
	trayDepth = 'trayDepth'
}
