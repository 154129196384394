import React, { ChangeEvent, FC, useCallback, useMemo } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import {
	changeRecalculateOrganizationId,
	toggleRecalculateWithOrganizationCheckbox
} from './CustomizeRecalculateProjectsAction'
import CastorCheckbox from 'Scenes/Components/CastorCheckbox'
import OrganizationSelector from 'Scenes/Components/OrganizationSelector'
import WithFeatureToggleHOC from 'Services/HOC/WithFeatureToggleHOC'
import { FeatureComponentId } from 'Services/models/Features'
import { getString } from 'Services/Strings/StringService'
import { renderOrganizationValue } from 'Services/Utils/organizationTools'

type IProps = {
	deepReset: string
}

const RecalculateOrganizationSelector: FC<IProps> = ({ deepReset }) => {
	const { availableOrganizations } = useSelector(
		(state: RootStateOrAny) => state.user
	)
	const { recalculateOrganizationId, recalculateWithOrganization } =
		useSelector(
			(state: RootStateOrAny) => state.CustomizeRecalculateProjectsReducer
		)
	const dispatch = useDispatch()

	const title = useMemo(
		() =>
			renderOrganizationValue(
				availableOrganizations,
				recalculateOrganizationId,
				true
			),
		[availableOrganizations, recalculateOrganizationId]
	)

	const renderValue = useCallback(
		(selected: unknown) =>
			renderOrganizationValue(availableOrganizations, selected as number, true),
		[availableOrganizations]
	)

	const handleOrganizationSelect = useCallback(
		(value: number) => dispatch(changeRecalculateOrganizationId(value)),
		[dispatch]
	)

	if (deepReset === '0') {
		return null
	}

	return (
		<div className="recalculate-projects--organization-selector">
			<CastorCheckbox
				checked={recalculateWithOrganization}
				onChange={(event: ChangeEvent<HTMLInputElement>) => {
					dispatch(
						toggleRecalculateWithOrganizationCheckbox(event.target.checked)
					)
				}}
				className="recalculate-projects--organization-checkbox"
			/>
			<label htmlFor="upload-with-organization">
				{getString('RECALCULATE_PROJECT_WITH_SITE_LABEL')}
			</label>
			<OrganizationSelector
				value={recalculateOrganizationId}
				disabled={!recalculateWithOrganization}
				title={title}
				id="upload-with-organization"
				className="recalculate-projects--organization--select-field"
				renderValue={renderValue}
				handleOrganizationSelect={handleOrganizationSelect}
				disabledMenuItem
			/>
		</div>
	)
}

export default WithFeatureToggleHOC(
	RecalculateOrganizationSelector,
	FeatureComponentId.CUSTOMIZE_ORGANIZATIONS
)
