import React, { FC, memo } from 'react'

import { toLower } from 'lodash'

import {
	getCategoriesOptions,
	getMaterialsOptions,
	getStatusColor,
	getTypesOptions
} from './ProjectMaterialBomMappingService'
import DataTableDropdown from 'Scenes/Components/DataTable/DataTableDropdown'
import DataTableStatusBar from 'Scenes/Components/DataTable/DataTableStatusBarForMaterialsMatch'
import { IMaterialCategory, Material } from 'Services/models/IMaterial'
import { getString } from 'Services/Strings/StringService'

interface TableRowProps {
	materialMapping: Record<string, any>
	materialTypes: string[]
	materialCategories: IMaterialCategory[]
	materials: Material[]
	allowedCategories: String[]
	updateMaterialType: (partBomDataId: number, value: string) => void
	updateMaterialCategory: (partBomDataId: number, value: string) => void
	updateMaterial: (partBomDataId: number, value: string) => void
}

const TableRow: FC<TableRowProps> = ({
	materialMapping,
	materialCategories,
	materialTypes,
	materials,
	updateMaterialType,
	updateMaterialCategory,
	updateMaterial,
	allowedCategories
}) => {
	const materialMappingId = materialMapping.id
	const materialType = materialMapping.newSetMaterial.type
	const materialCategory = materialMapping.newSetMaterial.category
	const materialId = materialMapping.newSetMaterial.materialId

	return (
		<>
			<div className="drawing-name-wrapper" title={'ds'}>
				<div>{materialMapping.materialName}</div>
			</div>
			<div className="drawing-selector-wrapper">
				<DataTableDropdown
					text={materialType}
					data={{
						options: getTypesOptions(materialTypes),
						className: 'without-border project-parts-mapping-table-dropdown',
						onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
							updateMaterialType(materialMappingId, e.target.value)
						},
						isFromStringTable: false
					}}
					className="without-border project-parts-mapping-table-dropdown"
				/>
			</div>
			<div className="drawing-selector-wrapper">
				<DataTableDropdown
					text={toLower(materialCategory)}
					data={{
						options: getCategoriesOptions(materialCategories, materialType, allowedCategories),
						className: 'without-border project-parts-mapping-table-dropdown',
						onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
							updateMaterialCategory(materialMappingId, e.target.value)
						},
						isFromStringTable: false
					}}
					className="without-border project-parts-mapping-table-dropdown"
				/>
			</div>
			<div className="drawing-selector-wrapper">
				<DataTableDropdown
					text={materialId}
					data={{
						options: getMaterialsOptions(
							materials,
							materialType,
							materialCategory
						),
						className: 'without-border project-parts-mapping-table-dropdown',
						onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
							updateMaterial(materialMappingId, e.target.value)
						},
						isFromStringTable: false
					}}
					className="without-border project-parts-mapping-table-dropdown"
				/>
			</div>
			<div>
				<DataTableStatusBar
					text={getString(materialMapping.status)}
					className=""
					data={getStatusColor(materialMapping)}
				/>
			</div>
		</>
	)
}

export default memo(TableRow)
