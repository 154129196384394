import { useCallback, useEffect, useMemo, useState } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { editInHousePrinter } from './CustomizeInHousePrintersActions'
import { Material } from 'Services/models/IMaterial'
import { IPrinter } from 'Services/models/IPrintersTypes'
import { getString } from 'Services/Strings/StringService'
import { sortArrayOfObjectsInAlphabeticalOrder } from 'Services/Utils/sortingTools'

export const useInHousePrinterRow = (
	printerMaterials: Material[],
	printerCompany: string,
	printerName: string,
	printerAmount: number,
	recordId: number,
	editMode: boolean,
	emptyOption: string
) => {
	const [loading, setLoading] = useState(false)
	const [company, setCompany] = useState('')
	const [name, setName] = useState('')
	const [selectedPrinter, setSelectedPrinter] = useState<IPrinter>(
		{} as IPrinter
	)
	const [materials, setMaterials] = useState<Material[]>([])
	const [selectedMaterialNames, setSelectedMaterialNames] = useState<string[]>(
		[]
	)
	const [selectedMaterialIds, setSelectedMaterialIds] = useState<number[]>([])
	const [amount, setAmount] = useState(1)
	const { printersCompanies, printers, userId } = useSelector(
		(state: RootStateOrAny) => state.CustomizeInHousePrintersReducer
	)
	const { adminSelectedOrganizationId } = useSelector(
		(state: RootStateOrAny) => state.CustomizeReducer
	)
	const { printersFullData } = useSelector(
		(state: RootStateOrAny) => state.user
	)
	const dispatch = useDispatch()

	const applyIsDisabled = useMemo(
		() =>
			!company ||
			company === emptyOption ||
			!name ||
			name === emptyOption ||
			!selectedMaterialIds.length ||
			!amount,
		[amount, company, emptyOption, name, selectedMaterialIds.length]
	)

	const onApplyClick = useCallback(() => {
		setLoading(true)
		dispatch(
			editInHousePrinter(
				selectedPrinter,
				userId,
				selectedMaterialIds,
				adminSelectedOrganizationId,
				recordId,
				amount,
				() => setLoading(false)
			)
		)
	}, [
		adminSelectedOrganizationId,
		amount,
		dispatch,
		recordId,
		selectedMaterialIds,
		selectedPrinter,
		userId
	])

	const onCompanyChange = useCallback(
		(value: string) => {
			setCompany(value)
			setName(emptyOption)
			setSelectedMaterialNames([])
			setSelectedMaterialIds([])
		},
		[emptyOption]
	)

	const getPrinterMaterialsList = useCallback(
		(printerName: string) => {
			let printerMaterialsList = []
			const company = printersFullData.find(
				(p: Record<string, any>) => p.companyName === selectedPrinter.company
			)
			if (company && company.printers && company.printers.length) {
				const printer = company.printers.find(
					(printer: Record<string, any>) => printer.name === printerName
				)
				if (printer && printer.materials && printer.materials.length) {
					printerMaterialsList = sortArrayOfObjectsInAlphabeticalOrder(
						printer.materials,
						'name'
					)
				}
			}
			return printerMaterialsList
		},
		[printersFullData, selectedPrinter?.company]
	)

	const onPrinterNameChange = useCallback(
		(value: string) => {
			const selectedPrinter = printers.find(
				(printer: IPrinter) => printer.name === value
			)
			const printerNotExist = selectedPrinter.inPrinterNamesList || false

			let printerMaterialsList = []
			if (!printerNotExist) {
				printerMaterialsList = getPrinterMaterialsList(value)
			}
			const selectedMaterials =
				printerMaterialsList?.map((m: Material) => m.name) || []
			const selectedMaterialIds =
				printerMaterialsList?.map((m: Material) => m.id) || []

			setName(value)
			setSelectedPrinter(selectedPrinter)
			setMaterials(printerMaterialsList)
			setSelectedMaterialNames(selectedMaterials)
			setSelectedMaterialIds(selectedMaterialIds)
		},
		[getPrinterMaterialsList, printers]
	)

	const onMaterialChange = useCallback(
		(materialNames: string[]) => {
			const selectedMaterialNames = materialNames.filter(
				m => m !== getString('ADMIN_MATERIALS_PAGE_HEADER')
			)
			const selectedMaterialIds = materials
				.filter(m => selectedMaterialNames.indexOf(m.name) > -1)
				.map(m => m.id)

			setSelectedMaterialNames(selectedMaterialNames)
			setSelectedMaterialIds(selectedMaterialIds)
		},
		[materials]
	)

	const onAmountChange = useCallback((value: number) => setAmount(value), [])

	useEffect(() => {
		const printerMaterialsCopy = sortArrayOfObjectsInAlphabeticalOrder(
			[...printerMaterials],
			'name'
		)
		const selectedPrinter = printers.find(
			(printer: IPrinter) => printer.name === printerName
		) as IPrinter
		const sortedSelectedMaterialNames =
			printerMaterialsCopy?.map(m => m.name) || []
		const selectedMaterialIds = printerMaterialsCopy?.map(m => m.id) || []

		const materialsList = getPrinterMaterialsList(printerName)

		setCompany(printerCompany)
		setName(printerName)
		setMaterials(materialsList)
		setSelectedMaterialNames(sortedSelectedMaterialNames)
		setSelectedMaterialIds(selectedMaterialIds)
		setSelectedPrinter(selectedPrinter)
		setAmount(printerAmount)
	}, [
		printerAmount,
		printerCompany,
		printerMaterials,
		printerName,
		editMode,
		printers,
		printersFullData,
		getPrinterMaterialsList
	])

	return {
		loading,
		company,
		name,
		amount,
		selectedMaterialNames,
		printersCompanies,
		materials,
		applyIsDisabled,
		onApplyClick,
		onCompanyChange,
		onPrinterNameChange,
		onMaterialChange,
		onAmountChange
	}
}
