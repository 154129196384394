import React, { FC, memo, useEffect, useMemo, useState } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { useParams } from 'react-router-dom'

import { onShowNotCostEffectiveAlert } from 'global actions'
import Flexbox from 'Scenes/Components/FlexBox'
import IconFactory from 'Scenes/Components/StarIcon/IconFactory'
import {
	addNewConfigurationId,
	addPrinterConfigurationId
} from 'Scenes/Home/NewPartAnalysis/PartAnalysisTab/PartAnalysisConstants'
import { UNPRINTABLE } from 'Scenes/Home/ProjectPending/ProjectPendingConstants'
import Loader from 'Scenes/Loader/Loader'
import { partResults } from 'Services/Constants'
import { IConfiguration } from 'Services/models/IConfiguration'
import { PartStatus } from 'Services/models/IPart'
import { projectRoute } from 'Services/routeFuncs'
import { getString } from 'Services/Strings/StringService'

interface ConfigurationFailedProcessingProps {
	bundleId?: string
}

const ConfigurationFailedProcessing: FC<ConfigurationFailedProcessingProps> = ({
	bundleId
}) => {
	const dispatch = useDispatch()
	const { configurations, part, cluster } = useSelector(
		(state: RootStateOrAny) => {
			return state?.MainPartAnalysisReducer
		}
	)
	const { state } = useLocation() as any as {
		state: { pushNotificationLink?: boolean }
	}
	const { projectId } = useParams() as { projectId?: string }
	const history = useHistory()

	useEffect(() => {
		if (state?.pushNotificationLink) {
			history.push(projectRoute(projectId))
		}
	}, [])

	const configurationLength = useMemo(
		() =>
			configurations.filter(
				(conf: IConfiguration) =>
					conf?.id !== addNewConfigurationId &&
					conf?.id !== addPrinterConfigurationId
			)?.length,
		[configurations]
	)

	const isAwaitingStatus =
		configurationLength === 0 &&
		(part?.status === PartStatus.awaitingCombinedHeatmap ||
			cluster?.status === PartStatus.awaitingCombinedHeatmap)

	useEffect(() => {
		if (part?.result === partResults.notCostEffective && !isAwaitingStatus) {
			dispatch(onShowNotCostEffectiveAlert(part, bundleId))
		}
	}, [])

	return (
		<div className="part-analysis-tab--failed-processing">
			<Flexbox alignItems="center" justifyContent="center" width="100%">
				{isAwaitingStatus ? (
					<Loader showFlex={false} size={50} fullPage={false} load={true} />
				) : (
					<IconFactory
						iconName={UNPRINTABLE}
						className="status-viewer-page--icon"
					/>
				)}
				<span>
					<b>
						{isAwaitingStatus
							? getString('ANALYZING_PART')
							: getString('FAILED_PROCESSING_PART_ALERT_TITLE')}
					</b>
				</span>
			</Flexbox>
		</div>
	)
}

export default memo(ConfigurationFailedProcessing)
