import { isEmpty } from 'lodash'

import {
	fileFormatError,
	fileMatch,
	getAcceptedFileFormats,
	getFilesWithMaterialAndQuantity,
	handleUploadFile,
	hasUserDateExpired,
	shouldShowUnitTypeSelector
} from './UploadProjectActionService'
import {
	ADDED_FILES,
	ADDITIONAL_NOTES_CHANGED,
	BEGIN_UPLOAD,
	BOM_REMOVED_UPLOAD_PROJ,
	BOM_SELECTED_UPLOAD_PROJ,
	CAD_NAME_CHANGED,
	CREATE_PROJECT,
	CREATE_PROJECT_FAILED,
	CREATE_PROJECT_SUCCESS,
	DRAGGING_OVER_DROPZONE,
	ERP_NAME_CHANGED,
	FILE_MATERIAL_OR_QUANTITY_CHANGED,
	GET_USER_REMAINING_PARTS,
	HIDE_TRIAL_USER_UPLOAD_PROJECT_POPUP,
	MATERIAL_CATEGORY_CHANGED_IN_MATERIAL_SELECTOR,
	MATERIAL_RADIO_BUTTON_VALUE_CHANGED_UPLOAD_PROJ,
	MATERIAL_TYPE_CHANGED_IN_MATERIAL_SELECTOR,
	MATERIAL_VALUE_CHANGED_UPLOAD_PROJ,
	PRINTER_MATERIAL_CATEGORY_CHANGED_IN_MATERIAL_SELECTOR,
	PRINTER_MATERIAL_TYPE_CHANGED_IN_MATERIAL_SELECTOR,
	PROJECT_APPLICATION_CHANGED,
	PROJECT_NAME_CHANGED,
	QUANTITY_CHANGED,
	QUICK_PROJECT_CONNECTION_ERROR_FAILED,
	QUICK_PROJECT_POLLER_STARTED,
	REMOVE_ERROR_FROM_DROP_ZONE,
	REMOVE_FILE_FROM_UPLOAD_DROPZONE,
	REMOVED_FILES,
	RESET_SETTINGS_STATE,
	SELECT_PROJECT_ORGANIZATION,
	UNIT_TYPE_CREATE_PROJECT_FAILED,
	UNIT_TYPE_CREATE_PROJECT_RESET,
	UPDATE_TRIAL_USER_UPLOAD_PROJECT_POPUP,
	UPLOAD_CLEAR_STATUS,
	UPLOAD_COMPLETE,
	UPLOAD_FAILED,
	UPLOAD_FAILED_FILES,
	UPLOAD_FILE_COMPLETE,
	UPLOAD_PROJECT_AM_VALUE_CHANGE,
	UPLOAD_PROJECT_EARLY_PRODUCTION_CHANGE,
	UPLOAD_PROJECT_FILTER_ALERT_CLOSED,
	UPLOAD_PROJECT_FILTER_FEATURES_ALERT_CONFIRMED,
	UPLOAD_PROJECT_MANUFACTURING_CHANGE,
	UPLOAD_PROJECT_SCENARIO_CHANGE,
	UPLOAD_PROJECT_SETUPED,
	UPLOAD_PROJECT_STANDART_COST_CHECKBOX,
	UPLOAD_PROJECT_TOLERANCE_CHANGED,
	UPLOAD_PROJECT_TOLERANCE_TOGGLED,
	UPLOAD_START_LOADING,
	UPLOAD_UNIT_TYPE_CHANGED,
	USER_SUBSCRIPTION_REQUEST_LOADING,
	USER_SUBSCRIPTION_REQUEST_SUCCEED
} from 'global actions/types'
import { ToleranceClass } from 'Scenes/Components/toleranceClassMenu/toleranceClassMenu'
import {
	KEY_2d,
	METADATA,
	METADATA_FORMAT,
	PDF_FORMAT
} from 'Scenes/Home/NewUploadProject/constants'
import { conversionFactorBytesToMB } from 'Services/Constants'
import { UPLOAD_PROJECT } from 'Services/Constants/pageNamesConstants'
import { getExtensionFromFileName } from 'Services/getExtensionFromFileName'
import { FormatType } from 'Services/models/IPart'
import { IFileWithMaterialAndQuantity } from 'Services/models/IUploadProject'
import {
	GENERAL_DROP_FILE_EXPLANATION,
	NO_FOLDER_ERROR,
	NOT_FOLDER_ERROR
} from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

const INITIAL_STATE = {
	error: '',
	draggingFiles: false,
	loading: false,
	uploading: false,
	showSubscriptionPopup: false,
	connectCastorPopup: false,
	filesToUpload: [],
	filesUploaded: [],
	filesFailed: [],
	totalFiles: [],
	progress: 0.0,
	allFilesPercentages: {},
	projectName: '',
	projectId: null,
	ERP: '',
	CAD: '',
	uploadAttempts: 0,
	uploadSuccess: false,
	quantity: '',
	unitType: '',
	radioButtonSelected: null,
	connectCastorRadioButtonSelected: 3,
	userSubscriptionRequestLoading: false,
	BOMError: '',
	BOMFile: null as null | File,
	fileExplanation: GENERAL_DROP_FILE_EXPLANATION,
	quickProject: false,
	pollerStarted: false,
	quickProjectConnectionError: false,
	fileInputKey: Date.now(),
	toleranceIncluded: null,
	customToleranceValue: ToleranceClass.TOLERANCE_CLASS_IRRELEVANT,
	uploadURLs: {},
	application: '',
	antivirusMessages: {},
	antivirusMessagingEndpoint: null,
	showFilterFieldsAlert: false,
	showUnitTypeSelector: false,
	pageName: UPLOAD_PROJECT,
	projectSettingsScenario: {},
	selectedProjectScenario: {},
	loadingLifeCycle: false,
	errorUnit: false,
	userRemainingPart: '',
	userDateExpired: false,
	trialUserPopupShown: false,
	trialUserPopupErrorMessage: '',
	projectStandardCost: 0,
	projectStandardCostError: true,
	manufactureMethod: '',
	manufactureMethodError: true,
	amValue: [],
	isSinglePart: false,
	enableUploadWithErrorMessage: false,
	filesWithMaterialAndQuantity: [],
	additionalNotes: '',
	isStandardCostBoxChecked: false,
	projectOrganizationId: '',
	materialChosen: null,
	amMaterialChosen: null
}

// TODO: FIX THE TYPES
const uploadProjectReducer = (state = INITIAL_STATE, action: any) => {
	switch (action.type) {
		case UPLOAD_PROJECT_SETUPED:
			const {
				defaultUploadProjectSelectedRadioButton,
				uploadProjectSelectedRadioButton,
				defaultProjectScenario,
				chainBenefitOn,
				projectSettingsScenariosOn,
				unitType,
				isStandardCostBoxChecked
			} = action.payload
			return {
				...INITIAL_STATE,
				projectOrganizationId: state.projectOrganizationId,
				isStandardCostBoxChecked,
				userRemainingPart: state.userRemainingPart,
				userDateExpired: state.userDateExpired,
				unitType: unitType,
				radioButtonSelected:
					defaultUploadProjectSelectedRadioButton?.toString() || null,
				projectSettingsScenario: defaultProjectScenario,
				...(uploadProjectSelectedRadioButton &&
				projectSettingsScenariosOn &&
				chainBenefitOn
					? {
							selectedProjectScenario: {
								name: defaultProjectScenario[
									uploadProjectSelectedRadioButton - 1
								]?.key,
								data: defaultProjectScenario[
									uploadProjectSelectedRadioButton - 1
								]
							},
							quantity:
								defaultProjectScenario[uploadProjectSelectedRadioButton - 1]
									?.quantity
					  }
					: {})
			}

		case UPLOAD_PROJECT_SCENARIO_CHANGE: {
			const { selectedProjectScenario, name } = action.payload

			return {
				...state,
				selectedProjectScenario: {
					name: name,
					data: selectedProjectScenario
				},
				quantity: selectedProjectScenario.quantity
			}
		}

		case UPLOAD_START_LOADING: {
			return {
				...state,
				loadingLifeCycle: action.payload
			}
		}
		case UPLOAD_PROJECT_STANDART_COST_CHECKBOX: {
			const { isStandardCostBoxChecked } = action.payload
			return {
				...state,
				isStandardCostBoxChecked
			}
		}

		case UPLOAD_PROJECT_FILTER_ALERT_CLOSED:
			return {
				...state,
				showFilterFieldsAlert: false
			}

		case UPLOAD_PROJECT_FILTER_FEATURES_ALERT_CONFIRMED:
			return { ...state, showFilterFieldsAlert: true }
		case PROJECT_NAME_CHANGED:
			return { ...state, projectName: action.payload }
		case CAD_NAME_CHANGED:
			return { ...state, CAD: action.payload }
		case ERP_NAME_CHANGED:
			return { ...state, ERP: action.payload }
		case PROJECT_APPLICATION_CHANGED:
			return { ...state, application: action.payload }
		case QUANTITY_CHANGED:
			return { ...state, quantity: action.payload }
		case DRAGGING_OVER_DROPZONE:
			return { ...state, draggingFiles: action.payload }

		case REMOVED_FILES: {
			let { formatType } = action.payload
			let filesList: any = []
			let error = null
			let typeSelector = state.showUnitTypeSelector
			switch (formatType) {
				case FormatType.threeD:
					filesList = state.filesToUpload.filter(
						(file: any) =>
							![PDF_FORMAT, ...METADATA_FORMAT].includes(
								file.name.split('.').pop().toLowerCase()
							)
					)
					if (filesList.length < state.filesToUpload?.length) {
						error = getString('PART_FORMAT_REMOVE_EXCEPT_CAD')
					}
					break

				case FormatType.pdf:
					filesList = state.filesToUpload.filter(
						(file: any) =>
							file.name.split('.').pop().toLowerCase() === PDF_FORMAT
					)
					if (filesList.length < state.filesToUpload?.length) {
						error = getString('PART_FORMAT_REMOVE_EXCEPT_PDF')
					}
					break

				case FormatType.threeDByPDF:
					filesList = state.filesToUpload.filter(
						(file: any) =>
							!METADATA_FORMAT.includes(
								file.name.split('.').pop().toLowerCase()
							)
					)
					if (filesList.length < state.filesToUpload?.length) {
						error = getString('PART_FORMAT_REMOVE_EXCEPT_PDF_CAD')
					}
					break

				case FormatType.metadata:
					filesList = state.filesToUpload.filter((file: any) =>
						METADATA_FORMAT.includes(file.name.split('.').pop().toLowerCase())
					)
					if (filesList.length < state.filesToUpload?.length) {
						error = getString('PART_FORMAT_REMOVE_EXCEPT_METADATA')
					}
					break

				default:
					typeSelector = false
			}

			return {
				...state,
				filesToUpload: filesList,
				totalFiles: filesList,
				filesWithMaterialAndQuantity:
					getFilesWithMaterialAndQuantity(filesList),
				draggingFiles: false,
				showUnitTypeSelector: typeSelector,
				error: null,
				enableUploadWithErrorMessage: false
			}
		}
		case ADDED_FILES:
			let {
				files,
				acceptedFileTypes,
				notSupportedUnitTypeFormats,
				selectedType,
				partsFileSizeLimit,
				rejectedFiles,
				allowNumberExtensionFeatureOn
			} = action.payload

			const is2dUpload = selectedType === KEY_2d
			const isMetaData = selectedType === METADATA

			acceptedFileTypes = getAcceptedFileFormats(
				is2dUpload,
				isMetaData,
				state.radioButtonSelected,
				acceptedFileTypes
			)
			acceptedFileTypes = acceptedFileTypes.map((format: any) =>
				format.substr(1).toLowerCase()
			)
			const acceptedValuesStr = acceptedFileTypes
				.reduce(
					(accumulator: any, currentValue: any) =>
						`${accumulator}, ${currentValue}`,
					''
				)
				.substring(1)

			const filesAlreadyExists = state?.totalFiles.filter((file: any) => {
				//check if same file was already added
				return files.find((payloadFile: any) => file.name === payloadFile.name)
			})

			const filesDontMatch = files.filter((payloadFile: any) => {
				const extension = getExtensionFromFileName(
					payloadFile.name,
					allowNumberExtensionFeatureOn
				)
				return !acceptedFileTypes.includes(extension.toLowerCase())
			})

			const errorMessage = fileFormatError(isMetaData, is2dUpload)

			// if some file doesn't match or rejected
			if (filesDontMatch.length > 0 || rejectedFiles?.length > 0) {
				return {
					...state,
					draggingFiles: false,
					error: `${errorMessage} ${acceptedValuesStr}`,
					enableUploadWithErrorMessage: true
				}
			}

			// if some file already exist
			if (filesAlreadyExists.length > 0) {
				const filesNotExists = files.filter((f: any) => {
					return !filesAlreadyExists.find((fae: any) => fae.name === f.name)
				})

				if (!filesNotExists.length) {
					return {
						...state,
						draggingFiles: false,
						error: NO_FOLDER_ERROR,
						enableUploadWithErrorMessage: true
					}
				} else {
					files = filesNotExists
				}
			}

			for (let file of files) {
				const fileName = file.name
				const extension = getExtensionFromFileName(
					fileName,
					allowNumberExtensionFeatureOn
				)

				if (!extension) {
					return {
						...state,
						draggingFiles: false,
						error: NOT_FOLDER_ERROR,
						enableUploadWithErrorMessage: true
					}
				}

				// Blocking upload files by size limit.
				if (
					partsFileSizeLimit > 0 &&
					file.size / conversionFactorBytesToMB > partsFileSizeLimit
				) {
					return {
						...state,
						draggingFiles: false,
						error: getString('MAXIMUM_FILE_SIZE_ERROR').format(
							partsFileSizeLimit
						),
						enableUploadWithErrorMessage: true
					}
				}

				if (!acceptedFileTypes.includes(extension.toLowerCase())) {
					const errorMessage = fileFormatError(isMetaData, is2dUpload)

					return {
						...state,
						draggingFiles: false,
						error: `${errorMessage} ${acceptedValuesStr}`,
						enableUploadWithErrorMessage: true
					}
				}
			}

			const filesToUpload = [...state.filesToUpload, ...files]

			let errorUnit = state.errorUnit
			const showUnitTypeSelector = shouldShowUnitTypeSelector(
				filesToUpload,
				notSupportedUnitTypeFormats,
				allowNumberExtensionFeatureOn
			)

			if (showUnitTypeSelector && isEmpty(state.unitType)) {
				errorUnit = true
			}

			return {
				...state,
				filesToUpload,
				totalFiles: [...state.totalFiles, ...files],
				draggingFiles: false,
				error: null,
				enableUploadWithErrorMessage: false,
				errorUnit,
				showUnitTypeSelector,
				filesWithMaterialAndQuantity: [
					...state.filesWithMaterialAndQuantity,
					...getFilesWithMaterialAndQuantity(files)
				]
			}

		case REMOVE_ERROR_FROM_DROP_ZONE:
			return {
				...state,
				error: null
			}
		case CREATE_PROJECT:
			return {
				...state,
				loading: true,
				error: null,
				enableUploadWithErrorMessage: false
			}
		case CREATE_PROJECT_SUCCESS: {
			const { projectId, quickProject, files, bomFile } = action.payload
			return {
				...state,
				projectName: '',
				projectId,
				quickProject,
				filesToUpload: files,
				totalFiles: files,
				pollerStarted: false,
				loading: false,
				uploading: true,
				error: null,
				errorUnit: false,
				quickProjectFailed: false,
				enableUploadWithErrorMessage: false,
				bomFile
			}
		}
		case CREATE_PROJECT_FAILED:
			return {
				...state,
				error: action.payload,
				loading: false,
				enableUploadWithErrorMessage: false
			}
		case UNIT_TYPE_CREATE_PROJECT_FAILED:
			return { ...state, errorUnit: true, loading: false }
		case BEGIN_UPLOAD:
			return { ...state, uploading: true }
		case UPLOAD_FAILED_FILES:
			return {
				...state,
				filesFailed: [],
				uploadAttempts: state.uploadAttempts + 1,
				filesToUpload: [...state.filesToUpload, ...state.filesFailed]
			}
		case UPLOAD_FAILED:
			return {
				...state,
				error: action.payload,
				enableUploadWithErrorMessage: false
			}
		case UPLOAD_COMPLETE:
			return { ...INITIAL_STATE }
		case UPLOAD_FILE_COMPLETE:
			return handleUploadFile(state, action)
		case UPLOAD_UNIT_TYPE_CHANGED:
			return { ...state, unitType: action.payload, errorUnit: false }
		case USER_SUBSCRIPTION_REQUEST_LOADING:
			return { ...state, userSubscriptionRequestLoading: true }
		case USER_SUBSCRIPTION_REQUEST_SUCCEED:
			return {
				...state,
				connectCastorPopup: false,
				userSubscriptionRequestLoading: false
			}
		case MATERIAL_RADIO_BUTTON_VALUE_CHANGED_UPLOAD_PROJ: {
			const { value } = action.payload
			return {
				...state,
				materialChosen: null,
				amMaterialChosen: null,
				radioButtonSelected: value,
				fileInputKey: Date.now() //force the file input to rerender
			}
		}
		case MATERIAL_VALUE_CHANGED_UPLOAD_PROJ: {
			const { material } = action.payload
			return {
				...state,
				materialChosen: material,
				BOMFile: null
			}
		}
		case BOM_SELECTED_UPLOAD_PROJ:
			const BOMFile = action.payload
			return {
				...state,
				BOMFile,
				totalFiles: [...state.totalFiles, BOMFile],
				projectStandardCostError: false
			}
		case BOM_REMOVED_UPLOAD_PROJ: {
			// to remove only BOM file
			const totalFiles = state.totalFiles.filter(
				(file: File) => file.name !== state.BOMFile?.name
			)

			return {
				...state,
				BOMFile: null,
				totalFiles,
				projectStandardCostError: !state.projectStandardCost
			}
		}
		case QUICK_PROJECT_POLLER_STARTED:
			return { ...state, pollerStarted: true }
		case QUICK_PROJECT_CONNECTION_ERROR_FAILED:
			return { ...state, quickProjectConnectionError: true }
		case REMOVE_FILE_FROM_UPLOAD_DROPZONE: {
			const {
				fileToDelete,
				notSupportedUnitTypeFormats,
				allowNumberExtensionFeatureOn
			} = action.payload
			const totalFiles = state.totalFiles.filter(
				(file: any) => !fileMatch(file, fileToDelete)
			)
			const filesToUpload = state.filesToUpload.filter(
				(file: any) => !fileMatch(file, fileToDelete)
			)
			const filesWithMaterialAndQuantity =
				state.filesWithMaterialAndQuantity.filter(
					(file: IFileWithMaterialAndQuantity) =>
						file.name !== fileToDelete.name
				)

			return {
				...state,
				totalFiles,
				filesToUpload,
				showUnitTypeSelector: shouldShowUnitTypeSelector(
					filesToUpload,
					notSupportedUnitTypeFormats,
					allowNumberExtensionFeatureOn
				),
				filesWithMaterialAndQuantity
			}
		}

		case UPLOAD_PROJECT_TOLERANCE_TOGGLED:
			return {
				...state,
				toleranceIncluded: !state.toleranceIncluded
			}

		case UPLOAD_PROJECT_TOLERANCE_CHANGED:
			const { customToleranceValue } = action.payload
			return {
				...state,
				customToleranceValue
			}
		case MATERIAL_TYPE_CHANGED_IN_MATERIAL_SELECTOR:
		case MATERIAL_CATEGORY_CHANGED_IN_MATERIAL_SELECTOR:
			return {
				...state,
				toleranceIncluded: state.toleranceIncluded
			}

		case PRINTER_MATERIAL_TYPE_CHANGED_IN_MATERIAL_SELECTOR: //TODO:SHLOMY
		case PRINTER_MATERIAL_CATEGORY_CHANGED_IN_MATERIAL_SELECTOR:
			return {
				...state,
				toleranceIncluded: state.toleranceIncluded
			}

		case HIDE_TRIAL_USER_UPLOAD_PROJECT_POPUP: {
			return {
				...state,
				trialUserPopupShown: false
			}
		}

		case UPDATE_TRIAL_USER_UPLOAD_PROJECT_POPUP: {
			const { trialUserPopupErrorMessage } = action.payload
			return {
				...state,
				trialUserPopupErrorMessage
			}
		}

		case UPLOAD_PROJECT_EARLY_PRODUCTION_CHANGE: {
			const productionCostValue = Number(action.payload)
			const isError = productionCostValue <= 0 && !state.BOMFile
			return {
				...state,
				projectStandardCostError: isError,
				projectStandardCost: productionCostValue.toString()
			}
		}

		case GET_USER_REMAINING_PARTS: {
			const { userRemainingPart, userExpirationDate, isAdmin } = action.payload

			return {
				...state,
				userRemainingPart,
				userDateExpired: hasUserDateExpired(userExpirationDate, isAdmin)
			}
		}

		case UPLOAD_PROJECT_MANUFACTURING_CHANGE: {
			return {
				...state,
				manufactureMethodError: isEmpty(action.payload),
				manufactureMethod: action.payload
			}
		}

		case UPLOAD_PROJECT_AM_VALUE_CHANGE: {
			return {
				...state,
				amValue: action.payload
			}
		}

		case FILE_MATERIAL_OR_QUANTITY_CHANGED: {
			const filesWithMaterialAndQuantity = action.payload

			return {
				...state,
				filesWithMaterialAndQuantity
			}
		}

		case ADDITIONAL_NOTES_CHANGED: {
			const additionalNotes = action.payload

			return {
				...state,
				additionalNotes
			}
		}

		case UNIT_TYPE_CREATE_PROJECT_RESET: {
			return {
				...state,
				errorUnit: false
			}
		}

		case SELECT_PROJECT_ORGANIZATION: {
			const {
				organizationId,
				unitType,
				uploadProjectSelectedRadioButton,
				defaultProjectScenario,
				projectSettingsScenariosOn,
				chainBenefitOn,
				reset
			} = action.payload
			const prevState = reset ? INITIAL_STATE : state
			return {
				...prevState,
				projectOrganizationId: organizationId,
				unitType: unitType || state.unitType,
				...(uploadProjectSelectedRadioButton &&
				projectSettingsScenariosOn &&
				chainBenefitOn
					? {
							selectedProjectScenario: {
								name: defaultProjectScenario[
									uploadProjectSelectedRadioButton - 1
								]?.key,
								data: defaultProjectScenario[
									uploadProjectSelectedRadioButton - 1
								]
							},
							quantity:
								defaultProjectScenario[uploadProjectSelectedRadioButton - 1]
									?.quantity
					  }
					: {}),
				projectSettingsScenario:
					defaultProjectScenario || state.projectSettingsScenario
			}
		}
		case RESET_SETTINGS_STATE: {
			return INITIAL_STATE
		}

		case UPLOAD_CLEAR_STATUS: {
			return {
				...state,
				pollerStarted: false,
				quickProjectConnectionError: false
			}
		}

		default:
			return state
	}
}

export default uploadProjectReducer
