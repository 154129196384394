import React, {
	FC,
	memo,
	useCallback,
	useEffect,
	useRef,
	useState
} from 'react'

import { isEqual } from 'lodash'
import { MyX3DViewer } from 'x3d-viewer'

import { useConfig } from './ViewerWrapperService'
import {
	D_KEY_CODES,
	getCharCode,
	SPACE_KEY_CODES
} from 'Services/getKeyCodesService'
import { Part } from 'Services/models/IPart'
import { PartPrintIssue, PrintIssueId } from 'Services/models/PartPrintIssue'

import './ViewerWrapper.scss'

interface IProps {
	src: string
	axis?: string
	sliderValue?: number | string
	clipPlane?: boolean
	solution: any
	part: Part
	showOnlyPrintIssue?: PrintIssueId
	configurationPrintIssues: PartPrintIssue[]
	isSurfaceMachiningOn: boolean
	onViewerModelError: Function
	onCoordinatesChange?: Function
	setXYZValue?: (x: number, y: number, z: number) => void
	setManualChange?: Function
	manualChange?: boolean
	showOrientation?: boolean
	showTray?: boolean
	showCylinder?: boolean
	xAngle?: number
	yAngle?: number
	zAngle?: number
	showXYZ?: boolean
	customConfig?: Record<string, any>
}

const ViewerWrapper: FC<IProps> = ({
	src,
	solution,
	part,
	showOnlyPrintIssue,
	configurationPrintIssues,
	isSurfaceMachiningOn,
	axis,
	sliderValue,
	clipPlane,
	onViewerModelError,
	setXYZValue,
	setManualChange,
	showTray,
	showOrientation,
	manualChange,
	showCylinder = false,
	xAngle,
	yAngle,
	zAngle,
	showXYZ,
	customConfig = {}
}) => {
	// it's needed for some reason... without it, we will have some problems :(
	const [active, setActive] = useState<any>(null)
	const [clip, setClip] = useState<any>(false)
	const [over, setOver] = useState(null)
	const prevSrc = useRef<string>()

	useEffect(() => {
		if (!isEqual(src, prevSrc.current)) {
			prevSrc.current = src
		}
	}, [src])

	useEffect(() => {
		if (clip !== clipPlane) {
			setClip(clipPlane)
		}
	}, [clipPlane, clip])

	const config = useConfig(
		src,
		part,
		configurationPrintIssues,
		isSurfaceMachiningOn,
		showOnlyPrintIssue,
		solution
	)
	const clickHandler = useCallback((payload: any) => {
		setActive(payload)
	}, [])
	const mouseoverHandler = useCallback((payload: any) => {
		setOver(payload.id)
	}, [])
	const mouseoutHandler = useCallback((payload: any) => {
		setOver(null)
	}, [])

	useEffect(() => {
		document.addEventListener('keydown', keyBoardPressFunction, false)
		return () => {
			document.removeEventListener('keydown', keyBoardPressFunction, false)
		}
	}, [])

	const keyBoardPressFunction = (e: any) => {
		if (
			SPACE_KEY_CODES.includes(getCharCode(e as KeyboardEvent)) ||
			D_KEY_CODES.includes(getCharCode(e as KeyboardEvent))
		) {
			e.preventDefault()
		}
	}

	// we need to rerender x3d viewer to reset it's state
	if (!isEqual(src, prevSrc.current) || clip !== clipPlane)
		return <div className="content"></div>

	return (
		<>
			<div className="content" data-qa="data-qa-3d-model">
				<MyX3DViewer
					axis={axis}
					sliderValue={sliderValue}
					clipPlane={clipPlane}
					conf={{ ...config, showXYZ: showXYZ, ...customConfig }}
					mouseoutHandler={mouseoutHandler}
					mouseoverHandler={mouseoverHandler}
					clickHandler={clickHandler}
					errorHandler={(error: any) => onViewerModelError(error)}
					timeoutSecs={60}
					showDefaultViewpoints={false}
					setXYZValue={setXYZValue}
					setManualChange={setManualChange}
					manualChange={manualChange}
					showOrientation={showOrientation}
					showTray={showTray}
					showCylinder={showCylinder}
					xAngle={xAngle}
					yAngle={yAngle}
					zAngle={zAngle}
				/>
			</div>
		</>
	)
}

export default memo(ViewerWrapper)
