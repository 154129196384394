import { del, post, put } from '../../Network'

export const updateProject = (
	projectId: string,
	project: any,
	isBundle: boolean
) => {
	const params = { project, isBundle }
	return put(`/projects/admin/${projectId}`, params)
}

export const publishClusters = (projectId: string) =>
	put(`/unification/admin/publish/all/${projectId}`)

export const publishCluster = (clustertId: number) =>
	put(`/unification/admin/publish/${clustertId}`)

export const rejectCluster = (clustertId: number) =>
	put(`/unification/admin/reject/${clustertId}`)

export const removeCluster = (clustertId: number) =>
	del(`/unification/admin/${clustertId}`)

export const removeClusterParts = (
	clustertId: number,
	transformationMatrixHash: string,
	partExternalId: string
) => {
	const params = { transformationMatrixHash, partExternalId }
	return post(`/unification/removeParts/${clustertId}`, params)
}
