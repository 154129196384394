export const sortArrayOfObjectsInAlphabeticalOrder = <T extends Array<any>>(
	objectsArray: T,
	property: string
): T => {
	return objectsArray.sort((a, b) => {
		const aProperty = a[property]?.toLowerCase()
		const bProperty = b[property]?.toLowerCase()
		return aProperty < bProperty ? -1 : aProperty > bProperty ? 1 : 0
	})
}

export const sortOriginalMaterials = (materialA: any, materialB: any) => {
	// sort by topPriority, and material.name alphabetically, ignore capital letters
	const priorityComparison = materialB.topPriority - materialA.topPriority
	if (priorityComparison !== 0) {
		return priorityComparison
	}
	const nameA = materialA.name?.toLowerCase()
	const nameB = materialB.name?.toLowerCase()
	if (nameA < nameB) {
		return -1
	}
	if (nameA > nameB) {
		return 1
	}
	return 0
}
