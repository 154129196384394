import React, { FC, useCallback, useEffect, useMemo } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { ConfigurationResultTypes } from '../ConfigurationResultTypes'
import { changeOrganizationToConfigure } from '../SolutionAnalysisActions'
import OrganizationSelector from 'Scenes/Components/OrganizationSelector'
import WithFeatureToggleHOC from 'Services/HOC/WithFeatureToggleHOC'
import { FeatureComponentId } from 'Services/models/Features'
import { IOrganization } from 'Services/models/IOrganization'
import { getString } from 'Services/Strings/StringService'
import { renderOrganizationValue } from 'Services/Utils/organizationTools'

type IProps = {
	configurationOrganizationId: number
	configurationId: number
	disabled: boolean
	configurationResultType: ConfigurationResultTypes
}

const ConfigurationOrganizationSelector: FC<IProps> = ({
	configurationOrganizationId,
	configurationId,
	disabled,
	configurationResultType
}) => {
	const { availableOrganizations } = useSelector(
		(state: RootStateOrAny) => state.user
	)
	const dispatch = useDispatch()

	const title = useMemo(
		() =>
			renderOrganizationValue(
				availableOrganizations,
				configurationOrganizationId
			),
		[availableOrganizations, configurationOrganizationId]
	)

	const renderValue = useCallback(
		(selected: unknown) =>
			renderOrganizationValue(availableOrganizations, selected as number),
		[availableOrganizations]
	)

	const handleOrganizationSelect = useCallback(
		(value: number) =>
			dispatch(
				changeOrganizationToConfigure(
					configurationId,
					value,
					false,
					configurationResultType
				)
			),
		[configurationId, configurationResultType, dispatch]
	)

	useEffect(() => {
		if (availableOrganizations.length && configurationOrganizationId) {
			const chosenOrganization = availableOrganizations?.find(
				(organization: IOrganization) =>
					organization.id === configurationOrganizationId
			)
			if (!chosenOrganization) {
				dispatch(
					changeOrganizationToConfigure(
						configurationId,
						availableOrganizations[0].id,
						false,
						configurationResultType
					)
				)
			}
		}
	}, [
		availableOrganizations,
		configurationId,
		configurationOrganizationId,
		configurationResultType,
		dispatch
	])

	return (
		<div className="solution-analysis--organization-selector">
			<label htmlFor="upload-with-organization">
				{getString('SOLUTION_CONFIGURATION_CALCULATE_WITH_SITE')}
			</label>
			<OrganizationSelector
				disabled={disabled}
				id="upload-with-organization"
				className="customize-settings--header--organization--select-field"
				value={configurationOrganizationId}
				title={title}
				renderValue={renderValue}
				handleOrganizationSelect={handleOrganizationSelect}
			/>
		</div>
	)
}

export default WithFeatureToggleHOC(
	ConfigurationOrganizationSelector,
	FeatureComponentId.CUSTOMIZE_ORGANIZATIONS
)
