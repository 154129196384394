import React, { FC, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { stopPCPoller } from 'global actions'
import { NotificationSilentType } from 'Scenes/Components/PushNotification/PushNotificationEnum'
import { usePushNotificationSilentEvent } from 'Scenes/Components/PushNotification/PushNotificationHooks'

const AwaitingClusterAnalysis: FC = () => {
	const { projectId } = useParams<{ projectId: string }>()

	usePushNotificationSilentEvent(
		projectId,
		NotificationSilentType.NOTIFICATION_PC_SEARCHING
	)

	useEffect(() => {
		return () => stopPCPoller()
	}, [])

	return <div />
}

export default AwaitingClusterAnalysis
