import React, { FC, memo, useEffect } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import ItemsWithPicturesList from '../../Components/ItemsWithPicturesList'
import DetailsPopup from 'Scenes/Components/DetailsPopup'
import Loader from 'Scenes/Loader/Loader'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { allClustersView } from 'Services/routeFuncs'
import { getString } from 'Services/Strings/StringService'

interface PrintableClustersOverviewProps {
	clusterItemsWithPictures: any[]
	projectIdentifier: string
	setupClustersOverview: Function
	projectId: string
}

const PrintableClustersOverview: FC<PrintableClustersOverviewProps> = ({
	clusterItemsWithPictures,
	projectIdentifier,
	setupClustersOverview
}) => {
	const { projectId: projectIdParams } = useParams() as {
		projectId: string
	}
	const { loading } = useSelector(
		(state: RootStateOrAny) => state.PrintableClustersOverviewReducer
	)
	const disabledUnification = !Feature.isFeatureActive(
		FeatureComponentId.UNIFICATION
	)

	useEffect(() => {
		setupClustersOverview(projectIdParams)
	}, [projectIdParams, setupClustersOverview])

	return (
		<div className="printable-clusters--wrapper">
			<Loader load={loading} wrapperClassName="printable-clusters--loader" />
			<DetailsPopup
				isHover={disabledUnification}
				data={getString('LIGHT_USER_BUTTONS_INFORMATION')}
				popperDirection="top-start"
				popperClassName="info-box-wrapper details-popup--contact-us"
				popperContactClassName="info-box-data"
				targetClassName="all-clusters--popup"
			>
				<ItemsWithPicturesList
					items={clusterItemsWithPictures}
					seeMoreURL={allClustersView(projectIdentifier)}
					isCluster={true}
					disabled={disabledUnification}
				/>
			</DetailsPopup>
		</div>
	)
}

export default memo(PrintableClustersOverview)
