import { addCustomOrientation } from 'Services/Network'

export const createCustomOrientation = async (
	configurationId: number,
	partId: number,
	trayNormalVector: number[],
	rotationAngle: Record<string, number>
) => {
	return await addCustomOrientation(configurationId, partId, trayNormalVector, rotationAngle)
}
