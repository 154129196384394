import React, { memo, useEffect, useState } from 'react'

import FormControlLabel from '@material-ui/core/FormControlLabel'

import { useShowNoPresentationMessage } from './GeometryAnalysisReviewAndFixesService'
import CastorCheckbox from 'Scenes/Components/CastorCheckbox'
import ClipPlane from 'Scenes/Components/ClipPlane'
import {
	defaultAxisValue,
	defaultSliderValue
} from 'Scenes/Components/ClipPlane/constants'
import RatePart from 'Scenes/Components/RatePart'
import ViewerWrapper from 'Scenes/Components/ViewerWrapper/ViewerWrapper'
import {
	D_KEY_CODES,
	getCharCode,
	SPACE_KEY_CODES
} from 'Services/getKeyCodesService'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { Part } from 'Services/models/IPart'
import {
	PartPrintIssue,
	PrintIssue,
	PrintIssueId
} from 'Services/models/PartPrintIssue'
import { getPrintIssueById } from 'Services/PrintIssueService'
import { getString } from 'Services/Strings/StringService'

const LabelFormControl: any = FormControlLabel

interface Props {
	partImageUrl: string
	showOnlyPrintIssue?: PrintIssueId
	configurationPrintIssues: PartPrintIssue[]
	printIssues: PrintIssue[]
	orientationVector: number[]
	onViewerModelError: Function
	solution: any
	part: Part
	isSurfaceMachiningOn: boolean
	onCheckBoxSelected: (checked: boolean) => void
}

const GeometryAnalysisReviewAndFixesContentPartdisplay: React.FC<Props> = ({
	partImageUrl,
	showOnlyPrintIssue,
	onViewerModelError,
	configurationPrintIssues,
	orientationVector,
	printIssues,
	solution,
	part,
	isSurfaceMachiningOn,
	onCheckBoxSelected
}) => {
	const [axis, setAxis] = useState(defaultAxisValue)
	const [showSlider, setShowSlider] = useState(false)
	const [disabledSlider, setDisabledSlider] = useState(true)
	const [sliderValue, setSliderValue] = useState<number | string>(
		defaultSliderValue
	)
	const isClipPlaneOn = Feature.isFeatureOn(
		FeatureComponentId.CLIP_PLANE_GEOMETRY_ANALYSIS
	)
	const [checkBoxSelected, setCheckBoxSelected] = useState(true)
	const [selectedPrintIssue, setSelectedPrintIssue] = useState<
		PartPrintIssue | undefined
	>()
	const showNoPresentationMessage = useShowNoPresentationMessage(
		printIssues,
		showOnlyPrintIssue
	)

	useEffect(() => {
		if (!!showOnlyPrintIssue) {
			setCheckBoxSelected(false)
		}
		setSelectedPrintIssue(
			getPrintIssueById(
				showOnlyPrintIssue as PrintIssueId,
				configurationPrintIssues,
				orientationVector
			)
		)
	}, [showOnlyPrintIssue])

	const renderNoPresentationIssue = () => {
		if (showNoPresentationMessage) {
			return (
				<div className="geometry-analysis--content--part-display--viewer--non-present-issue">
					{getString('GEOMETRY_ANALYSIS_NO_PRESENTATION_DESCRIPTION')}
				</div>
			)
		}
		return <div></div>
	}

	return (
		<div className="geometry-analysis--content--part-display">
			{!part.isDrawing && (
				<header className="geometry-analysis--content--part-display--header">
					<div>{getString('PART_DISPLAY')}</div>
					<LabelFormControl
						control={
							<CastorCheckbox
								checked={checkBoxSelected}
								className="geometry-analysis--content--part-display--header--checkbox"
							/>
						}
						label={getString('GEOMETRY_ANALYSIS_DISPLAY_ALL_ISSUES')}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
							setCheckBoxSelected(event.target.checked)
							onCheckBoxSelected(event.target.checked)
						}}
						classes={{
							root: 'geometry-analysis--content--part-display--header--checkbox--label',
							label:
								'geometry-analysis--content--part-display--header--checkbox--label--text'
						}}
					/>
				</header>
			)}
			<div className="geometry-analysis--content--part-display--viewer">
				<ViewerWrapper
					axis={axis}
					sliderValue={sliderValue}
					clipPlane={showSlider}
					src={partImageUrl}
					solution={solution}
					part={part}
					showOnlyPrintIssue={showOnlyPrintIssue}
					configurationPrintIssues={configurationPrintIssues}
					onViewerModelError={onViewerModelError}
					isSurfaceMachiningOn={isSurfaceMachiningOn}
				/>
				{!!part.isDrawing && (
					<RatePart
						partId={part?.id}
						projectId={part?.projectId}
						rate={part?.rate}
					/>
				)}
				{isClipPlaneOn && (
					<ClipPlane
						axis={axis}
						setAxis={setAxis}
						disabledSlider={disabledSlider}
						setDisabledSlider={setDisabledSlider}
						showSlider={showSlider}
						setShowSlider={setShowSlider}
						sliderValue={sliderValue}
						setSliderValue={setSliderValue}
					/>
				)}
				{renderNoPresentationIssue()}
			</div>
		</div>
	)
}

export default memo(GeometryAnalysisReviewAndFixesContentPartdisplay)
