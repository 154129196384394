import { toFixedOnlyIfNeeded } from './global/toFixedOnlyIfNeeded'
import { dynamicRound } from './Utils/numberService'

export enum UnitSystem {
	metric = 'metric',
	imperial = 'imperial'
}

export enum WeightUnit {
	kg = 'kg',
	lb = 'lb',
	g = 'g'
}

export enum TemperatureUnit {
	C = 'C',
	F = 'F'
}

export enum LengthUnit {
	mm = 'mm',
	cm = 'cm',
	inch = 'inch',
	meter = 'meter',
	feet = 'feet'
}

export enum DistanceUnit {
	km = 'km',
	mi = 'mi'
}

export class UnitsConversionService {
	public inputSystem: UnitSystem = UnitSystem.metric
	public outputSystem: UnitSystem = UnitSystem.imperial

	constructor(inputSystem?: UnitSystem, outputSystem?: UnitSystem) {
		this.inputSystem = inputSystem || this.inputSystem
		this.outputSystem = outputSystem || this.outputSystem
	}

	private convertPricePerKgToOutputUnit(price: number) {
		switch (this.outputSystem) {
			case UnitSystem.imperial: {
				return price / 2.20462
			}
			case UnitSystem.metric:
			default: {
				return +price
			}
		}
	}

	private convertPricePerLBToOutputUnit(price: number) {
		switch (this.outputSystem) {
			case UnitSystem.metric: {
				return price * 2.20462
			}
			case UnitSystem.imperial:
			default: {
				return +price
			}
		}
	}

	private convertCelsiusToOutputUnit(temperature: number) {
		switch (this.outputSystem) {
			case UnitSystem.imperial: {
				return (temperature * 9) / 5 + 32
			}
			case UnitSystem.metric:
			default: {
				return +temperature
			}
		}
	}

	private convertFahrenheitToOutputUnit(temperature: number) {
		switch (this.outputSystem) {
			case UnitSystem.metric: {
				return (temperature - 32) * (5 / 9)
			}
			case UnitSystem.imperial:
			default: {
				return +temperature
			}
		}
	}

	private convertMMToOutputUnit(length: number) {
		switch (this.outputSystem) {
			case UnitSystem.imperial: {
				return length / 25.4
			}
			case UnitSystem.metric:
			default: {
				return +length
			}
		}
	}

	private convertInchToOutputUnit(length: number) {
		switch (this.outputSystem) {
			case UnitSystem.metric: {
				return length * 25.4
			}
			case UnitSystem.imperial:
			default: {
				return +length
			}
		}
	}

	private convertCubicMMToOutputUnit(volume: number) {
		switch (this.outputSystem) {
			case UnitSystem.imperial: {
				return volume * 0.0000610237
			}
			case UnitSystem.metric:
			default: {
				return +volume
			}
		}
	}

	private convertCubicInchToOutputUnit(volume: number) {
		switch (this.outputSystem) {
			case UnitSystem.metric: {
				return volume / 0.0000610237
			}
			case UnitSystem.imperial:
			default: {
				return +volume
			}
		}
	}

	private convertSquareMMToOutputUnit(area: number): number {
		switch (this.outputSystem) {
			case UnitSystem.imperial: {
				return area / 645.16
			}
			case UnitSystem.metric:
			default: {
				return +area
			}
		}
	}

	private convertSquareInchToOutputUnit(area: number) {
		switch (this.outputSystem) {
			case UnitSystem.metric: {
				return area * 645.16
			}
			case UnitSystem.imperial:
			default: {
				return +area
			}
		}
	}

	private convertKgOutputUnit(weight: number) {
		switch (this.outputSystem) {
			case UnitSystem.imperial: {
				return weight * 2.20462
			}
			case UnitSystem.metric:
			default: {
				return +weight
			}
		}
	}

	private convertLBToOutputUnit(weight: number) {
		switch (this.outputSystem) {
			case UnitSystem.metric: {
				return weight / 2.20462
			}
			case UnitSystem.imperial:
			default: {
				return +weight
			}
		}
	}

	private convertKmToOutputUnit(distance: number) {
		switch (this.outputSystem) {
			case UnitSystem.imperial: {
				return distance * 0.621371
			}
			case UnitSystem.metric:
			default: {
				return +distance
			}
		}
	}

	private convertMilesToOutputUnit(distance: number) {
		switch (this.outputSystem) {
			case UnitSystem.metric: {
				return distance / 0.621371
			}
			case UnitSystem.imperial:
			default: {
				return +distance
			}
		}
	}

	public convertPricePerWeightUnit(price: number, fixOutput: boolean = true) {
		let outputPrice

		switch (this.inputSystem) {
			case UnitSystem.metric: {
				outputPrice = this.convertPricePerKgToOutputUnit(price)
				break
			}
			case UnitSystem.imperial: {
				outputPrice = this.convertPricePerLBToOutputUnit(price)
				break
			}
		}

		if (fixOutput) {
			outputPrice = toFixedOnlyIfNeeded(outputPrice)
		}

		return outputPrice
	}

	public convertTemperature(temperature: number, fixOutput: boolean = true) {
		let outputTemperature

		switch (this.inputSystem) {
			case UnitSystem.metric: {
				outputTemperature = this.convertCelsiusToOutputUnit(temperature)
				break
			}
			case UnitSystem.imperial: {
				outputTemperature = this.convertFahrenheitToOutputUnit(temperature)
				break
			}
		}

		if (fixOutput) {
			outputTemperature = toFixedOnlyIfNeeded(outputTemperature)
		}
		return outputTemperature
	}

	public convertLength(length: number, fixOutput: boolean = true) {
		let outputLength

		switch (this.inputSystem) {
			case UnitSystem.metric: {
				outputLength = this.convertMMToOutputUnit(length)
				break
			}
			case UnitSystem.imperial: {
				outputLength = this.convertInchToOutputUnit(length)
				break
			}
		}

		if (fixOutput) {
			outputLength = dynamicRound(outputLength)
		}
		return outputLength
	}

	public convertVolume(volume: number, fixOutput: boolean = true) {
		let outputVolume

		switch (this.inputSystem) {
			case UnitSystem.metric: {
				outputVolume = this.convertCubicMMToOutputUnit(volume)
				break
			}
			case UnitSystem.imperial: {
				outputVolume = this.convertCubicInchToOutputUnit(volume)
				break
			}
		}

		if (fixOutput) {
			outputVolume = toFixedOnlyIfNeeded(outputVolume)
		}
		return outputVolume
	}

	public convertArea(area: number, fixOutput: boolean = true) {
		let outputArea

		switch (this.inputSystem) {
			case UnitSystem.metric: {
				outputArea = this.convertSquareMMToOutputUnit(area)
				break
			}
			case UnitSystem.imperial: {
				outputArea = this.convertSquareInchToOutputUnit(area)
				break
			}
		}

		if (fixOutput) {
			outputArea = toFixedOnlyIfNeeded(outputArea)
		}
		return outputArea
	}

	public convertWeight(weight: number, fixOutput: boolean = true) {
		let outputWeight

		switch (this.inputSystem) {
			case UnitSystem.metric: {
				outputWeight = this.convertKgOutputUnit(weight)
				break
			}
			case UnitSystem.imperial: {
				outputWeight = this.convertLBToOutputUnit(weight)
				break
			}
		}

		if (fixOutput) {
			outputWeight = toFixedOnlyIfNeeded(outputWeight)
		}
		return outputWeight
	}

	public convertDistance(distance: number, fixOutput: boolean = true) {
		let outputDistance

		switch (this.inputSystem) {
			case UnitSystem.metric: {
				outputDistance = this.convertKmToOutputUnit(distance)
				break
			}
			case UnitSystem.imperial: {
				outputDistance = this.convertMilesToOutputUnit(distance)
				break
			}
		}

		if (fixOutput) {
			outputDistance = toFixedOnlyIfNeeded(outputDistance)
		}
		return outputDistance
	}
}
