import React, { FC, useCallback, useEffect, useMemo } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { InputLabel } from '@material-ui/core'
import { isEmpty } from 'lodash'

import * as ProfileActions from './ProfileActions'
import {
	ERROR,
	NAV_TITLE_USER_PROFILE,
	PROFILE_FORM_COMPANY_LABEL,
	PROFILE_FORM_EMAIL_LABEL,
	PROFILE_FORM_FULL_NAME_LABEL,
	PROFILE_FORM_HEADER,
	PROFILE_FORM_SITE_LABEL,
	SUCCESS,
	UPDATE_USER_PROFILE,
	USER_ADDRESS,
	USER_PROFILE_ADDRESS_ALERT,
	USER_PROFILE_ADDRESS_PLACEHOLDER,
	USER_PROFILE_COMPANY_ALERT,
	USER_PROFILE_EMAIL_ALERT,
	USER_PROFILE_NAME_ALERT
} from '../../../Services/Strings'
import ButtonWithLoader from '../../Components/ButtonWithLoader'
import CastorForm from '../../Components/CastorForm/CastorForm'
import CastorLocationDropdown from '../../Components/CastorLocationSearchInput/CastorLocationDropdown/CastorLocationDropdown'
import CastorLocationSearchInput from '../../Components/CastorLocationSearchInput/CastorLocationSearchInput'
import NavBarAndMaterial from '../../Components/NavBarAndMaterial'
import {
	CustomInput,
	Danger
} from '../../Components/thirdParty/CreativeTim/components'
import { UserRole } from '../UserRole.enum'
import OrganizationSelector from 'Scenes/Components/OrganizationSelector'
import customInputStyle from 'Scenes/Components/thirdParty/CreativeTim/variables/styles/customInputStyle'
import usePrevious from 'Services/CustomHooks/usePrevious'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { renderOrganizationValue } from 'Services/Utils/organizationTools'

import './index.scss'
import styles from './profile.css'

const Profile: FC = () => {
	const {
		userDetails,
		availableOrganizations,
		organizationDetails,
		isAdminByRole,
		roles
	} = useSelector((state: RootStateOrAny) => state.user)
	const { isOnPrem } = useSelector(
		(state: RootStateOrAny) => state.GlobalReducer
	)
	const { validAddress, address, city, state, country, long, lat, zip_code } =
		useSelector((state: RootStateOrAny) => state.CastorLocationSearchReducer)
	const {
		userName,
		userEmail,
		userCompany,
		validUserName,
		validUserEmail,
		validUserCompany,
		userOrganization,
		validUserAddress,
		profileLoading,
		disableUploadButton
	} = useSelector((state: RootStateOrAny) => state.ProfileReducer)
	const dispatch = useDispatch()
	const prevUserDetails = usePrevious(userDetails)
	const prevValidAddress = usePrevious(validAddress)

	const customizeOrganizationsIsOn = Feature.isFeatureOn(
		FeatureComponentId.CUSTOMIZE_ORGANIZATIONS
	)
	const isSiteAdmin = roles?.includes(UserRole.SITE_ADMIN)

	useEffect(() => {
		if (isEmpty(prevUserDetails) && userDetails !== prevUserDetails) {
			dispatch(
				ProfileActions.setupProfileDetails(userDetails, organizationDetails)
			)
		}
		if (prevValidAddress !== validAddress) {
			dispatch(ProfileActions.onValidAddressChange(validAddress))
		}
	}, [
		dispatch,
		prevUserDetails,
		prevValidAddress,
		userDetails,
		validAddress,
		organizationDetails
	])

	const title = useMemo(
		() => renderOrganizationValue(availableOrganizations, userOrganization),
		[userOrganization, availableOrganizations]
	)

	const onUserNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(ProfileActions.onUserNameChange(event.target.value))
	}

	const onUserEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(ProfileActions.onUserEmailChange(event.target.value))
	}

	const onUserCompanyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(ProfileActions.onUserCompanyChange(event.target.value))
	}

	const onUserOrganizationChange = useCallback(
		(value: number) => {
			dispatch(
				ProfileActions.onUserOrganizationChange(
					value,
					organizationDetails,
					userDetails,
					userOrganization,
					isAdminByRole
				)
			)
		},
		[
			dispatch,
			isAdminByRole,
			organizationDetails,
			userDetails,
			userOrganization
		]
	)

	const renderAlert = (validationModel: string, alertText: string) => {
		if (validationModel !== ERROR) {
			return <div />
		}
		return <Danger>{alertText}</Danger>
	}

	const renderValue = useCallback(
		(selected: unknown) => {
			return renderOrganizationValue(availableOrganizations, selected as number)
		},
		[availableOrganizations]
	)

	const updateUserProfile = () => {
		const newUserDetails: Record<string, any> = {
			name: userName,
			email: userEmail,
			company: userCompany,
			formatted_address: address,
			city,
			state,
			country,
			long,
			lat,
			zip_code
		}
		if (customizeOrganizationsIsOn) {
			newUserDetails.organizationId = userOrganization
			if (userOrganization !== organizationDetails.id) {
				newUserDetails.prevOrganizationId = organizationDetails.id
				if (!isAdminByRole) {
					newUserDetails.organization_owner = false
				}
			}
		}
		dispatch(ProfileActions.updateUserProfile(newUserDetails))
	}

	const renderProfileFormContent = () => {
		return (
			<div style={{ padding: '15px' }}>
				<div>
					<CustomInput
						success={validUserName === SUCCESS}
						error={validUserName === ERROR}
						labelText={PROFILE_FORM_FULL_NAME_LABEL}
						formControlProps={{
							fullWidth: true
						}}
						inputProps={{
							onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
								onUserNameChange(event),
							type: 'text',
							value: userName
						}}
					/>
					{renderAlert(validUserName, USER_PROFILE_NAME_ALERT)}
				</div>

				<div>
					<CustomInput
						success={validUserEmail === SUCCESS}
						error={validUserEmail === ERROR}
						labelText={PROFILE_FORM_EMAIL_LABEL}
						formControlProps={{
							fullWidth: true
						}}
						inputProps={{
							onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
								onUserEmailChange(event),
							type: 'text',
							value: userEmail,
							disabled: true
						}}
					/>
					{renderAlert(validUserEmail, USER_PROFILE_EMAIL_ALERT)}
				</div>

				{customizeOrganizationsIsOn ? (
					<div>
						<InputLabel
							style={customInputStyle.labelRoot}
							className="profile--select-field--label"
							htmlFor="profile-organization"
						>
							{PROFILE_FORM_SITE_LABEL}
						</InputLabel>
						<OrganizationSelector
							value={+userOrganization}
							disabled={isSiteAdmin}
							title={title}
							className="profile--select-field"
							renderValue={renderValue}
							handleOrganizationSelect={onUserOrganizationChange}
						/>
					</div>
				) : (
					<div>
						<CustomInput
							success={validUserCompany === SUCCESS}
							error={validUserCompany === ERROR}
							labelText={PROFILE_FORM_COMPANY_LABEL}
							formControlProps={{
								fullWidth: true
							}}
							inputProps={{
								onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
									onUserCompanyChange(event),
								type: 'text',
								value: userCompany
							}}
						/>
						{renderAlert(validUserCompany, USER_PROFILE_COMPANY_ALERT)}
					</div>
				)}

				<div>
					{isOnPrem ? (
						<CastorLocationDropdown />
					) : (
						<>
							<CastorLocationSearchInput
								labelText={USER_ADDRESS}
								placeHolder={USER_PROFILE_ADDRESS_PLACEHOLDER}
							/>
							{renderAlert(validUserAddress, USER_PROFILE_ADDRESS_ALERT)}
						</>
					)}
				</div>

				<ButtonWithLoader
					style={styles.profileSubmitButtonStyle}
					loading={profileLoading}
					disabled={disableUploadButton}
					primary={true}
					onClick={updateUserProfile}
				>
					{UPDATE_USER_PROFILE}
				</ButtonWithLoader>
			</div>
		)
	}

	return (
		<NavBarAndMaterial title={NAV_TITLE_USER_PROFILE}>
			<CastorForm
				formTitle={PROFILE_FORM_HEADER}
				content={renderProfileFormContent()}
				superViewStyle={{ marginBottom: '155px' }}
			/>
		</NavBarAndMaterial>
	)
}

export default Profile
