import { DispatchProp } from 'react-redux'
import { AnyAction } from 'redux'

import {
	filterSkipAnalysisConfiguration,
	MainPartAnalysisService
} from './MainPartAnalysisService'
import { Action } from 'global actions/ActionModels'
import {
	CONFIGURATION_CALCULATED,
	DO_REFRESH_CONFIGURATIONS,
	HANDLE_LOADER
} from 'global actions/types'
import {
	ALTERNATIVE_SOLUTION_MAP_FILTER_CHANGE,
	CLUSTER_PARTS_FETCHED,
	CONFIGURATION_INHOUSE_ALERT_CANCELED,
	CONFIGURATION_INHOUSE_ALERT_WITH_MATERIAL,
	NEW_PAGE_VISITED,
	PART_ANALYSIS_CAM_EXISTENCE_CHANGE,
	PART_ANALYSIS_CAST_TOOLING_CHANGE,
	PART_ANALYSIS_INVESTMENT_CAST_TOOLING_CHANGE,
	PART_ANALYSIS_MOLD_PRICE_CHANGE,
	PART_ANALYSIS_SET_CURRENT_STEP,
	SOLUTION_CONFIGURATION_ADDED,
	SOLUTION_CONFIGURATION_SOLUTION_UPDATED,
	SOLUTION_CONFIGURE_PRESSED,
	SOLUTION_PRINTER_MATERIAL_CONFIGURE_PRESSED,
	SOLUTIONS_FETCHED,
	SOLUTIONS_FETCHED_CLEARED,
	SOLUTIONS_FETCHED_FORMATED,
	TOGGLE_PRINTING_ALERT
} from 'global actions/types/partAnalysisTypes'
import {
	TOUR_STARTED,
	TOUR_STEPS_UPDATED
} from 'global actions/types/takeATourTypes'
import { store } from 'index'
import { TourPages } from 'Scenes/Components/TakeATour/types'
import { UserRole } from 'Scenes/Home/UserRole.enum'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { IConfiguration } from 'Services/models/IConfiguration'
import {
	getPartClusterParts,
	getPartClusterSolutions,
	getPartSolutions
} from 'Services/Network/PartAnalysisNetwork'
import { UnitSystem } from 'Services/UnitsConversionService'

export const fetchSolutions = (
		partId: number,
		projectId: string,
		solutionsFetched: boolean,
		solutionsFetchCalled: boolean,
		clusterId: number,
		weightReductionProgressFromProject: boolean,
		feaId?: string,
		pageName?: string,
		tourPages?: TourPages
	): any => {
		return async (dispatch: any) => {
			if (pageName) {
				dispatch({
					type: NEW_PAGE_VISITED,
					payload: pageName
				})
			}
			let tourSteps: any[] = []
			if (tourPages) {
				tourSteps =
					tourPages.find(tourPage => tourPage.name === pageName)?.tourSteps ||
					[]
			}
			try {
				if (!solutionsFetched) {
					if (!solutionsFetchCalled) {
						dispatch({
							type: HANDLE_LOADER,
							payload: 1
						})

						dispatch({
							type: SOLUTIONS_FETCHED
						})

						const disableRibbonInfo = Feature.isFeatureOn(
							FeatureComponentId.DISABLE_RIBBON_INFORMATION
						)

						const mainPartAnalysis = new MainPartAnalysisService()

						const user = store.getState().user
						const isAdmin =
							user?.roles?.length > 0
								? user.roles.includes(UserRole.SUPER_ADMIN)
								: undefined
						if (partId) {
							await mainPartAnalysis.fetchPartConfigurations(
								partId,
								projectId,
								dispatch,
								tourSteps,
								weightReductionProgressFromProject,
								feaId,
								disableRibbonInfo,
								isAdmin
							)
						} else if (clusterId) {
							await mainPartAnalysis.fetchClusterConfiguration(
								clusterId,
								projectId,
								dispatch,
								tourSteps,
								weightReductionProgressFromProject,
								disableRibbonInfo,
								isAdmin
							)
						}

						dispatch({
							type: HANDLE_LOADER,
							payload: -1
						})

						dispatch({
							type: CONFIGURATION_CALCULATED
						})

						//start part poller
						await mainPartAnalysis.startPartPoller(
							partId || clusterId,
							dispatch,
							false,
							feaId
						)
					}
				} else {
					dispatch({
						type: SOLUTIONS_FETCHED_FORMATED
					})
				}
				dispatch({
					type: DO_REFRESH_CONFIGURATIONS,
					payload: { doRefreshConfigurations: false }
				})
			} catch (error: any) {
				console.error(error)
				dispatch({
					type: HANDLE_LOADER,
					payload: -1
				})
				dispatch({
					type: DO_REFRESH_CONFIGURATIONS,
					payload: { doRefreshConfigurations: false }
				})
			}
		}
	},
	fetchSkippedSolutions = (
		partId: number,
		feaId: string,
		configurations: Array<IConfiguration>,
		isCluster: boolean
	) => {
		return async (dispatch: DispatchProp<AnyAction>) => {
			try {
				const nonSolutionConfigIdsLength =
					filterSkipAnalysisConfiguration(configurations)

				if (!!nonSolutionConfigIdsLength) {
					const response = isCluster
						? await getPartClusterSolutions(partId, false)
						: await getPartSolutions(partId, false, false)

					const part = isCluster
						? response?.data?.cluster
						: response?.data?.part

					const mainPartAnalysis = new MainPartAnalysisService(
						part.status,
						nonSolutionConfigIdsLength
					)
					await mainPartAnalysis.startPartPoller(
						partId,
						dispatch,
						false,
						feaId,
						isCluster
					)
				}
			} catch (err) {
				console.error(err)
			}
		}
	},
	fetchMoreClusterParts = (
		clusterId: number,
		currentClusterPartsCount: number
	) => {
		return async (dispatch: any) => {
			try {
				const response = await getPartClusterParts(
					clusterId,
					currentClusterPartsCount
				)
				const clusterParts = response?.data?.parts
				dispatch({
					type: CLUSTER_PARTS_FETCHED,
					payload: { clusterParts }
				})
			} catch (error) {
				console.error('error while fetching more parts', error)
			}
		}
	},
	tourPagesUpdated = (
		pagesVisited: any,
		pageName: any,
		customFlag: boolean = false
	) => {
		return (dispatch: any) => {
			updateShowTour(dispatch, pagesVisited, pageName, customFlag)
		}
	},
	updateShowTour = (
		dispatch: any,
		pagesVisited: any,
		pageName: any,
		customFlag: boolean
	) => {
		if (customFlag) {
			dispatch({
				type: TOUR_STEPS_UPDATED,
				payload: {
					run: true
				}
			})
		} else if (pagesVisited && !pagesVisited.includes(pageName)) {
			dispatch({
				type: TOUR_STARTED,
				payload: {
					stepIndex: 0
				}
			})
		}
	},
	incrementTourStep = (stepIndex: number) => {
		return (dispatch: any) => {
			dispatch({
				type: TOUR_STEPS_UPDATED,
				payload: {
					stepIndex,
					pauseTour: false
				}
			})
			dispatch({
				type: PART_ANALYSIS_SET_CURRENT_STEP
			})
		}
	},
	addSimpleConfigurationClick = () => {
		return {
			type: SOLUTION_PRINTER_MATERIAL_CONFIGURE_PRESSED,
			payload: { id: -1 }
		}
	},
	addConfigurationClick = () => {
		return {
			type: SOLUTION_CONFIGURE_PRESSED,
			payload: { id: 0 }
		}
	},
	newSolutionConfigurationAdded = () => {
		return {
			type: SOLUTION_CONFIGURATION_ADDED
		}
	},
	clearComponentData = (): Action<any> => {
		return {
			type: SOLUTIONS_FETCHED_CLEARED
		}
	},
	hideInhouseAlertWithMaterial = (
		id: number,
		keepMaterialForInHouseClick: boolean = false
	) => {
		return (dispatch: any) => {
			dispatch({
				type: CONFIGURATION_INHOUSE_ALERT_WITH_MATERIAL,
				payload: { id, keepMaterialForInHouseClick }
			})
		}
	},
	hideInhouseAlert = (): Action<any> => {
		return {
			type: CONFIGURATION_INHOUSE_ALERT_CANCELED
		}
	},
	updateConfiguration = (id: number, configuration: any): Action<any> => {
		const { userUnitSystem } = store.getState().user

		const customizeUnitSystem = Feature.isFeatureOn(
			FeatureComponentId.CUSTOMIZE_UNIT_SYSTEM
		)
		const unitSystem = customizeUnitSystem ? userUnitSystem : UnitSystem.metric

		return {
			type: SOLUTION_CONFIGURATION_SOLUTION_UPDATED,
			payload: { id, configuration, unitSystem }
		}
	},
	onCamExistenceChange = (camExistence: number) => {
		return {
			type: PART_ANALYSIS_CAM_EXISTENCE_CHANGE,
			payload: { camExistence }
		}
	},
	onCastToolingCostChange = (castToolingCost: number) => {
		return {
			type: PART_ANALYSIS_CAST_TOOLING_CHANGE,
			payload: { castToolingCost }
		}
	},
	onInvestmentCastToolingCostChange = (investmentCastToolingCost: number) => {
		return {
			type: PART_ANALYSIS_INVESTMENT_CAST_TOOLING_CHANGE,
			payload: { investmentCastToolingCost }
		}
	},
	onMoldPriceCostChange = (moldCost: number) => {
		return {
			type: PART_ANALYSIS_MOLD_PRICE_CHANGE,
			payload: { moldCost }
		}
	},
	togglePrintingAlert = (showPrintingStandardAlert: boolean) => {
		return {
			type: TOGGLE_PRINTING_ALERT,
			payload: { showPrintingStandardAlert }
		}
	},
	onChangeSolutionMapFilter = (value: string) => {
		return {
			type: ALTERNATIVE_SOLUTION_MAP_FILTER_CHANGE,
			payload: { value }
		}
	}
