import React, {
	FC,
	memo,
	useCallback,
	useEffect,
	useMemo,
	useState
} from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import EditIcon from '@material-ui/icons/Edit'

import OrganizationSelector from '../../../Components/OrganizationSelector'
import { onOrganizationSelect } from '../CustomizeActions'
import AddOrganizationModal from './AddOrganizationModal'
import EditOrganizationModal from './EditOrganizationModal'
import Devider from 'Scenes/Components/Devider/Devider'
import WithFeatureToggleHOC from 'Services/HOC/WithFeatureToggleHOC'
import { FeatureComponentId } from 'Services/models/Features'
import { IOrganization } from 'Services/models/IOrganization'
import { getString } from 'Services/Strings/StringService'
import { renderOrganizationValue } from 'Services/Utils/organizationTools'

import { ReactComponent as Plus } from 'assets/img/svg/icons/plus-circle.svg'

const PlusIcon: any = Plus

type IProps = {
	showEdit: boolean
	showAdd: boolean
}

const OrganizationPanel: FC<IProps> = ({ showEdit, showAdd }) => {
	const [showEditModal, setShowEditModal] = useState(false)
	const [showAddModal, setShowAddModal] = useState(false)
	const { availableOrganizations, organizationDetails, isAdminByRole } =
		useSelector((state: RootStateOrAny) => state.user)
	const { adminSelectedOrganizationId } = useSelector(
		(state: RootStateOrAny) => state.CustomizeReducer
	)
	const dispatch = useDispatch()

	const adminSelectedOrganization = useMemo(
		() =>
			availableOrganizations.find(
				(organization: IOrganization) =>
					organization.id === adminSelectedOrganizationId
			),
		[adminSelectedOrganizationId, availableOrganizations]
	)

	const selectedOrganization: IOrganization = isAdminByRole
		? adminSelectedOrganization
		: organizationDetails

	useEffect(() => {
		if (!adminSelectedOrganizationId) {
			dispatch(onOrganizationSelect(organizationDetails?.id))
		}
	}, [adminSelectedOrganizationId, dispatch, organizationDetails?.id])

	const onEditOrganizationClick = () => {
		setShowEditModal(true)
	}

	const cancelEditOrganizationModal = () => {
		setShowEditModal(false)
	}

	const onAddOrganizationClick = () => {
		setShowAddModal(true)
	}

	const cancelAddOrganizationModal = () => {
		setShowAddModal(false)
	}

	const handleOrganizationSelect = useCallback(
		(value: number) => {
			dispatch(onOrganizationSelect(value))
		},
		[dispatch]
	)

	const renderValue = useCallback(
		(selected: unknown) =>
			renderOrganizationValue(availableOrganizations, selected as number),
		[availableOrganizations]
	)

	const title = useMemo(
		() =>
			renderOrganizationValue(
				availableOrganizations,
				adminSelectedOrganizationId
			),
		[adminSelectedOrganizationId, availableOrganizations]
	)

	return (
		<>
			<Devider />
			<div className="customize-settings--header--organization">
				<div className="customize-settings--header--organization-name">
					<div>
						{getString('SETTINGS_CURRENT_SITE_LABEL')}{' '}
						<b>{selectedOrganization?.name}</b>
					</div>
					{showEdit && <EditIcon onClick={onEditOrganizationClick} />}
				</div>
				{isAdminByRole && (
					<div className="customize-settings--header--organization-selector">
						<span>{getString('SELECT_ORGANIZATION_LABEL')}</span>
						<OrganizationSelector
							value={adminSelectedOrganizationId}
							disabled={!showAdd}
							handleOrganizationSelect={handleOrganizationSelect}
							renderValue={renderValue}
							title={title}
						/>
						{showAdd && (
							<PlusIcon
								className="add-new-organization"
								onClick={onAddOrganizationClick}
							/>
						)}
					</div>
				)}
			</div>
			<EditOrganizationModal
				show={showEditModal}
				savedOrganizationName={selectedOrganization?.name}
				isOrganizationPrivate={!!selectedOrganization?.private}
				organizationId={selectedOrganization?.id}
				onCancel={cancelEditOrganizationModal}
			/>
			<AddOrganizationModal
				show={showAddModal}
				onCancel={cancelAddOrganizationModal}
			/>
		</>
	)
}

export default WithFeatureToggleHOC(
	memo(OrganizationPanel),
	FeatureComponentId.CUSTOMIZE_ORGANIZATIONS
)
