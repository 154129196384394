import React, { FC, memo, useState } from 'react'
import { useDispatch } from 'react-redux'

import { Close } from '@material-ui/icons'
import cx from 'classnames'
import { isString } from 'lodash'

import Card from '../../Components/Card/Card'
import PartCardActionIcons from './PartCard/PartCardActionIcons'
import PartCardFooter from './PartCard/PartCardFooter'
import {
	onPartPropertiesClick,
	onToggleGrabCad
} from './ProjectAnalysisActions'
import {
	getWeightReductionRate,
	renderBenefits,
	renderConsolidationDeletionAlert,
	stopEvent
} from './ProjectPartHelpers'
import ErrorBoundary from 'Scenes/Components/ErrorBoundary/ErrorBoundary'
import { InapplicableStatus } from 'Services/Constants'
import { CADAnalysisResult } from 'Services/models/CADAnalysisResult'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { Part, PartStatus } from 'Services/models/IPart'

interface Props {
	part: Part
	cardClassName?: string
	cardSizeWrapperClassName?: string
	cardContentClassName?: string
	starPartClicked?: (e: React.MouseEvent<SVGSVGElement>) => void
	onRemovePartConfirm?: Function
	onRemovePartClick?: Function
	onRemoveClusterPartClick?: (e: React.MouseEvent<SVGSVGElement>) => void
	onPartClick: Function
	children?: JSX.Element[] | JSX.Element | string
}

const ProjectPartItem: FC<Props> = ({
	part,
	starPartClicked,
	onRemovePartConfirm,
	onRemovePartClick,
	onRemoveClusterPartClick,
	onPartClick,
	cardClassName = '',
	cardSizeWrapperClassName = '',
	cardContentClassName = '',
	children
}) => {
	const materialName = part?.isAmOriginalMaterial
		? isString(part.printerMaterialName)
			? part.printerMaterialName
			: part.materialName
		: part.materialName

	const dispatch = useDispatch()
	const [showAlert, setShowAlert] = useState(false)
	const { id, imageURL, partNumber, starred, isDefaultMaterial } = part

	const onRemoveClicked = () => {
		onRemovePartClick && onRemovePartClick()
	}

	const onConfirmRemoveClicked = () => {
		setShowAlert(false)
		onRemovePartConfirm && onRemovePartConfirm()
	}

	const onShowModalOrRemove = (
		e: React.MouseEvent<HTMLDivElement, MouseEvent>
	) => {
		stopEvent(e)

		if (part.isUsedByCluster) {
			setShowAlert(true)
		} else {
			onRemoveClicked()
		}
	}

	const isInapplicable =
		part?.isDrawing && part?.drawingStatusCode === InapplicableStatus

	const allowRemovePart = Feature.isFeatureActive(
		FeatureComponentId.DELETE_PART
	)

	const allowRemoveAssemblyPart = Feature.isFeatureActive(
		FeatureComponentId.DELETE_ASSEMBLY_PART
	)

	const showRemovePart = part.parentAssemblyId
		? allowRemoveAssemblyPart
		: allowRemovePart

	return (
		<ErrorBoundary extra="ProjectPartItem">
			<div
				className="card"
				data-qa="data-qa-part-thumbnail-view"
				style={{ cursor: 'pointer', height: '100%' }}
				onClick={() => {
					if (part?.result === CADAnalysisResult.notCostEffective) {
						return onPartClick()
					}
					isInapplicable || !part?.materialId
						? dispatch(onPartPropertiesClick(part.projectId, part.id, true))
						: onPartClick()
				}}
			>
				<div className="section-header">
					<div className="section-header--text">
						<div className="title" title={partNumber}>
							{partNumber}
						</div>
						{materialName && (
							<div
								title={materialName}
								className={cx('', { defaultText: isDefaultMaterial })}
							>
								{materialName}
							</div>
						)}
					</div>
					<div>
						{(starPartClicked && onRemovePartClick && (
							<PartCardActionIcons
								starred={starred}
								onStarClicked={starPartClicked}
								onRemoveClicked={onShowModalOrRemove}
								showRemove={showRemovePart}
							/>
						)) || <div />}
						{onRemoveClusterPartClick && (
							<Close
								className="remove-cluster-part-icon"
								onClick={(e: React.MouseEvent<SVGSVGElement>) =>
									onRemoveClusterPartClick(e)
								}
							/>
						)}
					</div>
				</div>
				<Card
					selectedGrabCad={part.grabCadPrint}
					withGrabCad={!part.isDrawing}
					onToggleGrabCad={() => {
						dispatch(onToggleGrabCad(part.id, part.grabCadPrint))
					}}
					isCard={false}
					className={cardClassName}
					sizeWrapperClassName={cardSizeWrapperClassName}
					contentClassName={cardContentClassName}
					key={id}
					imageUrl={imageURL}
					isContentTextInclined={isDefaultMaterial}
					imageAlt="..."
					partFormat={part?.formatType}
					extraTitles={getWeightReductionRate(part) || []}
					contentCustomComponent={children ? <div>{children || ''}</div> : null}
					dataQATitle={partNumber}
					footer={
						<PartCardFooter
							isInapplicable={isInapplicable}
							isMissingInfo={part.status === PartStatus.missingInformation}
							isPartUpdating={
								part.status === PartStatus.awaitingCombinedHeatmap
							}
							result={part.result}
							customBenefitSection={renderBenefits(part)}
							unprintableReason={part.unprintableReason}
							extraUnprintableReasonsCount={part.extraUnprintableReasonsCount}
						/>
					}
				/>
				{showAlert &&
					renderConsolidationDeletionAlert({
						show: showAlert,
						onCancel: () => setShowAlert(false),
						onConfirm: onConfirmRemoveClicked
					})}
			</div>
		</ErrorBoundary>
	)
}

export default memo(ProjectPartItem)
