import {
	ChangeEvent,
	FC,
	memo,
	useCallback,
	useEffect,
	useMemo,
	useState
} from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { FormControlLabel, FormLabel, RadioGroup } from '@material-ui/core'

import {
	onAdminRadioButtonChange,
	onChangePermission
} from '../AdminUsersActions'
import {
	ALL_SITES_OPTION,
	NOT_ADMIN_OPTION,
	togglePermission
} from '../constants'
import CastorRadio from 'Scenes/Components/CastorRadio'
import CastorSwitch from 'Scenes/Components/CastorSwitch'
import DetailsPopup from 'Scenes/Components/DetailsPopup'
import FlexBox from 'Scenes/Components/FlexBox'
import OrganizationSelector from 'Scenes/Components/OrganizationSelector'
import IconFactory from 'Scenes/Components/StarIcon/IconFactory'
import { UserRole } from 'Scenes/Home/UserRole.enum'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { IOrganization } from 'Services/models/IOrganization'
import { PROFILE_FORM_SITE_LABEL } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'
import { renderOrganizationValue } from 'Services/Utils/organizationTools'

import './AdminUserInfo.scss'

const AdminUserPermissions: FC = () => {
	const [adminCategory, setAdminCategory] = useState(NOT_ADMIN_OPTION)
	const dispatch = useDispatch()
	const {
		organizationOwner,
		adminUserManager,
		isSiteAdmin,
		userOrganization,
		userSelected
	} = useSelector((state: RootStateOrAny) => state.AdminUsersReducer)
	const { availableOrganizations, roles } = useSelector(
		(state: RootStateOrAny) => state.user
	)

	const customizeOrganizationsIsOn = Feature.isFeatureOn(
		FeatureComponentId.CUSTOMIZE_ORGANIZATIONS
	)

	const showOrganizationsSelector =
		customizeOrganizationsIsOn && !roles.includes(UserRole.SITE_ADMIN)

	const userOrganizationDetails = availableOrganizations.find(
		(organization: IOrganization) => organization.id === userOrganization
	)

	const title = useMemo(() => {
		return renderOrganizationValue(availableOrganizations, userOrganization)
	}, [availableOrganizations, userOrganization])

	const renderUserOrganizationValue = useCallback(
		(selected: unknown) =>
			renderOrganizationValue(availableOrganizations, selected as number),
		[availableOrganizations]
	)

	const handleOrganizationSelect = useCallback(
		(value: number) => {
			dispatch(
				onChangePermission(
					value,
					togglePermission.userOrganization,
					userOrganization
				)
			)
		},
		[dispatch, userOrganization]
	)

	useEffect(() => {
		if (customizeOrganizationsIsOn) {
			setAdminCategory(
				isSiteAdmin
					? userOrganization.toString()
					: adminUserManager
					? ALL_SITES_OPTION
					: NOT_ADMIN_OPTION
			)
		}
	}, [
		adminUserManager,
		customizeOrganizationsIsOn,
		isSiteAdmin,
		userOrganization
	])

	return (
		<div className="admin-user-permission">
			<FlexBox
				data-qa="data-qa-organization-owner-toggle"
				alignItems="center"
				justifyContent="flex-start"
			>
				<CastorSwitch
					onChange={(e: ChangeEvent<HTMLInputElement>) =>
						dispatch(
							onChangePermission(
								e.target.checked,
								togglePermission.organizationOwner
							)
						)
					}
					checked={organizationOwner}
				/>
				<span className="data-table-text-field">
					{getString('ORGANIZATION_OWNER')}
				</span>
				<DetailsPopup
					isHover={true}
					data={getString('ORGANIZATION_OWNER_POPUP_MESSAGE')}
					popperDirection="bottom"
					popperClassName="info-box-wrapper"
					popperContactClassName="info-box-data"
					inPortal={true}
				>
					<IconFactory
						iconName="info"
						className="solution-analysis-header-text-icon without-stroke"
					/>
				</DetailsPopup>
			</FlexBox>
			{showOrganizationsSelector && (
				<div className="admin-user-permission--organization-wrapper">
					<div className="admin-user-permission--organization-label">
						{PROFILE_FORM_SITE_LABEL}:
					</div>
					<OrganizationSelector
						value={userOrganization}
						title={title}
						className="admin-user-permission--organization-select"
						renderValue={renderUserOrganizationValue}
						handleOrganizationSelect={handleOrganizationSelect}
						disableOutOfLicenseOrganizations={userSelected.inLicense}
					/>
				</div>
			)}
			{showOrganizationsSelector ? (
				<div className="admin-user-permission--radio-button-wrapper">
					<FormLabel className="admin-user-permission--radio-group-label">
						{getString('ADMINISTRATION_PERMISSIONS_LABEL')}
					</FormLabel>
					<RadioGroup
						row={false}
						value={adminCategory}
						onChange={(e: ChangeEvent<HTMLInputElement>) =>
							dispatch(
								onAdminRadioButtonChange(
									e.target.value,
									userOrganizationDetails?.name
								)
							)
						}
						color="primary"
						className="admin-user-permission--radio-group"
					>
						<FormControlLabel
							className="material-radio"
							value={NOT_ADMIN_OPTION}
							control={<CastorRadio color="primary" />}
							label={getString('NOT_ADMIN_OPTION_LABEL')}
						/>
						<FormControlLabel
							className="material-radio"
							value={ALL_SITES_OPTION}
							control={<CastorRadio color="primary" />}
							label={getString('ALL_SITES_ADMIN_OPTION_LABEL')}
						/>
						<FormControlLabel
							className="material-radio"
							value={userOrganizationDetails?.id}
							control={<CastorRadio color="primary" />}
							label={getString('SITE_ADMIN_OPTION_LABEL').format(
								userOrganizationDetails?.name
							)}
						/>
					</RadioGroup>
				</div>
			) : (
				<FlexBox
					data-qa="data-qa-admin-toggle"
					alignItems="center"
					justifyContent="flex-start"
				>
					<CastorSwitch
						onChange={(e: ChangeEvent<HTMLInputElement>) =>
							dispatch(
								onChangePermission(
									e.target.checked,
									togglePermission.adminUserManager
								)
							)
						}
						checked={adminUserManager || isSiteAdmin}
					/>
					<span className="data-table-text-field">{getString('ADMIN')}</span>
					<DetailsPopup
						isHover={true}
						data={getString('ADMIN_POPUP_MESSAGE')}
						popperDirection="bottom"
						popperClassName="info-box-wrapper"
						popperContactClassName="info-box-data"
						inPortal={true}
					>
						<IconFactory
							iconName="info"
							className="solution-analysis-header-text-icon without-stroke"
						/>
					</DetailsPopup>
				</FlexBox>
			)}
		</div>
	)
}

export default memo(AdminUserPermissions)
