import {
	HANDLE_NOTIFICATION,
	PART_ANALYSIS_3D_MODAL_CLOSED,
	PART_ANALYSIS_WALL_THICKNESS_UPDATED,
	WALL_THICKNESS_ALERT_CANCELED,
	WALL_THICKNESS_CLICKED,
	WALL_THICKNESS_CONFIRMED,
	WALL_THICKNESS_INPUT_CHANGED,
	WALL_THICKNESS_POLLER_FINISHED,
	WALL_THICKNESS_UPDATED
} from '../../../global actions/types'
import {
	SOLUTION_DO_SETUP,
	SOLUTIONS_FETCHED_FINISHED,
	SOLUTIONS_FETCHED_FORMATED
} from '../../../global actions/types/partAnalysisTypes'
import { getCustomizationSettings } from '../../../Services/Network'
import { getPartSolutions } from '../../../Services/Network/PartAnalysisNetwork'
import {
	POLLER_REACHED_TIMEOUT,
	SHOW_NOTIFICATION,
	WALL_THICKNESS_NOTIFICATION_FAILED,
	WALL_THICKNESS_NOTIFICATION_SUCCEDED,
	WALL_THICKNESS_NOTIFICATION_TOO_LONG
} from '../../../Services/Strings'
import { CastorWallThicknessDemandService } from './CastorWallThicknessDemandService'

let service = new CastorWallThicknessDemandService()

export const onWallThicknessDemandClick = () => {
		return dispatch => {
			dispatch({
				type: WALL_THICKNESS_CLICKED
			})
			dispatch({
				type: PART_ANALYSIS_3D_MODAL_CLOSED
			})
		}
	},
	onAlertCancel = () => {
		return {
			type: WALL_THICKNESS_ALERT_CANCELED
		}
	},
	onInputChange = e => {
		return {
			type: WALL_THICKNESS_INPUT_CHANGED,
			payload: e.target.value
		}
	},
	onAlertConfirm = (customWallThickness, partId) => {
		return async dispatch => {
			try {
				dispatch({
					type: WALL_THICKNESS_CONFIRMED
				})
				dispatch({
					type: SOLUTIONS_FETCHED_FORMATED
				})
				service = new CastorWallThicknessDemandService(partId)
				await service.updateWallThickness(customWallThickness)

				dispatch({
					type: WALL_THICKNESS_UPDATED
				})

				await service.handlePoller()

				const response = await getPartSolutions(partId, true)
				const settingsResponse = await getCustomizationSettings()

				const { configurations } = response.data

				const { defaultSettings = {} } = settingsResponse?.data

				const { drawingCostPercentage } = defaultSettings

				dispatch({
					type: SOLUTIONS_FETCHED_FINISHED,
					payload: {
						...response.data,
						solutions: configurations.reduce((acc, configuration) => {
							if (configuration.solution) {
								acc.push(configuration.solution)
							}
							return acc
						}, []),
						project: response.data?.part?.project,
						drawingCostPercentage,
						partId
					}
				})

				configurations.forEach(configuration => {
					dispatch({
						type: SOLUTION_DO_SETUP,
						payload: { id: configuration.id }
					})
				})

				dispatch({
					type: PART_ANALYSIS_WALL_THICKNESS_UPDATED,
					payload: response.data.part
				})
				dispatch({
					type: WALL_THICKNESS_POLLER_FINISHED
				})
				dispatch({
					type: HANDLE_NOTIFICATION,
					payload: {
						notificationType: SHOW_NOTIFICATION.SUCCESS,
						notificationMessage: WALL_THICKNESS_NOTIFICATION_SUCCEDED
					}
				})
			} catch (error) {
				console.error(error)
				dispatch({
					type: WALL_THICKNESS_POLLER_FINISHED
				})
				if (error === POLLER_REACHED_TIMEOUT) {
					dispatch({
						type: HANDLE_NOTIFICATION,
						payload: {
							notificationType: SHOW_NOTIFICATION.INFO,
							notificationMessage: WALL_THICKNESS_NOTIFICATION_TOO_LONG
						}
					})
				} else {
					dispatch({
						type: HANDLE_NOTIFICATION,
						payload: {
							notificationType: SHOW_NOTIFICATION.ERROR,
							notificationMessage: WALL_THICKNESS_NOTIFICATION_FAILED
						}
					})
				}
			}
		}
	},
	clearPoller = () => {
		service.clearPoller()
		return {
			type: WALL_THICKNESS_POLLER_FINISHED
		}
	}
