import React, { ChangeEvent, memo, PureComponent } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { Element } from 'react-scroll'
import { AnyAction, bindActionCreators } from 'redux'
import { change } from 'redux-form'

import Checkbox from '@material-ui/core/Checkbox'
import { default as classNames } from 'classnames'

import * as IntegrationProviderActions from '../../../../IntegrationProvider/IntegrationProviderActions'
import * as SolutionAnalysisActions from './SolutionAnalysisActions'
import { Button } from '../../../../Components/thirdParty/CreativeTim/components'
import ToleranceSlider from '../../../NewUploadProject/UploadTolerance/ToleranceSlider'
import { incrementTourStep } from '../MainPartAnalysisActions'
import { ConfigurationResultTypes } from './ConfigurationResultTypes'
import GeometryAnalysisReviewAndFixes from './GeometryAnalysisReviewAndFixes'
import SolutionAnalysisContent from './SolutionAnalysisContent/SolutionAnalysisContent'
import ChainBenefitsForm from './SolutionAnalysisContent/SolutionAnalysisTabs/Tabs/CostComparisonTab/ChainBenefitsForm/index'
import SolutionAnalysisHeader from './SolutionAnalysisHeader/SolutionAnalysisHeader'
import { IProps, IReduxState, IState } from './SolutionAnalysisInterface'
import { SolutionAnalysisInitialState } from './SolutionAnalysisReducer'
import SolutionAnalysisTopDetails from './SolutionAnalysisTopDetails/SolutionAnalysisTopDetails'
import SolutionConfigure from './SolutionConfigure/SolutionConfigure'
import SolutionConfigureFeaturePostProcesses from './SolutionConfigure/SolutionConfigureFeatures/SolutionConfigureFeaturePostProcesses'
import SolutionFea from './SolutionFea'
import FilterFeaturesModal from './SolutionFilterFeature'
import SolutionOrientation from './SolutionOrientation'
import SolutionPrioritizeFeaturesModal from './SolutionPrioritizeFeaturesModal'
import ThreeDViewer from './ThreeDViewer'
import TolerancesChanger from './TolerancesChanger'
import WallThicknessDemand from './WallThicknessDemand'
import AdditiveMindsPopup from 'Scenes/Components/AdditiveMinds/AdditiveMindsPopup'
import { AlertType } from 'Scenes/Components/alerts/AlertTypes'
import CastorAlert from 'Scenes/Components/alerts/CastorAlert'
import ButtonWithLoader from 'Scenes/Components/ButtonWithLoader'
import ErrorBoundary from 'Scenes/Components/ErrorBoundary/ErrorBoundary'
import Flexbox from 'Scenes/Components/FlexBox'
import WeightReductionProgress from 'Scenes/Components/WeightReductionProgress'
import { METADATA } from 'Scenes/Home/NewUploadProject/constants'
import { defaultSolutionColor, partResults } from 'Services/Constants'
import { getCallbackURLFromLocalStorage } from 'Services/LocalStorageService'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { IUserProvider } from 'Services/models/IUserProvider'
import {
	ARE_YOU_SURE_DELETE,
	CANCEL,
	COMBINE_AROUND_THIS_PART,
	COMBINE_AROUND_THIS_PART_INFO,
	DELETE_ITEM,
	NO,
	PRINTING_ORIENTATION_ALERT_CONFIRM_BUTTON_TEXT,
	PRINTING_ORIENTATION_ALERT_TEXTS,
	SEARCH_WITHIN_SAME_CATEGORY,
	YES
} from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'
import { getTheme } from 'themes/getTheme'

export enum CalculationType {
	SOLUTION_MAP = 'solutionMap',
	REGULAR = 'regular'
}

const theme = getTheme()
const CheckBoxTS: any = Checkbox

const Elm: any = Element
const SolutionConfigureObject: any = SolutionConfigure

class SolutionAnalysis extends PureComponent<IProps, IState> {
	elementId: string = ''

	constructor(props: IProps) {
		super(props)
		this.elementId = `solution-analysis-object_${props.configuration.id}`
		this.state = {
			showRemoveAlert: false,
			showCombineAlert: false,
			sameMaterial: true,
			renderCheckbox: false,
			quantityChanged: false,
			newQuantity: 1,
			callbackURL: '',
			showTolerance: false,
			showPdf: false,
			hasError: false
		}
	}

	static getDerivedStateFromError(error: any) {
		// if an error is thrown it won't break the page
		console.error(error)
		return { hasError: true }
	}

	componentDidMount = () => {
		const {
			part,
			cluster,
			configuration,
			filters,
			priorities,
			initialBatchSize,
			partMaterial,
			materials,
			allOptionalPostProcessesData,
			optionalPostProcessAvailability,
			toleranceIncluded,
			partSolution,
			userCurrencySign,
			printersFullData,
			solutionFea,
			feaId,
			isWeightReductionPart,
			partPrintIssues,
			materialCategories,
			disablePart,
			optionalPostProcessesBreakDown,
			setupSolution,
			printerMaterials,
			printerMaterialsSubCategories,
			printerMaterialsCategories
		} = this.props
		this.setState({ newQuantity: initialBatchSize })
		this.setState({ callbackURL: getCallbackURLFromLocalStorage() })

		setupSolution(
			configuration,
			part,
			cluster,
			filters,
			priorities,
			initialBatchSize,
			partMaterial,
			materials,
			partSolution,
			allOptionalPostProcessesData,
			optionalPostProcessAvailability,
			optionalPostProcessesBreakDown,
			toleranceIncluded,
			userCurrencySign,
			printersFullData,
			isWeightReductionPart,
			solutionFea,
			partPrintIssues,
			materialCategories,
			disablePart,
			printerMaterials,
			printerMaterialsSubCategories,
			printerMaterialsCategories,
			feaId
		)
	}

	setIsError = (va: any) => console.log(va)

	componentWillUnmount = () => {
		const { formatComponent, configuration, disableUnmount } = this.props
		if (!disableUnmount) formatComponent(configuration.id)
	}

	componentDidUpdate = (prevProps: IProps) => {
		const {
			part,
			cluster,
			configuration,
			setupSolution,
			filters,
			priorities,
			initialBatchSize,
			partMaterial,
			materials,
			partSolution,
			allOptionalPostProcessesData,
			optionalPostProcessAvailability,
			optionalPostProcessesBreakDown,
			doSetup,
			toleranceIncluded,
			userCurrencySign,
			printersFullData,
			solutionFea,
			tourStepIndex,
			isWeightReductionPart,
			partPrintIssues,
			materialCategories,
			incrementTourStep,
			disablePart,
			printerMaterials,
			printerMaterialsSubCategories,
			printerMaterialsCategories
		} = this.props

		if (prevProps.tourStepIndex !== tourStepIndex) {
			incrementTourStep(tourStepIndex)
		}
		if (doSetup || prevProps.disablePart !== disablePart) {
			setupSolution(
				configuration,
				part,
				cluster,
				filters,
				priorities,
				initialBatchSize,
				partMaterial,
				materials,
				partSolution,
				allOptionalPostProcessesData,
				optionalPostProcessAvailability,
				optionalPostProcessesBreakDown,
				toleranceIncluded,
				userCurrencySign,
				printersFullData,
				isWeightReductionPart,
				solutionFea,
				partPrintIssues,
				materialCategories,
				disablePart,
				printerMaterials,
				printerMaterialsSubCategories,
				printerMaterialsCategories
			)
		}
	}

	onConfigureClick = (e: any, configurationId: number): void => {
		e.stopPropagation()
		const { onConfigureClick, tourConfigurationId, showConfigure } = this.props

		onConfigureClick(configurationId, tourConfigurationId, showConfigure)
	}

	onCloseClick = (e: any) => {
		e.stopPropagation()
		const { callbackURL, quantityChanged } = this.state
		if (callbackURL) {
			this.props.onConfigureCancelClick(callbackURL, quantityChanged)
		}
	}

	toggleTolerancePopup = (value: boolean) => {
		this.setState({ showTolerance: value })
	}

	onRetrieveResultClick = (e: any, configurationId: number): void => {
		e.stopPropagation()
		this.props.onRetrieveResultClick(configurationId)
	}

	onRemoveClick = (e: any): void => {
		e.stopPropagation()
		this.setState({ showRemoveAlert: true })
	}

	onSetLeadingClick = (e: any): void => {
		e.stopPropagation()
		const {
			onSetLeadingConfigurationClick,
			configuration,
			leadingByUserChoice
		} = this.props
		onSetLeadingConfigurationClick(configuration.id, !leadingByUserChoice)
	}

	onCombineClick = (e: any): void => {
		e.stopPropagation()
		this.setState({ showCombineAlert: true })
	}

	onGetAQClick = (e: any, btnElementId: string): void => {
		e.stopPropagation()
		const {
			onConfigurationQuoteClick,
			configuration,
			currentStepTargetId,
			tourStepIndex,
			incrementTourStep
		} = this.props
		onConfigurationQuoteClick(configuration.id)
		if (currentStepTargetId === `#${btnElementId}`) {
			incrementTourStep(tourStepIndex)
		}
	}
	on3dExportClick = (e: ChangeEvent<HTMLInputElement>): void => {
		e.stopPropagation()
		const { on3dExportClick, configuration, part, userProviders } = this.props
		on3dExportClick(configuration.id, part, userProviders)
	}

	onPrintingOptionClick = (
		e: any,
		userProvider: IUserProvider,
		providerPrinterId: number
	): void => {
		e.stopPropagation()
		const { part, configuration, userEmail, onSendPartToProviderClick } =
			this.props

		onSendPartToProviderClick(
			part,
			configuration,
			window.location.href,
			userProvider,
			providerPrinterId,
			userEmail
		)
	}

	onPostProcesses = (configurationId: number, showCheckbox: boolean) => {
		const { onPostProcessesClick } = this.props

		this.setState({ renderCheckbox: showCheckbox })
		onPostProcessesClick(configurationId)
	}

	onPrinterChange = (alternativeSolutionId: number) => {
		const {
			onAlternativeSolutionChange,
			alternativeSolutions,
			alternativeSolutionsPrintersList,
			configuration
		} = this.props
		const chosenSolution = alternativeSolutionsPrintersList.find(
			item => item.id === alternativeSolutionId
		)
		const index =
			chosenSolution && alternativeSolutionsPrintersList.indexOf(chosenSolution)
		if (index && index !== 0) {
			onAlternativeSolutionChange(
				alternativeSolutionId,
				alternativeSolutions,
				configuration
			)
		}
	}
	onSolutionClick = (e: any) => {
		if (!this.props.isNewDesign) {
			e.stopPropagation()
			const {
				configuration,
				showSolutionDetails,
				tourConfigurationId,
				showConfigure,
				onSolutionClick
			} = this.props
			onSolutionClick({
				id: configuration.id,
				show: !showSolutionDetails,
				tourConfigurationId,
				showConfigure
			})
		}
	}

	isSameOrientationVector = (
		chosenOrientationVector: number[],
		configuration: any
	) => {
		const currentVector = configuration?.trayOrientation?.trayNormalVector
		return (
			JSON.stringify(currentVector) === JSON.stringify(chosenOrientationVector)
		)
	}
	onConfirmOrientationModel = (
		withPrintingOrientationCalc: boolean,
		currentChosenOrientationVector: number[]
	) => {
		const {
			configuration,
			partId,
			projectId,
			solutionPriorities,
			filterValues,
			filtersErrors,
			solutionName,
			configurationId,
			onCalculateClick,
			onOrientationConfirmNoChange,
			chosenMaterial,
			chosenSubCategory,
			batchSize,
			tempSolutionPostProcessToggles,
			allOptionalPostProcessesData,
			optionalPostProcessesBreakDown,
			optionalPostProcessAvailability,
			toleranceIncluded,
			clusterId,
			part,
			userCurrencySign,
			newSupportVolume,
			solution,
			simpleConfiguration,
			simpleConfigurationSelectorMaterialValue,
			simpleConfigurationSelectorPrinterValue,
			simpleInhouseConfiguration,
			printCostQuantity,
			isAmOriginalMaterial,
			calculateWithOrganizationId
		} = this.props

		const objectSolutionPriorities = Object.fromEntries(solutionPriorities)

		if (
			this.isSameOrientationVector(
				currentChosenOrientationVector,
				configuration
			) &&
			!withPrintingOrientationCalc
		) {
			onOrientationConfirmNoChange(configuration.id)
		} else {
			onCalculateClick(
				configurationId,
				solution,
				partId,
				projectId,
				objectSolutionPriorities,
				filterValues,
				filtersErrors,
				solutionName,
				configuration.solution && configuration.solution.id,
				chosenMaterial,
				chosenSubCategory,
				batchSize,
				tempSolutionPostProcessToggles,
				allOptionalPostProcessesData,
				optionalPostProcessesBreakDown,
				optionalPostProcessAvailability,
				toleranceIncluded,
				clusterId,
				true,
				part,
				userCurrencySign,
				printCostQuantity,
				isAmOriginalMaterial,
				withPrintingOrientationCalc
					? undefined
					: currentChosenOrientationVector,
				withPrintingOrientationCalc,
				true,
				simpleConfiguration,
				simpleConfigurationSelectorPrinterValue,
				simpleConfigurationSelectorMaterialValue,
				simpleInhouseConfiguration,
				newSupportVolume,
				undefined,
				undefined,
				calculateWithOrganizationId
			)
		}
	}

	renderRemoveAlert() {
		const {
			onRemoveApprove,
			configuration,
			simpleConfiguration,
			removeConfigurationLoading
		} = this.props
		const { showRemoveAlert } = this.state
		if (!showRemoveAlert) {
			return <div />
		}
		return (
			<CastorAlert
				headerTitle={DELETE_ITEM}
				onCancel={() => this.setState({ showRemoveAlert: false })}
				show={showRemoveAlert}
				onConfirm={() => onRemoveApprove(configuration.id, simpleConfiguration)}
				cancelOptionalText={NO}
				confirmOptionalText={YES}
				alertType={AlertType.WARNING}
				loadingCalculation={removeConfigurationLoading}
			>
				{ARE_YOU_SURE_DELETE}
			</CastorAlert>
		)
	}

	renderCombineAlert() {
		const { onCombineClick, partId, projectId } = this.props
		const { showCombineAlert, sameMaterial } = this.state

		if (!showCombineAlert) {
			return <div />
		}
		return (
			<CastorAlert
				headerTitle={COMBINE_AROUND_THIS_PART}
				onCancel={() => this.setState({ showCombineAlert: false })}
				show={showCombineAlert}
				onConfirm={() => {
					partId && onCombineClick(partId, projectId, sameMaterial)
					this.setState({ showCombineAlert: false })
				}}
				cancelOptionalText={NO}
				confirmOptionalText={YES}
				alertType={AlertType.WARNING}
			>
				{COMBINE_AROUND_THIS_PART_INFO}
				<div>
					<CheckBoxTS
						color="primary"
						checked={sameMaterial}
						onChange={(e: ChangeEvent<HTMLInputElement>) =>
							this.setState({ sameMaterial: e.target.checked })
						}
					/>
					<span>{SEARCH_WITHIN_SAME_CATEGORY}</span>
				</div>
			</CastorAlert>
		)
	}

	renderPrintingOrientationAlert(
		PrintingOrientationAlert: boolean,
		loadingPrintingOrientationAlert: boolean
	) {
		const { onPrintingOrientationAlertCancel, configuration } = this.props
		if (!PrintingOrientationAlert) {
			return <div />
		}
		return (
			<CastorAlert
				show={PrintingOrientationAlert}
				alertType={AlertType.WARNING}
				headerTitle={getString('PRINTING_ORIENTATION_ALERT_TITLE')}
				onConfirm={(
					close_modal: boolean,
					currentChosenOrientationVector: number[]
				) =>
					this.onConfirmOrientationModel(
						close_modal,
						currentChosenOrientationVector
					)
				}
				onCancel={() => onPrintingOrientationAlertCancel(configuration.id)}
				confirmOptionalText={PRINTING_ORIENTATION_ALERT_CONFIRM_BUTTON_TEXT}
				cancelOptionalText={CANCEL}
				loadingCalculation={loadingPrintingOrientationAlert}
				showCancel={false}
			>
				{PRINTING_ORIENTATION_ALERT_TEXTS}
			</CastorAlert>
		)
	}

	renderPostProcessesModal = () => {
		const {
			showPostProcessesModal,
			onPostProcessesModalCancel,
			configuration,
			onPostProcessesToggleChange,
			tempSolutionPostProcessToggles,
			allOptionalPostProcessesData,
			optionalPostProcessesBreakDown,
			partId,
			projectId,
			solutionPriorities,
			filterValues,
			filtersErrors,
			solutionName,
			configurationId,
			onCalculateClick,
			chosenMaterial,
			batchSize,
			optionalPostProcessAvailability,
			toleranceIncluded,
			clusterId,
			loadingCalculationPostProcess,
			part,
			userCurrencySign,
			printingOrientationCalc,
			solution,
			simpleConfiguration,
			simpleConfigurationSelectorMaterialValue,
			simpleConfigurationSelectorPrinterValue,
			simpleInhouseConfiguration,
			printCostQuantity,
			initialMaterial,
			initialSubCategory,
			chosenSubCategory,
			isAmOriginalMaterial,
			calculateWithOrganizationId
		} = this.props

		const { renderCheckbox } = this.state

		const objectSolutionPriorities = Object.fromEntries(solutionPriorities)
		const material = simpleConfiguration ? initialMaterial : chosenMaterial
		const subCategory = simpleConfiguration
			? initialSubCategory
			: chosenSubCategory
		return (
			<CastorAlert
				headerTitle={getString('POST_PROCESS_TITLE')}
				onCancel={() => onPostProcessesModalCancel(configuration.id)}
				show={showPostProcessesModal}
				loadingCalculation={loadingCalculationPostProcess}
				disabled={
					!Object.values(tempSolutionPostProcessToggles).find(
						(tempSolutionPostProcessToggleValue: any) =>
							!tempSolutionPostProcessToggleValue.disabled
					)
				}
				inPortal
				onConfirm={() =>
					onCalculateClick(
						configurationId,
						solution,
						partId,
						projectId,
						objectSolutionPriorities,
						filterValues,
						filtersErrors,
						solutionName,
						configuration.solution && configuration.solution.id,
						material,
						subCategory,
						batchSize,
						tempSolutionPostProcessToggles,
						allOptionalPostProcessesData,
						optionalPostProcessesBreakDown,
						optionalPostProcessAvailability,
						toleranceIncluded,
						clusterId,
						true,
						part,
						userCurrencySign,
						printCostQuantity,
						isAmOriginalMaterial,
						configuration?.trayOrientation?.trayNormalVector,
						printingOrientationCalc,
						false,
						simpleConfiguration,
						simpleConfigurationSelectorPrinterValue,
						simpleConfigurationSelectorMaterialValue,
						simpleInhouseConfiguration,
						undefined,
						undefined,
						undefined,
						calculateWithOrganizationId
					)
				}
			>
				<SolutionConfigureFeaturePostProcesses
					allOptionalPostProcessesData={allOptionalPostProcessesData}
					showPostProcesses={tempSolutionPostProcessToggles}
					configurationId={configuration.id}
					onPostProcessesToggleChange={onPostProcessesToggleChange}
					renderCheckbox={renderCheckbox}
					solution={solution}
				/>
			</CastorAlert>
		)
	}

	onQuantityUpdated = (id: number, value: number) => {
		const { configuration, onBatchSizeChange } = this.props
		const numberValue = +value //string to number

		this.setState({ newQuantity: value })
		onBatchSizeChange(id, numberValue)
		if (configuration.quantity !== numberValue && numberValue) {
			this.setState({ quantityChanged: true })
		} else {
			this.setState({ quantityChanged: false })
		}
	}

	onChangeViewer = (open: boolean) => this.setState({ showPdf: open })
	onClickUpdate = (id: number, value: number) => {
		const {
			part,
			configuration,
			manufacturingMethod,
			tempChainBenefits,
			expectedYearsOfDemand,
			chosenOrientation,
			onCostQuantityUpdated
		} = this.props

		onCostQuantityUpdated(
			configuration.id,
			this.state.newQuantity,
			configuration.postProcessesOptional || {},
			part,
			manufacturingMethod,
			tempChainBenefits,
			expectedYearsOfDemand,
			chosenOrientation
		)
		this.setState({ quantityChanged: false })
	}

	renderConfigurationFooter = () => {
		const { updateQuantityLoader, showAnalysis } = this.props

		const { quantityChanged, callbackURL } = this.state

		if (!Feature.isFeatureOn(FeatureComponentId.CONFIGURATION_FOOTER)) {
			return <div />
		}
		return (
			<Flexbox
				justifyContent="flex-end"
				className="solution-analysis-object-footer"
			>
				<ButtonWithLoader
					color="primary"
					loading={updateQuantityLoader}
					disabled={!quantityChanged || !showAnalysis}
					onClick={this.onClickUpdate}
				>
					{getString('NEW_PART_CONFIGURATION_UPDATE')}
				</ButtonWithLoader>
				{callbackURL !== '' && (
					<Button
						color="primary"
						disabled={updateQuantityLoader}
						onClick={this.onCloseClick}
					>
						{getString('NEW_PART_CONFIGURATION_CLOSE')}
					</Button>
				)}
			</Flexbox>
		)
	}

	render() {
		const {
			configuration,
			part,
			partSolution,
			cluster,
			resultTitle,
			failedPrintIssuesIds,
			resultBody,
			showSolutionDetails,
			leadingByUserChoice,
			isLeading,
			newSolution,
			editMode,
			showConfigure,
			newConfiguration,
			solutionName,
			enableSolutionButtons,
			showPrioritiesFeaturesModal,
			priorities,
			tempSolutionPrioritiesToggles,
			solutionPriorities,
			solution,
			configurationResult,
			showAnalysis,
			showBenefits,
			timeBenefit,
			costDetails,
			colorOnBackground,
			showChangeOrientationModal,
			showWallThicknessDemand,
			wallThicknessLoading,
			wallThicknessTestInMM,
			show3dIframe,
			ledWithTitleIndexes,
			threeDViewerURL,
			orientationsData,
			PrintingOrientationAlert,
			wallThickessModelURL,
			loadingPrintingOrientationAlert,
			userCurrencySign,
			alternativeSolutionsPrintersList,
			simpleConfiguration,
			orientationModalExplanationText,
			showFeaAnalysisAlert,
			solutionFeaStrengthTitle,
			solutionFeaStrength,
			solutionFeaSDStrength,
			solutionFeaUserInputTitle,
			solutionFeaSliderMarks,
			solutionFeaSliderMaxValue,
			solutionFeaSliderStartValue,
			solutionFeaSliderValue,
			solutionFeaSliderIsRange,
			solutionFeaSliderUnits,
			solutionFeaSliderMinValue,
			solutionFeaResult,
			solutionFea,
			solutionFeaAlertLoading,
			feaAnalysisResultsId,
			loadingCalculationPostProcess,
			tempChainBenefits,
			showChainBenefitEditPopup,
			chainBenefitEditType,
			batchSize,
			chainBenefits,
			postProcessesOptional,
			manufacturingMethod,
			configurations,
			chainBenefitsFormLoading,
			showTolerancesChangerModel,
			tolerancesLoading,
			isWeightReductionPart,
			showWeightReduceProgress,
			printersFullData,
			WeightReductionMaterialsData,
			showWeightReductionButton,
			showFeaAnalysisOldAnalysis,
			freezeConfiguration,
			showGeometryAnalysisReviewAndFixesAlert,
			configurationPrintIssues,
			chosenOrientationVector,
			printIssues,
			printIssueLoaders,
			initialToleranceValue,
			disableRibbonInfo,
			disableConfiguration,
			isSpecifiedQuantity,
			customConfiguration,
			disablePart,
			chosenMaterialType,
			chosenMaterialCategory,
			materialTypesList,
			categoriesList,
			userProviders,
			sendToLoader,
			onGeometryAnalysisReviewAndFixesAlertCancel,
			onInputFocusOut,
			onInputFocusIn,
			onSolutionNameChange,
			onPrioritiesModalCancel,
			onPrioritiesModalConfirm,
			onPriorityToggleChange,
			onChangeOrientationCancel,
			onChainBenefitsFormsEditCancelled,
			onWallThicknessConfirm,
			onPartPrintIssueChanged,
			onWallThicknessCancel,
			hide3dModalIframe,
			onWallThicknessClick,
			onTolerancesClick,
			onMeshHealingClick,
			onFeaAlertCancel,
			onFeaAlertConfirm,
			chainBenefitsValuesChanged,
			onCostChainBenefitsUpdated,
			orientationInfoButtonClicked,
			onViewerModelError,
			onTolerancesConfirm,
			onTolerancesCancel,
			onTolerancesRemove,
			onCancelWeightReductionButtonClick,
			onWeightReductionButtonClick,
			onChangeMaterialCategory,
			onChangeMaterialType,
			isOnPrem,
			chartLeadData,
			showFullIcon,
			isNewDesign,
			showEditName,
			disableConfigurationHeader,
			disableAlternativeSolution,
			showFilterFeaturesModal,
			onFilterModalCancel,
			onFilterModalConfirm,
			loadingCalculation,
			toPrint,
			tempSolutionPostProcessToggles
		} = this.props
		const disableMeshHealing = !Feature.isFeatureActive(
			FeatureComponentId.SHOW_SAVE_BUTTON
		)
		return (
			<>
				{this.renderRemoveAlert()}
				{this.renderCombineAlert()}
				<FilterFeaturesModal
					showFilterFeaturesModal={showFilterFeaturesModal}
					onFilterModalCancel={onFilterModalCancel}
					onFilterModalConfirm={onFilterModalConfirm}
					configuration={configuration}
				/>
				{this.renderPostProcessesModal()}
				{this.renderPrintingOrientationAlert(
					PrintingOrientationAlert,
					loadingPrintingOrientationAlert
				)}
				{part && (
					<WeightReductionProgress
						fromPart={true}
						show={showWeightReduceProgress && configuration?.id > 0}
						onCancel={() =>
							onCancelWeightReductionButtonClick(configuration.id)
						}
						WeightReductionMaterialsData={WeightReductionMaterialsData}
						partId={part.id}
						radioButtonSelected={'1'}
						printersFullData={printersFullData}
						configuration={configuration}
						feaExistAndPassed={showFeaAnalysisOldAnalysis}
						isSTLFile={!part.stepURL}
						hasBrepData={part.hasBrepData}
						initialStep={2}
					/>
				)}
				<ChainBenefitsForm
					show={showChainBenefitEditPopup}
					chainBenefitsFormLoading={chainBenefitsFormLoading}
					onConfirm={() =>
						onCostChainBenefitsUpdated(
							configuration.id,
							batchSize,
							postProcessesOptional,
							manufacturingMethod,
							tempChainBenefits,
							chainBenefitEditType,
							configurations
						)
					}
					onCancel={() => onChainBenefitsFormsEditCancelled(configuration.id)}
					chainBenefitEditType={chainBenefitEditType}
					chainBenefits={tempChainBenefits}
					configuration={configuration}
					onBenefitValueChange={chainBenefitsValuesChanged}
				/>
				<SolutionFea
					show={showFeaAnalysisAlert}
					onCancel={() => onFeaAlertCancel(configuration.id, solutionFea?.id)}
					onConfirm={(userSelectedInputs: number[]) =>
						onFeaAlertConfirm(
							configuration.id,
							solutionFea.id,
							userSelectedInputs,
							feaAnalysisResultsId,
							part?.weightReductionType
						)
					}
					strengthTitle={solutionFeaStrengthTitle}
					strength={solutionFeaStrength}
					SDStrength={solutionFeaSDStrength}
					userInputTitle={solutionFeaUserInputTitle}
					sliderMarks={solutionFeaSliderMarks}
					sliderMaxValue={solutionFeaSliderMaxValue}
					sliderStartPointValue={solutionFeaSliderStartValue}
					solutionFeaSliderValue={solutionFeaSliderValue}
					solutionFeaSliderIsRange={solutionFeaSliderIsRange}
					solutionFeaSliderUnits={solutionFeaSliderUnits}
					solutionFeaSliderMinValue={solutionFeaSliderMinValue}
					feaResult={solutionFeaResult}
					solutionFeaAlertLoading={solutionFeaAlertLoading}
				/>
				<ErrorBoundary
					extra="GeometryAnalysisReviewAndFixes"
					isError={this.setIsError}
				>
					<GeometryAnalysisReviewAndFixes
						show={showGeometryAnalysisReviewAndFixesAlert}
						partImageUrl={wallThickessModelURL || part?.meshUploadURL || ''}
						configurationPrintIssues={configurationPrintIssues}
						orientationVector={chosenOrientationVector}
						configuration={configuration}
						solution={solution}
						printIssues={printIssues}
						partHealedStlURLExist={!!part?.healedStlURL}
						printIssueLoaders={printIssueLoaders}
						onWallThicknessClick={() => onWallThicknessClick(configuration.id)}
						onTolerancesClick={() => onTolerancesClick(configuration.id)}
						part={part}
						onPartPrintIssueChanged={(
							partIssueId: number,
							issueActivity: boolean
						) =>
							onPartPrintIssueChanged(
								partIssueId,
								issueActivity,
								part?.id || cluster?.id,
								configuration.id
							)
						}
						onMeshHealingClick={() =>
							onMeshHealingClick(
								part?.id || cluster?.id,
								configuration,
								solution,
								configuration.quantity,
								part?.CADScore === 100,
								isOnPrem,
								disableMeshHealing
							)
						}
						onViewerModelError={(error: any) =>
							onViewerModelError(configuration.id, error)
						}
						onCancel={() =>
							onGeometryAnalysisReviewAndFixesAlertCancel(configuration.id)
						}
					/>
				</ErrorBoundary>
				<SolutionOrientation
					onCancel={() => onChangeOrientationCancel(configuration.id)}
					showChangeOrientationModal={showChangeOrientationModal}
					onConfirm={(
						closeModal: boolean,
						currentChosenOrientationVector: number[]
					) =>
						this.onConfirmOrientationModel(
							closeModal,
							currentChosenOrientationVector
						)
					}
					orientationsData={orientationsData}
					configuration={configuration}
					orientationModalExplanationText={orientationModalExplanationText}
					loadingCalculation={loadingCalculationPostProcess}
					orientationInfoButtonClicked={orientationInfoButtonClicked}
					chosenOrientationVector={chosenOrientationVector}
					solution={solution}
					part={part}
					tempSolutionPostProcessToggles={tempSolutionPostProcessToggles}
					configurationPrintIssues={configurationPrintIssues}
				/>
				<ThreeDViewer
					solution={solution}
					materialType={solution?.printerMaterial?.type}
					wallThickessModelUrl={wallThickessModelURL}
					show3dIframe={show3dIframe}
					hide3dModalIframe={() => hide3dModalIframe(configuration.id)}
					wallThicknessTestInMM={wallThicknessTestInMM}
					ledWithTitleIndexes={ledWithTitleIndexes}
					showWTButoon={!cluster && !disableConfiguration}
					onWallThicknessDemandClick={() =>
						onWallThicknessClick(configuration.id)
					}
					threeDViewerURL={threeDViewerURL}
					onViewerModelError={(error: any) =>
						onViewerModelError(configuration.id, error)
					}
					part={part}
				/>
				<TolerancesChanger
					show={showTolerancesChangerModel}
					onConfirm={(customToleranceValue: string) =>
						onTolerancesConfirm(
							cluster?.id || part?.id,
							configuration.id,
							customToleranceValue,
							false
						)
					}
					onCancel={() => onTolerancesCancel(configuration.id)}
					onRemove={() => onTolerancesRemove(configuration.id, part)}
					loading={tolerancesLoading}
					initialToleranceValue={initialToleranceValue}
					toggleTolerancePopup={this.toggleTolerancePopup}
				/>
				<ToleranceSlider
					changeToleranceAlert={() => this.toggleTolerancePopup(false)}
					showToleranceAlert={this.state.showTolerance}
				/>
				<WallThicknessDemand
					show={showWallThicknessDemand}
					onConfirm={(customWallThickness: number) =>
						onWallThicknessConfirm(
							configuration.id,
							customWallThickness,
							partSolution,
							part,
							configuration,
							manufacturingMethod
						)
					}
					onCancel={() => onWallThicknessCancel(configuration.id)}
					loading={wallThicknessLoading}
					wallThickness={wallThicknessTestInMM}
				/>
				<SolutionPrioritizeFeaturesModal
					showPrioritiesFeaturesModal={showPrioritiesFeaturesModal}
					onPrioritiesModalCancel={onPrioritiesModalCancel}
					onPrioritiesModalConfirm={onPrioritiesModalConfirm}
					priorities={priorities}
					tempSolutionPrioritiesToggles={tempSolutionPrioritiesToggles}
					configuration={configuration}
					onPriorityToggleChange={onPriorityToggleChange}
					solutionPriorities={solutionPriorities}
				/>
				<ErrorBoundary extra="SolutionAnalysis">
					<div
						className={classNames('solution-analysis-object', {
							new: newSolution,
							'with-3d-button':
								(configuration.result === partResults.printable ||
									configuration.result === partResults.borderline) &&
								part?.isDrawing &&
								part?.meshUploadURL &&
								Feature.isFeatureOn(FeatureComponentId.DRAWING_3D),
							expand: isNewDesign || disableRibbonInfo || showSolutionDetails,
							'without-ribbon-info': !isNewDesign || disableRibbonInfo,
							disabled: disablePart,
							footer:
								!isNewDesign ||
								Feature.isFeatureOn(FeatureComponentId.CONFIGURATION_FOOTER)
						})}
						style={{ borderTopColor: colorOnBackground }}
					>
						<Elm
							style={{ width: '100%' }}
							name={`scrollToSolution_${configuration.id}`}
						>
							<div
								id={this.elementId}
								className="solution-analysis-object-top"
								onClick={e => !disableRibbonInfo && this.onSolutionClick(e)}
							>
								<SolutionAnalysisHeader
									showEditName={showEditName}
									disableHeader={isNewDesign}
									showFullIcon={showFullIcon}
									isCluster={cluster?.isCluster}
									canCombine={part?.canCombine}
									onCombineClick={this.onCombineClick}
									benefits={partSolution?.benefits}
									sendToLoader={sendToLoader}
									onConfigureClick={this.onConfigureClick}
									onRemoveClick={this.onRemoveClick}
									onSetLeadingClick={this.onSetLeadingClick}
									onGetAQClick={this.onGetAQClick}
									on3dExportClick={this.on3dExportClick}
									onPrintingOptionClick={this.onPrintingOptionClick}
									onWeightReductionClick={() =>
										onWeightReductionButtonClick(configuration.id, solution)
									}
									solutionColor={colorOnBackground}
									showSolutionDetails={
										showSolutionDetails || !!disableRibbonInfo
									}
									editMode={editMode}
									newSolution={newSolution}
									disableConfiguration={disableConfiguration}
									onInputFocusOut={() =>
										onInputFocusOut(
											configuration.id,
											solutionName,
											configuration.name,
											simpleConfiguration
										)
									}
									freezeConfiguration={freezeConfiguration}
									onInputFocusIn={onInputFocusIn}
									configurationId={configuration.id}
									showConfigure={showConfigure}
									solutionName={solutionName}
									enableSolutionButtons={
										enableSolutionButtons || !!disableRibbonInfo
									}
									onSolutionNameChange={onSolutionNameChange}
									leadingByUserChoice={leadingByUserChoice}
									isLeading={isLeading}
									showBenefits={showBenefits}
									solution={partSolution?.id}
									quantity={configuration.quantity}
									isDesktopConfiguration={
										configuration.resultType ===
										ConfigurationResultTypes.Desktop
									}
									showDownloadButton={isWeightReductionPart}
									partWeightReducedStlURL={part?.fileURL || cluster?.fileURL}
									showWeightReductionButton={showWeightReductionButton}
									userProviders={userProviders}
									isSpecifiedQuantity={isSpecifiedQuantity}
									configuration={configuration}
									partId={part?.id}
									showCrabCad={!part?.isDrawing}
									loadingCalculation={loadingCalculation}
								/>
								{!disableRibbonInfo && (
									<SolutionAnalysisTopDetails
										disableAlternativeSolution={disableAlternativeSolution}
										classNameTopDetails={classNames(
											'solution-analysis-object-top-details',
											{
												expand:
													isNewDesign ||
													disableRibbonInfo ||
													showSolutionDetails
											}
										)}
										disableConfigurationHeader={disableConfigurationHeader}
										showCostInRange={
											part?.isDrawing || part?.formatType === METADATA
										}
										printingStandards={
											part?.printingStandards || cluster?.printingStandards
										}
										solution={solution}
										customConfiguration={customConfiguration}
										configuration={configuration}
										result={configurationResult}
										resultTitle={resultTitle}
										failedPrintIssuesIds={failedPrintIssuesIds}
										configurationPrintIssues={configurationPrintIssues}
										resultBody={resultBody}
										newConfiguration={newConfiguration}
										onConfigureClick={this.onConfigureClick}
										onRetrieveResultClick={this.onRetrieveResultClick}
										showAnalysis={showAnalysis}
										showSolutionDetails={showSolutionDetails || isNewDesign}
										timeBenefit={timeBenefit}
										costDetails={costDetails}
										userCurrencySign={userCurrencySign}
										onPrinterChange={this.onPrinterChange}
										alternativeSolutionsPrintersList={
											alternativeSolutionsPrintersList
										}
										loading={loadingCalculationPostProcess}
										isCluster={!!cluster}
										disablePart={disablePart}
										chainBenefits={chainBenefits}
										firstParts={chartLeadData?.firstParts}
										isSpecifiedQuantity={isSpecifiedQuantity}
										isSmallPart={!!part?.smallPart}
										toPrint={toPrint}
									/>
								)}
							</div>
						</Elm>

						<div
							className={classNames('solution-analysis-object-content', {
								'show-configure': showConfigure,
								'show-pdf-viewer': this.state.showPdf,
								'with-footer':
									!isNewDesign &&
									Feature.isFeatureOn(FeatureComponentId.CONFIGURATION_FOOTER)
							})}
						>
							<SolutionConfigureObject
								part={part}
								configuration={configuration}
								partSolution={partSolution}
							/>
							<SolutionAnalysisContent
								onChangeViewer={this.onChangeViewer}
								part={part}
								configuration={configuration}
								freezeConfiguration={freezeConfiguration}
								cluster={cluster}
								chosenMaterialType={chosenMaterialType}
								chosenMaterialCategory={chosenMaterialCategory}
								categoriesList={categoriesList}
								materialTypesList={materialTypesList}
								showAnalysis={showAnalysis}
								batchSize={batchSize}
								onChangeMaterialType={onChangeMaterialType}
								onChangeMaterialCategory={onChangeMaterialCategory}
								onCostQuantityChanged={this.onQuantityUpdated}
								onPostProcesses={this.onPostProcesses}
							/>
						</div>
						{this.renderConfigurationFooter()}
						<AdditiveMindsPopup configurationId={configuration.id} />
					</div>
				</ErrorBoundary>
			</>
		)
	}
}

const mapStateToProps = (state: any, ownProps: IProps) => {
	const {
		SolutionAnalysisReducer,
		GlobalReducer: { isOnPrem },
		MainPartAnalysisReducer: {
			partMaterial,
			solutions,
			partId,
			projectId,
			clusterId,
			partFeas,
			feaId,
			configurations,
			currentStepTargetId,
			tourConfigurationId,
			isWeightReductionPart,
			partPrintIssues,
			updateQuantityLoader,
			updateConfigurationNameLoader,
			initialToleranceValue,
			disablePart,
			removeConfigurationLoading,
			allConfigurationsOrganizationSettings
		},
		user: {
			filters,
			priorities,
			printers,
			printingTechnologies,
			materials,
			allOptionalPostProcessesData,
			optionalPostProcessesBreakDown,
			optionalPostProcessAvailability,
			threeDViewerURL,
			userCurrencySign,
			printersFullData,
			printIssues,
			materialCategories,
			userProviders,
			userDetails: { email },
			printerMaterials,
			printerMaterialsSubCategories,
			printerMaterialsCategories
		},
		IntegrationProviderReducer: { sendToLoader },
		ProjectAnalysisReducer: {
			WeightReductionMaterialsData,
			weightReductionPartsLeadingData
		}
	}: IReduxState = state
	const {
		color,
		fetched,
		showAnalysis,
		isAmOriginalMaterial,
		calculateWithOrganizationId
	}: SolutionAnalysisInitialState =
		SolutionAnalysisReducer.states[ownProps.configuration.id] ||
		new SolutionAnalysisInitialState()

	const configurationProp = ownProps.configuration || {}
	const isDisabledPart =
		disablePart ||
		!!configurationProp.loadingInitialAnalysis ||
		!!configurationProp.skippedInitialAnalysis

	const configurationOrganizationId =
		calculateWithOrganizationId || configurationProp.organizationId
	const configurationOrganizationSettings =
		allConfigurationsOrganizationSettings[configurationOrganizationId] || {}

	return {
		...(SolutionAnalysisReducer.states[configurationProp.id] ||
			new SolutionAnalysisInitialState()),
		solutionFetched: fetched,
		solutionColor: color || defaultSolutionColor,
		showAnalysis,
		isAmOriginalMaterial,
		filters,
		priorities,
		printers: configurationOrganizationSettings.printers || printers,
		printingTechnologies,
		materials: configurationOrganizationSettings.materials || materials,
		partMaterial,
		allOptionalPostProcessesData:
			configurationOrganizationSettings.allOptionalPostProcessesData ||
			allOptionalPostProcessesData,
		optionalPostProcessesBreakDown,
		optionalPostProcessAvailability:
			configurationOrganizationSettings.optionalPostProcessAvailability ||
			optionalPostProcessAvailability,
		threeDViewerURL,
		partId,
		projectId,
		clusterId,
		userCurrencySign,
		printersFullData:
			configurationOrganizationSettings.printersFullData || printersFullData,
		WeightReductionMaterialsData,
		weightReductionPartsLeadingData,
		initialToleranceValue,
		partSolution: solutions?.find(
			s => s.id === configurationProp?.solution?.id
		),
		solutionFea: partFeas?.find(
			pF =>
				(pF.configuration?.id || pF.configurationId) === configurationProp?.id
		),
		configurationPrintIssues: partPrintIssues.filter(
			partPrintIssue =>
				partPrintIssue.configurationId === configurationProp?.id ||
				!partPrintIssue.configurationId
		),
		partPrintIssues,
		feaId,
		configurations,
		currentStepTargetId,
		tourConfigurationId,
		isWeightReductionPart,
		printIssues,
		updateQuantityLoader,
		updateConfigurationNameLoader,
		materialCategories,
		disablePart: isDisabledPart,
		userProviders,
		userEmail: email,
		isOnPrem,
		removeConfigurationLoading,
		sendToLoader,
		printerMaterials:
			configurationOrganizationSettings.printerMaterials || printerMaterials,
		printerMaterialsSubCategories:
			configurationOrganizationSettings.printerMaterialsSubCategories ||
			printerMaterialsSubCategories,
		printerMaterialsCategories:
			configurationOrganizationSettings.printerMaterialsCategories ||
			printerMaterialsCategories,
		allConfigurationsOrganizationSettings
	}
}

const mapDispatchToProps = (dispatch: DispatchProp<AnyAction>) =>
	bindActionCreators(
		{
			...SolutionAnalysisActions,
			...IntegrationProviderActions,
			...theme.actions,
			change,
			incrementTourStep
		},
		dispatch
	)

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(memo(SolutionAnalysis))
