import React, { FC, memo } from 'react'

import {
	ListItemText,
	MenuItem,
	Select,
	TableCell,
	TableRow,
	withStyles
} from '@material-ui/core'
import Close from '@material-ui/icons/Close'
import EditIcon from '@material-ui/icons/Edit'
import cx from 'classnames'

import { useInHousePrinterRow } from './InHousePrinterHooks'
import CastorCheckbox from 'Scenes/Components/CastorCheckbox'
import FlexBox from 'Scenes/Components/FlexBox'
import { dynamicSort } from 'Scenes/Components/MaterialSelector/Common/MultipleMaterialSelector'
import NumberField from 'Scenes/Components/NumberField'
import { Button } from 'Scenes/Components/thirdParty/CreativeTim/components'
import tableStyle from 'Scenes/Components/thirdParty/CreativeTim/variables/styles/tableStyle'
import TransparentButton from 'Scenes/Components/TransparentButton'
import {
	MIN_NUMBER_FIELD_VALUE,
	NAME,
	ORGANIZATION_ID
} from 'Services/Constants'
import { Material } from 'Services/models/IMaterial'
import { MATERIAL_COST_DELETE_ITEM } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

const emptyOption = '0'

const tableStyleTS: any = tableStyle

type IProps = {
	printerCompany: string
	printerName: string
	printerMaterials: Material[]
	printerAmount: number
	editMode: boolean
	classes?: Record<string, any>
	recordId: number
	onDeleteClick: () => void
	onEditClick: () => void
	onCancelClick: () => void
}

const InHousePrinterRow: FC<IProps> = ({
	printerCompany,
	printerName,
	printerMaterials,
	printerAmount,
	recordId,
	editMode,
	classes,
	onDeleteClick,
	onEditClick,
	onCancelClick
}) => {
	const {
		loading,
		company,
		name,
		amount,
		selectedMaterialNames,
		printersCompanies,
		materials,
		applyIsDisabled,
		onApplyClick,
		onCompanyChange,
		onPrinterNameChange,
		onMaterialChange,
		onAmountChange
	} = useInHousePrinterRow(
		printerMaterials,
		printerCompany,
		printerName,
		printerAmount,
		recordId,
		editMode,
		emptyOption
	)

	const tableCellClass = cx(
		classes?.tableCell,
		classes?.materialCostTableCell,
		'in-house-printers-selector'
	)

	const renderPrinterNamesMenuItems = (printers?: Record<string, any>[]) => {
		return printers && printers.length ? (
			printers
				.map((printer: Record<string, any>) => {
					return (
						<MenuItem key={printer.name} value={printer.name}>
							{printer.name}
						</MenuItem>
					)
				})
				.sort(
					(
						firstPrinter: Record<string, any>,
						secondPrinter: Record<string, any>
					) => firstPrinter.key.localeCompare(secondPrinter.key)
				)
		) : (
			<div />
		)
	}

	const renderMaterialNamesMenuItems = () => {
		return materials && materials.length ? (
			materials
				.sort(dynamicSort([ORGANIZATION_ID, NAME]))
				.map(printerMaterial => {
					return (
						<MenuItem key={printerMaterial.id} value={printerMaterial.name}>
							<CastorCheckbox
								checked={
									selectedMaterialNames.indexOf(printerMaterial.name) > -1
								}
							/>
							<ListItemText primary={printerMaterial.name} />
						</MenuItem>
					)
				})
		) : (
			<div />
		)
	}

	if (!editMode) {
		return (
			<TableRow className="in-house-printers--table-row">
				<TableCell className={tableCellClass}>{printerCompany}</TableCell>
				<TableCell className={tableCellClass}>{printerName}</TableCell>
				<TableCell className={tableCellClass}>
					{printerMaterials?.map(material => (
						<div key={material.id}>{material.name}</div>
					))}
				</TableCell>
				<TableCell className={tableCellClass}>{printerAmount}</TableCell>
				<TableCell className={tableCellClass}>
					<div>
						<Button
							title={getString('EDIT_ITEM')}
							onClick={onEditClick}
							color="dangerNoBackground"
							style={{ margin: 'unset', padding: 'unset' }}
							customClass={classes?.actionButton}
						>
							<EditIcon
								className={cx(classes?.icon, 'edit-in-house-printer')}
							/>
						</Button>
						<Button
							title={MATERIAL_COST_DELETE_ITEM}
							onClick={onDeleteClick}
							color="dangerNoBackground"
							style={{ margin: 'unset', padding: 'unset' }}
							customClass={classes?.actionButton}
						>
							<Close className={classes?.icon} />
						</Button>
					</div>
				</TableCell>
			</TableRow>
		)
	}

	return (
		<TableRow className="in-house-printers--table-row editMode">
			<TableCell className={tableCellClass}>
				<Select
					className="select"
					value={company}
					onChange={e => onCompanyChange(e.target.value as string)}
					MenuProps={{
						style: { zIndex: 99999 },
						variant: 'menu',
						getContentAnchorEl: null,
						PaperProps: {
							style: {
								transform: 'translate3d(0, 0, 0)'
							}
						}
					}}
				>
					<MenuItem value={emptyOption} disabled>
						{getString('IN_HOUSE_PRINTERS_COMPANY_SELECTOR_NAME')}
					</MenuItem>
					{Object.keys(printersCompanies).map(company => {
						return (
							<MenuItem key={company} value={company}>
								{company}
							</MenuItem>
						)
					})}
				</Select>
			</TableCell>

			<TableCell className={tableCellClass}>
				<Select
					className="select"
					value={name}
					onChange={e => onPrinterNameChange(e.target.value as string)}
					MenuProps={{
						style: { zIndex: 99999 },
						variant: 'menu',
						getContentAnchorEl: null,
						PaperProps: {
							style: {
								transform: 'translate3d(0, 0, 0)'
							}
						}
					}}
					data-qa="data-qa-printer-name"
				>
					<MenuItem value={emptyOption} disabled>
						{getString('IN_HOUSE_PRINTERS_NAME_SELECTOR_NAME')}
					</MenuItem>
					{renderPrinterNamesMenuItems(printersCompanies[company])}
				</Select>
			</TableCell>

			<TableCell className={tableCellClass}>
				<Select
					className="select"
					multiple
					value={
						selectedMaterialNames.length
							? selectedMaterialNames
							: [getString('ADMIN_MATERIALS_PAGE_HEADER')]
					}
					onChange={e => onMaterialChange(e.target.value as string[])}
					MenuProps={{
						style: { zIndex: 99999 },
						variant: 'menu',
						getContentAnchorEl: null
					}}
					renderValue={(selected: any) => selected.join(', ')}
					data-qa="data-qa-printer-materials"
				>
					<MenuItem value="0" disabled>
						{getString('ADMIN_MATERIALS_PAGE_HEADER')}
					</MenuItem>
					{renderMaterialNamesMenuItems()}
				</Select>
			</TableCell>
			<TableCell className={tableCellClass}>
				<NumberField
					allowEmpty={false}
					value={amount}
					onChangeValue={onAmountChange}
					inputClass="number-text-field"
					idInput="plastic-form-quantity"
					placeholder="1"
					minValue={MIN_NUMBER_FIELD_VALUE}
				/>
			</TableCell>
			<TableCell className={tableCellClass}>
				<FlexBox flexDirection="row" alignItems="baseline">
					<TransparentButton
						className="custom-input--action"
						disabled={false}
						onClick={onCancelClick}
						loading={false}
					>
						{getString('CANCEL')}
					</TransparentButton>
					<TransparentButton
						className="custom-input--action"
						disabled={applyIsDisabled}
						onClick={onApplyClick}
						loading={loading}
					>
						{getString('APPLY')}
					</TransparentButton>
				</FlexBox>
			</TableCell>
		</TableRow>
	)
}

export default memo(withStyles(tableStyleTS)(InHousePrinterRow))
