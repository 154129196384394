import { CADAnalysisResult } from './CADAnalysisResult'
import { IChainBenefits } from './IChainBenefits'
import { ICncProps } from './ICncProps'
import { IConfiguration } from './IConfiguration'
import { Material } from './IMaterial'
import { Project } from './IProject'
import { ISolutionBenefit } from './ISolutionBenefit'
import { FeaResult } from './ISolutionFea'
import { IOwner } from './IUser'
import { WallThickness } from './IWallThickness'

export enum PartStatus {
	awaitingFile = 'awaitingFile',
	toBeSentForAnalysis = 'toBeSentForAnalysis',
	awaitingAnalysis = 'awaitingAnalysis',
	awaitingForBOM = 'awaitingForBOM',
	toBeSentForWallThickness = 'toBeSentForWallThickness',
	awaitingCombinedHeatmap = 'awaitingCombinedHeatmap',
	complete = 'complete',
	failed = 'failed',
	published = 'published',
	rejected = 'rejected',
	missingInformation = 'missingInformation',
	awaitingTrayOrientation = 'awaitingTrayOrientation',
	awaitingWallThickness = 'awaitingWallThickness'
}

export enum WeightReductionType {
	NOT_TESTED_FOR_WR = 'notTestedForWR',
	NOT_SUITABLE_FOR_WR = 'notSuitableForWR',
	WR_SUCCEED = 'weightReductionSucceed',
	WR_FAILED = 'weightReductionFailed',
	SUITABLE_FOR_WR = 'suitableForWR',
	FAST_WEIGHT_REDUCED = 'fastWeightReduced',
	COMPLEX_WEIGHT_REDUCED = 'complexWeightReduced',
	PREPARING_FOR_WEIGHT_REDUCTION = 'preparingForWeightReduction',
	AWAITING_WEIGHT_REDUCTION = 'awaitingWeightReduction'
}

export enum FormatType {
	threeD = '3D',
	pdf = '2D',
	threeDByPDF = '3Dby2D',
	metadata = 'metadata'
}

export interface Box3d {
	xmax: number
	xmin: number
	ymax: number
	ymin: number
	zmax: number
	zmin: number
}

export interface Point3d {
	x: number
	y: number
	z: number
}

export type CompositionSetPart = {
	partId: string
	transformationMatrixHash: string
	isLeaf: boolean
}

//TODO: remove any
export interface Part {
	id: number
	partFileName: string
	partNumber: string
	partName: string
	projectId: string
	project: Project
	ownerId: number
	owner: IOwner
	benefits: ISolutionBenefit[]
	countInAssembly: number
	solidsCount: number
	actuallQuantity: number
	originalCost: number
	castToolingCost?: number
	investmentCastToolingCost?: number
	moldCost: number
	moldPartCost: number
	moldMaintenanceCost: number
	DFMCosts: number
	printer: string
	materialId: string
	material: Material
	serviceBureau: string
	fileURL: string
	imageURL: string
	stlURL: string
	prcURL: string
	wallThicknessExternalId: number
	width: number
	depth: number
	height: number
	area: number
	volume: number
	transformationMatrix: number[]
	facetsCount: number
	shellsCount: number
	invNormalsCount: number
	badEdgesCount: number
	holesCount: number
	isClosed: boolean
	isOriented: boolean
	isManifolded: boolean
	isNotSelfIntersected: boolean
	isThick: boolean
	externalId: string
	parentAssemblyId: string | null
	isOffTheShelf: boolean
	needsHighAccuracy: boolean
	needsSurfaceFinish: boolean
	status: PartStatus
	result: CADAnalysisResult
	wallThicknessData?: WallThickness[]
	sizeScore?: number
	sizeFailReason?: string
	materialScore?: number
	materialFailReason?: string
	CADScore?: number
	CADFailReason?: string
	errorMessage?: string
	errors?: any[]
	warnings?: any[]
	notes?: string
	starred: boolean
	calledDiagnose: number
	relatedParts?: any[]
	compositionSetParts?: CompositionSetPart[]
	externalIdOld: string
	cncProps: ICncProps
	lock: boolean
	stepURL: string
	hasBrepData: boolean
	weightReductionType: WeightReductionType
	weightReducedStlURL?: string
	weightReducedVolume?: number
	chainBenefits?: IChainBenefits
	feaResult?: FeaResult
	tolerances?: any
	customToleranceValue: string
	expectedYearsOfDemand?: number
	boundingBox?: Box3d
	cog?: Point3d
	isDrawing?: boolean
	productLifeCycle?: string
	tmInitialTechnologySetupTime?: string
	quantity?: number
	isTooling?: boolean
	standardCost?: any
	partThreadsDataCollection?: any
	isTopLevelAssembly?: boolean
	formatType?: FormatType
	enginePublished?: any
	uniqueSolidsCount?: number
	healedStlURL?: string
	combinePdfUrl?: string
	meshHealingOperated?: any
	defaultOrientationVector?: any
	fullTrayAssumption?: boolean
	downloaded?: any
	trayOrientations?: any
	partHolesData?: any
	sheetMetal?: boolean
	smallPart?: boolean
	isScrew?: boolean
	isNut?: boolean
	isPipe?: boolean
	isRod?: boolean
	canCombine?: boolean
	isReanalysing?: boolean
	analysisWithOrientation?: boolean
	moq?: any
	removalRateCNC?: any
	originalMaterialMatch?: any
	packingDensity?: any
	retrieveResultsPreformed?: any
	puYearsCO2?: any
	disposeFactor?: any
	puAnnualKgCO2?: any
	mass?: any
	externalDataAnalysis?: any
	drawingStatusCode?: any
	firstAnalysisEnded?: boolean
	disregardMaterial?: boolean
	recalculateSolutions?: boolean
	recalculateProject?: boolean
	failOnVolume?: boolean
	clusterId?: string
	createdAt?: string
	updatedAt?: string
	isChargedForSubscription?: boolean
	isManufacturingShelf?: boolean
	reducedVolumePercentage?: number
	cncPrintingData?: boolean
	partComplexityScore?: number
	partBuyToFlyScore?: number
	blockManufacturingMethodOperation?: boolean
	grabCadPrint?: boolean
	meshUploadURL?: string
	stlUploadURL?: string
	rate?: number
	configurationsCount?: number
	isSpecifiedQuantity?: boolean
	isDefaultMaterial?: boolean
	isUsedByCluster?: boolean
	unprintableReason?: string
	extraUnprintableReasonsCount?: number
	thumbnailURL?: string
	healedStlURLExist?: boolean
	initialBatchSize: number
	weightReductionDensity?: number | null
	materialName?: string
	printingStandards?: string | null
	printingStandardsAlertAccepted?: boolean
	parts?: Part[]
	configurations?: IConfiguration[]
	isAmOriginalMaterial?: boolean
	printerMaterialName?: string
	organizationId: number | null
	isWeightReductionLoading?: boolean
	isLeaf?: boolean
	transformationMatrixHash?: string
}

export interface LocationPartsParams {
	isWeightReduction: any
	pathname: string
	search: string
	hash: string
	key: string
	state: {
		isWeightReduction?: boolean
	}
}
