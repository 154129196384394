import React, { FC, useCallback, useEffect, useMemo } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { projectOrganizationChanged } from 'global actions'
import OrganizationSelector from 'Scenes/Components/OrganizationSelector'
import WithFeatureToggleHOC from 'Services/HOC/WithFeatureToggleHOC'
import { FeatureComponentId } from 'Services/models/Features'
import { getString } from 'Services/Strings/StringService'
import { renderOrganizationValue } from 'Services/Utils/organizationTools'

type IProps = {
	comsTool: boolean
}

const UploadOrganizationSelector: FC<IProps> = ({ comsTool = false }) => {
	const { availableOrganizations, organizationDetails } = useSelector(
		(state: RootStateOrAny) => state.user
	)
	const { projectOrganizationId } = useSelector(
		(state: RootStateOrAny) => state.uploadProject
	)
	const dispatch = useDispatch()

	const title = useMemo(
		() =>
			renderOrganizationValue(availableOrganizations, projectOrganizationId),
		[availableOrganizations, projectOrganizationId]
	)

	const renderValue = useCallback(
		(selected: unknown) =>
			renderOrganizationValue(availableOrganizations, selected as number),
		[availableOrganizations]
	)

	const handleOrganizationSelect = useCallback(
		(value: number) => {
			dispatch(projectOrganizationChanged(value, false, comsTool))
		},
		[comsTool, dispatch]
	)

	useEffect(() => {
		if (!projectOrganizationId) {
			const organizationId =
				organizationDetails?.id || availableOrganizations[0].id
			dispatch(projectOrganizationChanged(organizationId, true, comsTool))
		}
	}, [
		availableOrganizations,
		comsTool,
		dispatch,
		organizationDetails?.id,
		projectOrganizationId
	])

	if (availableOrganizations.length === 0) {
		return null
	}

	return (
		<div className="new-upload-project--organization-selector">
			<label htmlFor="upload-with-organization">
				{getString('REGISTER_FORM_ORGANIZATION_LABEL')}
			</label>
			<OrganizationSelector
				id="upload-with-organization"
				value={projectOrganizationId}
				renderValue={renderValue}
				handleOrganizationSelect={handleOrganizationSelect}
				title={title}
				disabledMenuItem={comsTool}
			/>
		</div>
	)
}

export default WithFeatureToggleHOC(
	UploadOrganizationSelector,
	FeatureComponentId.CUSTOMIZE_ORGANIZATIONS
)
