import { cloneDeep } from 'lodash'

import {
	PART_ADMIN_ALL_CLUSTER_PUBLISHED,
	PART_ADMIN_CHECKBOX_SELECTED,
	PART_ADMIN_CLUSTER_PUBLISHED,
	PART_ADMIN_CLUSTER_REJECTED,
	PART_ADMIN_CLUSTER_REMOVED,
	PART_ADMIN_DELETE_PARTS,
	PART_ADMIN_DELETE_PARTS_SUCCESS,
	PART_ADMIN_DOWNLOAD_STL,
	PART_ADMIN_DOWNLOAD_STL_COMPLETE,
	PART_ADMIN_DOWNLOAD_STL_SUCCESS,
	PART_ADMIN_GET_PARTS,
	PART_ADMIN_GET_PARTS_SUCCESS,
	PART_ADMIN_MERGE_PARTS,
	PART_ADMIN_MERGE_PARTS_SUCCESS,
	PART_ADMIN_MORE_CLUSTER_PARTS_FETCHED,
	PART_ADMIN_MORE_CLUSTERS_FETCHED,
	PART_ADMIN_MORE_PARTS_FETCHED,
	PART_ADMIN_PROJECT_CLUSTER_STATUS_CHANGED,
	PART_ADMIN_PUBLISH_ALL_CLUSTER_TOGGLED,
	PART_ADMIN_PUBLISH_CLUSTER_TOGGLED,
	PART_ADMIN_REJECT_CLUSTER_TOGGLED,
	PART_ADMIN_REMOVE_CLUSTER_TOGGLED,
	PART_ADMIN_SELECTED_ACTION,
	PART_ADMIN_SERVER_CALL_FAILED,
	PART_ADMIN_UPDATE_CLUSTER_TOGGLED,
	UPDATE_PROJECT_CLUSTER_STATUS
} from '../../../../global actions/types'
import { ProjectClusterStatus } from '../../../../Services/models/IProject'
import { getString } from '../../../../Services/Strings/StringService'
import { getClustersStatuses } from 'Scenes/Home/ProjectAnalysis/ProjectAnalysisService'

const INITIAL_STATE = {
	loading: false,
	error: null,
	parts: [],
	clusters: [],
	showingSimpleAlertTitle: null,
	showingSimpleAlertText: null,
	selectedAction: '',
	selectedPartsIds: [],
	stlDownloadURL: null,
	publishAllClustersLoading: false,
	updateClusterLoading: false,
	projectHasMoreParts: false,
	projectHasMoreClusters: false,
	publishClusterLoaders: {},
	rejectClusterLoaders: {},
	removeClusterLoaders: {},
	clusterStructure: '',
	projectClusterHeaderText: '',
	setMaxHeightForPartsTable: false,
	setMaxHeightForClustersTable: false,
	allProjectParts: []
}
const adminPartsReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case PART_ADMIN_MERGE_PARTS:
		case PART_ADMIN_DELETE_PARTS:
		case PART_ADMIN_GET_PARTS:
			return { ...state, loading: true, error: null }
		case PART_ADMIN_SERVER_CALL_FAILED:
			return { ...state, loading: false, error: action.payload }
		case PART_ADMIN_GET_PARTS_SUCCESS: {
			const {
				parts,
				clusters,
				clusterStructure,
				projects,
				displayComplex,
				projectHasMoreParts,
				projectHasMoreClusters,
				allProjectParts
			} = action.payload

			parts.forEach(part => {
				part.selected = false
				part.displayComplex = displayComplex
			})

			let clusterStatus
			const clusterStatuses = projects.map(project => project.clusterStatus)
			if (
				clusterStatuses.length === 1 ||
				clusterStatuses.every(status => status === clusterStatuses[0])
			) {
				clusterStatus = clusterStatuses[0]
			} else {
				clusterStatus = getClustersStatuses(projects, clusters.length)
			}

			let projectClusterHeaderText = getProjectClusterHeaderText(clusterStatus)
			return {
				...state,
				loading: false,
				parts,
				clusters,
				showingSimpleAlertTitle: null,
				showingSimpleAlertText: null,
				selectedPartsIds: [],
				clusterStructure,
				projectClusterHeaderText,
				projectHasMoreParts,
				projectHasMoreClusters,
				allProjectParts,
				setMaxHeightForPartsTable: projectHasMoreParts,
				setMaxHeightForClustersTable: projectHasMoreClusters
			}
		}
		case UPDATE_PROJECT_CLUSTER_STATUS: {
			const { projectClusterStatus } = action.payload
			let projectClusterHeaderText = state.projectClusterHeaderText
			let clusters = state.clusters

			if (projectClusterStatus) {
				projectClusterHeaderText =
					getProjectClusterHeaderText(projectClusterStatus)
				clusters = []
			}
			return {
				...state,
				clusters,
				projectClusterHeaderText
			}
		}

		case PART_ADMIN_DELETE_PARTS_SUCCESS:
		case PART_ADMIN_MERGE_PARTS_SUCCESS:
			const { parts, projectHasMoreParts, displayComplex } = action.payload
			parts.forEach(part => {
				part.selected = false
				part.displayComplex = displayComplex
			})
			return {
				...state,
				loading: false,
				parts,
				showingSimpleAlertTitle: null,
				showingSimpleAlertText: null,
				selectedPartsIds: [],
				projectHasMoreParts
			}

		case PART_ADMIN_MORE_PARTS_FETCHED: {
			const { parts, projectHasMoreParts, displayComplex } = action.payload
			parts.forEach(part => {
				part.selected = false
				part.displayComplex = displayComplex
			})
			return {
				...state,
				parts: parts ? state.parts.concat(parts) : state.parts,
				projectHasMoreParts
			}
		}

		case PART_ADMIN_MORE_CLUSTERS_FETCHED: {
			const { clusters, projectHasMoreClusters } = action.payload
			return {
				...state,
				clusters: clusters ? state.clusters.concat(clusters) : state.clusters,
				projectHasMoreClusters
			}
		}

		case PART_ADMIN_MORE_CLUSTER_PARTS_FETCHED: {
			const { clusterParts, clusterId } = action.payload
			const clusters = cloneDeep(state.clusters)
			const cluster = clusters.find(
				cluster => cluster.id === parseInt(clusterId)
			)
			cluster.parts = clusterParts
				? cluster.parts?.concat(clusterParts)
				: cluster.parts
			return {
				...state,
				clusters
			}
		}
		case PART_ADMIN_SELECTED_ACTION:
			return { ...state, selectedAction: action.payload }
		case PART_ADMIN_CHECKBOX_SELECTED:
			const part = action.payload
			const selectedPartsIds = state.selectedPartsIds.slice()
			const indexIfSelected = selectedPartsIds.indexOf(part.id)
			const selected = indexIfSelected > -1
			part.selected = selected
			if (selected) {
				selectedPartsIds.splice(indexIfSelected, 1)
			} else {
				selectedPartsIds.push(part.id)
			}
			return { ...state, selectedPartsIds }
		case PART_ADMIN_DOWNLOAD_STL:
			return { ...state, loading: false, error: null }
		case PART_ADMIN_DOWNLOAD_STL_COMPLETE:
			return { ...state, stlDownloadURL: null }
		case PART_ADMIN_DOWNLOAD_STL_SUCCESS:
			return { ...state, stlDownloadURL: action.payload, load: false }
		case PART_ADMIN_PUBLISH_ALL_CLUSTER_TOGGLED:
			return {
				...state,
				publishAllClustersLoading: !state.publishAllClustersLoading
			}
		case PART_ADMIN_ALL_CLUSTER_PUBLISHED:
			const { clusters } = action.payload
			return { ...state, publishAllClustersLoading: false, clusters }
		case PART_ADMIN_PUBLISH_CLUSTER_TOGGLED:
			const { clusterId } = action.payload
			return {
				...state,
				publishClusterLoaders: {
					...state.publishClusterLoaders,
					[clusterId]: !state.publishClusterLoaders[clusterId]
				}
			}
		case PART_ADMIN_CLUSTER_PUBLISHED: {
			const { clusters, clusterId } = action.payload
			return {
				...state,
				publishClusterLoaders: {
					...state.publishClusterLoaders,
					[clusterId]: false
				},
				clusters
			}
		}

		case PART_ADMIN_REJECT_CLUSTER_TOGGLED: {
			const { clusterId } = action.payload
			return {
				...state,
				rejectClusterLoaders: {
					...state.rejectClusterLoaders,
					[clusterId]: !state.rejectClusterLoaders[clusterId]
				}
			}
		}
		case PART_ADMIN_CLUSTER_REJECTED: {
			const { clusters, clusterId } = action.payload
			return {
				...state,
				rejectClusterLoaders: {
					...state.rejectClusterLoaders,
					[clusterId]: false
				},
				clusters
			}
		}
		case PART_ADMIN_PROJECT_CLUSTER_STATUS_CHANGED:
			const { projectClusterStatus } = action.payload
			let projectClusterHeaderText = state.projectClusterHeaderText
			if (projectClusterStatus) {
				projectClusterHeaderText =
					getProjectClusterHeaderText(projectClusterStatus)
			}
			return {
				...state,
				projectClusterHeaderText
			}
		case PART_ADMIN_REMOVE_CLUSTER_TOGGLED: {
			const { clusterId } = action.payload
			return {
				...state,
				removeClusterLoaders: {
					...state.removeClusterLoaders,
					[clusterId]: !state.removeClusterLoaders[clusterId]
				}
			}
		}
		case PART_ADMIN_CLUSTER_REMOVED: {
			const { clusters, clusterId } = action.payload
			return {
				...state,
				removeClusterLoaders: {
					...state.removeClusterLoaders,
					[clusterId]: false
				},
				clusters
			}
		}

		case PART_ADMIN_UPDATE_CLUSTER_TOGGLED:
			return {
				...state,
				updateClusterLoading: !state.updateClusterLoading
			}
		default:
			return state
	}
}

const getProjectClusterHeaderText = clusterStatus => {
	let projectClusterHeaderText = ''
	switch (clusterStatus) {
		case ProjectClusterStatus.awaitingAnalysis:
			projectClusterHeaderText += getString('AWAITING_ANALYSIS')
			break
		case ProjectClusterStatus.complete:
			projectClusterHeaderText += getString('COMPLETE')
			break
		case ProjectClusterStatus.failed:
			projectClusterHeaderText += getString('FAILED')
			break
		case ProjectClusterStatus.noClustersFound:
			projectClusterHeaderText += getString('NO_UNIFICATIONS_FOUND')
			break
		default:
			projectClusterHeaderText = getString(
				'ADMIN_UNIFICATIONS_NO_UNIFICATIONS_FOUND'
			)
			break
	}
	return projectClusterHeaderText
}

export default adminPartsReducer
