import { Dispatch } from 'react'
import { AnyAction } from 'redux'

import {
	HANDLE_LOADER,
	HANDLE_NOTIFICATION,
	PART_CONSOLIDATION_SETTINGS_CHECKBOX_CHANGED,
	PART_CONSOLIDATION_SETTINGS_FETCHED,
	PART_CONSOLIDATION_SETTINGS_LOADER_CHANGED
} from 'global actions/types'
import { PartConsolidationSettings } from 'Services/models/IPartConsolidationSettings'
import {
	getCustomizationSettings,
	updatePartConsolidationSettings
} from 'Services/Network'
import { SHOW_NOTIFICATION } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

export const setupPartConsolidationSetting = (organizationId?: number) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		try {
			dispatch({
				type: HANDLE_LOADER,
				payload: 1
			})

			const response = await getCustomizationSettings(organizationId)
			const partConsolidationSettings =
				response.data?.userSettings?.partConsolidationSettings || {}

			dispatch({
				type: PART_CONSOLIDATION_SETTINGS_FETCHED,
				payload: { partConsolidationSettings }
			})

			dispatch({
				type: HANDLE_LOADER,
				payload: -1
			})
		} catch (err) {
			console.error(err)
			dispatch({
				type: HANDLE_LOADER,
				payload: -1
			})
		}
	}
}

export const onPCCheckboxChange = (
	key: keyof PartConsolidationSettings,
	value: boolean
) => {
	return {
		type: PART_CONSOLIDATION_SETTINGS_CHECKBOX_CHANGED,
		payload: { key, value }
	}
}

export const onUpdatePartConsolidationSettings = (
	partConsolidationSettings: PartConsolidationSettings,
	organizationId?: number
) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		try {
			dispatch({
				type: PART_CONSOLIDATION_SETTINGS_LOADER_CHANGED,
				payload: true
			})

			const response = await updatePartConsolidationSettings(
				partConsolidationSettings,
				organizationId
			)
			const updatedPartConsolidationSettings =
				response.data?.userSettings?.partConsolidationSettings || {}

			dispatch({
				type: PART_CONSOLIDATION_SETTINGS_FETCHED,
				payload: { partConsolidationSettings: updatedPartConsolidationSettings }
			})

			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.SUCCESS,
					notificationMessage: getString(
						'PART_CONSOLIDATION_SETTINGS_UPDATED_SUCCESS'
					)
				}
			})
		} catch (err) {
			console.error(err)
			dispatch({
				type: PART_CONSOLIDATION_SETTINGS_LOADER_CHANGED,
				payload: false
			})
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.WARNING,
					notificationMessage: getString(
						'PART_CONSOLIDATION_SETTINGS_UPDATED_ERROR'
					)
				}
			})
		}
	}
}
