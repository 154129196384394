import { getJsonItemFromLocalStorage } from '../LocalStorageService'

export const strings = {
	ACCEPT: 'Accept',
	SOMETHING_WENT_WRONG: 'Something went wrong',
	CONNECTION_LOST:
		'Connection with server is lost, please refresh the page or try later',
	CONTEXT_ERROR: 'Context error',
	RELOAD_THE_PAGE: 'Reload the page',
	ACTION_UNAUTHORIZED_MASSAGE: 'You are not authorized to perform this action',
	ADD: 'add',
	ADD_FILTER_FEATURES_TITLE:
		'Add the important filters for your application needs.',
	ADD_FILTER_FIELDS_TITLE: 'Please set your preferences.',
	ADD_IMAGE_DROPZONE_DROPZONE_HAS_FILES:
		'An image of the part was added. To replace it drag and drop a different image here',
	ADD_IMAGE_DROPZONE_NO_FILES: 'Drop an image of the part here',
	ADD_NEW: 'Add new',
	ADD_PART_RESULT_SUBMIT_BUT: 'Submit',
	ADD_TO_FAVORITES: 'Add to saved',
	ADDED_PART_MESSAGE: 'The part analysis was added.',
	ADMIN_MATERIAL_PARAMS: [
		'name',
		'type',
		'material properties',
		'strength',
		'organization id',
		'organization owner',
		'id'
	],
	ADMIN_MATERIAL_PRINTER_PAIRS_ADDED_SUCCESS: 'MaterialPrinterPair added',
	ADMIN_MATERIAL_PRINTER_PAIRS_EDIT: 'Edit material printer pair',
	ADMIN_MATERIAL_PRINTER_PAIRS_ERROR: 'Error recieving data',
	ADMIN_MATERIAL_PRINTER_PAIRS_NEW: 'New material printer pair',
	ADMIN_MATERIAL_PRINTER_PAIRS_NEW_ERROR: 'Error adding material printer pair',
	ADMIN_MATERIAL_PRINTER_PAIRS_NEW_MATERIAL_PRINTER_PAIR:
		'New material printer pair',
	ADMIN_MATERIAL_PRINTER_PAIRS_NO_MATERIAL_PRINTER_PAIRS_FOUND:
		'No material printer pairs found',
	ADMIN_MATERIAL_PRINTER_PAIRS_PAGE_HEADER:
		'Printer Material Unique Build Rate',
	ADMIN_MATERIAL_PRINTER_PAIRS_PARAMS: [
		'printer',
		'printer material',
		'build rate [mm³/hr]',
		'id'
	],
	ADMIN_MATERIAL_PRINTER_PAIRS_SUB_TITLE: 'List of all material printer pairs',
	ADMIN_MATERIAL_PRINTER_PAIRS_TABLE_TEXTS: {
		ID: 'Id',
		NAME: 'Name'
	},
	ADMIN_MATERIAL_PRINTER_PAIRS_UPDATE_ERROR:
		'Error updating material printer pair',
	ADMIN_MATERIAL_PRINTER_PAIRS_UPDATE_SUCCESS: 'MaterialPrinterPair updated',
	ADMIN_MATERIAL_TABLE_TEXTS: {
		WALL_THICKNESS: 'thickness',
		DENCITY: 'Density',
		YIELD_STRENGTH: 'Yield Strength [MPa]',
		ELONGATION: 'Elongation at Break [%]',
		YOUNGS_MODULUS: "Young's Modulus"
	},
	ADMIN_MATERIALS_ADDED_SUCCESS: 'Material added',
	ADMIN_MATERIALS_EDIT: 'Edit material',
	ADMIN_MATERIALS_ERROR: 'Error recieving data',
	ADMIN_MATERIALS_NEW: 'New material',
	ADMIN_MATERIALS_NEW_USER_MATERIAL:
		'New Custom Traditional Manufacturing Material',
	ADMIN_MATERIALS_NEW_ERROR: 'Error adding material',
	ADMIN_MATERIALS_NEW_MATERIAL: 'New material',
	ADMIN_MATERIALS_NO_MATERIALS_FOUND: 'No materials found',
	ADMIN_MATERIALS_PAGE_HEADER: 'Materials',
	ADMIN_MATERIALS_SUB_TITLE: 'List of all materials',
	ADMIN_MATERIALS_UPDATE_ERROR: 'Error updating material',
	ADMIN_MATERIALS_UPDATE_SUCCESS: 'Material updated',
	ADMIN_PANEL: 'Admin Panel',
	ADMIN_PARTS_AMOUNT_IN_ASSMBLY: 'Amount in Assembly',
	ADMIN_PARTS_AREA: 'Area',
	ADMIN_PARTS_CAD_SCORE: 'CAD score',
	ADMIN_PARTS_CLUSTER_PARTS_NO: 'Number of parts',
	ADMIN_PARTS_EXTERNAL_ID: 'External Id',
	ADMIN_PARTS_IS_OFF_THE_SHELF: 'is off the shelf',
	ADMIN_PARTS_ORIGINAL_MATERIAL: 'Original material',
	ADMIN_PARTS_PART_ID: 'Part Id',
	ADMIN_PARTS_RESULT: 'Result',
	ADMIN_PARTS_SIZE: 'Size [w * h * d]',
	ADMIN_PARTS_SIZE_SCORE: 'Size score',
	ADMIN_PARTS_STATUS: 'Status',
	ADMIN_PARTS_TRANSFORMATION_MATRIX_HASH: 'Transformation matrix hash',
	ADMIN_PARTS_VOLUME: 'Volume',
	ADMIN_PARTS_WALL_THICKNESS_SCORE: 'Thickness score',
	ADMIN_PRINTER_MATERIALS_ADDED_SUCCESS: 'Printer Material added',
	ADMIN_PRINTER_MATERIALS_EDIT: 'Edit Printer Material',
	ADMIN_PRINTER_MATERIALS_ERROR: 'Error recieving data',
	ADMIN_PRINTER_MATERIALS_NEW: 'New printer material',
	ADMIN_PRINTER_MATERIALS_NEW_ERROR: 'Error adding Printer Material',
	ADMIN_PRINTER_MATERIALS_NEW_PRINTER_MATERIAL: 'New Printer Material',
	ADMIN_PRINTER_MATERIALS_NO_PRINTER_MATERIALS_FOUND:
		'No Printer Materials found',
	ADMIN_PRINTER_MATERIALS_PAGE_HEADER: 'Printer Materials',
	ADMIN_PRINTER_MATERIALS_SUB_TITLE: 'List of all Printer Materials',
	ADMIN_PRINTER_MATERIALS_UPDATE_ERROR: 'Error updating Printer Material',
	ADMIN_PRINTER_MATERIALS_UPDATE_SUCCESS: 'Printer Material updated',
	ADMIN_PRINTER_NAMES_ADDED_SUCCESS: 'PrinterName added',
	ADMIN_PRINTER_NAMES_EDIT: 'Edit printerName',
	ADMIN_PRINTER_NAMES_ERROR: 'Error recieving data',
	ADMIN_PRINTER_NAMES_MOVE_TO_PRINTERS: 'Move to printers',
	ADMIN_PRINTER_NAMES_NEW: 'New printerName',
	ADMIN_PRINTER_NAMES_NEW_ERROR: 'Error adding printerName',
	ADMIN_PRINTER_NAMES_NEW_PRINTER_NAME: 'New printerName',
	ADMIN_PRINTER_NAMES_NO_PRINTER_NAMES_FOUND: 'No printerNames found',
	ADMIN_PRINTER_NAMES_PAGE_HEADER: 'Printer Names',
	ADMIN_PRINTER_NAMES_PARAMS: ['name', 'company', 'others', 'id'],
	ADMIN_PRINTER_NAMES_SUB_TITLE: 'List of all printerNames',
	ADMIN_PRINTER_NAMES_TABLE_TEXTS: {},
	ADMIN_PRINTER_NAMES_UPDATE_ERROR: 'Error updating printerName',
	ADMIN_PRINTER_NAMES_UPDATE_SUCCESS: 'PrinterName updated',
	ADMIN_PRINTERS_ADDED_SUCCESS: 'Printer added',
	USER_PRINTERS_ADDED_SUCCESS: 'Printer added',
	ADMIN_PRINTERS_EDIT: 'Edit printer',
	ADMIN_PRINTERS_ERROR: 'Error recieving data',
	ADMIN_PRINTERS_NEW: 'New printer',
	ADMIN_PRINTERS_NEW_ERROR: 'Error adding printer',
	ADMIN_PRINTERS_NEW_PRINTER: 'New printer',
	ADMIN_PRINTERS_NO_PRINTERS_FOUND: 'No printers found',
	ADMIN_PRINTERS_PAGE_HEADER: 'Printers',
	ADMIN_PRINTERS_PARAMS: [
		'name',
		'company',
		'technology',
		'tray size',
		'costs',
		'organization id',
		'organization owner',
		'id'
	],
	ADMIN_PRINTER_TECHNOLOGY_TABLE_HEADERS: [
		'Name',
		'Stack Models Vertically',
		'Machine Warmup Time',
		'Machine Cool down Time',
		'Support Material As Percent Of Part Mass',
		'Infrastructure cost',
		'Machine up time',
		'Additional operation cost',
		'Tray Density',
		'Use Support'
	],
	ADMIN_VERSION_PAGE_TABLE_HEADERS: [
		'Service Name',
		'Version',
		'Repeat Interval (Minutes)',
		'Last Update',
		'Status'
	],
	ADMIN_PRINTERS_SUB_TITLE: 'List of all printers',
	ADMIN_PRINTERS_TABLE_TEXTS: {
		TRAY_X: 'Tray-x',
		TRAY_Y: 'Tray-y',
		TRAY_Z: 'Tray-z',
		BUILT_RATE: 'Build rate',
		MACHINE_COST: 'Machine cost'
	},
	USER_PRINTERS_NAME_ALREADY_EXIST_ERROR:
		'This printer name is already taken, please try another',
	USER_PRINTERS_UPDATE_ERROR: 'Error updating printer',
	ADMIN_PRINTERS_UPDATE_ERROR: 'Error updating printer',
	ADMIN_PRINTERS_UPDATE_SUCCESS: 'Printer updated',
	ADMIN_PROJECTS_ASSEMBLY_OR_INDIVIDUAL: 'Assembly / Individual Parts project',
	ADMIN_PROJECTS_COMPANY: 'Company',
	ADMIN_PROJECTS_CREATED_AT: 'Created on',
	ADMIN_PROJECTS_PUBLISHED_AT: 'Publish date',
	ADMIN_PROJECTS_DEFAULT_MATERIAL: 'Default Material',
	ADMIN_PROJECTS_EMAIL: 'Email',
	ADMIN_PROJECTS_EXTERNAL_ASSEMBLY_FILE_ID: 'External Assembly File Id',
	ADMIN_PROJECTS_ID: 'Id',
	ADMIN_PROJECTS_MATERIAL_OR_BOM: 'material selected / BOM',
	ADMIN_PROJECTS_NAME: 'Name',
	ADMIN_PROJECTS_PARTS_IN_PROJECT: 'Parts in Project',
	ADMIN_PROJECTS_STATUS: 'Status',
	ADMIN_PROJECTS_FILTER_NAME: 'Filter project',
	ADMIN_PROJECTS_FILTER_NOT_PUBLISHED: 'Unpublished projects only',
	ADMIN_PROJECTS_FILTER_NOT_QA: 'Non QA projects only',
	ADMIN_PROJECTS_FILTER_NOT_ADMIN: 'Non-admin projects only',
	ADMIN_TABLE_ERROR: 'Error recieving data',
	ADMIN_TABLE_NO_DATA_FOUND: 'No data found',
	ADMIN_UNIFICATIONS_ERROR: 'Error recieving data',
	ADMIN_UNIFICATIONS_NO_UNIFICATIONS_FOUND: 'No unifications found',
	ADMIN_UNIFICATIONS_PAGE_HEADER: 'Unifications',
	ADMIN_USER_INFO_EDIT_HEADER: 'User',
	ADMIN_USER_INFO_EDIT_SUB_HEADER: 'Edit User Subscription',
	ADMIN_USERS_ADMIN_USER: 'Admin user',
	ADMIN_USERS_CHARGED: 'Charged',
	ADMIN_USERS_CUSTOMIZE_USER: 'customize',
	ADMIN_USERS_CUSTOMIZE_USER_ADDED: 'Customization added by the user',
	ADMIN_USER_LIGHT_USER: 'Light user',
	ADMIN_USERS_ERROR: 'Error recieving data',
	ADMIN_USERS_IS_TRIAL: 'User in trial',
	ADMIN_USERS_NO_USERS_FOUND: 'No users found',
	ADMIN_USERS_PAGE_HEADER: 'Users',
	ADMIN_USERS_SCANNED: 'Scanned',
	ADMIN_USERS_SORT_SUBSCRIPTION: 'Sort by subscription',
	ADMIN_USERS_SUBSCRIPTION_ENDS: 'Subscription ends',
	ADMIN_USERS_TRIAL_ENDS: 'Trial ends',
	ADMIN_USERS_VIEW_PROJECTS: 'view projects',
	ADMIN_USERS_VIEW_USER_INFO: 'view info',
	ADVANCED_MATERIAL_SETTINGS: 'Advanced materials settings',
	ADVANCED_SETTINGS: 'Advanced settings',
	ALL: 'all',
	AM_PRINTING_CHART_INDEX: '3D-Printing cost-effective price point',
	AMOUNT_FIELD_TECH_ANALYSIS: "Project's production batch size",
	ARE: 'are',
	ARE_YOU_SURE_DELETE: 'Are you sure you want to delete this item?',
	ASSEMBLY: 'Assembly',
	ASSEMBLY_DROP_FILE_EXPLANATION:
		'Drag and drop your CAD files into the area bellow. Files supported: STEP (or STP) assembly files',
	BANNER_PARTS_END_MSG:
		'You have reached the limit of uploads for this trial version. Please contact us to unlock your account.',
	BANNER_LICENSE_PARTS_END_MSG:
		'No more credits on license. Please contact your administrator',
	BANNER_PROJECT_QUEUED_TO_RECALCULATE:
		'The project is queued for update. It is recommended to wait for its completion since results might change.',
	BOM: 'BOM',
	BOM_RAIDO_TEXT_ON_UPLOAD:
		'Upload a BOM from your CAD software that specifies the material for each part',
	BUY_FULL_VERSION: 'I would like to buy full CASTOR software.',
	CAD_UNIT_TYPES: [
		{
			key: 'mm',
			name: 'Millimeters'
		},
		{
			key: 'cm',
			name: 'Centimeters'
		},
		{
			key: 'feet',
			name: 'Feet'
		},
		{
			key: 'inch',
			name: 'Inches'
		},
		{
			key: 'meter',
			name: 'Meters'
		}
	],
	CANCEL: 'Cancel',
	EXPORT_FILE_NAME: 'results.xlsx',
	START_DATE: 'Start Date',
	END_DATE: 'End Date',
	SELECT_START_AND_END_DATES: 'Select start and end dates for Export',
	CHANGE_QUANTITY: 'Change quantity',
	CHART_LABEL_INPROPER_CAD: 'CAD file problem',
	CHART_LABEL_OFFTHESHELF: 'Off-the-shelf',
	CHART_LABEL_SIZE_OUT_OF_BOUND: 'Size limit exceeded',
	CHART_LABEL_WALL_THICKNESS: 'Thickness',
	CHOOSE_MATERIAL_ON_UPLOAD_EXPLINATION:
		'Please set the materials for the uploaded parts/assembly',
	CITY: 'City',
	CLUSTER_STRUCTURE: 'Cluster structure',
	COMBINE_MULTIPLE_INTO_ONE_CARD_REQ_TXT:
		'CASTOR can scan the assembly to look for clusters of parts that can be combined into a single part for 3D-Printing. This reduces the complexity of the machine, saves assembly and repair costs, removes possible failure points such as valves, and can also reduce the accumulation of tolerances for example.',
	COMBINE_AROUND_THIS_PART_SEARCH_EMPTY:
		'We will search again for part consolidation opportunities, using the updated parts in the projects.',
	PART_CONSOLIDATION_OPPORTUNITIES: 'Parts Consolidation Opportunities',
	PART_CONSOLIDATION_OPPORTUNITIES_HELPER:
		'We automatically identify opportunities for clusters of parts that can be consolidated into a single part and check for its 3D printability. Consolidating multiple parts into one, reduces the complexity of an assembly, has the potential to save assembly time, reduce inventory management costs and can potentially improve mechanical properties.',
	SEARCH_AGAIN: 'Search again',
	WITH_ANY_MATERIAL: 'with any material type',
	WITH_SAME_MATERIAL: 'with the same material type',
	CONSOLIDATION_WILL_BE_OVERWRITTEN:
		'All current consolidation results will be overwritten',
	CONSOLIDATION_DELETE_RESULTS: 'delete current results and start new search',
	CONSOLIDATION_KEEP_RESULTS: 'keep current results and cancel the search',
	CONSOLIDATION_WILL_BE_REMOVED:
		'This part and all related consolidation results will be deleted',
	CONSOLIDATION_DELETE_PART:
		'delete this part and all related consolidation results',
	COMBINE_AROUND_THIS_PART: 'Combine around this part',
	SEARCH_WITHIN_SAME_CATEGORY: 'search within same material category',
	COMBINE_AROUND_THIS_PART_INFO:
		"We will automatically identify opportunities for clusters of parts that can be combined into a single part and check for its 3D printability.\n        Combining multiple parts into one, reduces the complexity of an assembly, has the potential to save assembly time, reduce inventory management costs and can potentially improve mechanical properties.\n        Results will be available in the project page view, in the ''Advanced features section",
	CONSOLIDATION_KEEP_PART:
		'keep this part and all related consolidation results',
	SEARCH_AGAIN_FOR_RESULT: 'Search again for updated results',
	COMBINE_MULTIPLE_INTO_ONE_CARD_TITLE: 'Combine multiple parts into one',
	COMBINE_MULTIPLE_INTO_ONE_REQ_BODY:
		'The request was sent to CASTOR and will be evaluated shortly. An Email informing you of results will be sent in the next few days. In some cases you might also need to provide additional information to improve the results.',
	COMBINE_MULTIPLE_INTO_ONE_REQ_TITLE: 'Request sent',
	COMBINE_MULTIPLE_NUMBER_OF_PARTS: 'Number of Parts',
	COMBINE_MULTIPLE_PARTS_INCLUDED: 'Parts included in cluster',
	COMBINE_MULTIPLE_SUGGESTION:
		'Suggested clusters of parts that can be combined into one',
	COMBINE_MULTIPLE_SUGGESTION_NUMBER: 'Suggestion #',
	COMPARE: 'compare',
	COMPLETE_FAST_UPLOAD_SUCCESS_MSG:
		'You may leave this page now. You will receive an email when the project is ready.',
	COMPLETE_FAST_UPLOAD_SUCCESS_TITLE:
		'Processing, this may take several minutes',
	COMPLETE_UPLOAD_FAILED_MSG: 'Do you want to retry uploading these files?',
	COMPLETE_UPLOAD_FAILED_TITLE: 'Some files were not successfully uploaded',
	COMPLETE_UPLOAD_SUCCESS_MSG:
		"You will receive an email with CASTORs' analysis and report results when complete.",
	COMPLETE_UPLOAD_SUCCESS_TITLE: 'All files uploaded successfully',
	CONFIGURATION_CALCULATION_ERROR:
		'Unfortunately we were not able to process your request. Please try again after refreshing the page.\r\nIf problem persists, kindly contact our support team',
	CONFIGURATION_CALCULATION_LONG_TIME:
		'Your request is taking us a little longer than usual to process. We just need a few more seconds',
	CONFIGURATION_INHOUSE_ALERT_TEXT:
		'In order to filter the results to match your in-house 3D printers only, please select a printer at the settings page of your account.',
	CONFIGURATION_MESH_HEALING_CONFIRM: 'Operate Mesh Healing',
	CONFIGURATION_MESH_HEALING_EXPLENATION: `Issues might effect the print, depends on the severity, slicer or printer.`,
	CONFIGURATION_MESH_HEALING_OPERATED_EXPLENATION:
		'The part completed the mesh healing process. In order to print the part, click on "Get a quote". In order to download the healed part, click on "Download"',
	CONFIGURATION_MESH_HEALING_OPERATED_TITLE: 'Mesh healing operated',
	CONFIGURATION_RESULT_BORDERLINE: 'Printable with changes',
	CONFIGURATION_RESULT_NOT_PRINTABLE: 'Unprintable',
	CONFIGURATION_RESULT_PRINTABLE: 'Printable',
	CONFIRM: 'Confirm',
	CONNECT_CASTOR: 'Contact CASTOR',
	CONNECT_CASTOR_POPUP_HEADER: 'Connect CASTOR',
	CONNECT_CASTOR_POPUP_RADIO_HEADER: 'Please select one of the options',
	COST_AND_LEAD_ACTION_BUTTON_CANCEL_TEXT: 'Cancel',
	COST_AND_LEAD_ACTION_BUTTON_DEFAULT_TEXT: 'Return to default value',
	COST_AND_LEAD_ACTION_BUTTON_EDIT_TEXT: 'Edit',
	COST_AND_LEAD_APPLY_CHANGES: 'Apply all changes',
	COST_AND_LEAD_APPLY_CHANGES_ALERT: 'Please fix error fields',
	COST_AND_LEAD_BUTTON_ACTIONS: {
		DEFAULT: 'Return to default value',
		EDIT: 'Edit',
		CANCEL: 'Cancel',
		APPLY: 'Apply'
	},
	COST_AND_LEAD_EDIT_ALL: 'Edit all',
	COST_AND_LEAD_LABELS: {
		YEARS: 'years',
		HOURS: 'hours',
		DAYS: 'days',
		RATE: 'rate',
		PERCENTAGE: 'percentage'
	},
	COST_AND_LEAD_PROPERTY_NAME: 'Property name',
	COST_AND_LEAD_VALUE: 'Value',
	COST_ESTIMATE_INFO:
		"Estimates the cost of manufacturing a single part with the recommended material and printer.\r\nThis is an estimate only. For an exact price quote please press the 'Get quotation from service bureau' button at the bottom of this page",
	COST_ESTIMATE_TITLE_INFO: 'Cost estimate',
	COST_ESTIMATE_WARNING:
		'The price shown is an estimate of manufacturing cost. When ordering from a third party, profit margins, shipping and handling, taxes etc. will be added.',
	COUNTRY: 'Country',
	CUSTOM: 'custom',
	CUSTOMIZE: 'Customize',
	CUSTOMIZE_COST_AND_LEAD_EXPLANATION_ARRAY: [
		'Help us make a more accurate cost and lead-time estimation by answering the following questions'
	],
	CUSTOMIZE_COST_AND_LEAD_TITLE: 'Cost calculation parameters',
	CUSTOMIZE_EXPLANATION_ARRAY: [
		"Use the following options to tailor CASTOR's advice to your needs.",
		'By doing so, CASTOR will be able to provide you with even more accurate and relevant results, and cost estimations.'
	],
	CUSTOMIZE_EXPLINATION_1_HEADER: 'Customize CASTOR for your needs',
	CUSTOMIZE_FORM_COST_AND_LEAD_HEADER: 'Cost estimation',
	CUSTOMIZE_FORM_HEADER: 'Customize',
	CUSTOMIZE_FORM_TAGS_HEADER: 'Customize part filtering',
	CUSTOMIZE_FORM_USER_MATERIAL_HEADER: 'Custom materials',
	CUSTOMIZE_FORM_USER_NEW_MATERIAL_HEADER:
		'New Custom Traditional Manufacturing Material',
	CUSTOMIZE_FORM_USER_PRINTER_MATERIAL_HEADER:
		'New Custom Additive Manufacturing Material',
	CUSTOMIZE_FORM_USER_PRINTER_HEADER: 'New Custom 3D Printer',
	CUSTOMIZE_RECALCULATE_PROJECTS_EXPLANATION: [
		'User settings changes does not affect already uploaded projects automatically. To apply settings changes to previously uploaded projects, please use the recalculate option.'
	],
	CUSTOMIZE_IN_HOUSE_PRINTERS_EXPLANATION_ARRAY: [
		'If your company has its own 3D-Printers and you would like to use them for manufacturing, add them here.',
		'After adding printers it will be possible to get suggestions based on the in house printers, and compare them to suggestions that can be fabricated at an AM service bureaus.'
	],
	CUSTOMIZE_RECALCULATE_PROJECTS_TITLE: 'Recalculate Projects',
	CUSTOMIZE_IN_HOUSE_PRINTERS_TITLE: 'In-house printers',
	CUSTOMIZE_MATERIAL_COST_EXPLANATION_ARRAY: [
		'Please provide your own 3D-printing material price here, if different from the market price.'
	],
	CUSTOMIZE_MATERIAL_COST_TITLE: 'Material costs',
	CUSTOMIZE_OFF_THE_SHELF_EXPLANATION_ARRAY: [
		'In order to reduce clutter, and save you money, we do not analyze items that are marked as off-the-shelf. Many companies mark off-the-shelf items by adding a unique substring to the part number/name.',
		'Add your scheme below and CASTOR will filter out your off-the-shelf items.'
	],
	CUSTOMIZE_GEOMETRY_ANALYSIS_TITLE: 'Customize Geometry Analysis tab',
	CUSTOMIZE_GEOMETRY_ANALYSIS_EXPLANATION_ARRAY:
		"['During analysis, CASTOR may encounter certain features or characteristics that can pose a challenge during the manufacturing process. To ensure that the analysis results are tailored to your specific needs, you can choose between two options: 'Alert' or 'Do not notify'.']",
	CUSTOMIZE_MACHINING_TITLE: 'Default Post Processes',
	CUSTOMIZE_MACHINING_EXPLANATION_ARRAY: [
		'Please provide your preferences for default post processes to be added to the 3D printed parts` cost'
	],
	POST_PROCESSES_MACHINING_LABEL: 'Machining',
	POST_PROCESSES_MACHINING_INFO:
		'Cost of machining by CNC will be added to the 3D printed part’s cost. Machining is needed for most metal 3D printed parts in order to achieve tolerances less than +/-0.1 mm, or to achieve the requires surface finish or for other purposes.',
	CUSTOMIZE_OFF_THE_SHELF_TITLE: 'Customize part filtering',
	CUSTOMIZE_USER_MATERIAL_EXPLANATION_ARRAY: [
		'Add a non 3D-Printable material here (one that is used in traditional manufacturing).',
		'After adding a new material you will be able to select it when reviewing a part. CASTOR can then suggest a 3D printer and material with similar mechanical properties.'
	],
	CUSTOMIZE_ADDITIVE_MANUFACTURING_MATERIALS_EXPLANATION_ARRAY: [
		'Add a 3D-printable material here (one that used in additive manufacturing).',
		'After adding a new material, it will be included in our calculations and might be suggested as a suitable solution.”'
	],
	CUSTOMIZE_USER_3D_PRINTERS_EXPLANATION_ARRAY: [
		'Add a 3D printer here.',
		'After adding a new printer, it will be included in our calculations and might be suggested as a suitable solution.',
		"NOTE: After adding a new 3D printer to the database, please ensure that you specify the additive manufacturing materials associated with this printer in the 'Add Additive Manufacturing Materials' section located above."
	],
	CUSTOMIZE_USER_MATERIAL_TITLE: 'Add Materials and Printers to the Database',
	CUSTOMIZE_TRADITIONAL_MANUFACTURING_MATERIALS_TITLE:
		'Add Traditional Manufacturing Materials',
	CUSTOMIZE_ADDITIVE_MANUFACTURING_MATERIALS_TITLE:
		'Add Additive Manufacturing Materials',
	CUSTOMIZE_3D_PRINTERS_TITLE: 'Add 3D Printers',
	CUSTOMIZE_USER_SEMI_MATERIAL_EXPLANATION_ARRAY: [
		'Check this button to get a 3D Printing recommendation for Semi-Professional printers.',
		'Note that although cost estimation results might be less expensive, Semi-Professional printers tend to produce parts which are less suitable for industrial use.'
	],
	CUSTOMIZE_USER_SEMI_MATERIAL_TITLE: 'Semi professional printers',
	CUSTOMIZE_USER_SEMI_MATERIAL_TITLE_FOR_ADMIN: 'Desktop printers',
	CUSTOMIZE_CO2_CALCULATIONS_TITLE: 'Sustainability -  Life cycle assessment',
	CUSTOMIZE_CO2_CALCULATIONS_ARRAY:
		'["Help us better fit your product’s life cycle assessment in CASTOR, by customizing calculations and parameters to your model."]',
	CUSTOMIZE_CO2_EMISSIONS_TITLE:
		'Change the amount of CO2 emissions per 1Kg of raw material.',
	CUSTOMIZE_CO2_EMISSIONS_PRINTER_MATERIAL_TITLE:
		'Change the amount of CO2 emissions per 1Kg of raw Printer Material for your preferred printer material.',
	CO2_PER_KG_PLACE_HOLDER: 'Co2/Kg',
	CO2_PER_KWH: 'CO2/KWH',
	CARBON_CO2_CALCULATION: 'Carbon intensity of electricity',
	CARBON_CO2_CALCULATION_VALIDATION:
		'Carbon intensity of electricity value should be from 0.01 to 5.',
	TYPICAL_KWH_PER_PRINTER_EXPLANATION:
		'To help CASTOR provide more accurate CO2 emission estimation, you can specify the average typical power consumption (in KWh) for the most relevant 3D printer or traditional method machine.',
	TYPICAL_POWER_CONSUMPTION: 'Typical power consumption',
	CO2_CALCULATION_PRODUCT_USE_SUBTITLE:
		'The default amount of KgCO2 that will be saved yearly if a part is made in additive manufacturing compared to traditional methods. For example, an AM designed and printed airplane part will save CO2 by consuming less fuel during flights.',
	CO2_CALCULATION_PRODUCT_USE_INPUT_TEXT:
		'Amount of KgCO2 saved by additively manufacturing per year:',
	CO2_CALCULATION_PART_LIFE: 'A part’s life is',
	CO2_CALCULATION_END_OF_LIFE_SUBTITLE:
		'At the product’s end of life phase there may be several part that will be discarded. To evaluate the CO2 cost of discarding AM parts compared to TM parts, please provide the disposal difference of 1Kg of AM vs TM parts.',
	CO2_CALCULATION_END_OF_LIFE_INPUT_TEXT:
		'Please enter the KgCO2 cost difference between disposing 1Kg of AM parts or 1Kg of TM parts:',
	CO2_CALCULATION_END_OF_LIFE_DATA_VALIDATION:
		'Value must be a number from -25to 25.',
	CO2_PER_KG_DATA_VALIDATION: 'Value must be a number bigger than 0.',
	DELETE_ITEM: 'Delete Item',
	DO_NOT_FIT_TRAY: "doesn't fit in tray",
	DOWNLOAD: 'Download',
	DOWNLOAD_ASSEMBLY: 'Download assembly',
	DOWNLOAD_PART: 'Download part',
	DROPZONE_HAS_FILES:
		"Files added. Add more files, or click 'Start uploading' to Start",
	DROPZONE_NO_FILES: 'Drop your files or click here to upload',
	EDIT: 'Edit',
	EDIT_PART: 'Edit part',
	EDIT_PROJECT: 'Edit project',
	EMAIL_INVALID: 'The email entered is invalid',
	EMAIL_NOT_ENTERED: 'Email address missing',
	ENTER_PART_ANALYSIS_EXPLINATION_ARRAY: ['Enter parts analysis results here'],
	ERROR: 'error',
	ERROR_UPDATING_TAGS: 'Error updating data',
	ESTIMATED_PRICE_LABEL: 'Estimated cost per part',
	EXIT: 'Exit',
	EXPLODE_PROJECT: 'Explode Project',
	EXPORT: 'Export',
	EXTEND_SUBSCRIPTION: 'I would like to extend my current CASTOR uses.',
	FAILED_PROCESSING_PART_ALERT_TITLE: 'The part is not suitable for processing',
	FILE_EXPLANATION_ARRAY: [
		'Upload a BOM from your CAD software with a specified material for each part.'
	],
	FINANCIAL_COST_AFFECTIVE_LABEL: [
		'In this case, 3D-Printing the part will be cost-effective if the printing will cost no more than',
		'This is assuming a production run of',
		'parts'
	],
	FINANCIAL_COUNT_LABEL_1: 'This is assuming a production run of',
	FINANCIAL_COUNT_LABEL_2: 'parts',
	FINANCIAL_COUNT_LABEL_3:
		'(number of machines produced multiplied by the number of parts per machine).',
	FINANCIAL_COUNT_LABEL_4: 'Minimum',
	FINANCIAL_EDIT_EXPLANATION: 'Change advanced parameters',
	FINANCIAL_EDIT_MANUFACTURING_METHOD:
		'Compare with another manufacturing method:',
	FORGOT_PASSWORD: 'Forgot password',
	FORGOT_PASSWORD_POPUP_BODY:
		'Please enter the email address associated with your account',
	FORGOT_PASSWORD_POPUP_TITLE: 'Reset Password',
	GENERAL_DROP_FILE_EXPLANATION:
		'Drag and drop your 3D CAD & 2D Drawing files into the area below. Native 3D CAD file formats are supported (Creo, SOLIDWORKS, NX, CATIA, Inventor, Solid Edge), STEP (or STP) files and STL files. For 2D drawings, PDF format is supported.',
	GENERAL_DROP_FILE_EXPLANATION_SINGLE_AND_BOM:
		'Drag and drop your 3D CAD & 2D Drawing files into the area below. Native 3D CAD file formats are supported (Creo, SOLIDWORKS, NX, CATIA, Inventor, Solid Edge), STEP (or STP) files and STL files.',
	GENERAL_PROPERTIES: 'General properties',
	BATCH_SIZE: 'Batch size',
	GENERIC_ERROR_MESSAGE: 'An error has occurred',
	GO: 'GO',
	GO_TO_SETTINGS: 'Go to settings',
	GRAM_UNITS: 'g',
	HEATMAP_SWITCH_TITLE: 'Thickness heat-map',
	HIGH_ACCURACY_ALERT_TEXT:
		' Please contact the service bureau to estimate the cost of dimension accuracy post processing.',
	HIGH_ACCURACY_ALERT_TITLE: 'High accuracy',
	ID: 'Id',
	IMPORTANT: 'Important',
	IN_HOUSE_SWITCH_TITLE:
		'Calculate recommendations for in-house 3D-Printing only',
	INCLUDES_POST_PROCESS: 'Includes Post Process',
	INDEX: 'Index',
	IS: 'is',
	ITEM_ALLREADY_EXIST: 'Duplicate entry',
	KG_UNITS: 'Kg',
	LEAD_TIME_LABEL: 'Lead time',
	LEAD_TIME_TEXT:
		'An estimation of total time required to supply the parts, from order submission to pickup at your location. The time is based on the average lead-time of parts ordered via CASTOR over the preceding month. For a more accurate time estimate please click SUBMIT in the "Send to Print" section below, and fill in the Quotation Request form.',
	LEAD_TIME_TITLE_INFO: 'Lead Time',
	LEARN_HOW: 'Learn how',
	LOADING_MESSAGE_PART_TECH_ANALYSIS: 'Calculating printing solution...',
	LOCKED_PARTS_BANNER: [
		'Want to see',
		'more parts?',
		'This account is locked for additional uploads.',
		'parts were not analyzed. Contact us to resolve.',
		'part was not analyzed. Contact us to resolve.',
		'more part?'
	],
	LOCKED_PARTS_BANNER_6: '',
	LOCKED_PARTS_BANNER_7: '',
	LOCKED_PARTS_CARD_SENT_REQ_BUTTON_TEXT: 'Request sent',
	LOGIN_EMAIL_PLACEHOLDER: 'Email',
	LOGIN_PASSWORD_PLACEHOLDER: 'Password',
	LOGIN_REGISTER_BUT: 'Register',
	LOGIN_REGISTER_HEADER: 'New to CASTOR?',
	LOGIN_TITLE: 'Login',
	LOGIN_WITH: 'Sign up with',
	OR: 'or',
	LOGOUT: 'Logout',
	MAP_HEAT_TOGGLE_BUTTON_TEXT: 'Thickness heat-map',
	MATERIAL_COMPARISON_ELONGATION_AT_BREAK: 'Percentage of Elongation At Break',
	MATERIAL_COMPARISON_HEADER_COMPARISON: 'Comparison',
	MATERIAL_COMPARISON_HEADER_DEVIATION: '% Deviation',
	MATERIAL_COMPARISON_HEADER_ORIGINAL: 'Original Material',
	MATERIAL_COMPARISON_HEADER_PRINTED: 'Printed Material',
	MATERIAL_COMPARISON_NAME: 'Name',
	MATERIAL_COMPARISON_ORIGINAL_COLUMN_NAME: 'Original material',
	MATERIAL_COMPARISON_PERCENT_COLUMN_NAME: 'Percent of original',
	MATERIAL_COMPARISON_PRINTABLE_COLUMN_NAME: 'Printable material',
	MATERIAL_COMPARISON_YIELD_STENGTH: 'Yield Strength MPa',
	MATERIAL_COMPARISON_YOUNG_MODULES: "Young's Modulus",
	MATERIAL_COST_ACTION: 'Action',
	MATERIAL_COST_DELETE_ITEM: 'Delete item',
	MATERIAL_COST_FIELDS_ALERT: 'All fields must be filled',
	MATERIAL_COST_INPUTS_LBS_EXPLENATION:
		'The price will be converted to price per kg',
	MATERIAL_COST_INPUTS_TITLE: 'Select material and set its price',
	MATERIAL_COST_INPUTS_WEIGHT_UNIT: ['KG', 'LBS'],
	MATERIAL_COST_KG_PRICE: 'Price per KG',
	MATERIAL_COST_LB_PRICE: 'Price per LB',
	MATERIAL_COST_MATERIAL: 'Material',
	MATERIAL_COST_MATERIAL_NAME: 'Material Name',
	MATERIAL_COST_PRICE_IN_USD: 'Price in USD',
	MATERIAL_COST_PRINTER_TECH: 'Printer Technology',
	MATERIAL_COST_TECHNOLOGY: 'Technology',
	MATERIAL_TABLE_TEXTS: {
		NAME: 'Name',
		TYPE: 'Type',
		COMPANY: 'Company',
		CATEGORY: 'Category',
		DENCITY: 'Density [g/cm³]',
		TECHNOLOGY: 'Technology',
		YIELD_STRENGTH: 'Yield Strength [MPa]',
		ELONGATION: 'Elongation at Break [%]',
		YOUNGS_MODULUS: "Stiffness (Young's Modulus) [GPa]",
		TENSILE_STRENGTH: 'Ultimate Tensile Strength [MPa]',
		SERVICE_TEMPERATURE: 'Max. Service Temperature [℃]',
		THERMAL_CONDUCTIVITY: 'Thermal Conductivity [W/(m•°K)]',
		SURFACE_FINISH: 'Surface Finish (Ra) [µm]',
		COST: 'Average cost per cubic cm [$]',
		WALL_THICKNESS: 'Thickness',
		METHOD: 'Method',
		WEIGHT: 'Weight',
		ACCURACY: 'Accuracy [μm]',
		TRADITIONAL_COST: 'Material Cost [$/cm3]',
		DESCRIPTION: 'Description',
		PROS: 'Pros',
		CONS: 'CONS',
		YOUNGS_MODULUS_EXT: 'Youngs Modulus Ext',
		POISSON_RATIO_EXT: 'Poisson Ratio Ext',
		SHEAR_MODULUS_EXT: 'Shear Modulus Ext',
		ULTIMATE_TENSILE: 'Ultimate Tensile Strength Ext',
		YIELD_STRENGTH_EXT: 'Yield Strength MPa Ext',
		PRINTERS: 'printers',
		SPECIAL_MATERIAL: 'Special material properties:',
		ADDITIONAL_POST_PROCESSES: 'Additional post processes:',
		HEAT_TREATED: 'Heat treated',
		COUPLE_ID: 'Couple ID',
		MACHINING: 'Machining'
	},
	MATERIAL_TABLE_INFO: {
		DENCITY:
			'Please set the 3D-printed material density in grams / cubic centimeters.',
		COST: 'Please enter the 3D-printer material cost. Entering an accurate value is important so CASTOR will calculate part’s pricing accurately.',
		WALL_THICKNESS:
			'Please set the allowed minimal thickness printable with this material.',
		COUPLE_ID:
			'Heat treated check box and Couple ID present the heat treatments database. To add heat treatment to material, contact customer support.'
	},
	MATERIAL_TABLE_VALIDATION: {
		STRING_VALIDATION:
			'Must be a number string, allow letter, number and special characters.',
		NUMBER_VALIDATION: 'Must be a number from {0} to {1}.',
		NUMBER_VALIDATION_REQUIRED_NOT_INCLUDING_ZERO:
			'Must be greater than {0} and up to {1}, this value is required.',
		NUMBER_VALIDATION_NOT_INCLUDING_ZERO:
			'Must be greater than {0} and up to {1}, this value is optional.',
		NUMBER_VALIDATION_REQUIRED:
			'Must be a number from {0} to {1}, this value is required.'
	},
	MATERIALS_COMPARISON: 'Material comparison',
	MESH_HEALING: 'Mesh Healing',
	METRICS_TITLE: 'Decision priority',
	MISSING_BOM_OR_MATERIAL: 'Select a BOM file or a material for the project',
	MISSING_CAD_NAME: 'CAD software missing',
	MISSING_COMPANY_NAME: 'Company name missing',
	MISSING_ERP_NAME: 'ERP missing',
	MISSING_PROJECT_FILES: 'Missing project files',
	MISSING_PROJECT_NAME: 'Project name missing',
	MISSING_PROJECT_QUANTITY: 'Estimated yearly production quantity missing',
	MISSING_PROJECT_WRONG_QUANTITY:
		'Estimated yearly production quantity missing (Must be a whole number from 1 to 1000000)',
	MISSING_PROJECT_UPLOAD_TYPE:
		'Please select the upload type in the selection box above',
	MISSING_UNIT_TYPE: 'Select the file unit type',
	MULTIPLE_FILES_ERROR: 'Only one file can be added when uploading an assembly',
	MULTIPLE_PARTS_IN_PROJECT_WARNING:
		'Notice that there are [1] repetitions of this part in the project. Therefore, the results are calculated for the production of [2] parts.',
	MUST_AGREE_TO_TURMS: 'Please agree to our usage agreement',
	MUST_BE_MAXIMUM: 'Must be maximum',
	MUST_BE_MINIMUM: 'Must be minimum',
	MY_PANEL_SAVINGS: 'savings',
	MY_PANEL_DISSAVINGS: 'dissaving’s',
	MY_PANEL_TOTAL_SAVINGS: 'Total savings:',
	USER_PANEL_PRINTING_SAVING_UPFRONT_INDEX_TITLE: '{0} - Upfront costs',
	USER_PANEL_PRINTING_SAVING_INV_INDEX_TITLE: '{0} - Inventory costs',
	USER_PANEL_PRINTING_SAVING_MAN_INDEX_TITLE: '{0} - Manufacturing costs',
	USER_PANEL_PRINTING_SAVING_CO2_INDEX_TITLE: '{0} - CO₂ costs saving',
	NAME_NOT_ENTERED: 'Name missing',
	NAV_TITLE_CLUSTER_ANALYSIS: 'Multiple parts into one suggestion',
	NAV_TITLE_CUSTOMIZE_USER: 'Settings',
	NAV_TITLE_INPUT_PART: 'Input part analysis',
	NAV_TITLE_LOGIN: 'Login',
	NAV_TITLE_MY_PROJECTS: 'My Projects',
	NAV_TITLE_PART_ANALYSIS: ' Analysis',
	NAV_TITLE_PROJECT_ANALYSIS: 'Project Analysis',
	NAV_TITLE_PROJECT_UPLOAD: 'Upload Project',
	NAV_TITLE_ANALYSIS_CLUSTER: 'Cluster analysis',
	NAV_TITLE_PROJECT_UPLOADING: 'Uploading Project',
	NAV_TITLE_REGISTER: 'Register',
	NAV_TITLE_USER_PROFILE: 'Profile',
	NEW: 'new',
	NEXT: 'Next',
	NO: 'No',
	NO_CAD_FILE_ERROR: 'A CAD file / CAD files must be added',
	NO_INHOUSE_PRINTER_WAS_SET: 'No In-house printer was set',
	NO_PDF_FILE_ERROR: 'A PDF file / PDF files must be added',
	NO_FOLDER_ERROR: 'The file you attempted to add already exists',
	NO_MATERIAL_FOR_PROJECT_ERROR:
		'Select a material for the project or upload a material BOM',
	NO_RESULT_FOR_CONFIGURATION:
		'No recommendation could be made that meets these requirements.',
	NO_USER_MATERIALS_MSG: 'No User Materials',
	NO_USER_PRINTERS_MSG: 'No In-house Printers',
	NON_ASSEMBLY_DROP_FILE_EXPLANATION:
		'Drag and drop your CAD files into the area bellow. Files supported: STEP (or STP) and STL CAD files',
	NOT_ASSEMBLY_FORMAT_ERROR: 'The accepted formats for assemblies are:',
	NOT_FOLDER_ERROR: 'We do not accept folders or files with no extensions',
	NOT_PART_FORMAT_ERROR: 'The accepted formats for CAD files are:',
	OFF_THE_SHELF_ALERT_TEXT: '3D Printing may not be cost-effective.',
	OFF_THE_SHELF_ALERT_TITLE: 'Off-the-shelf item',
	OFF_THE_SHELF_PART_ALERT_TEXT:
		'The part was not analyzed because it is an off-the-shelf item, and is not cost-effective for 3D-Printing.',
	OFF_THE_SHELF_PART_ALERT_TITLE: 'Off-The-Shelf Item',
	OFF_THE_SHELF_SELECTOR_ITEMS: ['Off-the-shelf item', 'Manufactured item'],
	OK: 'Ok',
	BACK: 'Back',
	ORIGINAL_MATERIALS_BUTTON: 'Original material',
	OTHER: 'Other',
	OWNER_ID: 'Owner Id',
	OWNER_INVALID: 'The owner id entered is invalid',
	OWNER_NAME: 'Owner Name',
	PART: 'part',
	PART_ANALYSIS_3D_VIEW_TITLE: '3d View',
	PART_ANALYSIS_ADD_CONFIGURATION_BUTTON: 'Add configuration',
	ADD_NEW_CONFIGURATION: 'Add new configuration',
	PART_ANALYSIS_ADD_FEATURES: '+ Add properties',
	PART_ANALYSIS_ADD_FEATURES_FILTERS: '+ Add Filters',
	PART_ANALYSIS_ADD_POST_PROCESS_HEADER: 'Post processes options',
	PART_ANALYSIS_BEST_MATCH: 'Best Match',
	PART_ANALYSIS_CONFIGURE: 'Configure',
	PART_ANALYSIS_CONFIGURE_BACK: 'Back',
	PART_ANALYSIS_CONFIGURE_CALCULATE: 'Calculate',
	PART_ANALYSIS_CONFIGURE_FEATURE: 'Add',
	PART_ANALYSIS_CONFIGURE_FILTER_FEATURES: 'Add filters:',
	PART_ANALYSIS_CONFIGURE_FILTER_TITLE: 'Material properties filters',
	PART_ANALYSIS_CONFIGURE_FILTER_TITLE_HOVER:
		'Make sure the printing solution we suggest fulfills all your needs by filtering out printers and materials according to mechanical properties, cost, lead time and more.',
	PART_ANALYSIS_CONFIGURE_MAX_MIN: {
		max: 'Maximum',
		min: 'Minimum'
	},
	PART_ANALYSIS_CONFIGURE_PRIORITIES_FEATURES:
		'Please check the propeties you wish to include in this configuration:',
	PART_ANALYSIS_CONFIGURE_PRIORITIZE_TITLE: 'Material properties importance',
	PART_ANALYSIS_CONFIGURE_PRIORITIZE_TITLE_HOVER:
		'What properties would you like to maximize? Please rank the importance of the properties for your needs (where 5 is very important and 1 is of least importance). We will pick the printing solution that gives the best results in accordance with your choice bellow, relative to the original material.',
	PART_ANALYSIS_CONFIGURE_RESET: 'Reset all',
	PART_ANALYSIS_COST_COMPARISON_ACCURACY: 'Tolerances less than ±0.1mm',
	PART_ANALYSIS_COST_COMPARISON_CAM_EXISTENCE:
		'The part was already produced by CNC',
	PART_ANALYSIS_COST_COMPARISON_COMPLEXITY:
		'The part contains complex geometry',
	PART_ANALYSIS_COST_COMPARISON_OPERATION_PRICE: 'Operation Cost Per Hour [$]',
	PART_ANALYSIS_COST_COMPARISON_OPERATION_PRICE_INFO:
		'"Operation cost" includes Machine total expenses, labor total expenses and additional consumables.',
	PART_ANALYSIS_COST_COMPARISON_PROGRAMING_PRICE:
		'Engineering hourly rate for creating a CAM file [$]',
	PART_ANALYSIS_DIMENSIONS: 'Dimensions [mm]',
	PART_ANALYSIS_GETAQ: 'Get a Quote',
	PART_ANALYSIS_HEADER: 'Printing Configuration',
	PART_ANALYSIS_LED_WITH_TITLE_INDEXES: [
		{
			label: 'notPrintable',
			title:
				'Walls are not thick enough. Please increase the thickness to above {0} mm',
			color: '#d60a0a'
		},
		{
			label: 'printable',
			title: 'Thickness test passed',
			color: '#808080'
		}
	],
	PART_ANALYSIS_NEW_SOLUTION_NAME: 'Your New Configuration',
	PART_ANALYSIS_NON_PRINTABLE_BUTTON: 'Retrieve results',
	PART_ANALYSIS_NON_PRINTABLE_DESCRIPTION:
		'The part was found to be unsuitable for 3D-Printing. Retrieve results anyway?',
	PART_ANALYSIS_REMOVE: 'Remove',
	PART_ANALYSIS_SAVE: 'Save',
	PART_ANALYSIS_SAVED: 'Saved',
	PART_ANALYSIS_TABS_TITLE_ANALYSIS_RESULTS: 'Analysis Results',
	PART_ANALYSIS_TABS_TITLE_CLUSTER_PARTS: 'Cluster Parts',
	PART_ANALYSIS_TABS_TITLE_COST_COMPARTION: 'Cost Comparison',
	PART_ANALYSIS_TABS_TITLE_MATERIAL_COMPARTION: 'Material Comparison',
	PART_ANALYSIS_TOP_DETAILS_COST_ESTIMATION_POP_UP_ROWS: [
		'Cost estimation includes:',
		'Printing',
		'Post process',
		'Post processes',
		'Total'
	],
	PART_ANALYSIS_TOP_DETAILS_NEW_CONFIGURATION:
		'Manually Change Configuration and Calculate',
	PART_ANALYSIS_TOP_DETAILS_NO_SOLUTION: [
		'Click on',
		'Configure button to generate new result'
	],
	PART_ANALYSIS_WALL_THICKNESS_ANALYZE: 'Analyzing thickness...',
	PART_COUNT_IN_ASSEMBLY: 'Count in assembly',
	PART_DIMENSIONS: 'Dimensions (Width x Depth x Height) [mm]',
	PART_FILE_NOT_IN_ENGLISH:
		'Please make sure that the names of the uploaded files are in English. If not, please correct and try uploading again',
	PART_FINANCIAL_ANALYSIS_AVARAGE_COST: 'Average cost per part ($)',
	PART_FINANCIAL_ANALYSIS_CARD_SUB_TITLE:
		'Financial break-even analysis of 3D printing compared with injection molding',
	PART_FINANCIAL_ANALYSIS_CARD_SUB_TITLE_METAL:
		'Financial break-even analysis of 3D printing compared with CNC',
	PART_FINANCIAL_ANALYSIS_CARD_SUB_TITLE_LEAD_METAL:
		'Lead time analysis of 3D printing compared with CNC',
	PART_FINANCIAL_ANALYSIS_CARD_TITLE: 'Financial analysis',
	PART_FINANCIAL_ANALYSIS_DFM_COSTS_PLACEHOLDER: 'Estimated DFM costs [$]',
	PART_FINANCIAL_ANALYSIS_FORM_EXPLANATION:
		'Please provide the following information so that CASTOR can assess whether 3D-Printing the part will be cost-effective or not.',
	PART_FINANCIAL_ANALYSIS_INJECTION_MOLDING: 'Injection molding',
	MOLDINGS: 'Moldings',
	PART_FINANCIAL_ANALYSIS_MINIMUM_ORDER_QUANTITY_PLACEHOLDER:
		'Estimated Minimum order quantity',
	PART_FINANCIAL_ANALYSIS_MOLD_MAINTENANCE_PRICE_PLACEHOLDER:
		'Annual mold inventory costs',
	PART_FINANCIAL_ANALYSIS_MOLD_PART_PRICE_PLACEHOLDER:
		'Estimated cost per part, using injection molding [$]',
	PART_FINANCIAL_ANALYSIS_MOLD_PRICE_PLACEHOLDER: 'Estimated mold price [$]',
	PART_FINANCIAL_ANALYSIS_NUMBER_OF_PARTS_PRODUCED: 'Number of parts produced',
	PART_FINANCIAL_ANALYSIS_PART_COUNT: 'Part count',
	PART_IS_OFF_THE_SHELF: 'The part is an off-the-shelf item',
	PART_ORIGINAL_WEIGHT: 'Original weight [Kg]',
	PART_ORIGINAL_WEIGHT_GRAMS: 'Original weight [g]',
	PART_REQUIREMENT_SPECIAL_REQUIREMENTS: 'Special requirements',
	PART_REQUIREMENT_SURFACE_FINISH:
		'The part requires a non-standard surface finish',
	PART_REQUITEMENT_ACCURACY: 'The part requires an accuracy of less than 0.1mm',
	PART_RESULTS_BORDERLINE: 'borderline',
	PART_RESULTS_CAD: 'CAD file intact',
	PART_RESULTS_CARD_SUB_HEADER: 'CASTOR technical analysis results report',
	PART_RESULTS_CLICK_FOR_MORE: '  (read more)',
	PART_RESULTS_MATERIAL: 'Material properties supported',
	PART_RESULTS_NOT_PRINTABLE: 'notPrintable',
	PART_RESULTS_OPTIONS: [
		{ title: 'Inapplicable', value: 'inapplicable' },
		{ title: 'Missing Information', value: 'missingInformation' },
		{ title: 'Printable', value: 'printable' },
		{ title: 'Printable with changes', value: 'borderline' },
		{ title: 'Unprintable', value: 'notPrintable' },
		{ title: 'CAD file not received', value: null },
		{ title: 'Off the shelf item', value: 'offTheShelf' },
		{ title: 'not suitable for processing', value: 'failed' },
		{ title: 'Not cost effective', value: 'notCostEffective' },
		{ title: 'Dependency analysis failed', value: 'dependencyAnalysisFailed' },
		{ title: 'Cost saving', value: 'costSavingPrintable' },
		{ title: 'Time saving', value: 'timeSavingPrintable' },
		{ title: 'High Buy to Fly', value: 'buyToFlyPrintable' },
		{ title: 'Complex part geometry', value: 'complexityScorePrintable' },
		{
			title: 'Weight reduction opportunity',
			value: 'weightReductionPrintable'
		},
		{
			title: 'Part consolidation opportunity',
			value: 'partConsolidationPrintable'
		},
		{ title: 'Updating...', value: 'loading' },
		{ title: 'Optimized', value: 'optimized' },
		{ title: 'Move to Lathe + CNC', value: 'latheCNC' },
		{ title: 'Move to Casting + CNC', value: 'castingCNC' },
		{ title: 'Move to Sheet Metal', value: 'sheetMetal' }
	],
	PART_RESULTS_PRINTABLE: 'printable',
	PART_RESULTS_SIZE: 'Part size',
	PART_RESULTS_ORIENTATION_STABILITY: 'Orientation stability',
	PART_SIZE_FAILED_REASON: "The part is too big to fit in a 3D printer's tray.",
	PART_ORIENTATION_FAILED_REASON:
		'Printing may result in failure, due to high center of gravity',
	PART_RESULTS_WALL_THICKNESS: 'Thickness',
	PART_VOLUME: 'Volume [mm³]',
	PART_COMPLEXITY_SCORE: 'Complexity Score',
	PART_IS_COMPLEX: 'Part is complex',
	PART_IS_NOT_COMPLEX: 'Part is not complex',
	RATE_3D: '3D Rate:',
	PARTS: 'Parts',
	PARTS_ADMIN_PERFORM_ACTION: 'Perform action',
	PARTS_BUNDLE_END_NOT_TRIAL:
		'Your CASTOR subscription allows you to upload a limited amount of parts. Click below to contact us in order to upgrade your account.',
	PARTS_BUNDLE_END_TRIAL:
		'The free CASTOR trial allows you to upload a limited amount of parts. Click below to contact us in order to upgrade your account.',
	PARTS_CREDIT_END_POPUP_HEADER: 'Account lock for additional uploads',
	PASSWORD: 'Password',
	PASSWORD_INVALID: 'The password entered is invalid',
	PASSWORD_NOT_ENTERED: 'Password missing',
	PASSWORD_NOT_MATCHING: 'Passwords do not match',
	PASSWORD_STENGTH_EXPLINATION_BODY:
		'Good passwords are hard to crack. Use uncommon words or inside jokes, non-standard uPPercasing, creative spelllling, and unobvious numbers and symbols',
	PASSWORD_STENGTH_EXPLINATION_TITLE: 'This password is too weak :(',
	POLLER_REACHED_TIMEOUT: 'System timeout',
	POPUP_HOVER_COST_BENEFIT: 'COST SAVING -',
	POPUP_HOVER_TIME_BENEFIT: 'TIME SAVING -',
	POST_PROCESSES: 'Post Process',
	POST_PROCESSES_GENERAL_TITLE:
		'3D printing post processes can be added, to meet the original requirements',
	POWERED_BY: 'Powered by',
	PRICE: 'Price',
	PRINTER_MATERIAL_PARAMS: [
		'name',
		'printers',
		'printer material properties',
		'strength',
		'description',
		'cost [cm³]',
		'organization id',
		'organization owner',
		'id'
	],
	PRINTING_ORIENTATION_ALERT_CONFIRM_BUTTON_TEXT: 'Choose now',
	PRINTING_ORIENTATION_ALERT_TEXTS:
		"Tray orientation prices are calculated according to the orientation, printer, and material. Clicking 'Choose now' will update the printer and material according to your updated  material preferences.",
	PRINTING_ORIENTATION_BUTTON_DEFAULT_TEXT: '(default cheapest)',
	PRINTING_ORIENTATION_BUTTON_TEXT: 'Printing Orientation',
	PRINTING_ORIENTATION_GENERAL_TITLE:
		"The part's tray orientation can have a considerable influence on both the printing price and the strength of the printed part. By default Castor chooses the cheapest orientation. Use the tray orientation feature to customize the orientation to fit your needs.",
	PRINTING_ORIENTATION_NEW_CONF_MSG:
		'This function is only available after calculating a solution. In order to do so, first click on the "Calculate" button.',
	PRINTING_RECOMMENDATIONS_SUB_TITLE: "Based on CASTOR's analysis",
	PRINTING_RECOMMENDATIONS_TITLE: 'Printing Recommendations',
	PRINTING_TECH: [
		{
			name: 'SLS',
			fullName: 'Selective Laser Sintering',
			description:
				'The Selective Laser Sintering (SLS) printing process uses a laser to melt and solidify layers of powdered material into finished objects.'
		},
		{
			name: 'FDM',
			fullName: 'Fused Deposition Modeling',
			description:
				'The FDM printing process begins with a string of solid material called the filament. This filament is guided from a reel in the 3D printer to a heated nozzle inside that melts the material.'
		},
		{
			name: 'SLA',
			fullName: 'Stereolithography (SLA)',
			description:
				'The SLA printing process creates 3D printed objects using a liquid (photopolymer) resin, which is solidified using a light source.'
		},
		{
			name: 'Jet Fusion',
			fullName: 'Jet Fusion',
			description: 'Hewlett-Packard (HP) printing technology'
		},
		{
			name: 'mjf',
			fullName: 'Multi Jet Fusion',
			description:
				'The Multi Jet Fusion (MJF) printing process can produce high precision parts at a high speed by fusing together multiple voxels simultaneously. The MJF printing process is ideal for industrial printing of prototypes and parts.'
		}
	],
	PRINTING_TECHNOLOGY_VALUE_TO_READABLE: {
		fdm: 'FDM',
		sls: 'SLS',
		jetting: 'jetting',
		dlp: 'DLP',
		sla: 'SLA',
		ebm: 'EMB',
		cff: 'CFF',
		slm: 'SLM'
	},
	PRIORITY_ALERT_TEXT:
		"When you move the slider to maximum 'Strength',' the printing solution (the technology, printer and material) displayed is the closest possible match to the original material. The closer the slider is moved towards 'Price' the more concessions will be made in order to reduce the cost.",
	PRIORITY_ALERT_TITLE: 'Decision priority',
	PROFILE_FORM_COMPANY_LABEL: 'Company',
	PROFILE_FORM_EMAIL_LABEL: 'Email',
	PROFILE_FORM_FULL_NAME_LABEL: 'Full name',
	PROFILE_FORM_HEADER: 'Profile',
	PROFILE_NOTIFICATION_ERROR: 'Error updating profile information',
	PROFILE_NOTIFICATION_SUCCESS: 'Profile information updated',
	PROJECT_ANALYSIS_DELETE_PART_ALERT:
		'Are you sure you want to delete "{0}"?\r\nThe item will be deleted completely, and will not be recoverable.',
	PROJECT_ANALYSIS_OUT_OF: 'Out of a project of {0} parts, {1} {2} printable.',
	CANCEL_PROJECT: 'Cancel project',
	CANCEL_ANALYSIS_PROJECT_HOVER:
		'Project analysis is in process… Cancelling the project will remove it from the processing queue and delete it.',
	CANCEL_FAILED_PROJECT_HOVER:
		'Project analysis failed. Cancelling the project will delete it.',
	ARE_YOU_SURE_CANCEL_PROJECT:
		'Are you sure you want to cancel the analysis of "{0}" project? The project will be deleted completely from CASTOR.',
	PROJECT_ANALYSIS_CANCEL_PROJECT: `Cancel Project’s analysis`,
	PROJECT_ANALYSIS_REMOVE_PROJECT_ERROR: 'Error removing project',
	PROJECT_ANALYSIS_REMOVE_PROJECT_TEXT:
		'Are you sure you want to delete "{0}"?\r\nThe project will be deleted completely, and will not be recoverable.',
	PROJECT_ANALYSIS_SHOW_ALL_RESULTS: 'Show all results',
	PROJECT_ANALYSIS_SHOW_FAILED_PARTS:
		'{0} parts are not suitable for processing',
	PROJECT_ANALYSIS_SHOW_FAILED_PARTS_BUTTON: 'ADD ALL PARTS TO LIST',
	PROJECT_ANALYSIS_SUB_TITLE: 'An overview of the project results',
	PROJECT_ANALYSIS_WARNING:
		'This Project can only be viewed by admins. Publish the project to enable the\r\n                                          project owner to access it.',
	PROJECT_ANALYSYS_ERROR_DOWNLOAD: 'Error downloading data',
	PROJECT_ANALYSYS_ERROR_REMOVE_PART: 'Error deleting part',
	PROJECT_ERP_OPTIONS: [
		{
			title: 'SAP',
			value: 'sap'
		},
		{
			title: 'FIS Global',
			value: 'fis'
		},
		{
			title: 'Oracle',
			value: 'oracle'
		},
		{
			title: 'Fiserv',
			value: 'fiserv'
		},
		{
			title: 'Intuit Inc.',
			value: 'intuit'
		},
		{
			title: 'Cerner Corporation',
			value: 'cerner'
		},
		{
			title: 'Microsoft',
			value: 'microsoft'
		},
		{
			title: 'Infor',
			value: 'infor'
		},
		{
			title: 'SS&C Technologies',
			value: 'ss&c'
		},
		{
			title: 'Ericsson',
			value: 'ericsson'
		}
	],
	PROJECT_PENDING_SUBTITLE: 'Work in progress',
	PROJECT_PENDING_TEXT:
		'The project was uploaded successfully. \r\nThe CASTOR team will send you an in-depth analysis via Email shortly.',
	PROJECT_PENDING_TITLE: 'Project details',
	PROJECT_IS_NOT_AVAILABLE: 'Project is not available',
	PROJECT_UNIT_TYPE: 'Project Unit Type',
	PROJECT_UPDATED_SUCCESS: 'Project updated succesfuly',
	PUBLISH: 'Publish',
	PUBLISH_CLUSTER: 'Publish unification',
	PUBLISH_CLUSTERS: 'Publish unifications',
	PUBLISH_PROJECT: 'Publish project',
	QUICK_UPLOAD_FILES_CARD_HEADERS_ARRAY: [
		'File',
		'Material Type',
		'Material Category',
		'Original Material',
		'Yearly Production Quantity'
	],
	RE_ENTER_PASSWORD: 'Re-enter password',
	READ_MORE: 'Read more',
	RECOMMENDED_MATERIAL_LABEL: 'Recommended material',
	RECOMMENDED_PRINTER_LABEL: 'Recommended printer',
	RECOMMENDED_TECH_LABEL: 'Recommended technology',
	REGISTER_COMPANY_PLACEHOLDER: 'Company name',
	REGISTER_EMAIL_PLACEHOLDER: 'Email',
	REGISTER_EXPLINATION:
		'CASTOR’s part screening software informs manufacturers when it is beneficial to use 3D printing instead of traditional manufacturing methods. The results are reduced lead time, elimination of costly spending on limited quantities and increased production flexibility.',
	REGISTER_FORM_ORGANIZATION_LABEL: 'Site',
	REGISTER_LOGIN_BUT: 'Login',
	REGISTER_LOGIN_TEXT: 'Already have an account?',
	REGISTER_NAME_PLACEHOLDER: 'Full name',
	REGISTER_PASS_PLACEHOLDER: 'Password',
	REGISTER_REENTER_PASS_PLACEHOLDER: 'Re-enter password',
	REGISTER_TITLE: 'Register',
	REJECT: 'Reject',
	REMOVE_FROM_FAVORITES: 'Remove from saved',
	REQUEST_FAILED: 'Request failed',
	REQUIRED: 'Required',
	REQUIREMENTS_ALERT_TEXT: 'For special requirements such as this please ',
	RESET_PASSWORD: 'RESET PASSWORD',
	RESET_PASSWORD_EMAIL_POPUP_HEADER: 'Reset your password',
	RESET_VERIFY_USER_POPUP_HEADER: 'Verify your email',
	RESET_VERIFY_EMAIL_REQUEST_SUCCESS:
		"A verification link has been sent to your email account. Please click on the 'VERIFY' button, that has been sent to your email account.",
	RESET_PASSWORD_EMAIL_REQUEST_SUCCESS_HEADER: 'Email sent successfully',
	RESET_PASSWORD_ERROR_ALERT: 'Password reset failed, Please try again later',
	RESET_PASSWORD_REQUEST_ERROR:
		'Reset password request failed, please try again later',
	RESET_PASSWORD_REQUEST_SUCCESS:
		'An email was sent to your inbox explaining how to reset your password.',
	RESET_PASSWORD_SUCCESS_POPUP_BODY: 'Click OK to continue to login page',
	RESET_PASSWORD_SUCCESS_POPUP_HEADER: 'Your password was reset successfully',
	RESULT: 'Result',
	RESULT_LABEL: 'Analysis result',
	RESULTS: 'Results',
	SAME_MATERIAL_SWITCH:
		'3D-Printing recommendations should have the same material type',
	SAVE: 'Save',
	SCHEME: 'scheme',
	SEARCH: 'Search',
	SELECT_ACTION_PARTS_ADMIN_PLACEHOLDER: 'Select action',
	SELECT_MATERIAL_CATEGORY_PLACEHOLDER: "Original part's material category",
	SELECT_MATERIAL_EXPLAINED: "Original part's material",
	SELECT_MATERIAL_PLACEHOLDER: "Original part's material",
	SELECT_MATERIAL_TYPE_PLACEHOLDER: "Original part's material type",
	SELECTION_PLACEHOLDER: 'Project mode',
	SEND: 'Send',
	SEND_EMAIL_BUT: 'Send request',
	SEND_TO_SERVICE_BUREAU_ADDRESS_HEADER: 'Shipping address',
	SEND_TO_SERVICE_BUREAU_ADDRESS_LINE_1: 'Address line 1',
	SEND_TO_SERVICE_BUREAU_ADDRESS_LINE_2: 'Address line 2',
	SEND_TO_SERVICE_BUREAU_CITY: 'City',
	SEND_TO_SERVICE_BUREAU_COUNTRY: 'Country',
	SEND_TO_SERVICE_BUREAU_EXPLANATION:
		'CASTOR searches for the most suitable service bureau, taking into account the technologies they support, their location, lead time and reputation.',
	SEND_TO_SERVICE_BUREAU_HOW_TITLE: 'Printing parameters',
	SEND_TO_SERVICE_BUREAU_MATERIAL: 'Material:',
	SEND_TO_SERVICE_BUREAU_NOTES: 'Special requirements from service bureau',
	SEND_TO_SERVICE_BUREAU_NOTES_EXTRA:
		'Enter any advanced services you would like to add e.g. Post processing, time constraints...',
	SEND_TO_SERVICE_BUREAU_PRINTER: 'Printer:',
	SEND_TO_SERVICE_BUREAU_QUANTITY_LABEL: 'quantity',
	SEND_TO_SERVICE_BUREAU_STATE: 'State (US only)',
	SEND_TO_SERVICE_BUREAU_SUBMIT: 'Submit',
	SEND_TO_SERVICE_BUREAU_SUBTITLE: 'Get a Quote from Service Bureau',
	SEND_TO_SERVICE_BUREAU_SUCCESS_POPUP_MESSAGE:
		'We have sent the quotation request to the service bureau',
	SEND_TO_SERVICE_BUREAU_SUCCESS_POPUP_TITLE: 'Quotation request received',
	SEND_TO_SERVICE_BUREAU_TITLE: 'Get a Quote',
	SEND_TO_SERVICE_BUREAU_UPLOAD_DRAWING: 'Upload PDF',
	SEND_TO_SERVICE_BUREAU_UPLOAD_DRAWING_EXPLAINED:
		'Please supply the part drawing in PDF format. The drawing will help CASTOR evaluate whether additional post-processing is required after printing (e.g. in order to comply with high tolerances requirements).',
	SEND_TO_SERVICE_BUREAU_ZIP: 'Zip code',
	SERVICE_BUREAU_EXPLAINED:
		"Please send CASTOR an email explaining your needs, and we will help you find the most appropriate service bureau to supply the part.\r\nInclude the part's file name, the location it will need to be supplied to, and the requested amount of parts required.\r\nWe will reply with an offer within several days.",
	SERVICE_BUREAU_HEADER: 'Send to print',
	SERVICE_BUREAU_SUBHEADER:
		'Get an actual price estimation from a service bureau',
	SET: 'Set',
	SHOW_ALL: 'Show All',
	SHOW_NOTIFICATION: {
		SUCCESS: 'success',
		ERROR: 'error',
		INFO: 'info',
		WARN: 'warn'
	},
	CONFIGURE_SSO_TEXT: 'Configure SSO',
	CONFIGURE_SSO_CLIENT_ID: 'Client ID',
	CONFIGURE_SSO_ENVIRONMENT_ID: 'Environment ID',
	CONFIGURE_SSO_SECRET_KEY: 'Secret key',
	CONFIGURE_SSO_UPDATED: 'SSO Configuration Updated',
	CONFIGURE_SSO_UPDATED_TEXT: 'New SSO parameters were saved successfully',
	LICENSE_UPDATE: 'License update',
	UPDATE_DATABASE_LICENSE:
		'Please activate your software by uploading the license file provided by CASTOR team',
	FUTURE_START_DATE_LICENSE_MSG:
		'License file was loaded successfully. The start date is {0}',
	EXPIRED_DATE_LICENSE_MSG:
		'License is expired in {0}, Please activate your software by uploading the update license file provided by CASTOR team',
	DROP_DATABASE_LICENSE_FILE:
		'Drop your license file or click here to upload license file',
	RUN_DATABASE_INSERT_LICENSE: 'Load license file',
	LICENSE_SUCCESS_ALERT_TEXT: 'License successfully configured!!',

	UPDATE_DATABASE_SUCCESS: 'The file was uploaded successfully',
	UPDATE_DATABASE_FAILED:
		'Something went wrong, please contact your system`s admin or CASTOR`s customer support team',
	UPDATE_LICENSE_FAILED: 'File or license is expire, please contact admin',
	SHOW_PRINTABLE_PARTS_ONLY_SWITCH: 'Display Printable parts only',
	SIGN_OUT: 'Sign Out',
	SOLUTION_ORIENTATION_GENERAL_TEXT:
		"The parameters CASTOR used to calculate different cost estimations per each orientation are\r\namount of supports needed for the specific orientation and number of part's per tray, using the following tray positioning.\r\nYou can change the printing orientation by selecting one of the 6 directions shown here.\r\nThe best material properties  appears along the XY plane.",
	SOLUTION_ORIENTATION_HEADER: [
		'Cost estimation was done using the following assumptions:',
		'{0}% support material',
		'{0} parts per build (see parts orientation below)',
		'Select a preferred tray placement from the options below'
	],
	STARRED: 'Starred',
	START_OVER: 'Start Over',
	STATE: 'State',
	STRENGTH: 'Strength',
	SUBMIT: 'Submit',
	SUBMIT_CHANGES: 'Submit changes',
	SUBMIT_REQUEST: 'Submit Request',
	SUBSCRIPTION_REQUEST_SECCEDD:
		'Thank you for your interest in CASTOR. One of us will contact you soon.',
	SUBSCRIPTION_REQUEST_SECCEDD_HEADER: 'Requests send successfully',
	SUBSCRIPTION_REQUEST_SENT: 'Request sent',
	SUBSCRUPTION_LOGIN_MSG:
		'Your CASTOR subscription has expired. Click the button below if you are interested in CASTOR, and one of us will contact you.',
	SUBSCRUPTION_LOGIN_MSG_HEADER: 'Subscription expired',
	SUBSCRUPTION_TRIAL_LOGIN_MSG:
		'Your free CASTOR trial has expired. Click the button below if you are interested in CASTOR, and one of us will contact you.',
	SUBSCRUPTION_TRIAL_LOGIN_MSG_HEADER: 'Trial expired',
	SUCCESS: 'success',
	SUPPORT: 'Support',
	SURFACE_FINISH_ALERT_TEXT:
		'Please contact the service bureau to estimate the cost of the surface finish post processing.',
	SURFACE_FINISH_ALERT_TITLE: 'Surface finish',
	TESTING: 'testing',
	THREE_D_BUTTON_TEXT: '3D',
	TROUBLE_TO_LOGIN_TEXT: 'Having trouble to login? press ',
	TURMS_CHECKBOX_LINK_TEXT: 'usage agreement',
	TURMS_CHECKBOX_TEXT: 'I agree to the ',
	TWO_FACTOR_LOGIN_HEADER: 'Google Authenticator',
	TWO_FACTOR_LOGIN_SUB_HEADER: 'please enter your google authenticator code:',
	TWO_FACTOR_SETUP_HEADER: 'Connect Google Authenticator to your Account',
	TWO_FACTOR_SETUP_SUB_HEADER: 'Scan QR code and press',
	TWO_FACTOR_TROUBLE_POPUP_BODY_TEXT:
		'We received your request ,castor staff will connect you soon. ',
	TWO_FACTOR_TROUBLE_POPUP_ERROR: 'Error occurred,try again later',
	TWO_FACTOR_TROUBLE_POPUP_HEADER: 'Admin Login Issues',
	TWO_FACTOR_WRONG_CODE: 'Wrong code',
	UNDO_CHANGES: 'Undo Changes',
	UNIFORM_MATERIAL_RADIO_TEXT_ON_UPLOAD: 'Use a single material for all parts',
	UNIT_SELECTION_PLACEHOLDER: 'Select the file unit type',
	UPDATE_CLUSTER: 'Update unification',
	UPDATE_NOW: 'Upgrade Now',
	UPDATE_USER_PROFILE: 'Update',
	UPLOAD_ASSEMBLY_SELECTION: 'An assembly file',
	UPLOAD_COMPLETE_SUCCESSFULLY: 'Upload completed succesfully',
	UPLOAD_DROPEZONE_HEADER: '3. Upload files',
	UPLOAD_EXPLAIN_TEXT_TAB: 'Required files',
	UPLOAD_EXPLAIN_TITLE: 'Upload instructions',
	UPLOAD_EXPLANATION_ARRAY: [
		'After uploading, the algorithm will analyze the full assembly design.',
		'Once processing is completed, you will receive an email with a link to the results page.'
	],
	UPLOAD_EXPLINATION_1_HEADER: "Let's begin!",
	UPLOAD_EXPLINATION_2_HEADER: '2. Upload Instructions',
	UPLOAD_FAILED: 'Upload failed',
	UPLOAD_FILES_BUTTON: 'Start uploading',
	UPLOAD_FORM_HEADER: 'Upload project',
	UPLOAD_INDIVIDUAL_PARTS_SELECTION: 'Individual parts',
	UPLOAD_PARTS_KEEP_PAGE_OPEN:
		'Please keep this page open for the duration of the upload',
	UPLOAD_PARTS_SELECTION: 'Project mode',
	UPLOAD_PROJECT_APPLICATION: 'Application',
	UPLOAD_PROJECT_CAD_SOFTWARE: 'CAD Software (optional)',
	UPLOAD_PROJECT_MATERIAL_TOLERANCE:
		'This project includes parts with tolerances less than ±0.1mm (or exceeds medium or L tolerance classes designation mentioned in DIN ISO 2768)',
	UPLOAD_PROJECT_MATERIAL_TOLERANCE_ALERT_TITLE:
		'CASTOR might add extra machining cost according to the parts manufacturing tolerances. See the table below to determine whether there are parts in the project that requires an accuracy of less than the standard tolerances (according to DIN ISO 2768).\r\nSource: International Organization for Standardization, 2003.',
	UPLOAD_PROJECT_MATERIAL_TOLERANCE_WARNING:
		'Notice, additional machining costs will be incurred',
	UPLOAD_PROJECT_PROJECT_NAME: 'Project name',
	UPLOAD_PROJECT_YEARLY_PROD: 'Estimated yearly production quantity',
	UPLOAD_SOLIDWORKS_EXPORT_TAB: 'SolidWorks BOM export',
	USAGE_AGREEMENT_LINK: 'https://www.3dcastor.com/usage-agreement',
	USER_ADDRESS: 'Address',
	USER_INFO_UPDATED_SUCCESS: 'User info updated successfully',
	USER_INFORMATION_FROM_EDIT_LABELS: {
		TRIAL: 'trial',
		EXPIRE_DATE: 'subscription expire date',
		PARTS_CREDIT: 'parts credit',
		VERIFIED: 'verified'
	},
	USER_INFORMATION_HEADERS: {
		SUBSCRIPTION_INFORMATION_INFO_HEADER: 'Subscription Information',
		PROJECT_AND_PARTS_INFO_HEADER: 'Project & Parts Information',
		PERSONAL_INFO_HEADER: 'Personal Information',
		PRINTERS_INFO_HEADER: 'In-house Printers',
		MATERIALS_INFO_HEADER: 'Custom Materials',
		PERMISSIONS: 'Permissions'
	},
	USER_MATERIAL_ADD_FAILED: 'User material failed',
	USER_MATERIAL_ADD_NEW: 'Add new material',
	USER_PRINTER_ADD_NEW: 'Add new 3D Printer',
	USER_MATERIAL_ADDED: 'User material added',
	USER_MATERIAL_DELETE_ALERT_BODY:
		'The material will be erased permanently from the list.\r\nIf any part makes use of this material, it will be replaced by a similar material.',
	USER_PRINTER_DELETE_ALERT_BODY:
		'The printer will be erased permanently from the list.\nIf any part makes use of this printer, it will be replaced by a similar printer.',
	USER_PRINTER_MATERIAL_DELETE_ALERT_BODY:
		'The printer material will be erased permanently from the list.\nIf any part makes use of this printer material, it will be replaced by a similar printer material.',
	USER_MATERIAL_DELETE_ALERT_TITLE: 'Erase material?',
	USER_PRINTER_DELETE_ALERT_TITLE: 'Erase printer?',
	USER_MATERIAL_DELETE_FAILED: 'error removing material',
	USER_MATERIAL_METHOD: 'Method',
	USER_MATERIAL_NAME: 'name',
	USER_MATERIAL_NO_MATERIALS_FOUND: 'No custom materials found',
	USER_MATERIAL_SURFACE_FINISH_METHOD: 'Surface Finish Method',
	USER_MATERIAL_TEMPERATURE_UNIT: 'Temperature Unit',
	USER_MATERIAL_TYPE: 'Type',
	USER_MATERIAL_UNITS: 'Units',
	USER_MENU_PROFILE: 'Profile',
	USER_MENU_HEALTH_STATISTICS: 'Download health statistics',
	HEALTH_STATISTICS_ERROR:
		'There are no data to export, Please contact us at support@3dcastor.com',
	HEALTH_STATISTICS_SUCCESS: 'The data was exported',
	HEALTH_STATISTICS_FAILED: 'Export health statistics is failed',
	USER_MENU_SETTINGS: 'Settings',
	USER_MENU_TAKE_A_TOUR: 'Take a Tour',
	USER_PROFILE_ADDRESS_ALERT: 'Please enter a valid user address',
	USER_PROFILE_ADDRESS_PLACEHOLDER: 'Search Places ...',
	USER_PROFILE_COMPANY_ALERT: 'User company must be a valid company',
	USER_PROFILE_EMAIL_ALERT: 'Must be a valid email',
	USER_MENU_UPLOAD_SCRIPT: 'Database update',
	DROP_DATABASE_FILE: 'Drop your file or click here to upload the script',
	RUN_DATABASE_UPDATE: 'Run database update',
	UPDATE_DATABASE_INFO:
		'In order to Update the database, choose the file you received from a member of the Castor team and click "Run database update"',
	USER_PROFILE_NAME_ALERT: 'Please enter a valid user name',
	VERSION_POPUP_TEXT:
		'The page will reload in order to activate the new version',
	VERSION_POPUP_TITLE: 'A new version of CASTOR is available!',
	VIEW_PART: 'View part',
	COUNTRY_NOT_VALID: 'Please provide a valid Country',
	STATE_NOT_VALID: 'Please provide a valid State',
	MUST_CHOOSE_ORGANIZATION: 'Site is missing',
	VIEW_PROJECT: 'View project',
	WALL_THICKNESS_ALERT_HELPER_TEXT:
		'Thickness must be between 0.1 to 2.4. A maximum of one digit after the decimal point is allowed.',
	WALL_THICKNESS_DEMAND_ALERT_DESCRIPTION:
		'The recommended minimum thickness threshold for this part is > {0} [mm].\r\nIn order to perform a customized test, enter the minimum required thickness of the part here.',
	WALL_THICKNESS_DEMAND_ALERT_TITLE: 'Customized thickness',
	WALL_THICKNESS_DEMAND_BUTTON_PLACEHOLDER: 'Customized thickness',
	WALL_THICKNESS_DEMAND_BUTTON_TEXT: 'Customized thickness analysis',
	WALL_THICKNESS_NOTIFICATION_FAILED:
		'Request for customized thickness analysis failed',
	WALL_THICKNESS_NOTIFICATION_SUCCEDED: 'Thickness changed',
	WALL_THICKNESS_NOTIFICATION_TOO_LONG:
		'Request for customized thickness analysis has timed out. You will receive an Email when the results are ready',
	WALL_THICKNESS_UPDATE_ERROR: 'Error updating thickness',
	WEIGHT_RESULT_INFO:
		"The weight of the part, if manufactured with the suggested 3D-Printer and material. You can compare the part's weight with 3D-Printing to its weight if manufactured with the original material by clicking the 'Compare' button above.",
	WEIGHT_RESULT_LABEL: 'Weight',
	WEIGHT_RESULT_TITLE_INFO: 'Weight',
	YES: 'Yes',
	IMAGES_TAB_HEADER_STRUCTURAL_LIMITATION: 'Structural Limitation',
	IMAGES_TAB_HEADER_TRAY_ORIENTATION: 'Tray Orientation',
	CHOOSE_ORIENTATION_BUTTON: 'Choose orientation',
	ORIENTATION_CHANGED_MSG: 'Changing Orientation',
	MECHANICAL_ANALYSIS_ORIENTATION_EXPLANATION:
		'The analysis takes into account the orientation. Make sure the selected orientation suits you.',
	FORGOT_PASSWORD_ON_PREM_TEXT:
		"Please Contact Castor's support to reset your password",
	NEW_PART_CONFIGURATION_EDIT_MATERIAL: 'Edit: material',
	NEW_PART_CONFIGURATION_FIRST_METHOD: 'Method 1 Detail',
	NEW_PART_CONFIGURATION_SECOND_METHOD: 'Method 2 Detail',
	NEW_PART_CONFIGURATION_MATERIAL: 'Material',
	NEW_PART_CONFIGURATION_MATERIAL_DETAIL: 'Material detail',
	NEW_PART_CONFIGURATION_QUANTITY: 'Production Quantity',
	NEW_PART_CONFIGURATION_YEARS_OF_DEMAND: 'Years of demand',
	NEW_PART_CONFIGURATION_SEND_FAILURE_TEXT: 'New configuration was not sent',
	NEW_PART_CONFIGURATION_SEND_SUCCESS_TEXT: 'New configuration was sent',
	COST_SUMMARY_ADDITIVE_METHOD: 'Additive Manufacturing:',
	COST_SUMMARY_TRADITIONAL_METHOD: 'Traditional Manufacturing:',
	COST_SUMMARY_METHOD: 'Method',
	TAB_HEADER_MANUFACTURABILITY: 'Manufacturability',
	TAB_HEADER_PRODUCTION_TIME: 'Production time',
	TAB_HEADER_COST_PER_PART: 'Cost per part',
	SYSTEM_RECOMMENDATION: 'System recommendation',
	OFF_THE_SHELF: 'Is an off the shelf item',
	THICKNESS_SCORE: 'Thickness Score',
	THICKNESS_FAIL_REASON: 'Thickness Fail reason',
	SIZE_SCORE: 'Size Score',
	SIZE_FAIL_REASON: 'Size Fail reason',
	CAD_SCORE: 'Cad score',
	CAD_FAIL_REASON: 'CAD Fail reason',
	MATERIAL_SCORE: 'material Score',
	MATERIAL_FAIL_REASON: 'material fail reason',
	COMPLEXITY_SCORE: 'complexity score',
	UPDATE_PART_PROPERTIES: 'Update the parts properties',
	PART_RESULT: 'Part result',
	ERROR_WILL_BE_SHOWN:
		'error message (will be shown in popup when trying to open part)',
	PAGE_INVALIDATED:
		'This page was invalidated. please go back and try clicking the edit part button again',
	ERROR_FILE_FORMAT_SUPPORT_PDF:
		'Attachment has wrong format. We support PDF formats',
	NEW_CONFIGURATION_ERROR: 'New configuration error',
	MANUFACTURABILITY_SHOULD_BE_VALIDATED:
		'Manufacturability should be validated before production',
	GRID_TOGGLE: 'Table',
	GRAPH_TOGGLE: 'Graph',
	SHIPPING_ADDRESS_IS_MISSING: 'Shipping address is missing or invalid',
	PRINTING_ORIENTATION_ALERT_TITLE: 'Printing orientation',
	UNSUPPORTED_BROWSER_ALERT_TITLE: 'Browser not supported',
	UNSUPPORTED_BROWSER_ALERT_BUTTON: 'Continue anyway!',
	UNSUPPORTED_BROWSER_ALERT_MESSAGE:
		'Your browser is not supported using the Castor application. Please use Chrome browser',
	CUSTOM_CONFIGURATION: 'Custom Configuration',
	NEW_CONFIGURATION: 'New Configuration',
	ADMIN_PANEL_FEATURE_TOGGLE_TITLE: 'Feature Toggle',
	ADMIN_PANEL_FEATURE_TOGGLE_CURRENT_ENVIRONMENT: 'Current environment toggles',
	ADMIN_PANEL_FEATURE_TOGGLE_BACKUP_ENVIRONMENT: 'Back up environment',
	ADMIN_PANEL_FEATURE_TOGGLE_UPDATE_ENVIRONMENT: 'Update environment',
	ADMIN_PANEL_FEATURE_TOGGLE_UPDATE_CONFIGURATION:
		'Update toggle configuration',
	ADMIN_PANEL_FEATURE_TOGGLE_CREATE_CONFIGURATION: 'Create new configuration',
	ENTER_NEW_CONFIGURATION_EXPRESSION: 'Enter new configuration name',
	SAVING_FEATURES_WARNING_LIVE_ENVIRONMENT:
		'Are you sure you want to make these changes to the live environment?',
	SAVING_FEATURES_WARNING: 'Are you sure you want to save these changes?',
	UPDATE_FEATURE: 'Update features',
	ADMIN_FEATURE_UPDATE_SUCCESS: 'Features updated successfully',
	ADMIN_FEATURE_CREATE_SUCCESS: 'Features configuration added successfully',
	USER_SETTINGS_DEFAULT_OBJECTIVES_TITLE:
		'Default project life cycle stage is:',
	USER_SETTINGS_DEFAULT_OBJECTIVES_ARRAY: [
		'Please provide your preferences for default project life cycle stage to be for 3D printed parts cost model.'
	],
	UPLOAD_TAB_TEXTS: {
		TWO_D: 'Upload new 3D CAD files project',
		THREE_D: 'Upload new 2D drawing files project',
		METADATA: 'Upload new metadata files project'
	},
	UNSUPPORTED_DEVICE_ALERT_TITLE: 'Device not supported',
	UNSUPPORTED_DEVICE_ALERT_MESSAGE:
		'Your device is not supported using the Castor application.\\nPlease open the app from the computer.',
	UNSUPPORTED_DEVICE_ALERT_BUTTON: 'Continue anyway!',
	PAGE_IS_NOT_EXIST_OR_ACCESS:
		'This page is not exist or you don`t have access to view it',
	ONBOARDING_WIZARD_PAGE_NAME: 'Onboarding Wizard',
	USER_MENU_ONBOARDING_WIZARD: 'Onboarding',
	ONBOARDING_START_SCREEN_GREETING: 'Welcome to',
	ONBOARDING_START_SCREEN_DESCRIPTION_TEXTS:
		'["Before we begin analyzing your parts, we would like to ask you a few questions so that we are able to provide you with even more accurate and relevant results, and cost estimations.","In just a few steps, you can configure essential parameters such as cost models, printer & material preferences and supply chain settings, tailored to your unique manufacturing needs.","<b>Estimated time:</b> 30 minutes"]',
	ONBOARDING_START_SCREEN_START_BUTTON_TEXT: 'Start onboarding',
	ONBOARDING_DONE_SCREEN_HEADING: 'We’re Good To Go!',
	ONBOARDING_DONE_SCREEN_DESCRIPTION:
		'You can always adjust these parameters (and others) in the “Settings” section.',
	ONBOARDING_DONE_SCREEN_START_BUTTON_TEXT: 'Start using castor',
	ONBOARDING_USE_DEFAULT_AND_CONTINUE: 'Use default and continue',
	ONBOARDING_CONTINUE: 'Continue',
	ONBOARDING_BACK_BUTTON: 'Back',
	ONBOARDING_COST_MODEL_TITLE: 'Cost Model',
	ONBOARDING_IN_HOUSE_PRINTERS_TITLE: 'In-House Printers',
	ONBOARDING_IN_HOUSE_PRINTERS_HEADER: 'Specify 3D Printers Your Company Owns',
	ONBOARDING_IN_HOUSE_PRINTERS_DESCRIPTION:
		"By detailing the 3D printers your company owns or frequently utilizes, we'll give them priority.<br/>You can even let us know if you prefer results exclusively from these printers or if you're open to results from other printers too.",
	IN_HOUSE_PRINTERS_ONLY_EXPLANATION:
		'If you select this checkbox, CASTOR will recommend solutions that utilize only your in-house printers and materials.',
	IN_HOUSE_PRINTERS_FILTER_UPDATE_SUCCESS:
		'In-house printers filter was updated succesfully.',
	IN_HOUSE_PRINTERS_FILTER_UPDATE_ERROR:
		'In-house printers filter update failed.',
	ONBOARDING_COST_AND_SUPPLY_CHAIN_TITLE: 'Cost & Supply Chain Parameters',
	ONBOARDING_COST_AND_SUPPLY_CHAIN_HEADER:
		'Customize Cost- and Supply Chain-Related Parameters to Your Preference.',
	ONBOARDING_COST_AND_SUPPLY_CHAIN_DESCRIPTION:
		'By detailing your known costs, such as labor cost, we can better fit your company’s actual costs.',
	DEFAULT_PROJECT_SCENARIO_UPDATE_SUCCESS:
		'Default project life cycle stage was updated succesfully.',
	ONBOARDING_MATERIAL_NAMES_TITLE: 'Material Names',
	ONBOARDING_MATERIAL_NAMES_HEADER:
		'Match Your Project Material Names to Align With CASTOR`s Database',
	ONBOARDING_MATERIAL_NAMES_DESCRIPTION:
		'If your company uses customized material names, help us enhance your experience by mapping these materials to the ones we are familiar with.',
	ONBOARDING_SOLUTION_FILTERS_TITLE: 'Solution Filters',
	ONBOARDING_SOLUTION_FILTERS_HEADER:
		'Customized Results Based on Acceptable Additive Manufacturing Properties, Technologies, Unique Requirements, or Geometric Characteristics.',
	ONBOARDING_CUSTOMIZE_SOLUTION_PREFERENCES_TITLE:
		'Customize your solution preferences',
	ONBOARDING_CUSTOMIZE_GEOMETRY_ANALYSIS_PREFERENCES_TITLE:
		'Customize your geometry analysis preferences',
	WEIGHT_KG: '[Kg]',
	WEIGHT_GRAM: '[gram]',
	ACCESS_DENIED_ERROR: 'Access to the requested resource was denied.',
	INVALID_REQUEST_ERROR:
		'The request is missing a required parameter, includes an invalid parameter value, includes a parameter more than once, or is otherwise malformed.',
	AUTH_REQUEST_ERROR: 'An error occurred during authentication.'
}

export const userStringsFallback: Record<string, any> =
	getJsonItemFromLocalStorage('userStrings').userStrings || strings
