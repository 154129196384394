import React, { FC, memo, useEffect, useMemo, useRef, useState } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'

import { CircularProgress, MenuItem, Select } from '@material-ui/core'

import { IOrganization } from 'Services/models/IOrganization'
import { getString } from 'Services/Strings/StringService'

type IProps = {
	id?: string
	value: number
	disabled?: boolean
	className?: string
	selectClassName?: string
	title: string
	disabledMenuItem?: boolean
	disableOutOfLicenseOrganizations?: boolean
	renderValue: (selected: unknown) => string
	handleOrganizationSelect: (value: number) => void
}

const chunk = 50

const OrganizationSelector: FC<IProps> = ({
	disabled,
	className,
	selectClassName,
	value,
	id,
	title,
	disabledMenuItem,
	disableOutOfLicenseOrganizations,
	handleOrganizationSelect,
	renderValue
}) => {
	const [organizationsList, setOrganizationsList] = useState<IOrganization[]>(
		[]
	)
	const [trigger, setTrigger] = useState(false)
	const observerRef = useRef(null)
	const menuRef = useRef(null)

	const { availableOrganizations } = useSelector(
		(state: RootStateOrAny) => state.user
	)

	const hasMore = useMemo(
		() => availableOrganizations.length > organizationsList.length,
		[availableOrganizations.length, organizationsList.length]
	)

	useEffect(() => {
		if (organizationsList.length === 0) {
			const organizationsChunk = availableOrganizations.slice(0, chunk)
			setOrganizationsList(organizationsChunk)
		}
	}, [availableOrganizations, organizationsList.length])

	useEffect(() => {
		setTrigger(false)

		const current = observerRef.current

		if (!current) return

		const getNextChunk = () => {
			const organizationsChunk = availableOrganizations.slice(
				0,
				organizationsList.length + chunk
			)
			setOrganizationsList(organizationsChunk)
		}

		const observer = new IntersectionObserver(
			entries => {
				if (entries[0].isIntersecting && hasMore) {
					getNextChunk()
				}
			},
			{
				root: menuRef.current,
				rootMargin: '0px',
				threshold: 1.0
			}
		)

		observer.observe(current)

		return () => {
			current && observer.unobserve(current)
		}
	}, [availableOrganizations, hasMore, organizationsList.length, trigger])

	return (
		<Select
			id={id || 'organization-selector'}
			disabled={disabled}
			className={
				className || 'customize-settings--header--organization--select-field'
			}
			value={value}
			renderValue={renderValue}
			title={title}
			onChange={event => handleOrganizationSelect(event.target.value as number)}
			classes={{
				select:
					selectClassName || 'customize-settings--header--organization--select'
			}}
			onOpen={() => setTrigger(true)}
			inputProps={{
				name: 'simpleSelect',
				id: 'simple-select'
			}}
			MenuProps={{
				PaperProps: {
					style: {
						transform: 'translate3d(0, 0, 0)',
						maxHeight: '80%',
						width: '430px'
					}
				},
				ref: menuRef
			}}
		>
			{disabledMenuItem && (
				<MenuItem disabled>{getString('CHOOSE_SITE')}</MenuItem>
			)}
			{organizationsList?.map((organization: IOrganization) => {
				return (
					<MenuItem
						key={organization.id}
						value={organization.id}
						disabled={
							disableOutOfLicenseOrganizations && !organization.inLicense
						}
					>
						{`${organization?.name}${
							organization?.private ? ` (${getString('PRIVATE')})` : ''
						}`}
					</MenuItem>
				)
			})}
			{hasMore && (
				<MenuItem key="-1" disabled>
					<CircularProgress size={20} style={{ margin: '0 auto' }} />
				</MenuItem>
			)}
			<div key="-2" ref={observerRef} style={{ height: 1 }} />
		</Select>
	)
}

export default memo(OrganizationSelector)
