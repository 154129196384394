export enum MappingStatus {
	EXIST = 'EXIST',
	NOT_EXIST = 'NOT_EXIST',
	DEFAULT_APPLIED = 'DEFAULT_APPLIED'
}

export const PROJECT_MATERIAL_BOM_MAPPING_LOADING =
	'PROJECT_MATERIAL_BOM_MAPPING_LOADING'
export const GET_PROJECT_MATERIAL_MAPPING = 'GET_PROJECT_MATERIAL_MAPPING'
export const START_PROJECT_MATERIAL_MAPPING = 'START_PROJECT_MATERIAL_MAPPING'
export const START_PROJECT_MATERIAL_ERROR = 'START_PROJECT_MATERIAL_ERROR'
export const MATERIAL_TYPE_CHANGE = 'MATERIAL_TYPE_CHANGE'
export const MATERIAL_CATEGORY_CHANGE = 'MATERIAL_CATEGORY_CHANGE'
export const MATERIAL_CHANGE = 'MATERIAL_CHANGE'
export const RESET_ALL_MATERIAL_SELECTIONS = 'RESET_ALL_MATERIAL_SELECTIONS'
export const USE_DEFAULT_MATERIALS_SELECTIONS =
	'USE_DEFAULT_MATERIALS_SELECTIONS'

export const CHANGE_PROJECT_MATERIAL_MAPPING_PAGE =
	'CHANGE_PROJECT_MATERIAL_MAPPING_PAGE'
