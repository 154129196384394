import { FC, memo } from 'react'

import SkipIcon from '@material-ui/icons/Done'
import DoneIcon from '@material-ui/icons/DoneAllTwoTone'
import WarnIcon from '@material-ui/icons/WarningOutlined'
import cx from 'classnames'
import { isEmpty, isString } from 'lodash'

import { Danger } from '../thirdParty/CreativeTim/components'
import DetailsPopup from 'Scenes/Components/DetailsPopup'

import './DataTable.scss'

export enum StatusBarColor {
	GREEN = 'green',
	ORANGE = 'orange',
	RED = 'red',
	BLUE = 'blue'
}

interface IProps {
	text: string | number
	className: string
	data: any
}

const renderIcon = (color: StatusBarColor) => {
	switch (color) {
		case StatusBarColor.GREEN:
			return <DoneIcon />
		case StatusBarColor.ORANGE:
			return <WarnIcon />
		case StatusBarColor.BLUE:
			return <SkipIcon />
		default:
			return <></>
	}
}
const DataTableStatusBarForMaterialsMatch: FC<IProps> = ({
	text,
	className,
	data
}) => {
	let dataTableText =
		(isString(text) && isEmpty(text)) || text == null ? '' : text

	return (
		<DetailsPopup
			inPortal={true}
			isHover={data.tooltip}
			data={data.tooltipText}
			managerClassName={cx('data-table-status-bar with-border', {
				'with-tooltip': data.tooltip
			})}
			popperDirection="left-start"
			popperClassName="data-table-status-bar-popper-message"
			popperContactClassName="data-table-status-bar-popper-data"
		>
			<div className="data-table-status-bar-section">
				<div
					data-qa={`data-qa-part-filed-${dataTableText}`}
					className={cx('data-table-status-bar', className, data.className, {
						'with-tooltip': data.tooltip,
						'status-color-green': data.color == StatusBarColor.GREEN,
						'status-color-orange': data.color == StatusBarColor.ORANGE,
						'status-color-blue': data.color == StatusBarColor.BLUE
					})}
					title={data.tooltip || text == null ? '' : text.toString()}
				>
					{renderIcon(data.color)}
					{dataTableText}
				</div>
				{data.showErrorForTextField && data.error && (
					<Danger className={`text_field__error error-in-text-field`}>
						{data.error}
					</Danger>
				)}
			</div>
		</DetailsPopup>
	)
}

export default memo(DataTableStatusBarForMaterialsMatch)
