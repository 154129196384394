import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as AdminPrintersActions from './AdminPrintersActions'
import {
	adminPrintersEditFormRoute,
	adminPrintersNewFormRoute,
	adminPrintersRoute
} from '../../../../Services/routeFuncs'
import {
	ADMIN_PRINTERS_PAGE_HEADER,
	ADMIN_PRINTERS_PARAMS,
	ADMIN_PRINTERS_SUB_TITLE,
	ADMIN_PRINTERS_TABLE_TEXTS
} from '../../../../Services/Strings'
import AdminDataTable from '../AdminDataTable/AdminDataTable'
import { PRINTERS_ADMIN_ROUTE } from 'Services/Constants/RoutesConstants'
import { getString } from 'Services/Strings/StringService'

import '../adminHome.scss'

const printerParams = ADMIN_PRINTERS_PARAMS

class AdminPrinters extends Component {
	componentDidMount() {
		const {
			searchPhrase,
			limitPrinters,
			onAdminPrintersUnmounted,
			setupAdminPrintersPage,
			match,
			pageNumber
		} = this.props
		let urlPage = parseInt(match.params['page']) || 1
		setupAdminPrintersPage(searchPhrase, urlPage, limitPrinters)

		this.unListen = this.props.history.listen((location, action) => {
			if (
				action === 'PUSH' &&
				!location.pathname.includes(PRINTERS_ADMIN_ROUTE)
			) {
				onAdminPrintersUnmounted()
			}
		})
	}

	componentDidUpdate = prevProps => {
		const {
			match,
			pageNumber,
			setupAdminPrintersPage,
			limitPrinters,
			searchPhrase
		} = this.props
		let urlPage = parseInt(match.params['page']) || 1
		const isSearchChange = searchPhrase !== prevProps.searchPhrase

		if (isSearchChange && urlPage !== 1) {
			this.props.history.push(PRINTERS_ADMIN_ROUTE + `/1`)
		} else if (urlPage === 1 && isSearchChange) {
			setupAdminPrintersPage(searchPhrase, pageNumber, limitPrinters)
		}
	}

	componentWillUnmount() {
		this.unListen()
	}

	renderTechnologyField = (printer, printingTechnologies) => {
		const technologyName = printingTechnologies?.find(
			tech => tech?.name === printer?.printerTechnologyName
		)

		return (
			<div className="admin-multiple-line-elypsis" title={printer.description}>
				{printer.printerTechnologyName
					? `${
							technologyName?.userReadableName || printer?.printerTechnologyName
					  } - `
					: ''}
				{printer.description}
			</div>
		)
	}

	renderTraySizeField = printer => {
		return (
			<div className="admin-multiple-line-field">
				<div>
					{ADMIN_PRINTERS_TABLE_TEXTS.TRAY_X}:{printer.trayX}
				</div>
				<div>
					{ADMIN_PRINTERS_TABLE_TEXTS.TRAY_Z}:{printer.trayZ}
				</div>
				<div>
					{ADMIN_PRINTERS_TABLE_TEXTS.TRAY_Y}:{printer.trayY}
				</div>
			</div>
		)
	}

	renderCostsField = printer => {
		return (
			<div className="admin-multiple-line-field">
				<div>
					{ADMIN_PRINTERS_TABLE_TEXTS.BUILT_RATE}:{printer.buildRate}
				</div>
				<div>
					{ADMIN_PRINTERS_TABLE_TEXTS.MACHINE_COST}:{printer.machineCost}
				</div>
			</div>
		)
	}

	renderPrintersTableData = () => {
		const { printers, printingTechnologies } = this.props
		return printers.map(printer => {
			return [
				printer.name,
				printer.company,
				this.renderTechnologyField(printer, printingTechnologies),
				this.renderTraySizeField(printer),
				this.renderCostsField(printer),
				printer.organizationId || '',
				printer.organizationOwnerEmail || '',
				printer.id
			]
		})
	}

	extraMessageOnDelete = id => {
		const { printers } = this.props
		let extraMessage = ''
		const printer = printers.find(printer => printer.id === id)
		if (printer?.hasPrinterMaterials) {
			extraMessage = getString('DELETE_PRINTER_EXTRA_WARNING')
		}
		return extraMessage
	}

	deleteRow = id => {
		this.props.deletePrinter(id)
	}

	setSearchPhrase = searchPhrase => {
		this.props.setSearchPhrase(searchPhrase)
	}

	render() {
		const {
			showNoPrintersAlert,
			showPagination,
			pageNumber,
			isLastPage,
			showAdminPrintersAlert,
			totalPrinters,
			limitPrinters,
			searchPhrase,
			loading
		} = this.props
		return (
			<AdminDataTable
				loading={loading}
				formTitle={ADMIN_PRINTERS_PAGE_HEADER}
				formSubTitle={ADMIN_PRINTERS_SUB_TITLE}
				tableHeadParams={printerParams}
				tableData={this.renderPrintersTableData()}
				linkToRouteFunction={adminPrintersRoute}
				showNoDataAlert={showNoPrintersAlert}
				showPagination={showPagination}
				pageNumber={pageNumber}
				isLastPage={isLastPage}
				showAdminErrorAlert={showAdminPrintersAlert}
				totalResults={totalPrinters}
				limitResults={limitPrinters}
				linkToEdit={adminPrintersEditFormRoute}
				linkToNew={adminPrintersNewFormRoute}
				deleteRow={this.deleteRow}
				setSearchPhrase={this.setSearchPhrase}
				searchPhrase={searchPhrase}
				extraMessageOnDelete={this.extraMessageOnDelete}
			/>
		)
	}
}

const mapStateToProps = ({ AdminPrintersReducer, user }) => {
	const { printingTechnologies } = user

	return {
		...AdminPrintersReducer,
		printingTechnologies
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators({ ...AdminPrintersActions }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminPrinters)
