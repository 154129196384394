import React, { FC, memo, useEffect } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { AnyAction, bindActionCreators } from 'redux'

import { Divider } from '@material-ui/core'

import * as CustomizeCO2Actions from '../CustomizeCO2Cost/CustomizeCO2Actions'
import * as CustomizeCO2CalculationAction from './CustomizeCO2CalculationAction'
import CustomizeCO2Cost from '../CustomizeCO2Cost/CustomizeCO2Cost'
import CustomizeCO2Holding from '../CustomizeCO2Cost/CustomizeCO2Holding'
import OrganizationPanel from '../OrganizationPanel/OrganizationPanel'
import CustomizeCO2CarbonCreditCost from './CO2CarbonCreditCost/CustomizeCO2CarbonCreditCost'
import CustomizeCO2EmissionsPrinterMaterial from './CO2Emissions/CustomizeCO2EmissionsPrinterMaterial'
import CustomizeCO2EmissionsRawMaterial from './CO2Emissions/CustomizeCO2EmissionsRawMaterial'
import EmissionsPrinterMaterialTable from './CO2Emissions/EmissionsPrinterMaterialTable'
import EmissionsRawMaterialTable from './CO2Emissions/EmissionsRawMaterialTable'
import CustomizeCO2EndOfLife from './CO2EndofLife/CustomizeCO2EndOfLife'
import CustomizeCO2TypicalKwh from './CO2Manufacturing/CustomizeCO2TypicalKwh'
import CustomizeCO2ProductUse from './CO2ProductUse/CustomizeCO2ProductUse'
import CastorForm from 'Scenes/Components/CastorForm/CastorForm'
import CastorFormHeader from 'Scenes/Components/CastorForm/CastorFormHeader'
import NavBarAndMaterial from 'Scenes/Components/NavBarAndMaterial'
import {
	CUSTOMIZE_ROUTE,
	USER_HOME_ROUTE
} from 'Services/Constants/RoutesConstants'
import { getString } from 'Services/Strings/StringService'
import { getTheme } from 'themes/getTheme'

import './CustomizeCO2Calculation.scss'

const { defaultMaterial } = getTheme()

const DividerTSX: any = Divider

interface IReduxStore {
	user: any
	CustomizeCO2CalculationReducer: any
	CustomizeInHousePrintersReducer: any
	CustomizeCO2Reducer: any
	CustomizeReducer: any
}

interface IProps {
	onEditToggle: Function
	onEditAllToggle: Function
	onEditCO2Data: Function
	onUpdateCO2Data: Function
	onSetUserCustomizationSettings: Function
	onCo2PerKgMaterialChange: Function
	onPuAnnualKgCO2Change: Function
	onDisposeFactorChange: Function
	onCo2PerKgPrinterMaterialChange: Function
	onCreateOrUpdateMaterial: Function
	onDeleteMaterial: Function
	onCreateOrUpdatePrinterMaterial: Function
	onDeletePrinterMaterial: Function
	fetchUserCo2Data: Function
	onPuYearsCO2Change: Function
	materialTypes: any
	materials: any
	materialCategories: any
	co2PerKgMaterial: any
	co2PerKgPrinterMaterial: any
	materialList: any
	printersList: any
	printersFullData: any
	puAnnualKgCO2: number
	puYearsCO2: number
	disposeFactorValue: string
	puAnnualKgCO2Value: string
	puYearsCO2Value: string
	co2EmissionProps: any
	adminSelectedOrganizationId?: number
}

const CustomizeCO2EmissionsSelector: FC<IProps> = ({
	materialCategories,
	materialTypes,
	materials,
	onCo2PerKgMaterialChange,
	onCreateOrUpdateMaterial,
	co2PerKgMaterial,
	co2PerKgPrinterMaterial,
	materialList,
	printersList,
	onCreateOrUpdatePrinterMaterial,
	onPuYearsCO2Change,
	puAnnualKgCO2Value,
	puYearsCO2Value,
	puAnnualKgCO2,
	puYearsCO2,
	printersFullData,
	onSetUserCustomizationSettings,
	onPuAnnualKgCO2Change,
	onCo2PerKgPrinterMaterialChange,
	fetchUserCo2Data,
	co2EmissionProps,
	onEditToggle,
	onEditAllToggle,
	onEditCO2Data,
	onUpdateCO2Data,
	adminSelectedOrganizationId
}) => {
	const history = useHistory()

	useEffect(() => {
		fetchUserCo2Data(adminSelectedOrganizationId)
	}, [adminSelectedOrganizationId, fetchUserCo2Data])

	const removePrinterMaterial = (id: string) => {
		const updatePrinterMaterial = printersList.find(
			(list: Record<string, any>) => list?.material?.id === id
		)
		if (updatePrinterMaterial) {
			onCreateOrUpdatePrinterMaterial(
				{
					material: updatePrinterMaterial.material,
					co2PerKg: null
				},
				undefined,
				adminSelectedOrganizationId
			)
		}
	}

	const removeMaterial = (id: string) => {
		const updateMaterial = materialList.find(
			(list: Record<string, any>) => list?.material?.id === id
		)

		if (updateMaterial) {
			onCreateOrUpdateMaterial(
				{
					material: updateMaterial.material,
					co2PerKg: null
				},
				undefined,
				adminSelectedOrganizationId
			)
		}
	}

	const renderSelectors = () => {
		return (
			<div>
				<div className="custom-calculation-co2--title">
					{getString('CUSTOMIZE_CO2_MATERIAL_PRODUCTION')}
				</div>
				<CustomizeCO2EmissionsRawMaterial
					defaultMaterial={defaultMaterial}
					materialCategories={materialCategories}
					materialTypes={materialTypes}
					materials={materials}
					onCo2PerKgMaterialChange={onCo2PerKgMaterialChange}
					setNewMaterial={(material: Record<string, any>) =>
						onCreateOrUpdateMaterial(
							material,
							undefined,
							adminSelectedOrganizationId
						)
					}
					value={co2PerKgMaterial}
					dataQaSuffix="material-production"
				></CustomizeCO2EmissionsRawMaterial>
				<EmissionsRawMaterialTable
					materialList={materialList}
					deleteMaterial={(id: string) => removeMaterial(id)}
				></EmissionsRawMaterialTable>
				<CustomizeCO2EmissionsPrinterMaterial
					onCo2PerKgPrinterMaterialChange={onCo2PerKgPrinterMaterialChange}
					setNewPrinterMaterial={(printerMaterial: Record<string, any>) =>
						onCreateOrUpdatePrinterMaterial(
							printerMaterial,
							undefined,
							adminSelectedOrganizationId
						)
					}
					value={co2PerKgPrinterMaterial}
					printersFullData={printersFullData}
					dataQaSuffix="material-production"
				></CustomizeCO2EmissionsPrinterMaterial>
				<EmissionsPrinterMaterialTable
					printersList={printersList}
					deleteMaterial={(id: string) => removePrinterMaterial(id)}
				></EmissionsPrinterMaterialTable>
				<DividerTSX className="custom-calculation-co2--divider" />
				<div className="custom-calculation-co2--title">
					{getString('PRODUCTION')}
				</div>
				<div data-qa="data-qa-production-section">
					<CustomizeCO2TypicalKwh />
				</div>
				<DividerTSX className="custom-calculation-co2--divider" />
				<div className="custom-calculation-co2--title">
					{getString('CO2_PARAMETERS_PRODUCT_USE_HEADER_TEXT')}
				</div>
				<CustomizeCO2ProductUse
					onPuAnnualKgCO2Change={onPuAnnualKgCO2Change}
					onPuYearsCO2Change={onPuYearsCO2Change}
					puAnnualKgCO2Value={puAnnualKgCO2Value}
					puYearsCO2Value={puYearsCO2Value}
					setUserCustomizationSettings={onSetUserCustomizationSettings}
					puAnnualKgCO2={puAnnualKgCO2}
					puYearsCO2={puYearsCO2}
				></CustomizeCO2ProductUse>
				<DividerTSX className="custom-calculation-co2--divider" />
				<div className="custom-calculation-co2--title">
					{getString('CO2_PARAMETERS_END_OF_LIFE_HEADER_TEXT')}
				</div>
				<CustomizeCO2EndOfLife />
				<DividerTSX className="custom-calculation-co2--divider" />
				<div className="carbon-credit-section">
					<CustomizeCO2CarbonCreditCost />
				</div>
				<DividerTSX className="custom-calculation-co2--divider" />
				<div data-qa="data-qa-transportation-section">
					<div className="custom-calculation-co2--title big">
						{getString('TRANSPORTATION_AND_HOLDING')}
					</div>
					<div className="custom-calculation-co2--title">
						{getString('TRANSPORTATION')}
					</div>
					<CustomizeCO2Cost
						{...co2EmissionProps}
						onEditToggle={onEditToggle}
						onEditAllToggle={onEditAllToggle}
						onEditCO2Data={onEditCO2Data}
						onUpdateCO2Data={(reset: boolean, co2Key?: string) =>
							onUpdateCO2Data(reset, co2Key, adminSelectedOrganizationId)
						}
					/>
					<div className="custom-calculation-co2--title">
						{getString('HOLDING')}
					</div>
					<div className="carbon-credit-section">
						<CustomizeCO2Holding />
					</div>
				</div>
			</div>
		)
	}

	const renderContent = () => {
		return (
			<>
				<CastorFormHeader
					explanationHeader={getString('CUSTOMIZE_CO2_CALCULATIONS_TITLE')}
					explanationArray={getString('CUSTOMIZE_CO2_CALCULATIONS_ARRAY')}
					isInCard={true}
				/>
				{renderSelectors()}
			</>
		)
	}
	const renderFormHeader = () => {
		return (
			<div className="customize-settings--header">
				<CastorFormHeader
					goBack={() => history.push(USER_HOME_ROUTE + CUSTOMIZE_ROUTE)}
					explanationHeader={getString('CUSTOMIZE_EXPLINATION_1_HEADER')}
					explanationArray={getString('CUSTOMIZE_EXPLANATION_ARRAY')}
				/>
				<OrganizationPanel />
			</div>
		)
	}
	return (
		<>
			<NavBarAndMaterial title={getString('CUSTOMIZE_CO2_CALCULATIONS_TITLE')}>
				<CastorForm
					formHeader={renderFormHeader()}
					formTitle={getString('CUSTOMIZE_CO2_CALCULATIONS_TITLE')}
					content={renderContent()}
				/>
			</NavBarAndMaterial>
		</>
	)
}

const mapStateToProps = (state: IReduxStore) => {
	const {
		CustomizeCO2Reducer,
		CustomizeInHousePrintersReducer,
		CustomizeCO2CalculationReducer,
		user: {
			materialTypes,
			materialCategories,
			materials,
			printersFullData,
			printerMaterialUniqueNames,
			co2PerKW,
			puAnnualKgCO2,
			puYearsCO2,
			holdFactor
		},
		CustomizeReducer: { adminSelectedOrganizationId }
	} = state
	return {
		co2EmissionProps: CustomizeCO2Reducer,
		...CustomizeCO2CalculationReducer,
		...CustomizeInHousePrintersReducer,
		materialCategories,
		materialTypes,
		materials,
		printersFullData,
		printerMaterialUniqueNames,
		co2PerKW,
		puAnnualKgCO2,
		puYearsCO2,
		holdFactor,
		adminSelectedOrganizationId
	}
}

const mapDispatchToProps = (dispatch: DispatchProp<AnyAction>) =>
	bindActionCreators(
		{
			...CustomizeCO2CalculationAction,
			...CustomizeCO2Actions
		},
		dispatch
	)

export default memo(
	connect(mapStateToProps, mapDispatchToProps)(CustomizeCO2EmissionsSelector)
)
