import { Dispatch } from 'react'
import { AnyAction } from 'redux'

import { SolutionAnalysisService } from '../SolutionAnalysisService'
import { createCustomOrientation } from './SolutionCustomOrientationService'
import { CUSTOM_ORIENTATION_COMPLETED } from './SolutionCustomOrientationTypes'
import { HANDLE_NOTIFICATION } from 'global actions/types'
import {
	SOLUTION_CONFIGURE_CHANGE_ORIENTATION_FEATURE_CLICKED,
	SOLUTION_CONFIGURE_ORIENTATION_PRINTING_CHANGE
} from 'global actions/types/partAnalysisTypes'
import { IConfiguration } from 'Services/models/IConfiguration'
import { OrientationData } from 'Services/models/IOrinetationData'
import { PartPrintIssue } from 'Services/models/PartPrintIssue'
import { checkCustomOrientationCompleted } from 'Services/Network'
import Poller from 'Services/PollingService/Poller'
import { POLLER_REACHED_TIMEOUT, SHOW_NOTIFICATION } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

let poller = new Poller()

export const addCustomOrientation = (
	configurationId: number,
	partId: number,
	trayNormalVector: number[],
	solution: any,
	batchSize: number,
	optionalPostProcesses: any,
	onConfirm: Function,
	configuration: IConfiguration,
	configurationPrintIssues: PartPrintIssue[],
	rotationAngle: Record<string, number>,
	extraAction?: () => void
) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		try {
			const response = await createCustomOrientation(
				configurationId,
				partId,
				trayNormalVector,
				rotationAngle
			)
			if (response) {
				if (extraAction) {
					extraAction()
				}
				dispatch({
					type: HANDLE_NOTIFICATION,
					payload: {
						notificationType: SHOW_NOTIFICATION.SUCCESS,
						notificationMessage: getString('DATA_WAS_UPDATED')
					}
				})

				let timeOutDateTime = new Date()
				timeOutDateTime.setHours(timeOutDateTime.getHours() + 1)
				poller = new Poller(
					1000,
					timeOutDateTime,
					() =>
						checkCustomOrientationCompleted(
							partId,
							configurationId,
							solution?.id,
							batchSize,
							optionalPostProcesses
						),
					(res: any) => res.data.isCustomOrientationCompleted
				)

				await poller
					.start()
					.then(async res => {
						const {
							orientationData,
							chosenOrientationVector,
							partPrintIssues
						} = res?.data
						let defaultOrientation = '0'
						if (orientationData) {
							const solutionAnalysisService = new SolutionAnalysisService(
								configuration,
								null,
								null,
								null
							)
							const configurationPrintIssues: PartPrintIssue[] =
								partPrintIssues.filter(
									(partPrintIssue: PartPrintIssue) =>
										partPrintIssue.configurationId === configuration.id ||
										!partPrintIssue.configurationId
								)

							solutionAnalysisService.createOrientationsAnalysisObjectsForTable(
								orientationData,
								configuration,
								solution,
								configurationPrintIssues
							)

							defaultOrientation =
								solutionAnalysisService.getChosenOrientationVector(
									orientationData,
									chosenOrientationVector
								)
						}

						dispatch({
							type: SOLUTION_CONFIGURE_CHANGE_ORIENTATION_FEATURE_CLICKED,
							payload: {
								id: configurationId,
								orientationData,
								defaultOrientation,
								partPrintIssues
							}
						})

						const newSupportVolume = orientationData.find(
							(orientation: OrientationData) =>
								orientation.configurationId == configurationId
						)?.supportVolume
						dispatch({
							type: SOLUTION_CONFIGURE_ORIENTATION_PRINTING_CHANGE,
							payload: {
								id: configurationId,
								chosenOrientationVector,
								newSupportVolume
							}
						})

						dispatch({
							type: CUSTOM_ORIENTATION_COMPLETED
						})
						onConfirm()
					})
					.catch(error => {
						onConfirm()
						console.error(error)
						if (error === POLLER_REACHED_TIMEOUT) {
							window.location.reload()
							return
						}
					})
			}
		} catch (error) {
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.WARN,
					notificationMessage: getString('DATA_WAS_NOT_UPDATED')
				}
			})
		}
	}
}

export const onCancelCustomOrientationAlert = () => {
	return async (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: CUSTOM_ORIENTATION_COMPLETED
		})
	}
}
