import parseHTML from 'html-react-parser'
import React, {
	FC,
	memo,
	useCallback,
	useEffect,
	useRef,
	useState
} from 'react'
import Dropzone from 'react-dropzone'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import cx from 'classnames'
import { cloneDeep, toNumber, uniqBy } from 'lodash'

import LightUserUploadProjectAlert from '../../LightUserUploadProjectAlert/LightUserUploadProjectAlert'
import {
	addedFiles,
	additionalNotesChanged,
	BOMFileSelected,
	draggingToDropzoneChangedUpload,
	removeFile,
	uploadProjectClicked
} from '../../UploadProjectActions'
import { getAcceptedFileFormats } from '../../UploadProjectActionService'
import OrganizationSelector from '../UploadDetails/UploadOrganizationSelector'
import AddBOMFileAlert from './AddBOMFileAlert'
import { materialColumnNames } from './constants'
import FileRow from './FileRow'
import {
	useComsToolUploadErrorMessage,
	useFilesValidation,
	useGridColumns
} from './hooks'
import { setupAdvancedFilters } from 'Scenes/Components/AdvancedSettings/AdvancedSettingsActions'
import { AdvancedSettingsInitialState } from 'Scenes/Components/AdvancedSettings/AdvancedSettingsReducer'
import ButtonWithLoader from 'Scenes/Components/ButtonWithLoader'
import CardHeaderBox from 'Scenes/Components/CardHeaderBox'
import CastorSelectBox from 'Scenes/Components/CastorSelectBox'
import WarnMessage from 'Scenes/Components/CastorWarn'
import FieldWithLabel from 'Scenes/Components/FieldWithLabel'
import {
	Button,
	Muted
} from 'Scenes/Components/thirdParty/CreativeTim/components'
import { ToleranceClass } from 'Scenes/Components/toleranceClassMenu/toleranceClassMenu'
import { KEY_3d } from 'Scenes/Home/NewUploadProject/constants'
import { UPLOAD } from 'Scenes/Home/NewUploadProject/constants'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { IUploadProjectComponent } from 'Services/models/IUploadProjectComponent'
import { getString } from 'Services/Strings/StringService'

import { ReactComponent as CircleClose } from 'assets/img/svg/icons/circle_close.svg'
import { ReactComponent as DownloadBom } from 'assets/img/svg/icons/download_bom.svg'
import { ReactComponent as FileExcel } from 'assets/img/svg/icons/file_excel.svg'

import './index.scss'

const QuickUploadFiles: FC = () => {
	const {
		loading,
		filesToUpload,
		draggingFiles,
		ERP,
		CAD,
		unitType,
		toleranceIncluded,
		customToleranceValue,
		application,
		showUnitTypeSelector,
		selectedProjectScenario,
		BOMFile,
		amValue,
		trialUserPopupErrorMessage,
		filesWithMaterialAndQuantity,
		additionalNotes,
		error,
		isStandardCostBoxChecked,
		userRemainingPart,
		projectOrganizationId
	} = useSelector((state: RootStateOrAny) => state.uploadProject)
	const user = useSelector((state: RootStateOrAny) => state.user)
	const [showTrialUserPopup, setShowTrialUserPopup] = useState(false)
	const [showBomFilePopup, setShowBomFilePopup] = useState(false)
	const [quickUploadColumns, setQuickUploadColumns] = useState<
		IUploadProjectComponent[]
	>([])

	const { filters, geometryAnalysisPart } = useSelector(
		(state: RootStateOrAny) =>
			state.AdvancedSettingsReducer?.advancedStates[UPLOAD] ||
			new AdvancedSettingsInitialState()
	)
	const uploadRef = useRef()
	const dispatch = useDispatch()
	const history = useHistory()
	const { errorFiles, validateFiles, revalidateFiles } = useFilesValidation(
		filesWithMaterialAndQuantity,
		quickUploadColumns
	)
	const noRemainingParts = user.inLicense ? userRemainingPart === 0 : false
	const errorMessage = useComsToolUploadErrorMessage(noRemainingParts)
	const gridTemplateColumns = useGridColumns(quickUploadColumns.length)

	const lightUserMultipleUploads = Feature.isFeatureOn(
		FeatureComponentId.LIGHT_USER_MULTIPLE_UPLOADS
	)
	const customizeOrganizations = Feature.isFeatureOn(
		FeatureComponentId.CUSTOMIZE_ORGANIZATIONS
	)
	const communicationToolUser =
		lightUserMultipleUploads && user?.userDetails.name
	const disableDropFiles =
		filesToUpload.length >= toNumber(user.maxAllowedLightUploadParts)
	const noAddedFiles = filesToUpload.length === 0
	const disableUpload = !filesWithMaterialAndQuantity.length
	const footerWithoutSite =
		user.availableOrganizations.length === 0 || !customizeOrganizations

	const handleDrop = (
		files: File[],
		selectedType: string,
		rejectedFiles: File[]
	) => {
		if (
			disableDropFiles ||
			files.length > toNumber(user.maxAllowedLightUploadParts)
		) {
			return
		}

		dispatch(
			addedFiles(
				files,
				user?.acceptedFileTypes,
				user?.notSupportedUnitTypeFormats,
				selectedType,
				user?.partsFileSizeLimit,
				rejectedFiles
			)
		)
	}
	const dragEvent = useCallback(
		(show: boolean) => {
			dispatch(draggingToDropzoneChangedUpload(show))
		},
		[dispatch]
	)
	const fileChipDeleteClicked = useCallback(
		(file: File) => {
			dispatch(removeFile(file, user?.notSupportedUnitTypeFormats))
		},
		[dispatch, user?.notSupportedUnitTypeFormats]
	)

	const submitFormClicked = useCallback(
		(
			email: string = '',
			name: string = '',
			company: string = '',
			zipCode: string = ''
		) => {
			const projectCost = null
			const projectManufacturing = undefined
			const uniqMaterial: any = uniqBy(filesWithMaterialAndQuantity, 'id')
			const materialChosenSelected =
				uniqMaterial.length === 1 ? uniqMaterial[0] : null
			const quantity = materialChosenSelected?.quantity || null
			const drawingMaterial = false

			dispatch(
				uploadProjectClicked(
					filesToUpload[0]?.name,
					quantity,
					ERP,
					CAD,
					filesToUpload,
					unitType,
					BOMFile,
					toleranceIncluded,
					toleranceIncluded
						? customToleranceValue
						: ToleranceClass.TOLERANCE_CLASS_IRRELEVANT,
					user,
					application,
					filters,
					geometryAnalysisPart,
					showUnitTypeSelector,
					drawingMaterial,
					KEY_3d,
					selectedProjectScenario,
					history,
					false,
					{ email, name, company, communicationTool: true, zipCode },
					projectOrganizationId || user.organizationDetails.id,
					materialChosenSelected,
					undefined,
					projectCost,
					projectManufacturing,
					amValue,
					filesWithMaterialAndQuantity,
					additionalNotes,
					communicationToolUser,
					isStandardCostBoxChecked
				)
			)
		},
		[
			BOMFile,
			CAD,
			ERP,
			additionalNotes,
			amValue,
			application,
			customToleranceValue,
			dispatch,
			filesToUpload,
			filesWithMaterialAndQuantity,
			filters,
			history,
			isStandardCostBoxChecked,
			communicationToolUser,
			selectedProjectScenario,
			showUnitTypeSelector,
			toleranceIncluded,
			unitType,
			user
		]
	)

	const handleStartUploading = useCallback(() => {
		const successValidation = validateFiles()
		if (!successValidation || errorMessage) return
		if (communicationToolUser) {
			submitFormClicked(user?.userDetails.email, user?.userDetails.name)
		} else {
			setShowTrialUserPopup(true)
		}
	}, [
		communicationToolUser,
		errorMessage,
		submitFormClicked,
		user?.userDetails.email,
		user?.userDetails.name,
		validateFiles
	])

	const handleConfirmBOMFileAlert = () => {
		setShowBomFilePopup(false)
	}

	const handleCancelBOMFileAlert = () => {
		dispatch(BOMFileSelected(null))
		setShowBomFilePopup(false)
	}

	useEffect(() => {
		dispatch(setupAdvancedFilters(UPLOAD))
	}, [dispatch])

	useEffect(() => {
		let newQuickUploadColumns: IUploadProjectComponent[] = cloneDeep(
			user.quickUploadColumns
		)

		if (BOMFile && !showBomFilePopup) {
			newQuickUploadColumns = newQuickUploadColumns.map(column => {
				if (materialColumnNames.includes(column.name) && column.show) {
					column.optional = true
				}
				return column
			})
		}

		setQuickUploadColumns(newQuickUploadColumns)
	}, [BOMFile, showBomFilePopup, user.quickUploadColumns])

	return (
		<div className="new-upload-project__block quick-upload">
			<CardHeaderBox
				cardClass="new-upload-project__quick-upload--card"
				contentClass="new-upload-project__card new-upload-project__quick-upload--card--content"
				title={getString('QUICK_UPLOAD_FILES')}
				content={
					<div
						className={cx(
							'new-upload-project__quick-upload--card--content--wrapper',
							{ 'without-header': noAddedFiles }
						)}
					>
						<div
							className={cx(
								'new-upload-project__quick-upload--card--content--header',
								{ hidden: noAddedFiles }
							)}
							style={{ gridTemplateColumns }}
						>
							{quickUploadColumns?.map((column, index) => (
								<div
									key={column.headerStringKey + index}
									className="new-upload-project__quick-upload--card--content--header--cell"
								>
									{parseHTML(getString(column.headerStringKey))}
									{column.optional && <Muted>({getString('OPTIONAL')})</Muted>}
								</div>
							))}
						</div>
						<div className="new-upload-project__quick-upload--card--content--body">
							{filesToUpload?.map((file: File, index: number) => {
								return (
									<FileRow
										key={file.name + index}
										uploadedFile={file}
										fileChipDeleteClicked={fileChipDeleteClicked}
										errorFiles={errorFiles}
										onBlur={revalidateFiles}
										gridTemplateColumns={gridTemplateColumns}
										columns={quickUploadColumns}
									/>
								)
							})}
							<Dropzone
								//accept={acceptedFileTypes}
								ref={uploadRef}
								id="upload-project-dropzone"
								className={cx('upload-project-dropzone', {
									'drag-on': draggingFiles,
									empty: noAddedFiles
								})}
								onDrop={(files: File[], rejectedFiles: File[]) =>
									handleDrop(files, KEY_3d, rejectedFiles)
								}
								multiple
								onDragEnter={() => dragEvent(true)}
								onDragLeave={() => dragEvent(false)}
								data-qa="data-qa-dropzone-upload-part"
							>
								<DownloadBom className="upload-project-dropzone__icon" />
								<p className="upload-project-dropzone--title">
									{getString('NEW_UPLOAD_DROP_HERE')}
								</p>
								<p className="upload-project-dropzone--explanation">
									{getString('NEW_UPLOAD_ENTER_CAD')}
								</p>
							</Dropzone>
						</div>
						<div
							className={cx(
								'new-upload-project__quick-upload--card--content--footer',
								{
									'without-site': footerWithoutSite
								}
							)}
						>
							{!showBomFilePopup && BOMFile ? (
								<div>
									{getString('ATTACHED_BOM')}
									<CastorSelectBox
										key={BOMFile?.name}
										selected={true}
										withIcon={false}
										boxClassName="box-around"
									>
										<div>
											<FileExcel className="icon-file" />
											<div className="text">{BOMFile?.name}</div>
											<CircleClose
												onClick={() => dispatch(BOMFileSelected(null))}
												className="icon-close"
											/>
										</div>
									</CastorSelectBox>
								</div>
							) : (
								<Button
									color="secondary"
									className="new-upload-project__quick-upload--add-bom-file"
									onClick={() => setShowBomFilePopup(true)}
								>
									{getString('ADD_BOM_FILE')}
								</Button>
							)}
							{footerWithoutSite ? null : <OrganizationSelector comsTool />}
							<FieldWithLabel
								fieldId="new-upload-project__quick-upload--notes"
								labelName={getString('ADDITIONAL_NOTES')}
								fieldPlaceholder={getString('NEW_UPLOAD_ENTER_TEXT')}
								fieldValue={additionalNotes}
								fieldOnChange={value => dispatch(additionalNotesChanged(value))}
								fieldClassName="new-upload-project__quick-upload--notes--field"
								wrapperClassName="new-upload-project__quick-upload--notes"
								autoFocus={false}
							/>
							<WarnMessage
								warnClassName="new-upload-project__quick-upload--warn-message"
								show={Boolean(error || errorMessage)}
								message={error || errorMessage}
							/>
							<ButtonWithLoader
								disabled={disableUpload}
								id="new-upload-project__quick-upload--start-upload"
								loading={loading}
								primary={true}
								onClick={handleStartUploading}
								className="new-upload-project__quick-upload--start-upload"
							>
								{getString('UPLOAD_FILES_BUTTON')}&nbsp;
								{filesToUpload?.length > 1 && (
									<span>
										({filesToUpload?.length} {getString('NEW_UPLOAD_FILES')})
									</span>
								)}
							</ButtonWithLoader>
						</div>
					</div>
				}
			/>
			<div className="new-upload-project__quick-upload--footer">
				{getString('QUICK_UPLOAD_FILES_FOOTER_EXPLANATION')}
			</div>
			<LightUserUploadProjectAlert
				show={showTrialUserPopup}
				onConfirm={(
					email: string,
					name: string,
					company: string | undefined,
					zipCode: string
				) => {
					submitFormClicked(email, name, company, zipCode)
				}}
				onCancel={() => {
					setShowTrialUserPopup(false)
				}}
				errorMessage={trialUserPopupErrorMessage}
				hideCompany={customizeOrganizations}
			/>
			<AddBOMFileAlert
				show={showBomFilePopup}
				onCancel={handleCancelBOMFileAlert}
				onConfirm={handleConfirmBOMFileAlert}
				isSubmitDisabled={!BOMFile}
			/>
		</div>
	)
}

export default memo(QuickUploadFiles)
