import React, { FC, useCallback } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { DimensionType } from './GenerateMoreResultsTypes'
import { emptyOption } from './RequestMultiplePartsIntoOneCardReducer'
import { changeClusterTraySize } from 'global actions'
import {
	CustomInput,
	Muted
} from 'Scenes/Components/thirdParty/CreativeTim/components'
import {
	isCommaKeyPressed,
	isDisabledKeyPressed
} from 'Services/getKeyCodesService'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { getString } from 'Services/Strings/StringService'
import { LengthUnit, UnitSystem } from 'Services/UnitsConversionService'

type IProps = {
	selectSpecificTraySizeOption: () => void
}

const TraySizeInputs: FC<IProps> = ({ selectSpecificTraySizeOption }) => {
	const { trayWidth, trayHeight, trayDepth, selectedPrinterName } = useSelector(
		(state: RootStateOrAny) => state.RequestMultiplePartsIntoOneCardReducer
	)
	const { userUnitSystem } = useSelector((state: RootStateOrAny) => state.user)
	const customizeUnitSystem = Feature.isFeatureOn(
		FeatureComponentId.CUSTOMIZE_UNIT_SYSTEM
	)
	const unitSystem = customizeUnitSystem ? userUnitSystem : UnitSystem.metric
	const lengthUnit =
		unitSystem === UnitSystem.imperial ? LengthUnit.inch : LengthUnit.mm

	const dispatch = useDispatch()

	const updateDimension = useCallback(
		(dimension: DimensionType, value: string) => {
			dispatch(changeClusterTraySize(dimension, value))
			selectSpecificTraySizeOption()
		},
		[dispatch, selectSpecificTraySizeOption]
	)

	const onKeyDownPress = (e: KeyboardEvent) => {
		isCommaKeyPressed(e) && e.preventDefault()
		isDisabledKeyPressed(e) && e.preventDefault()
	}

	const onBlurWheel = (e: React.ChangeEvent<HTMLInputElement>) => {
		e.target.blur()
	}

	const trayDimensionData = [
		{
			type: DimensionType.trayWidth,
			value: trayWidth && trayWidth >= 0 ? trayWidth : '',
			label: `${getString('WIDTH')} [${lengthUnit}]`
		},
		{
			type: DimensionType.trayDepth,
			value: trayDepth && trayDepth >= 0 ? trayDepth : '',
			label: `${getString('DEPTH')} [${lengthUnit}]`
		},
		{
			type: DimensionType.trayHeight,
			value: trayHeight && trayHeight >= 0 ? trayHeight : '',
			label: `${getString('HEIGHT')} [${lengthUnit}]`
		}
	]

	return (
		<div className="generate-more-pc-results--tray-size-inputs">
			{trayDimensionData.map(data => {
				return (
					<CustomInput
						key={data.type}
						labelText={data.label}
						formControlProps={{
							fullWidth: true
						}}
						inputProps={{
							onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
								updateDimension(data.type, event.target.value),
							onBlur: onBlurWheel,
							onKeyDown: onKeyDownPress,
							type: 'number',
							min: 1,
							step: 1,
							value: data.value
						}}
					/>
				)
			})}
			{selectedPrinterName !== emptyOption && (
				<Muted className="generate-more-pc-results--printer-name">
					{getString('SELECTED_PRINTER_TRAY_SIZE').format(selectedPrinterName)}
				</Muted>
			)}
		</div>
	)
}

export default TraySizeInputs
