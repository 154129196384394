import { ChangeEvent, FC, memo, useEffect, useState } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { Checkbox } from '@material-ui/core'

import { addCustomOrientation } from './SolutionCustomOrientationActions'
import {
	ADD_CUSTOM_ORIENTATION,
	SHOW_HIDE_CUSTOM_ORIENTATION_ALERT
} from './SolutionCustomOrientationTypes'
import CastorAlert from 'Scenes/Components/alerts/CastorAlert'
import ErrorBoundary from 'Scenes/Components/ErrorBoundary/ErrorBoundary'
import FlexBox from 'Scenes/Components/FlexBox'
import InfoBox from 'Scenes/Components/InfoBox'
import TextField from 'Scenes/Components/TextField/TextField'
import ViewerWrapper from 'Scenes/Components/ViewerWrapper/ViewerWrapper'
import WithFeatureToggleHOC from 'Services/HOC/WithFeatureToggleHOC'
import { FeatureComponentId } from 'Services/models/Features'
import { IConfiguration } from 'Services/models/IConfiguration'
import { OrientationData } from 'Services/models/IOrinetationData'
import { Part } from 'Services/models/IPart'
import { PartPrintIssue } from 'Services/models/PartPrintIssue'
import { getString } from 'Services/Strings/StringService'
import { eulerToNormalVector } from 'Services/Utils/numberService'

import './SolutionCustomOrientation.scss'

const DEFAULT_ANGLE = 90
const MAX_ANGLE = 360
const MIN_ANGLE = 0
const INITIAL_VALUE = '0'
enum AXIS {
	x = 'x',
	y = 'y',
	z = 'z'
}

interface IProps {
	solution: any
	part: Part
	configuration: IConfiguration
	showAlert: boolean
	customOrientationCreated: boolean
	onViewerModelError: Function
	onCancel: Function
	onConfirm: Function
	tempSolutionPostProcessToggles: any
	configurationPrintIssues: PartPrintIssue[]
	customOrientationData: OrientationData
}
const SolutionCustomOrientation: FC<IProps> = ({
	solution,
	part,
	configuration,
	showAlert,
	customOrientationCreated,
	onViewerModelError,
	onCancel,
	onConfirm,
	tempSolutionPostProcessToggles,
	configurationPrintIssues,
	customOrientationData
}) => {
	const [xValue, setXValue] = useState(DEFAULT_ANGLE)
	const [yValue, setYValue] = useState(DEFAULT_ANGLE)
	const [zValue, setZValue] = useState(DEFAULT_ANGLE)

	const [showCylinder, setShowCylinder] = useState(false)
	const [showXYZ, setShowXYZ] = useState(false)
	const [manualChange, setManualChange] = useState(true)
	const [disabled, setDisabled] = useState(false)
	const { loading, showCustomOrientationAlert } = useSelector(
		(state: RootStateOrAny) => state.SolutionCustomOrientationReducer
	)
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch({
			type: SHOW_HIDE_CUSTOM_ORIENTATION_ALERT,
			payload: { showAlert }
		})
	}, [showAlert])

	useEffect(() => {
		if (customOrientationData) {
			const {
				rotationalAngleX = 0,
				rotationalAngleY = 0,
				rotationalAngleZ = 0
			} = customOrientationData
			setXValue(rotationalAngleX)
			setYValue(rotationalAngleY)
			setZValue(rotationalAngleZ)
		}
	}, [customOrientationData])

	const setXYZValue = (xValue: number, yValue: number, zValue: number) => {
		const x = parseFloat(xValue.toFixed(2))
		const y = parseFloat(yValue.toFixed(2))
		const z = parseFloat(zValue.toFixed(2))

		setManualChange(false)
		setXValue(x)
		setYValue(y)
		setZValue(z)
	}

	const resetValues = () => {
		setXValue(DEFAULT_ANGLE)
		setYValue(DEFAULT_ANGLE)
		setZValue(DEFAULT_ANGLE)
		onCancel()
	}
	const handleAlertConfirm = () => {
		// according to the euler angles, calculate the normal vector of the tray
		const trayNormalVector = eulerToNormalVector(zValue, yValue, xValue)
		const rotationAngle = {
			x: xValue,
			y: yValue,
			z: zValue
		}
		dispatch({ type: ADD_CUSTOM_ORIENTATION, payload: { loading: true } })

		dispatch(
			addCustomOrientation(
				configuration.id,
				part.id,
				trayNormalVector,
				solution,
				configuration.quantity,
				tempSolutionPostProcessToggles,
				onConfirm,
				configuration,
				configurationPrintIssues,
				rotationAngle,
				resetValues
			)
		)
	}

	const updateXYZAccordingInput = (stringValue: string, axis: string) => {
		const value = +stringValue

		setManualChange(true)
		switch (axis) {
			case AXIS.x:
				setXValue(value)
				break
			case AXIS.y:
				setYValue(value)
				break
			case AXIS.z:
				setZValue(value)
				break
		}

		if (value < MIN_ANGLE || value > MAX_ANGLE) {
			setDisabled(true)
		} else {
			setDisabled(false)
		}
	}

	return (
		<CastorAlert
			alertClass="solution-custom-orientation"
			headerTitle={
				customOrientationCreated
					? getString('CHANGE_CUSTOM_ORIENTATION')
					: getString('ADD_CUSTOM_ORIENTATION')
			}
			onCancel={() => resetValues()}
			show={showCustomOrientationAlert}
			onConfirm={handleAlertConfirm}
			confirmOptionalText={getString('CONFIRM')}
			loadingCalculation={loading}
			disabled={disabled}
		>
			<FlexBox className="solution-custom-orientation-content">
				<p className="solution-custom-orientation-content-explanation">
					{getString('CUSTOM_ORIENTATION_EXPLANATION')}
				</p>
				<FlexBox className="solution-custom-orientation-content-3dview">
					<div className="solution-custom-orientation-content-3dview-viewer">
						<ErrorBoundary errorClass="modal" extra="X3d">
							<div style={{ pointerEvents: loading ? 'none' : 'auto' }}>
								<ViewerWrapper
									setXYZValue={setXYZValue}
									xAngle={xValue}
									yAngle={yValue}
									zAngle={zValue}
									setManualChange={setManualChange}
									manualChange={manualChange}
									clipPlane={false}
									showOrientation={true}
									src={configuration?.wallThickessModelURL || ''}
									solution={solution}
									onViewerModelError={onViewerModelError}
									part={part}
									configurationPrintIssues={[]}
									isSurfaceMachiningOn={true}
									showTray={true}
									showCylinder={showCylinder}
									showXYZ={showXYZ}
									customConfig={{ errors: [], id: undefined }}
								/>
							</div>
						</ErrorBoundary>
					</div>
					<FlexBox className="solution-custom-orientation-content-3dview-coordinates">
						<div>
							<FlexBox alignItems="center" className="show-cylinder">
								<Checkbox
									disabled={loading}
									color="primary"
									checked={showXYZ}
									onChange={(e: ChangeEvent<HTMLInputElement>) =>
										setShowXYZ(e.target.checked)
									}
								/>
								<FlexBox alignItems="center" className="show-cylinder_info">
									{getString('SHOW_XYZ_AXIS')}
								</FlexBox>
							</FlexBox>
							<FlexBox alignItems="center" className="show-cylinder">
								<Checkbox
									disabled={loading}
									color="primary"
									checked={showCylinder}
									onChange={(e: ChangeEvent<HTMLInputElement>) =>
										setShowCylinder(e.target.checked)
									}
								/>
								<FlexBox alignItems="center" className="show-cylinder_info">
									{getString('ORIENTATION_MANAGER_CYLINDER')}
									<InfoBox
										targetClassName="show-cylinder-icon"
										boxDirection="auto"
										boxContact={getString('ORIENTATION_MANAGER_CYLINDER_INFO')}
										iconClassName="info-box__info_icon"
										boxClassName="info-box"
										inPortal={true}
									/>
								</FlexBox>
							</FlexBox>
						</div>
						<TextField
							maxNumberAfterDot={true}
							disabled={loading}
							initialValue={INITIAL_VALUE}
							disableMinus={true}
							disableDot={false}
							error={
								xValue < MIN_ANGLE || xValue > MAX_ANGLE
									? getString('ORIENTATION_MANAGER_ERROR')
									: ''
							}
							wrapperClassName="3dview-coordinates--text-field"
							type="number"
							label={getString('ORIENTATION_MANAGER_AXIS').format(AXIS.x)}
							input={{
								onChange: (e: ChangeEvent<HTMLInputElement>) => {
									updateXYZAccordingInput(e.target.value, AXIS.x)
								},
								value: xValue
							}}
						></TextField>
						<TextField
							maxNumberAfterDot={true}
							disabled={loading}
							initialValue={INITIAL_VALUE}
							disableMinus={true}
							disableDot={false}
							error={
								yValue < MIN_ANGLE || yValue > MAX_ANGLE
									? getString('ORIENTATION_MANAGER_ERROR')
									: ''
							}
							wrapperClassName="3dview-coordinates--text-field"
							type="number"
							label={getString('ORIENTATION_MANAGER_AXIS').format(AXIS.y)}
							input={{
								onChange: (e: ChangeEvent<HTMLInputElement>) => {
									updateXYZAccordingInput(e.target.value, AXIS.y)
								},
								value: yValue
							}}
						></TextField>
						<TextField
							maxNumberAfterDot={true}
							disabled={loading}
							initialValue={INITIAL_VALUE}
							disableMinus={true}
							disableDot={false}
							error={
								zValue < MIN_ANGLE || zValue > MAX_ANGLE
									? getString('ORIENTATION_MANAGER_ERROR')
									: ''
							}
							wrapperClassName="3dview-coordinates--text-field"
							type="number"
							label={getString('ORIENTATION_MANAGER_AXIS').format(AXIS.z)}
							input={{
								onChange: (e: ChangeEvent<HTMLInputElement>) => {
									updateXYZAccordingInput(e.target.value, AXIS.z)
								},
								value: zValue
							}}
						></TextField>
					</FlexBox>
				</FlexBox>
			</FlexBox>
		</CastorAlert>
	)
}
export default WithFeatureToggleHOC(memo(SolutionCustomOrientation), [
	FeatureComponentId.CUSTOM_ORIENTATION
])
