import React, { FC, useCallback } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { MenuItem, Select } from '@material-ui/core'

import { emptyOption } from './RequestMultiplePartsIntoOneCardReducer'
import {
	onGenerateMorePrinterApply,
	onGenerateMorePrinterCancel,
	onGenerateMorePrinterChange,
	onGenerateMorePrinterCompanyChange
} from 'global actions'
import TransparentButton from 'Scenes/Components/TransparentButton'
import { Printer } from 'Services/models/IPrinter'
import { getString } from 'Services/Strings/StringService'

type IProps = {
	onCancel: () => void
}
const PrinterTraySelector: FC<IProps> = ({ onCancel }) => {
	const { printersCompanies, selectedPrinterCompany, selectedPrinterName } =
		useSelector(
			(state: RootStateOrAny) => state.RequestMultiplePartsIntoOneCardReducer
		)
	const dispatch = useDispatch()

	const onCancelClick = () => {
		onCancel()
		dispatch(onGenerateMorePrinterCancel())
	}

	const onApplyClick = () => {
		dispatch(onGenerateMorePrinterApply())
		onCancel()
	}

	const renderPrintersList = useCallback(() => {
		return printersCompanies[selectedPrinterCompany] ? (
			printersCompanies[selectedPrinterCompany]
				.map((printer: Printer) => {
					return (
						<MenuItem key={printer.name} value={printer.name}>
							{printer.name}
						</MenuItem>
					)
				})
				.sort(
					(
						firstPrinter: Record<string, any>,
						secondPrinter: Record<string, any>
					) => firstPrinter.key.localeCompare(secondPrinter.key)
				)
		) : (
			<div />
		)
	}, [printersCompanies, selectedPrinterCompany])

	return (
		<div className="generate-more-pc-results--printer-tray-selector">
			<Select
				className="select"
				value={selectedPrinterCompany}
				onChange={e =>
					dispatch(onGenerateMorePrinterCompanyChange(e.target.value))
				}
				MenuProps={{
					style: { zIndex: 1300 },
					variant: 'menu',
					getContentAnchorEl: null,
					PaperProps: {
						style: {
							transform: 'translate3d(0, 0, 0)'
						}
					}
				}}
				data-qa="data-qa-tray-printer-company"
			>
				<MenuItem value={emptyOption} disabled>
					{getString('IN_HOUSE_PRINTERS_COMPANY_SELECTOR_NAME')}
				</MenuItem>
				{Object.keys(printersCompanies).map(company => {
					return (
						<MenuItem key={company} value={company}>
							{company}
						</MenuItem>
					)
				})}
			</Select>
			<Select
				className="select"
				value={selectedPrinterName}
				onChange={e => dispatch(onGenerateMorePrinterChange(e.target.value))}
				MenuProps={{
					style: { zIndex: 1300 },
					variant: 'menu',
					getContentAnchorEl: null,
					PaperProps: {
						style: {
							transform: 'translate3d(0, 0, 0)'
						}
					}
				}}
				data-qa="data-qa-tray-printer-name"
			>
				<MenuItem value={emptyOption} disabled>
					{getString('IN_HOUSE_PRINTERS_NAME_SELECTOR_NAME')}
				</MenuItem>
				{renderPrintersList()}
			</Select>
			<div className="generate-more-pc-results--printer-tray-selector--buttons">
				<TransparentButton
					disabled={false}
					onClick={onCancelClick}
					loading={false}
				>
					{getString('CANCEL')}
				</TransparentButton>
				<TransparentButton
					disabled={selectedPrinterName === emptyOption}
					onClick={onApplyClick}
				>
					{getString('APPLY')}
				</TransparentButton>
			</div>
		</div>
	)
}

export default PrinterTraySelector
