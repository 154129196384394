import {
	CHANGE_CLUSTER_TRAY_SIZE,
	GENERATE_MORE_CHANGE_PRINTER,
	GENERATE_MORE_CHANGE_PRINTER_COMPANY,
	GENERATE_MORE_PRINTER_APPLY,
	GENERATE_MORE_PRINTER_RESET,
	MPIO_REQUEST,
	MPIO_REQUEST_FAIL,
	MPIO_REQUEST_SUCCESS,
	SETUP_GENERATE_MORE_RESULTS_ALERT,
	TOGGLE_GENERATE_MORE_RESULTS_ALERT,
	UPDATE_PROJECT_CLUSTER_STATUS
} from '../../../../global actions/types'
import {
	ALERT_POPPED,
	ALERT_POPUP_CANCELED
} from '../../../../global actions/types/CastorAlertTypes'
import {
	getAllPrintersCompanies,
	requestGenerateMoreClusters,
	requestMultiplePartsIntoOne
} from '../../../../Services/Network'
import {
	COMBINE_AROUND_THIS_PART_SEARCH_EMPTY,
	COMBINE_MULTIPLE_INTO_ONE_REQ_BODY,
	COMBINE_MULTIPLE_INTO_ONE_REQ_TITLE,
	CONFIGURATION_CALCULATION_ERROR,
	CONSOLIDATION_DELETE_RESULTS,
	CONSOLIDATION_KEEP_RESULTS,
	CONSOLIDATION_WILL_BE_OVERWRITTEN,
	NO,
	OK,
	REQUEST_FAILED,
	SEARCH_AGAIN_FOR_RESULT,
	YES
} from '../../../../Services/Strings'
import { AlertType } from '../../../Components/alerts/AlertTypes'
import { GenerateMoreResultOption } from './GenerateMoreResultsTypes'
import { store } from 'index'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { ProjectClusterStatus } from 'Services/models/IProject'
import { getString } from 'Services/Strings/StringService'
import {
	UnitsConversionService,
	UnitSystem
} from 'Services/UnitsConversionService'

let clusterRequestText = (
	<div>
		{CONSOLIDATION_WILL_BE_OVERWRITTEN}
		<br />
		<br />
		<b>{YES}</b> {CONSOLIDATION_DELETE_RESULTS}
		<br />
		<b>{NO}</b> {CONSOLIDATION_KEEP_RESULTS}
	</div>
)

export const sendMultiplePartsIntoOneRequest = (projectId, bundleId) => {
	return async dispatch => {
		try {
			dispatch({ type: MPIO_REQUEST })
			await requestMultiplePartsIntoOne(projectId, bundleId)
			dispatch({ type: MPIO_REQUEST_SUCCESS, payload: { keepClusters: false } })
			dispatch({
				type: ALERT_POPPED,
				payload: {
					text: COMBINE_MULTIPLE_INTO_ONE_REQ_BODY,
					headerTitle: COMBINE_MULTIPLE_INTO_ONE_REQ_TITLE,
					showCancel: false,
					alertType: AlertType.SUCCESS,
					onConfirm: () => {
						dispatch({
							type: ALERT_POPUP_CANCELED
						})
					},
					confirmText: OK
				}
			})
		} catch (error) {
			console.error(error.message)
			dispatch({ type: MPIO_REQUEST_FAIL })
			dispatch({
				type: ALERT_POPPED,
				payload: {
					text: CONFIGURATION_CALCULATION_ERROR,
					headerTitle: REQUEST_FAILED,
					showCancel: false,
					alertType: AlertType.WARNING,
					onConfirm: () => {
						dispatch({
							type: ALERT_POPUP_CANCELED
						})
					},
					confirmText: OK
				}
			})
		}
	}
}

export const requestMultiplePartsIntoOneAdmin = (projectId, bundleId) => {
	return async dispatch => {
		try {
			dispatch({
				type: ALERT_POPPED,
				payload: {
					text: clusterRequestText,
					headerTitle: SEARCH_AGAIN_FOR_RESULT,
					alertType: AlertType.WARNING,
					onConfirm: () => {
						dispatch({
							type: ALERT_POPUP_CANCELED
						})
						dispatch(sendMultiplePartsIntoOneRequest(projectId, bundleId))

						dispatch({
							type: UPDATE_PROJECT_CLUSTER_STATUS,
							payload: {
								projectClusterStatus: ProjectClusterStatus.awaitingAnalysis
							}
						})
					},
					confirmText: YES,
					cancelText: NO
				}
			})
		} catch (error) {
			console.error(error.message)
			dispatch({
				type: ALERT_POPPED,
				payload: {
					text: getString('SOMETHING_WENT_WRONG'),
					headerTitle: REQUEST_FAILED,
					showCancel: false,
					alertType: AlertType.WARNING,
					onConfirm: () => {
						dispatch({
							type: ALERT_POPUP_CANCELED
						})
					},
					confirmText: OK
				}
			})
		}
	}
}

export const confirmConsolidationAnalysis = (
	projectId,
	clustersCount,
	bundleId,
	firstUnificationRequestOnDemand
) => {
	return async dispatch => {
		if (firstUnificationRequestOnDemand) {
			dispatch(sendMultiplePartsIntoOneRequest(projectId, bundleId))
			return
		}

		let text = !clustersCount ? (
			<div>{COMBINE_AROUND_THIS_PART_SEARCH_EMPTY}</div>
		) : (
			clusterRequestText
		)

		dispatch({
			type: ALERT_POPPED,
			payload: {
				text: text,
				headerTitle: SEARCH_AGAIN_FOR_RESULT,
				alertType: AlertType.WARNING,
				onConfirm: () => {
					dispatch({
						type: ALERT_POPUP_CANCELED
					})
					dispatch(sendMultiplePartsIntoOneRequest(projectId, bundleId))
				},
				confirmText: YES,
				cancelText: NO
			}
		})
	}
}

export const toggleGenerateMoreResultsAlert = show => {
	return { type: TOGGLE_GENERATE_MORE_RESULTS_ALERT, payload: { show } }
}

export const sendGenerateMoreClustersRequest = (
	projectId,
	bundleId,
	chosenOption,
	trayWidth,
	trayHeight,
	trayDepth
) => {
	return async dispatch => {
		try {
			dispatch({ type: MPIO_REQUEST })
			if (chosenOption !== GenerateMoreResultOption.setSpecificSize) {
				trayWidth = trayHeight = trayDepth = undefined
			} else {
				const customizeUnitSystem = Feature.isFeatureOn(
					FeatureComponentId.CUSTOMIZE_UNIT_SYSTEM
				)
				const { userUnitSystem } = store.getState().user
				const unitSystem = customizeUnitSystem
					? userUnitSystem
					: UnitSystem.metric

				const serverConversionService = new UnitsConversionService(
					unitSystem,
					UnitSystem.metric
				)
				trayWidth = serverConversionService.convertLength(+trayWidth, false)
				trayHeight = serverConversionService.convertLength(+trayHeight, false)
				trayDepth = serverConversionService.convertLength(+trayDepth, false)
			}
			await requestGenerateMoreClusters(
				projectId,
				bundleId,
				chosenOption,
				trayWidth,
				trayHeight,
				trayDepth
			)
			dispatch({ type: MPIO_REQUEST_SUCCESS, payload: { keepClusters: true } })
			dispatch({
				type: ALERT_POPPED,
				payload: {
					text: COMBINE_MULTIPLE_INTO_ONE_REQ_BODY,
					headerTitle: COMBINE_MULTIPLE_INTO_ONE_REQ_TITLE,
					showCancel: false,
					alertType: AlertType.SUCCESS,
					onConfirm: () => {
						dispatch({
							type: ALERT_POPUP_CANCELED
						})
					},
					confirmText: OK
				}
			})
		} catch (error) {
			console.error(error.message)
			dispatch({ type: MPIO_REQUEST_FAIL })
			dispatch({
				type: ALERT_POPPED,
				payload: {
					text: CONFIGURATION_CALCULATION_ERROR,
					headerTitle: REQUEST_FAILED,
					showCancel: false,
					alertType: AlertType.WARNING,
					onConfirm: () => {
						dispatch({
							type: ALERT_POPUP_CANCELED
						})
					},
					confirmText: OK
				}
			})
		}
	}
}

export const changeClusterTraySize = (dimension, value) => {
	return {
		type: CHANGE_CLUSTER_TRAY_SIZE,
		payload: { [dimension]: value }
	}
}

export const setupGenerateMoreResultsAlert = () => {
	return async dispatch => {
		try {
			const response = await getAllPrintersCompanies(undefined, false)
			const printersCompanies = response.data.printerCompanies
			const printers = Object.values(printersCompanies).flat()

			dispatch({
				type: SETUP_GENERATE_MORE_RESULTS_ALERT,
				payload: { printersCompanies, printers }
			})
		} catch (err) {
			console.error(err.message)
		}
	}
}

export const onGenerateMorePrinterCompanyChange = printerCompany => {
	return dispatch => {
		dispatch({
			type: GENERATE_MORE_CHANGE_PRINTER_COMPANY,
			payload: { printerCompany }
		})
	}
}

export const onGenerateMorePrinterChange = printerName => {
	return dispatch => {
		dispatch({
			type: GENERATE_MORE_CHANGE_PRINTER,
			payload: { printerName }
		})
	}
}

export const onGenerateMorePrinterCancel = () => {
	return dispatch => {
		dispatch({
			type: GENERATE_MORE_PRINTER_RESET
		})
	}
}

export const onGenerateMorePrinterApply = () => {
	return dispatch => {
		dispatch({
			type: GENERATE_MORE_PRINTER_APPLY
		})
	}
}
