import React, { FC, memo, ReactElement, ReactNode } from 'react'

import cx from 'classnames'

import PartImageWithFallback from '../PartImageWithFallback/PartImageWithFallback'
import CardContent from './CardContent'
import GrabCadPrint from 'Scenes/Components/GrabCadPrint'
import { FormatType } from 'Services/models/IPart'
import { doesImageDontHaveCoordinates } from 'Services/Utils/ImagesTools'

import coordinateImage from 'assets/img/axes_watermark.png'

import './Card.scss'

interface Props {
	className?: string
	imageClassName?: string
	contentClassName?: string
	imageUrl?: string
	imageAlt?: string
	title?: string
	contentTitleClassName?: string
	sizeWrapperClassName?: string
	extraTitles?: Array<string>
	contentText?: string
	addSizeWrapper?: boolean
	contentCustomComponent?: ReactNode
	footer?: ReactNode
	customCardContent?: ReactNode
	partFormat?:
		| FormatType.pdf
		| FormatType.threeD
		| FormatType.threeDByPDF
		| FormatType.metadata
	isContentTextInclined?: boolean
	isCard?: boolean
	dataQATitle?: string
	onToggleGrabCad?: Function
	withGrabCad?: boolean
	selectedGrabCad?: boolean
	customContent?: ReactElement<any, any>
	footerClassName?: string
}

const Card: FC<Props> = ({
	className = '',
	imageClassName = '',
	imageUrl = '',
	imageAlt = '',
	title = '',
	extraTitles = [],
	contentText = '',
	contentClassName = '',
	contentTitleClassName = '',
	addSizeWrapper = true,
	sizeWrapperClassName = '',
	contentCustomComponent = <div />,
	footer = <div />,
	footerClassName = '',
	customCardContent,
	partFormat,
	isContentTextInclined = false,
	isCard = true,
	dataQATitle = '',
	withGrabCad,
	onToggleGrabCad,
	selectedGrabCad,
	customContent
}) => {
	const cardContentProps = {
		title,
		extraTitles,
		contentText,
		contentCustomComponent,
		contentClassName,
		titleClassName: contentTitleClassName,
		isContentTextInclined
	}

	return (
		<div className={cx(className, { card: isCard })}>
			{customCardContent || (
				<>
					<div
						data-qa={`data-qa-image-${dataQATitle || title}`}
						className={cx(
							{
								'card--image-wrapper': addSizeWrapper
							},
							sizeWrapperClassName
						)}
					>
						{doesImageDontHaveCoordinates(imageUrl) && (
							<img
								className="card--image-inner-coordinate"
								src={coordinateImage}
								alt="..."
							/>
						)}
						<PartImageWithFallback
							src={imageUrl}
							alt={imageAlt}
							formatType={partFormat}
							className={`card--image-inner ${imageClassName}`}
							fallbackClassName="card--image-wrapper"
						/>
						{withGrabCad && (
							<GrabCadPrint
								viewOnly={true}
								selectedGrabCad={selectedGrabCad}
								onToggleGrabCad={onToggleGrabCad}
							/>
						)}
					</div>

					{customContent ? (
						customContent
					) : (
						<CardContent {...cardContentProps} />
					)}

					<div className={`card--footer ${footerClassName}`}>{footer}</div>
				</>
			)}
		</div>
	)
}

export default memo(Card)
