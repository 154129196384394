import React, { FC, memo, useMemo } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import EmptyTableRow from './EmptyTableRow'
import {
	changeMaterialMappingPage,
	updateMaterialCategoryMapping,
	updateMaterialMapping,
	updateMaterialTypeMapping
} from './ProjectMaterialBomMappingActions'
import { materialCategoriesList } from './ProjectMaterialBomMappingService'
import TableRow from './TableRow'
import CastorPaginationSelector from 'Scenes/Components/CastorPagination/index'
import { getString } from 'Services/Strings/StringService'

const ProjectMaterialBomMappingTable: FC<any> = () => {
	const { paginationData, projectMaterialBomData, projectMaterialBomDataList } =
		useSelector(
			(state: RootStateOrAny) => state.ProjectMaterialBomMappingReducer
		)
	const { materialTypes, materialCategories, materials } = useSelector(
		(state: RootStateOrAny) => state.user
	)
	const dispatch = useDispatch()

	const updateMaterialType = (partBomDataId: number, value: string) => {
		dispatch(updateMaterialTypeMapping(partBomDataId, value))
	}

	const updateMaterialCategory = (partBomDataId: number, value: string) => {
		dispatch(
			updateMaterialCategoryMapping(partBomDataId, value, materialCategories)
		)
	}
	const updateMaterial = (partBomDataId: number, value: string) => {
		dispatch(updateMaterialMapping(partBomDataId, value, materials))
	}

	const { allowedCategories } = useMemo(
		() => materialCategoriesList(materials),
		[materials]
	)

	return (
		<div>
			<div className="project-parts-mapping-table">
				<div className="project-parts-mapping-table-header">
					{getString('MATERIAL_COST_MATERIAL_NAME')}
				</div>
				<div className="project-parts-mapping-table-header">
					{getString('ORIGINAL_MATERIAL_TYPE')}
				</div>
				<div className="project-parts-mapping-table-header">
					{getString('ORIGINAL_MATERIAL_CATEGORY')}
				</div>
				<div className="project-parts-mapping-table-header">
					{getString('ORIGINAL_MATERIALS_BUTTON')}
				</div>
				<div className="project-parts-mapping-table-header">
					{getString('PROJECT_PARTS_MAPPING_STATUS')}
				</div>
				{projectMaterialBomData.length ? (
					projectMaterialBomDataList.map(
						(materialMapping: Record<string, any>) => {
							return (
								<TableRow
									key={materialMapping.id}
									materialMapping={materialMapping}
									materialTypes={materialTypes}
									materialCategories={materialCategories}
									materials={materials}
									updateMaterialType={updateMaterialType}
									updateMaterialCategory={updateMaterialCategory}
									updateMaterial={updateMaterial}
									allowedCategories={allowedCategories}
								/>
							)
						}
					)
				) : (
					<EmptyTableRow />
				)}
			</div>
			{!!projectMaterialBomData.length && (
				<CastorPaginationSelector
					showPagination={paginationData.totalPagesCount > 1}
					pageNumber={paginationData.page}
					isLastPage={!paginationData.enableNext}
					total={paginationData.totalPartsCount}
					limit={paginationData.limit}
					className={{ pagination: 'parts-pagination' }}
					showingFrom={paginationData.showingFrom}
					showingTo={paginationData.showingTo}
					onNextClick={() =>
						dispatch(changeMaterialMappingPage(paginationData.page + 1))
					}
					onPrevClick={() =>
						dispatch(changeMaterialMappingPage(paginationData.page - 1))
					}
					onPageClick={(page: number) =>
						dispatch(changeMaterialMappingPage(page))
					}
				/>
			)}
		</div>
	)
}

export default memo(ProjectMaterialBomMappingTable)
