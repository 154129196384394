import { Component } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import IconButton from '@material-ui/core/IconButton'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import CheckedIcon from '@material-ui/icons/CheckBox'
import UncheckedIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CloudDownload from '@material-ui/icons/CloudDownload'
import CodeIcon from '@material-ui/icons/Code'
import EditIcon from '@material-ui/icons/Edit'
import ViewPageIcon from '@material-ui/icons/Pageview'
import PlayIcon from '@material-ui/icons/PlayArrow'
import cx from 'classnames'

import * as AdminProjectsActions from '../AdminProjects/AdminProjectsActions'
import * as AdminPartsActions from './AdminPartsActions'
import { EDIT_PART_ADMIN_ROUTE } from '../../../../Services/Constants/RoutesConstants'
import {
	Feature,
	FeatureComponentId
} from '../../../../Services/models/Features'
import { partConfigurationsRoute } from '../../../../Services/routeFuncs'
import {
	ADMIN_PARTS_AMOUNT_IN_ASSMBLY,
	ADMIN_PARTS_AREA,
	ADMIN_PARTS_CAD_SCORE,
	ADMIN_PARTS_EXTERNAL_ID,
	ADMIN_PARTS_IS_OFF_THE_SHELF,
	ADMIN_PARTS_ORIGINAL_MATERIAL,
	ADMIN_PARTS_PART_ID,
	ADMIN_PARTS_RESULT,
	ADMIN_PARTS_SIZE,
	ADMIN_PARTS_SIZE_SCORE,
	ADMIN_PARTS_STATUS,
	ADMIN_PARTS_VOLUME,
	ADMIN_PARTS_WALL_THICKNESS_SCORE,
	DOWNLOAD_PART,
	EDIT_PART,
	GO,
	NO,
	OK,
	PART_COMPLEXITY_SCORE,
	PART_IS_COMPLEX,
	PART_IS_NOT_COMPLEX,
	RATE_3D,
	SELECT_ACTION_PARTS_ADMIN_PLACEHOLDER,
	VIEW_PART,
	YES
} from '../../../../Services/Strings'
import { getString } from '../../../../Services/Strings/StringService'
import CastorAlert from '../../../Components/alerts/CastorAlert'
import CastorForm from '../../../Components/CastorForm/CastorForm'
import NavBarAndMaterial from '../../../Components/NavBarAndMaterial'
import { Button } from '../../../Components/thirdParty/CreativeTim/components'
import Table from '../../../Components/thirdParty/CreativeTim/components/Table/Table.jsx'
import Loader from '../../../Loader/Loader'
import { getPartStatusForAdmin } from '../adminActionsService'
import AdminClusters from '../AdminProjects/AdminClusters'
import AdminProjectForm from '../AdminProjects/AdminProjectForm'
import AdminProjectInfo from '../AdminProjects/AdminProjectInfo'
import { actionsENUM } from './actionsEnum'
import { toggleDevMode } from 'global actions'
import CastorSwitch from 'Scenes/Components/CastorSwitch'
import Flexbox from 'Scenes/Components/FlexBox'
import PartImageWithFallback from 'Scenes/Components/PartImageWithFallback/PartImageWithFallback'
import { partToResultLabel } from 'Scenes/Home/ProjectAnalysis/ProjectAnalysisService'
import { PartStatus } from 'Services/models/IPart'
import {
	LengthUnit,
	UnitsConversionService,
	UnitSystem
} from 'Services/UnitsConversionService'

import './AdminParts.scss'

// get a string for  each enum
const actionToString = action => {
	switch (action) {
		case actionsENUM.DELETE_PARTS:
			return getString('DELETE_PARTS')
		case actionsENUM.COMBINE_PARTS:
			return getString('COMBINE_PARTS')
		default:
			return ''
	}
}

class AdminParts extends Component {
	//Lifecycle functions
	componentDidMount() {
		this.props.setupAdminPartsPage(this.props.match.params.projectId)
	}

	componentDidUpdate(prevProps) {
		if (this.props.stlDownloadURL) {
			window.open(this.props.stlDownloadURL)
			this.props.downloadedSTLComplete()
		}
	}

	//Actions
	actionChanged(event) {
		this.props.selectAction(event.target.value)
	}

	downloadSTLClicked(part) {
		this.props.downloadSTL(part)
	}

	checkboxClicked(part) {
		this.props.checkboxSelected(part)
	}

	runAction() {
		this.props.submittedAction(
			this.props.selectedAction,
			this.props.match.params.projectId,
			this.props.selectedPartsIds
		)
	}

	getStatusClassName(status) {
		switch (status) {
			case PartStatus.complete:
			case PartStatus.published: {
				return PartStatus.published
			}
			case PartStatus.failed:
			case PartStatus.rejected: {
				return PartStatus.failed
			}
			default: {
				return PartStatus.awaitingAnalysis
			}
		}
	}

	//Render functions
	renderAlert() {
		if (!this.props.showingSimpleAlertTitle) {
			return <div />
		}

		let body = this.props.showingSimpleAlertText

		return (
			<CastorAlert
				headerTitle={this.props.showingSimpleAlertTitle}
				onConfirm={() =>
					this.props.publishConfirmed(this.props.projectWaitingForPublishing)
				}
				onCancel={() => this.props.cancelAlert()}
				confirmOptionalText={OK}
			>
				{body}
			</CastorAlert>
		)
	}

	tableLine(part, editPart, userConversionService, lengthUnitType, devMode) {
		const isAssembly = part.isTopLevelAssembly
		const partWidth = userConversionService.convertLength(part.width)
		const partHeight = userConversionService.convertLength(part.height)
		const partDepth = userConversionService.convertLength(part.depth)
		const partVolume = userConversionService.convertVolume(part.volume)
		const partArea = userConversionService.convertArea(part.area)
		const partStatus = getPartStatusForAdmin(part)
		const statusClassName = this.getStatusClassName(partStatus)
		const partResult = partToResultLabel(part.result)

		const tableLine = editPart
			? [
					<Checkbox
						tabIndex={-1}
						onClick={this.checkboxClicked.bind(this, part)}
						checked={this.props.selectedPartsIds.includes(part.id)}
						checkedIcon={<CheckedIcon />}
						icon={<UncheckedIcon />}
					/>
			  ]
			: []

		return [
			...tableLine,
			<div style={{ height: '100%', display: 'contents' }}>
				<PartImageWithFallback
					src={
						part.wallThiknessImageURL
							? part.wallThiknessImageURL
							: part.imageURL
					}
					alt={part.partNumber}
					style={styles.thumbnail}
				/>
			</div>,
			<div>
				<h4 className="admin-projects--table--project-name">
					{part.partNumber}
					{isAssembly && (
						<span className="admin-projects--assembly-part">
							{getString('ASSEMBLY')}
						</span>
					)}
				</h4>
				{devMode && (
					<p>
						<span>{ADMIN_PARTS_PART_ID}</span>: {part.id}
					</p>
				)}
				{devMode && (
					<p>
						<span>{ADMIN_PARTS_EXTERNAL_ID}</span>: {part.externalId}
					</p>
				)}
				<p>
					<span>{ADMIN_PARTS_AMOUNT_IN_ASSMBLY}</span>: {part.countInAssembly}
				</p>
				<p>
					<span>{ADMIN_PARTS_IS_OFF_THE_SHELF}</span>:{' '}
					{part.isOffTheShelf ? YES : NO}
				</p>
				<p>
					<span>{ADMIN_PARTS_SIZE}</span>:{' '}
					{`${partWidth} * ${partHeight} * ${partDepth} ${lengthUnitType}`}
				</p>
				<p>
					<span>{ADMIN_PARTS_VOLUME}</span>:{' '}
					{partVolume ? `${partVolume} ${lengthUnitType}³` : '-'}
				</p>
				<p>
					<span>{ADMIN_PARTS_AREA}</span>: {`${partArea} ${lengthUnitType}²`}
				</p>
			</div>,
			<div>
				<p>
					<span>{ADMIN_PARTS_RESULT}</span>: {partResult}
				</p>
				<p>
					<span>{ADMIN_PARTS_STATUS}</span>:{' '}
					<span
						className={cx('admin-projects--project-status', statusClassName)}
					>
						{partStatus}
					</span>
				</p>
				<p>
					<span>{ADMIN_PARTS_ORIGINAL_MATERIAL}</span>:{' '}
					{part.material ? part.material.name : ''}
				</p>
				{devMode && (
					<p>
						<span>{ADMIN_PARTS_WALL_THICKNESS_SCORE}</span>:{' '}
						{part.wallThicknessScore ? part.wallThicknessScore : '-'}
					</p>
				)}
				{devMode && (
					<p>
						<span>{ADMIN_PARTS_SIZE_SCORE}</span>:{' '}
						{part.sizeScore ? part.sizeScore : '-'}
					</p>
				)}
				{devMode && (
					<p>
						<span>{ADMIN_PARTS_CAD_SCORE}</span>:{' '}
						{`${part.CADScore ? part.CADScore : '-'}`}
					</p>
				)}
				{part.displayComplex && (
					<>
						{devMode && (
							<p>
								<span>{PART_COMPLEXITY_SCORE}:</span> {part.partComplexityScore}
							</p>
						)}
						<p>
							<b>{part.isComplex ? PART_IS_COMPLEX : PART_IS_NOT_COMPLEX}</b>
						</p>
					</>
				)}
				{part.rate && (
					<p>
						<span>{RATE_3D}</span> {part.rate}
					</p>
				)}
			</div>,
			this.renderTableLineButtons(part, editPart)
		]
	}

	static wrapButtonWithLinkIfEnabled(button, link) {
		return button.props.disabled ? button : <Link to={link}>{button}</Link>
	}

	renderTableLineButtons(part, editPart) {
		const isAdmin = this.props.user?.userDetails?.admin

		const downloadSTL = (
			<IconButton
				title={DOWNLOAD_PART}
				onClick={this.downloadSTLClicked.bind(this, part)}
			>
				<CloudDownload />
			</IconButton>
		)
		const viewButton =
			part?.isTopLevelAssembly || (part.lock && !isAdmin) ? (
				<></>
			) : (
				AdminParts.wrapButtonWithLinkIfEnabled(
					<IconButton title={VIEW_PART}>
						<ViewPageIcon />
					</IconButton>,
					partConfigurationsRoute(part.projectId, part.id)
				)
			)
		const editButtonLinkTo = {
			pathname: `${EDIT_PART_ADMIN_ROUTE}/${part.id}`,
			part
		}
		const editButton =
			!part?.isTopLevelAssembly && editPart ? (
				AdminParts.wrapButtonWithLinkIfEnabled(
					<IconButton title={EDIT_PART}>
						<EditIcon />
					</IconButton>,
					editButtonLinkTo
				)
			) : (
				<></>
			)

		return (
			<Flexbox flexDirection="column" alignItems="flex-start">
				{editButton}
				{viewButton}
				{downloadSTL}
			</Flexbox>
		)
	}

	renderTable(editPart) {
		const {
			selectedAction,
			match,
			parts: allParts,
			error,
			getMoreProjectParts,
			projectHasMoreParts,
			loading,
			setMaxHeightForPartsTable,
			user,
			toggleDevMode
		} = this.props
		const devMode = user?.devMode || false
		const parts = devMode
			? allParts
			: allParts.filter(part => !part.isTopLevelAssembly)
		const customizeUnitSystem = Feature.isFeatureOn(
			FeatureComponentId.CUSTOMIZE_UNIT_SYSTEM
		)
		const userUnitSystem = customizeUnitSystem
			? user.userUnitSystem
			: UnitSystem.metric
		const userConversionService = new UnitsConversionService(
			UnitSystem.metric,
			userUnitSystem
		)
		const lengthUnitType =
			userUnitSystem === UnitSystem.metric ? LengthUnit.mm : LengthUnit.inch
		const tableHeaders = [
			'',
			getString('PART_INFORMATION'),
			getString('RESULTS'),
			getString('ACTIONS')
		]
		if (editPart) {
			tableHeaders.unshift('')
		}
		const tableContent = parts.map(part =>
			this.tableLine(
				part,
				editPart,
				userConversionService,
				lengthUnitType,
				devMode
			)
		)
		return (
			<div className="parts-table--wrapper">
				<div className="dev-mode-toggle">
					<CastorSwitch
						onChange={e => toggleDevMode(e.target.checked)}
						checked={devMode}
					/>
					<CodeIcon />
					{getString('DEVELOPERS_MODE_LABEL')}
				</div>
				<p style={styles.errorTextStyle}>{error}</p>
				{editPart && (
					<Flexbox flexDirection="row" style={styles.actionSelectFlex}>
						<FormControl style={{ paddingBottom: 13 }}>
							<InputLabel htmlFor="age-simple">
								{SELECT_ACTION_PARTS_ADMIN_PLACEHOLDER}
							</InputLabel>
							<Select
								style={styles.actionSelect}
								classes={{}}
								value={selectedAction}
								floatingLabelText={actionToString(selectedAction)}
								onChange={this.actionChanged.bind(this)}
								inputProps={{
									name: 'simpleSelect',
									id: 'simple-select'
								}}
								MenuProps={{
									PaperProps: {
										style: {
											transform: 'translate3d(0, 0, 0)'
										}
									}
								}}
							>
								<MenuItem disabled>
									{SELECT_ACTION_PARTS_ADMIN_PLACEHOLDER}
								</MenuItem>

								{Object.values(actionsENUM).map(action => {
									return (
										<MenuItem value={action}>{actionToString(action)}</MenuItem>
									)
								})}
							</Select>
						</FormControl>
						<Button
							size="sm"
							style={styles.runActionButton}
							onClick={this.runAction.bind(this)}
							color="primary"
						>
							<PlayIcon /> {GO}
						</Button>
					</Flexbox>
				)}

				<InfiniteScroll
					dataLength={parts.length}
					next={() =>
						getMoreProjectParts(match.params.projectId, allParts.length)
					}
					hasMore={projectHasMoreParts}
					loader={
						<Loader
							load={true}
							size={50}
							showFlex={false}
							wrapperClassName="admin-parts--parts-table--loader"
						/>
					}
					height={setMaxHeightForPartsTable ? 1200 : 'unset'}
				>
					<Table
						loading={loading}
						tableHead={tableHeaders}
						tableData={tableContent}
						className={cx('admin-projects--table parts-table', {
							'with-checkboxes': editPart
						})}
					/>
				</InfiniteScroll>
			</div>
		)
	}

	render() {
		const {
			match,
			projectUpdateLoading,
			loading,
			projectSelected,
			projectHasMoreClusters,
			allProjectParts,
			getMoreProjectClusters,
			onProjectUpdateSubmitClick,
			clusters,
			publishAllClusters,
			publishAllClustersLoading,
			onPublishClusterClick,
			onRejectClusterClick,
			publishClusterLoaders,
			rejectClusterLoaders,
			removeClusterLoaders,
			onRemoveClusterClick,
			onClusterStructureClick,
			clusterStructure,
			projectClusterHeaderText,
			setMaxHeightForClustersTable,
			parts
		} = this.props
		if (loading) {
			return <Loader load={loading} />
		}

		const editPartProject = Feature.isFeatureOn(FeatureComponentId.EDIT_PROJECT)
		const editPart = Feature.isFeatureOn(FeatureComponentId.EDIT_PART)

		return (
			<NavBarAndMaterial title={getString('PROJECTS')}>
				<div>
					{this.renderAlert()}
					<CastorForm
						formTitle={getString('INFO_PROJECT')}
						content={
							<AdminProjectInfo
								project={projectSelected}
								allProjectParts={allProjectParts}
							/>
						}
						style={{ maxWidth: 'unset' }}
					/>
					{editPartProject && (
						<CastorForm
							formTitle={getString('EDIT_PROJECT')}
							content={
								<AdminProjectForm
									initialValues={{
										name: projectSelected?.name,
										ownerId: projectSelected?.owner.id
									}}
									onSubmitClick={data =>
										onProjectUpdateSubmitClick(
											projectSelected?.id,
											data,
											projectSelected?.isBundle
										)
									}
									projectUpdateLoading={projectUpdateLoading}
								/>
							}
							style={{ maxWidth: 'unset' }}
						/>
					)}
					<CastorForm
						formTitle={getString('PARTS')}
						formSubTitle={getString('LIST_OF_PARTS')}
						content={this.renderTable(editPart)}
						style={{ maxWidth: 'unset' }}
					/>
					<AdminClusters
						clusters={clusters}
						isBundle={projectSelected?.isBundle}
						projectStatus={projectSelected?.status}
						projectId={match.params.projectId}
						setMaxHeightForClustersTable={setMaxHeightForClustersTable}
						projectHasMoreClusters={projectHasMoreClusters}
						getMoreProjectClusters={getMoreProjectClusters}
						projectClusterHeaderText={projectClusterHeaderText}
						publishAllClusters={() => publishAllClusters(projectSelected?.id)}
						publishAllClustersLoading={publishAllClustersLoading}
						onPublishClusterClick={onPublishClusterClick}
						onRejectClusterClick={onRejectClusterClick}
						rejectClusterLoaders={rejectClusterLoaders}
						onRemoveClusterClick={onRemoveClusterClick}
						removeClusterLoaders={removeClusterLoaders}
						publishClusterLoaders={publishClusterLoaders}
						onClusterStructureClick={() =>
							onClusterStructureClick(clusterStructure)
						}
					/>
				</div>
			</NavBarAndMaterial>
		)
	}
}

const styles = {
	textField: { width: '70%', minWidth: 350 },
	smallTextField: { width: 150, marginLeft: 6, marginRight: 6 },
	chart: { height: '300px', marginRight: 20, marginTop: 20, flexGrow: 5 },
	horizontalFlex: { flex: 1, alignItems: 'center' },
	xAxisLabel: { textAlign: 'center' },
	divider: { margin: 10 },
	thumbnail: { width: '100%' },
	iconButton: { height: 40, alignSelf: 'center' },
	errorTextStyle: {
		fontSize: 15,
		alignSelf: 'center',
		color: 'red'
	},
	actionSelect: { flex: 3, width: 300 },
	actionSelectFlex: { alignItems: 'center', justifyContent: 'flex-end' },
	runActionButton: { margin: 15 }
}

const mapStateToProps = ({
	user,
	AdminPartsReducer,
	AdminProjectsReducer: {
		projectSelected,
		projectUpdateLoading,
		projectInfoData
	}
}) => {
	return {
		...AdminPartsReducer,
		projectSelected,
		projectUpdateLoading,
		user,
		projectInfoData
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{ ...AdminPartsActions, ...AdminProjectsActions, toggleDevMode },
		dispatch
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminParts)
