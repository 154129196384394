import { FC, memo, useEffect } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { match, useHistory } from 'react-router-dom'

import WithFeatureToggleHOC from '../../../Services/HOC/WithFeatureToggleHOC'
import { FeatureComponentId } from '../../../Services/models/Features'
import { getString } from '../../../Services/Strings/StringService'
import CastorForm from '../../Components/CastorForm/CastorForm'
import CastorFormHeader from '../../Components/CastorForm/CastorFormHeader'
import FlexBox from '../../Components/FlexBox'
import NavBarAndMaterial from '../../Components/NavBarAndMaterial'
import {
	clearLoading,
	getProjectPartsMapping,
	resetAllMaterialSelections,
	startAnalyzing,
	usingDefaultForAllMaterialSelections
} from './ProjectMaterialBomMappingActions'
import ProjectMaterialBomMappingTable from './ProjectMaterialBomMappingTable'
import { clearRoutingPoller } from 'global actions'
import ButtonWithLoader from 'Scenes/Components/ButtonWithLoader'
import TransparentButton from 'Scenes/Components/TransparentButton'
import Loader from 'Scenes/Loader/Loader'
import { ProjectStatus } from 'Services/models/IProject'
import { projectRoute } from 'Services/routeFuncs'

import './ProjectMaterialBomMapping.scss'

interface IProps {
	match: match<any>
	showProjectPartsMappingAlert: boolean
	setProjectPartsMappingAlert: (value: boolean) => void
	updateMaterialType: Function
	updateMaterialCategory: Function
	updateMaterial: Function
}

const ProjectMaterialBomMapping: FC<IProps> = ({
	match: {
		params: { projectId: projectOrBundleId }
	}
}) => {
	const history = useHistory()
	const {
		loading,
		projectStatus,
		projectMaterialBomData,
		disableAnalyzingButton,
		pageLoading
	} = useSelector(
		(state: RootStateOrAny) => state.ProjectMaterialBomMappingReducer
	)
	const { materials, userMaterialNamesMapping } = useSelector(
		(state: RootStateOrAny) => state.user
	)

	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(clearLoading())
		dispatch(clearRoutingPoller())
	}, [])

	useEffect(() => {
		if (projectOrBundleId) {
			dispatch(
				getProjectPartsMapping(
					projectOrBundleId,
					materials,
					userMaterialNamesMapping
				)
			)
		}
		if (
			!!projectStatus &&
			projectStatus !== ProjectStatus.awaitingStopMapping
		) {
			history.push(projectRoute(projectOrBundleId))
		}
	}, [])

	const onStartAnalyzing = async () => {
		dispatch(startAnalyzing(projectMaterialBomData))
	}

	const onResetAll = async () => {
		dispatch(resetAllMaterialSelections(materials, userMaterialNamesMapping))
	}

	const onUseDefault = async () => {
		dispatch(
			usingDefaultForAllMaterialSelections(materials, userMaterialNamesMapping)
		)
	}

	const setProjectPartsMappingContent = () => {
		return (
			<FlexBox
				flexDirection="column"
				alignItems="baseline"
				justifyContent="flex-start"
				className="project-parts-mapping-section"
			>
				<CastorFormHeader
					explanationHeader={getString(
						'CUSTOMIZE_MATERIAL_PARTS_NAMES_MAPPING_HEADER'
					)}
					explanationArray={[
						getString('CUSTOMIZE_MATERIAL_PARTS_NAMES_MAPPING_EXPLANATION')
					]}
					isInCard
				/>

				{pageLoading ? (
					<FlexBox width="100%" alignItems="center" justifyContent="center">
						<Loader showFlex={false} load={true} />
					</FlexBox>
				) : (
					<ProjectMaterialBomMappingTable />
				)}

				<FlexBox
					className="project-parts-mapping-buttons"
					flexDirection="row"
					alignItems="baseline"
					justifyContent="flex-end"
				>
					<TransparentButton
						className="project-parts-mapping-buttons-ignore-all"
						onClick={() => onResetAll()}
					>
						{getString('RESET_ALL')}
					</TransparentButton>
					<TransparentButton
						className="project-parts-mapping-buttons-ignore-all"
						onClick={() => onUseDefault()}
					>
						{getString('USED_DEFAULT_FOR_ALL_REQUIRED')}
					</TransparentButton>
					<ButtonWithLoader
						className="project-parts-mapping-buttons-submit"
						color="primary"
						disabled={disableAnalyzingButton}
						loading={loading}
						onClick={() => onStartAnalyzing()}
					>
						{getString('UPLOAD_FILES_BUTTON')}
					</ButtonWithLoader>
				</FlexBox>
			</FlexBox>
		)
	}

	return (
		<NavBarAndMaterial title={getString('PROJECT_MATERIAL_BOM_MAPPING')}>
			<CastorForm
				formTitle={getString('CUSTOMIZE_MATERIAL_NAMES_MAPPING_TITLE')}
				content={setProjectPartsMappingContent()}
				style={{ maxWidth: '80%', minWidth: '1000px' }}
			/>
		</NavBarAndMaterial>
	)
}

export default WithFeatureToggleHOC(
	memo(ProjectMaterialBomMapping),
	FeatureComponentId.BOM_MAPPING_MATERIAL
)
