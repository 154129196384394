import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { isEmpty } from 'lodash'

import {
	clearComponentData,
	fetchSkippedSolutions,
	fetchSolutions,
	tourPagesUpdated
} from '../MainPartAnalysis/MainPartAnalysisActions'
import { defaultTabOpen, getXAxisDropDownValues } from './PartAnalysisConstants'
import { useAllData, useProjectReducer } from './PartAnalysisSelector'
import { onTourStop } from 'Scenes/Components/TakeATour/TakeATourActions'
import { onSendPartToProvider } from 'Scenes/IntegrationProvider/IntegrationProviderActions'
import {
	getStringItemFromLocalStorage,
	setStringItemToLocalStorage
} from 'Services/LocalStorageService'
import { IConfiguration } from 'Services/models/IConfiguration'
import { PartStatus } from 'Services/models/IPart'
import { UnitSystem } from 'Services/UnitsConversionService'

export const useTabChange = (
	filteredConfigurations: IConfiguration[],
	partId: string
) => {
	const confLength = filteredConfigurations.length
	const oldValue =
		confLength > 1 ? getStringItemFromLocalStorage(partId) : defaultTabOpen
	const [value, setValue] = useState(+oldValue || defaultTabOpen)
	const { state } = useLocation() as { state?: Record<string, boolean> }

	const leadingConfigurationTab = useMemo(
		() => filteredConfigurations.findIndex(config => config.isLeading),
		[filteredConfigurations]
	)

	useEffect(() => {
		let openedConfiguration: number
		if (confLength > 1) {
			if (oldValue && !(state && state.showLeadingTab)) {
				openedConfiguration = +oldValue
			} else {
				openedConfiguration = leadingConfigurationTab
				window.history.replaceState({}, document.title)
			}
		} else {
			openedConfiguration = defaultTabOpen
		}

		setValue(openedConfiguration)
	}, [confLength])

	const handleChange = (event: any, newValue: number, toDefault?: boolean) => {
		let currentValue
		if (toDefault) {
			currentValue = leadingConfigurationTab
		} else {
			currentValue = newValue
		}
		setValue(currentValue)
		setStringItemToLocalStorage(partId, currentValue.toString())
	}

	return { value, handleChange }
}

export const useGetAllDataEffect = ({
	partId,
	projectId,
	clusterId,
	feaId,
	configurationId
}: any): any => {
	const dispatch = useDispatch()
	const partAnalysis = useAllData()
	const { part, cluster } = partAnalysis
	const { doRefreshConfigurations } = useProjectReducer()
	const [oldPartId, setOldPartId] = useState(partAnalysis.partId)
	const [loadercounter, setLoadercounter] = useState(partAnalysis.loaderCounter)
	const partStatus = clusterId ? cluster?.status : part?.status
	const isAwaitingStatus = partStatus === PartStatus.awaitingCombinedHeatmap

	useEffect(() => {
		if (isAwaitingStatus) {
			setOldPartId(null)
		}

		if (partId !== oldPartId || doRefreshConfigurations) {
			dispatch(clearComponentData())
			dispatch(onTourStop())
			setOldPartId(partId)
		}

		if (
			((oldPartId || clusterId) && partId === oldPartId) ||
			doRefreshConfigurations
		) {
			dispatch(
				fetchSolutions(
					partId,
					projectId,
					partAnalysis.solutionsFetched,
					partAnalysis.solutionsFetchCalled,
					clusterId,
					partAnalysis.weightReductionProgressFromProject,
					feaId,
					partAnalysis.pageName,
					partAnalysis.tourPages
				)
			)

			if (!isEmpty(partAnalysis.providerState)) {
				const configuration = undefined
				if (configurationId && configurationId > 0) {
					partAnalysis.configurations.find((c: any) => c.id === configurationId)
				}
				dispatch(
					onSendPartToProvider(
						partAnalysis.providerState.partName,
						partAnalysis.providerState.partStlUrl,
						partAnalysis.providerState.location,
						partAnalysis.providerState.provider_printer,
						partAnalysis.providerState,
						partAnalysis.userEmail,
						configuration,
						partAnalysis?.part?.partNumber
					)
				)
			}
		}
	}, [partId, oldPartId, doRefreshConfigurations])

	useEffect(() => {
		if (loadercounter !== partAnalysis.loaderCounter) {
			setLoadercounter(partAnalysis.loaderCounter)
			dispatch(
				tourPagesUpdated(
					partAnalysis.pagesVisited,
					partAnalysis.pageName,
					partAnalysis.weightReductionProgressFromProject
				)
			)
		}
	}, [partAnalysis.loaderCounter])

	useEffect(() => {
		if ((part || cluster) && !isAwaitingStatus) {
			dispatch(
				fetchSkippedSolutions(
					clusterId || partId,
					feaId,
					partAnalysis.configurations,
					!!clusterId
				)
			)
		}
	}, [partAnalysis.configurations, isAwaitingStatus])
}

export const useChangeAxis = (initialValue: string, unitSystem: UnitSystem) => {
	const [selectedXAxis, setSelectedXAxis] = useState<any>(initialValue)
	const [selectedXAxisExplanation, setSelectedXExplanation] =
		useState<any>(null)

	useEffect(() => {
		const xAxisDropDownValues = getXAxisDropDownValues(unitSystem)
		const selectedInfo =
			xAxisDropDownValues?.find((el: any) => el.value === selectedXAxis)
				?.iIcon || ''

		setSelectedXExplanation(selectedInfo)
	}, [selectedXAxis, unitSystem])

	return [selectedXAxis, setSelectedXAxis, selectedXAxisExplanation]
}

export const useDeleteConfiguration = (initialState: boolean = false) => {
	const [showRemoveAlert, setShowRemoveAlert] = useState<boolean>(initialState)
	const [removeConfiguration, setRemoveConfiguration] = useState<{
		label: string
		id: number
	} | null>(null)

	return [
		showRemoveAlert,
		setShowRemoveAlert,
		removeConfiguration,
		setRemoveConfiguration
	]
}
