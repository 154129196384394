import { FC, memo, useCallback } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useDispatch } from 'react-redux'

import { GridList, GridListTile } from '@material-ui/core'
import { Close } from '@material-ui/icons'

import ProjectPartItem from '../../../../../../ProjectAnalysis/ProjectPartItem'
import { onRemoveClusterPartClick } from 'global actions'
import Loader from 'Scenes/Loader/Loader'
import { Part } from 'Services/models/IPart'
import { getString } from 'Services/Strings/StringService'

import '../SolutionAnalysisTabs.scss'

const GridListTSX: any = GridList
const GridListTileTSX: any = GridListTile

interface IProps {
	parts: Part[] | null
	onPartClick: Function
	fetchMoreData: any
	hasMoreData: boolean
	clusterId: number
}

const ClusterPartsTab: FC<IProps> = ({
	parts,
	onPartClick,
	fetchMoreData,
	hasMoreData,
	clusterId
}) => {
	const dispatch = useDispatch()

	const clusterPartsExplanationArray =
		getString('REMOVE_CLUSTER_PART_EXPLANATION_ARRAY') || []

	const handleRemoveClusterPartClick = useCallback(
		(
			transformationMatrixHash: string,
			partExternalId: string,
			e: React.MouseEvent<SVGSVGElement>
		) => {
			e.stopPropagation()
			dispatch(
				onRemoveClusterPartClick(
					clusterId,
					transformationMatrixHash,
					partExternalId
				)
			)
		},
		[clusterId, dispatch]
	)

	return (
		<InfiniteScroll
			dataLength={parts?.length || 0}
			next={fetchMoreData}
			hasMore={hasMoreData}
			loader={
				<Loader
					load={true}
					size={50}
					showFlex={false}
					wrapperClassName="solution-tab-cluster-part-tile--loader"
				/>
			}
			height={330}
		>
			{clusterPartsExplanationArray.length ? (
				<div className="solution-tab-cluster--parts-explanation">
					{clusterPartsExplanationArray[0]}
					<Close />
					{clusterPartsExplanationArray[1]}
					<Close />
					{clusterPartsExplanationArray[2]}
				</div>
			) : null}
			<GridListTSX className="solution-tab-cluster" spacing={15} cols={3}>
				{parts?.map(part => (
					<GridListTileTSX
						className="solution-tab-cluster-part-tile"
						classes={{ tile: 'solution-tab-cluster-part-tile--element' }}
						key={part.id}
						sm={4}
					>
						<ProjectPartItem
							part={part}
							onPartClick={() => onPartClick(part)}
							onRemoveClusterPartClick={
								part.isLeaf && parts.length > 2
									? e =>
											handleRemoveClusterPartClick(
												part.transformationMatrixHash as string,
												part.externalId,
												e
											)
									: undefined
							}
							cardClassName="solution-tab-cluster-part-tile--card"
							cardSizeWrapperClassName="solution-tab-cluster-part-tile--card--image-wrapper"
							cardContentClassName="solution-tab-cluster-part-tile--card--content"
						/>
					</GridListTileTSX>
				))}
			</GridListTSX>
		</InfiniteScroll>
	)
}

export default memo(ClusterPartsTab)
