import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { FormControlLabel } from '@material-ui/core'

import { onOrganizationAddClick } from '../CustomizeActions'
import { validateOrganizationName } from '../CustomizeLogic'
import CastorAlert from 'Scenes/Components/alerts/CastorAlert'
import CastorCheckbox from 'Scenes/Components/CastorCheckbox'
import DetailsPopup from 'Scenes/Components/DetailsPopup'
import OrganizationSelector from 'Scenes/Components/OrganizationSelector'
import IconFactory from 'Scenes/Components/StarIcon/IconFactory'
import {
	CustomInput,
	Danger
} from 'Scenes/Components/thirdParty/CreativeTim/components'
import { getString } from 'Services/Strings/StringService'
import { renderOrganizationValue } from 'Services/Utils/organizationTools'

type IProps = {
	show: boolean
	onCancel: () => void
}

const AddOrganizationModal: FC<IProps> = ({ show, onCancel }) => {
	const [organizationName, setOrganizationName] = useState('')
	const [organizationNameError, setOrganizationNameError] = useState('')
	const [isPrivate, setIsPrivate] = useState(false)
	const [duplicateOrganization, setDuplicateOrganization] = useState(0)
	const [loading, setLoading] = useState(false)
	const { availableOrganizations } = useSelector(
		(state: RootStateOrAny) => state.user
	)
	const dispatch = useDispatch()

	const onOrganizationNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const organizationNameError = validateOrganizationName(e.target.value)
		setOrganizationNameError(organizationNameError)
		setOrganizationName(e.target.value)
	}

	const onOrganizationPrivacyChange = (checked: boolean) => {
		setIsPrivate(checked)
	}

	const onDuplicateOrganizationSelect = useCallback((value: number) => {
		setDuplicateOrganization(value)
	}, [])

	const handleConfirmModal = () => {
		dispatch(
			onOrganizationAddClick(
				organizationName,
				isPrivate,
				duplicateOrganization,
				setLoading,
				setOrganizationNameError
			)
		)
	}

	const renderValue = useCallback(
		(selected: unknown) =>
			renderOrganizationValue(
				availableOrganizations,
				selected as number,
				false,
				true
			),
		[availableOrganizations]
	)

	const title = useMemo(
		() =>
			renderOrganizationValue(
				availableOrganizations,
				duplicateOrganization,
				false,
				true
			),
		[availableOrganizations, duplicateOrganization]
	)

	useEffect(() => {
		setOrganizationName('')
		setOrganizationNameError('')
		setIsPrivate(false)
		setDuplicateOrganization(0)
	}, [show])

	return (
		<CastorAlert
			alertBodyClass="costing-function-editor--alert"
			headerTitle={getString('ADD_ORGANIZATION_MODAL_TITLE')}
			show={show}
			onConfirm={handleConfirmModal}
			showCancel={true}
			onCancel={onCancel}
			cancelOptionalText={getString('CANCEL')}
			confirmOptionalText={getString('ADD_ORGANIZATION_CONFIRM_BUTTON_TEXT')}
			onFooterCancel={onCancel}
			disabled={!organizationName || !!organizationNameError}
			loadingCalculation={loading}
		>
			<div className="edit-organization--alert-body">
				<div className="edit-organization--alert-body--name-input">
					<CustomInput
						error={!!organizationNameError}
						labelText={getString('EDIT_ORGANIZATION_NAME_LABEL')}
						formControlProps={{
							fullWidth: true
						}}
						inputProps={{
							onChange: onOrganizationNameChange,
							type: 'text',
							value: organizationName
						}}
					/>
					{!!organizationNameError && (
						<Danger
							style={{
								textAlign: 'left',
								position: 'absolute',
								bottom: '-10px',
								fontSize: '12px'
							}}
						>
							{organizationNameError}
						</Danger>
					)}
				</div>
				<div className="edit-organization--alert-body--private-checkbox">
					<FormControlLabel
						label={getString('PRIVATE')}
						classes={{ root: 'edit-organization--alert-body--checkbox' }}
						control={
							<CastorCheckbox
								checked={isPrivate}
								inputProps={{
									'aria-label': 'secondary checkbox'
								}}
							/>
						}
						onChange={(e, checked) => onOrganizationPrivacyChange(checked)}
					/>
					<DetailsPopup
						isHover={true}
						data={getString('EDIT_ORGANIZATION_PRIVACY_INFO')}
						popperDirection="right"
						popperClassName="info-box-wrapper"
						popperContactClassName="info-box-data"
					>
						<IconFactory
							iconName="info"
							className="edit-organization--alert-body--private-checkbox--info"
						/>
					</DetailsPopup>
				</div>
				<div className="edit-organization--alert-body--duplicating-selector">
					<span>{getString('ADD_ORGANIZATION_CHOOSE_DUPLICATE')}</span>
					<OrganizationSelector
						value={duplicateOrganization}
						renderValue={renderValue}
						handleOrganizationSelect={onDuplicateOrganizationSelect}
						title={title}
					/>
				</div>
			</div>
		</CastorAlert>
	)
}

export default AddOrganizationModal
