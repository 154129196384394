import {
	GET_PROJECT_CLUSTERS,
	GET_PROJECT_CLUSTERS_FAILED,
	GET_PROJECT_CLUSTERS_SUCCESS,
	HANDLE_LOADER,
	MPIO_REQUEST_FINISHED
} from '../../../global actions/types'
import {
	getProjectPrintableClusters,
	getProjectsInfo
} from '../../../Services/Network'
import { ProjectClusterStatus } from 'Services/models/IProject'
import Poller from 'Services/PollingService/Poller'
import { getString } from 'Services/Strings/StringService'

let poller = new Poller()

export const setupClustersOverview = projectId => {
	return async dispatch => {
		dispatch({ type: HANDLE_LOADER, payload: 1 })
		try {
			const { data } = await getProjectsInfo(projectId)
			const isBundle = data?.data?.isBundle
			let bundleId = isBundle ? projectId : ''

			dispatch({ type: GET_PROJECT_CLUSTERS, payload: projectId })
			const response = await getProjectPrintableClusters(projectId, bundleId)
			if (response) {
				dispatch({
					type: GET_PROJECT_CLUSTERS_SUCCESS,
					payload: response?.data
				})
			} else {
				dispatch({
					type: GET_PROJECT_CLUSTERS_FAILED,
					payload: getString('SOMETHING_WENT_WRONG')
				})
			}
		} catch (error) {
			console.log(error)
			dispatch({ type: GET_PROJECT_CLUSTERS_FAILED, payload: error.message })
		}
		dispatch({ type: HANDLE_LOADER, payload: -1 })
	}
}

export const handleClustersUpdate = (projectId, bundleId, clusterStatus) => {
	return async dispatch => {
		try {
			dispatch({ type: GET_PROJECT_CLUSTERS, payload: projectId })
			const isBundle = bundleId && bundleId !== 'null'
			const response = await getProjectPrintableClusters(
				isBundle ? undefined : projectId,
				isBundle ? bundleId : undefined
			)
			const clusters = response.data?.clusters
			const projects = response.data?.projects

			dispatch({
				type: MPIO_REQUEST_FINISHED,
				payload: { projectClusterStatus: clusterStatus, clusters, projects }
			})
			if (response) {
				dispatch({ type: GET_PROJECT_CLUSTERS_SUCCESS, payload: response.data })
			} else {
				dispatch({
					type: GET_PROJECT_CLUSTERS_FAILED,
					payload: getString('SOMETHING_WENT_WRONG')
				})
			}
		} catch (error) {
			console.log(error)
			dispatch({ type: GET_PROJECT_CLUSTERS_FAILED, payload: error.message })
		}
	}
}

export const getPCPoller = (projectId, bundleId) => {
	return async dispatch => {
		let timeOutDateTime = new Date()
		timeOutDateTime.setHours(timeOutDateTime.getHours() + 1)
		poller.stop()
		poller = new Poller(
			2000,
			timeOutDateTime,
			() => getProjectsInfo(bundleId || projectId),
			res => {
				return (
					res.data?.data?.clusterStatus === ProjectClusterStatus.complete ||
					res.data?.data?.clusterStatus === ProjectClusterStatus.failed ||
					res.data?.data?.clusterStatus === ProjectClusterStatus.noClustersFound
				)
			}
		)

		poller
			.start()
			.then(res => {
				dispatch(
					handleClustersUpdate(
						res.data?.isBundle ? undefined : projectId,
						res.data?.isBundle ? bundleId : undefined,
						res.data?.clusterStatus
					)
				)
			})
			.catch(error => {
				console.error(error)
			})
	}
}

export const stopPCPoller = () => {
	if (poller.pollerInterval) {
		poller.stop()
	}
}
