import React, { FC, memo, useEffect, useState } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { Action, AnyAction, bindActionCreators } from 'redux'
import { Field, getFormValues, InjectedFormProps, reduxForm } from 'redux-form'

import { Portal } from '@material-ui/core'
import cx from 'classnames'
import { isEmpty, isNull } from 'lodash'

import * as CastorAlertActions from './CastorAlertActions'
import ModalFooter from '../Modal/ModalFooter'
import ModalHeader from '../Modal/ModalHeader'
import TextField from '../TextField/TextField'
import MySimpleAlert from './MySimpleAlert'
import { AlertType } from 'Scenes/Components/alerts/AlertTypes'
import ErrorBoundary from 'Scenes/Components/ErrorBoundary/ErrorBoundary'
import Flexbox from 'Scenes/Components/FlexBox'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { getString } from 'Services/Strings/StringService'

import './CastorAlert.scss'

const ReduxFormField: any = Field

interface IProps {
	headerTitle?: string
	show: boolean
	alertType?: AlertType
	type?: string
	inputType?: string
	validationMsg?: string
	alertClass?: string
	alertBodyClass?: string
	confirmOptionalText?: string | React.ReactNode
	cancelOptionalText?: string
	inputLabel?: string
	inputPlaceholder?: string
	loadingCalculation?: boolean
	children?: any
	custom?: any
	customIcon?: any
	disabled?: boolean
	fullScreen?: boolean
	mediumSize?: boolean
	showConfirm?: boolean
	showCancel?: boolean
	showFooter?: boolean
	extraButton?: any
	onConfirm?: any
	onCancel: Function
	onFooterCancel?: Function
	onAlertCancel: () => Action<any>
	headerWithoutActions?: boolean
	onConfirmReset?: Function
	showReset?: boolean
	showCancelIcon?: boolean
	onButtonHoverText?: string
	inputDefaultValue?: string
	withSubtitle?: boolean
	preventCancel?: boolean
	closeOnClickOutside?: boolean
	buttonHoverClassName?: string
	resetFormOnCancel?: boolean
	inPortal?: boolean
	formValues?: any
}

const rootAlert = document.createElement('div')
rootAlert.setAttribute('id', 'rootAlert')
document.body.appendChild(rootAlert)

const validateEmail = (value: string, validationMsg: string) =>
	value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
		? validationMsg
		: undefined

const validate = (
	value: any,
	allValues: any,
	{ inputType, validationMsg }: any,
	fieldName: string
) => {
	switch (inputType) {
		case 'email':
			return validateEmail(value, validationMsg)
		default:
			break
	}
}

const renderTextField = ({
	input,
	label,
	type,
	placeholder,
	meta: { touched, error },
	inputProps
}: any) => (
	<TextField
		wrapperClassName="castor-alert--text-field"
		inputClassName="castor-alert--text-field-input"
		label={label}
		error={error}
		type={type}
		input={input}
		touched={touched}
		placeholder={placeholder}
		inputProps={inputProps}
	/>
)

const CastorAlert: FC<InjectedFormProps<any, IProps> & IProps> = ({
	headerTitle,
	onCancel,
	show,
	onConfirm,
	valid,
	alertType,
	type,
	inputType,
	alertClass = '',
	alertBodyClass = '',
	children,
	confirmOptionalText,
	loadingCalculation,
	cancelOptionalText,
	custom,
	customIcon,
	disabled,
	showCancel,
	showFooter = true,
	showConfirm,
	inputPlaceholder,
	inputLabel,
	extraButton,
	handleSubmit,
	onFooterCancel,
	onAlertCancel,
	fullScreen = false,
	mediumSize = false,
	headerWithoutActions = false,
	onConfirmReset,
	showReset = false,
	showCancelIcon,
	onButtonHoverText = '',
	withSubtitle = false,
	buttonHoverClassName = '',
	preventCancel = false,
	closeOnClickOutside = true,
	resetFormOnCancel = false,
	inputDefaultValue = null,
	reset,
	change,
	inPortal,
	...props
}) => {
	const [showAlert, setShowAlert] = useState<boolean>(false)
	const sideBarAndMenu = Feature.isFeatureOn(
		FeatureComponentId.SIDE_BAR_AND_MENU
	)
	const shouldUseDefaultInput =
		!!inputType && !isNull(inputDefaultValue) && type === 'input'

	useEffect(() => {
		if (show) {
			document.body.style.overflow = 'hidden'
			setShowAlert(true)
		}
		return () => {
			document.body.style.overflow = 'unset'
			setShowAlert(false)
		}
	}, [show])

	useEffect(() => {
		if (inputType && shouldUseDefaultInput && inputDefaultValue) {
			const currentValue = props.formValues?.[inputType]
			shouldUseDefaultInput &&
				isEmpty(currentValue) &&
				change(inputType, inputDefaultValue)
		}
	}, [change, shouldUseDefaultInput, inputDefaultValue, inputType])

	const onDialogCancel = () => {
		if (loadingCalculation) {
			setShowAlert(false)
			onAlertCancel()
		} else {
			if (resetFormOnCancel) {
				reset()
				shouldUseDefaultInput && change(inputType, inputDefaultValue)
			}
			onCancel()
		}
	}

	const renderInput = () => {
		if (type !== 'input') {
			return <div />
		}
		return (
			<ReduxFormField
				name={inputType}
				component={renderTextField}
				label={inputLabel}
				type={inputType}
				placeholder={inputPlaceholder}
				validate={validate}
			/>
		)
	}

	const renderHeader = () => {
		if (!headerTitle) return
		if (headerWithoutActions) return headerTitle

		return (
			<ModalHeader
				alertType={alertType}
				title={headerTitle}
				onCancel={onDialogCancel}
				showCancelIcon={showCancelIcon}
				disableCancel={preventCancel}
			/>
		)
	}

	const renderAlert = () => {
		return (
			<MySimpleAlert
				title={renderHeader() || ''}
				custom={custom}
				customIcon={customIcon}
				show={showAlert}
				onCancel={onDialogCancel}
				showConfirm={false}
				onConfirm={onConfirm}
				preventCancel={preventCancel}
				customClass={cx('castor-alert', alertClass, {
					'full-screen': fullScreen,
					'without-sideba': !sideBarAndMenu,
					'medium-size': mediumSize,
					'with-subtitle': withSubtitle
				})}
				closeOnClickOutside={closeOnClickOutside}
			>
				<ErrorBoundary errorClass="modal" extra="CastorAlert">
					{withSubtitle && (
						<div className="header-title">
							<span className="title">{getString('ADDITIVE_MINDS')}</span>
							<span className="subtitle">{getString('CONTACT_US')}</span>
						</div>
					)}
					<Flexbox flexDirection="column">
						{children && (
							<div
								data-qa="data-qa-alert-body"
								className={`castor-alert--body ${alertBodyClass} ${
									fullScreen ? 'full-screen' : ''
								}`}
							>
								{children}
							</div>
						)}
						{renderInput()}
						{showFooter && (
							<ModalFooter
								preventCancel={preventCancel}
								disabled={disabled || !valid}
								onCancel={onDialogCancel}
								onConfirm={onConfirm && handleSubmit(onConfirm)}
								cancelOptionalText={cancelOptionalText}
								confirmOptionalText={confirmOptionalText}
								loadingCalculation={loadingCalculation}
								showConfirm={showConfirm}
								showCancel={showCancel}
								extraButton={extraButton}
								onFooterCancel={onFooterCancel}
								onConfirmReset={onConfirmReset}
								showReset={showReset}
								onButtonHoverText={onButtonHoverText}
								buttonHoverClassName={buttonHoverClassName}
							/>
						)}
					</Flexbox>
				</ErrorBoundary>
			</MySimpleAlert>
		)
	}

	if (inPortal) {
		return <Portal container={rootAlert}>{renderAlert()}</Portal>
	}

	return <>{renderAlert()}</>
}

const mapStateToProps = (state: any, props: any) => {
	const formValues = getFormValues(`alertForm`)(state)
	return {
		form: 'alertForm',
		formValues,
		...props
	}
}

const mapDispatchToProps = (dispatch: DispatchProp<AnyAction>) =>
	bindActionCreators({ ...CastorAlertActions }, dispatch)

export default memo(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(
		reduxForm<any, IProps>({
			form: 'alertForm',
			enableReinitialize: true
		})(CastorAlert)
	)
)
