import { FC, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Divider from '@material-ui/core/Divider'

import { onChangeMaterialMappingPage } from '../CustomizeActions'
import {
	checkIfEmpty,
	filterUserMaterialMapping
} from './CastorMaterialNamesMappingService'
import MaterialNamesMappingRow from './MaterialNamesMappingRow'
import ButtonWithLoader from 'Scenes/Components/ButtonWithLoader'
import CastorPaginationSelector from 'Scenes/Components/CastorPagination/index'
import { Material } from 'Services/models/IMaterial'
import { IUserMaterialNamesMapping } from 'Services/models/IMaterialNamesMapping'
import { getString } from 'Services/Strings/StringService'

import './CustomizeMaterialNamesMapping.scss'

interface IProps {
	title: string
	explanation: string
	materialCategories: any
	materialTypes: string[]
	materials: Material[]
	isActive?: boolean
	isDefault?: boolean
	allowRemove?: boolean
	disableMaterialNameMappingSaveAll?: boolean
	isLoadingMaterial?: boolean
	updateMaterialNameMapping: Function
	removeUserMaterialNameMapping: Function
	removeAllUserMaterialNameMapping?: (active: boolean) => void
	changeSelectedMaterial?: Function
	devider?: boolean
}
const DividerTSX: any = Divider

const MaterialNamesMappingList: FC<IProps> = ({
	title,
	explanation,
	materialCategories,
	materials,
	materialTypes,
	isActive = false,
	isDefault = false,
	allowRemove = true,
	disableMaterialNameMappingSaveAll,
	isLoadingMaterial,
	updateMaterialNameMapping,
	removeUserMaterialNameMapping,
	removeAllUserMaterialNameMapping,
	changeSelectedMaterial,
	devider = true
}) => {
	const dispatch = useDispatch()
	const {
		userMaterialNamesActiveList,
		userMaterialNamesInactiveList,
		userMaterialNamesDefaultList,
		userMaterialNamesMapping,
		paginationActiveMaterialData,
		paginationDefaultMaterialData,
		paginationInactiveMaterialData
	} = useSelector((state: any) => state.user)

	const materialList = useMemo(
		() =>
			isDefault
				? userMaterialNamesDefaultList
				: isActive
				? userMaterialNamesActiveList
				: userMaterialNamesInactiveList,
		[
			isDefault,
			isActive,
			userMaterialNamesActiveList,
			userMaterialNamesDefaultList,
			userMaterialNamesInactiveList
		]
	)

	const materialPagination = useMemo(
		() =>
			isDefault
				? paginationDefaultMaterialData
				: isActive
				? paginationActiveMaterialData
				: paginationInactiveMaterialData,
		[
			isDefault,
			isActive,
			paginationDefaultMaterialData,
			paginationActiveMaterialData,
			paginationInactiveMaterialData
		]
	)

	if (
		!userMaterialNamesMapping ||
		checkIfEmpty(userMaterialNamesMapping, isActive)
	) {
		return <div />
	}

	const onUpdateMaterialNamesMapping = () => {
		updateMaterialNameMapping(
			filterUserMaterialMapping(userMaterialNamesMapping, isActive)
		)
	}

	return (
		<div data-qa="data-qa-added-materials-block">
			{devider && <DividerTSX className="custom-material-names--divider" />}
			<div className="custom-material-names--title">{title}</div>
			<div className="custom-material-names--explanation">
				{explanation}

				{!isActive && (
					<ButtonWithLoader
						style={{
							padding: '5px 20px',
							width: 'unset',
							marginRight: '5px'
						}}
						onClick={onUpdateMaterialNamesMapping}
						loading={false}
						disabled={disableMaterialNameMappingSaveAll}
					>
						{getString('SAVE_ALL')}
					</ButtonWithLoader>
				)}
				{!!materialList.length && removeAllUserMaterialNameMapping && (
					<ButtonWithLoader
						style={{
							padding: '5px 20px',
							width: 'unset',
							marginRight: '5px'
						}}
						onClick={removeAllUserMaterialNameMapping}
						loading={false}
					>
						{getString('REMOVE_ALL')}
					</ButtonWithLoader>
				)}
			</div>
			{materialList?.map(
				(userMaterialNameMapping: IUserMaterialNamesMapping) => (
					<MaterialNamesMappingRow
						isLoadingMaterial={isLoadingMaterial}
						key={userMaterialNameMapping.id}
						userMaterialNameMapping={userMaterialNameMapping}
						userMaterialNamesMapping={userMaterialNamesMapping}
						materialCategories={materialCategories}
						materials={materials}
						allowRemove={allowRemove}
						materialTypes={materialTypes}
						updateSelectedMaterial={updateMaterialNameMapping}
						removeUserMaterialNameMapping={removeUserMaterialNameMapping}
						changeSelectedMaterial={changeSelectedMaterial}
					/>
				)
			)}
			{!!materialList.length && materialPagination.totalPagesCount > 1 && (
				<CastorPaginationSelector
					showPagination={materialPagination.totalPagesCount > 1}
					pageNumber={materialPagination.page}
					isLastPage={!materialPagination.enableNext}
					total={materialPagination.totalPartsCount}
					limit={materialPagination.limit}
					className={{ pagination: 'parts-pagination' }}
					showingFrom={materialPagination.showingFrom}
					showingTo={materialPagination.showingTo}
					onNextClick={() =>
						dispatch(
							onChangeMaterialMappingPage(
								materialPagination.page + 1,
								isDefault,
								isActive
							)
						)
					}
					onPrevClick={() =>
						dispatch(
							onChangeMaterialMappingPage(
								materialPagination.page - 1,
								isDefault,
								isActive
							)
						)
					}
					onPageClick={(page: number) =>
						dispatch(onChangeMaterialMappingPage(page, isDefault, isActive))
					}
				/>
			)}
		</div>
	)
}

export default MaterialNamesMappingList
