import { cloneDeep } from 'lodash'

import { createPaginationData } from '../ProjectAnalysis/ProjectAnalysisService'
import {
	isDisableToAnalyzing,
	setNewMaterialSet,
	setNewMaterialToList,
	setStatusToPartBomData
} from './ProjectMaterialBomMappingService'
import {
	CHANGE_PROJECT_MATERIAL_MAPPING_PAGE,
	GET_PROJECT_MATERIAL_MAPPING,
	MappingStatus,
	MATERIAL_CATEGORY_CHANGE,
	MATERIAL_CHANGE,
	MATERIAL_TYPE_CHANGE,
	PROJECT_MATERIAL_BOM_MAPPING_LOADING,
	RESET_ALL_MATERIAL_SELECTIONS,
	START_PROJECT_MATERIAL_ERROR,
	START_PROJECT_MATERIAL_MAPPING,
	USE_DEFAULT_MATERIALS_SELECTIONS
} from './ProjectMaterialBomMappingTypes'
import { getTheme } from 'themes/getTheme'

const { defaultMaterial } = getTheme()

const INITIAL_STATE = {
	pageLoading: false,
	loading: false,
	projectStatus: '',
	projectMaterialBomInitial: [],
	projectMaterialBomData: [],
	disableAnalyzingButton: true,
	projectMaterialBomDataList: [],
	projectOrBundleId: null,
	paginationData: {
		page: 1,
		limit: 30,
		totalPartsCount: 0,
		totalPagesCount: 1,
		enableNext: false,
		enablePrev: false,
		showingFrom: 0,
		showingTo: 0
	}
}
const ProjectMaterialBomMappingReducer = (
	state = INITIAL_STATE,
	action: any
) => {
	switch (action.type) {
		case START_PROJECT_MATERIAL_MAPPING: {
			return {
				...INITIAL_STATE,
				pageLoading: true
			}
		}

		case GET_PROJECT_MATERIAL_MAPPING: {
			const {
				projectMaterialBomData,
				projectStatus,
				projectOrBundleId,
				materials,
				userMaterialNamesMapping
			} = action.payload

			const preparedMaterialBomData = projectMaterialBomData.map(
				(materialBomData: Record<string, any>) =>
					setNewMaterialSet(
						materialBomData,
						materials,
						userMaterialNamesMapping
					)
			)

			let projectMaterialBomDataList = preparedMaterialBomData.slice(
				0,
				INITIAL_STATE.paginationData.limit
			)

			const paginationData = createPaginationData(
				INITIAL_STATE.paginationData.page,
				INITIAL_STATE.paginationData.limit,
				preparedMaterialBomData.length,
				preparedMaterialBomData.length > INITIAL_STATE.paginationData.limit
					? INITIAL_STATE.paginationData.limit
					: preparedMaterialBomData.length
			)
			
			const disableAnalyzingButton = isDisableToAnalyzing(
				projectMaterialBomData
			)

			return {
				...state,
				projectMaterialBomData: preparedMaterialBomData,
				projectMaterialBomInitial: preparedMaterialBomData,
				projectStatus,
				paginationData,
				projectMaterialBomDataList,
				projectOrBundleId,
				pageLoading: false,
				disableAnalyzingButton
			}
		}
		case START_PROJECT_MATERIAL_ERROR: {
			return {
				...state,
				pageLoading: false
			}
		}

		case MATERIAL_TYPE_CHANGE: {
			const { partBomDataId, value } = action.payload
			const projectMaterialBomData: Record<string, any>[] =
				state.projectMaterialBomData

			const index = projectMaterialBomData.findIndex(
				partBomData => partBomData?.id === partBomDataId
			)

			if (index !== -1) {
				projectMaterialBomData[index].newSetMaterial.type = value
				projectMaterialBomData[index].newSetMaterial.category = ''
				projectMaterialBomData[index].newSetMaterial.materialId = ''
				projectMaterialBomData[index].newSetMaterial.useDefault = false
				projectMaterialBomData[index].status = MappingStatus.NOT_EXIST
			}
			const disableAnalyzingButton = isDisableToAnalyzing(
				projectMaterialBomData
			)

			const projectMaterialBomDataList = projectMaterialBomData.slice(
				state.paginationData.showingFrom - 1,
				state.paginationData.showingTo
			)
			return {
				...state,
				projectMaterialBomData,
				projectMaterialBomDataList,
				disableAnalyzingButton
			}
		}

		case MATERIAL_CATEGORY_CHANGE: {
			const { partBomDataId, value, materialCategories } = action.payload
			const projectMaterialBomData: Record<string, any>[] =
				state.projectMaterialBomData
			const index = projectMaterialBomData.findIndex(
				partBomData => partBomData?.id === partBomDataId
			)

			if (index !== -1) {
				projectMaterialBomData[index].newSetMaterial.category = value
				if (!projectMaterialBomData[index].newSetMaterial.type) {
					const materialCategory = materialCategories.find(
						(category: Record<string, any>) =>
							category.name.toLowerCase() === value.toLowerCase()
					)
					projectMaterialBomData[index].newSetMaterial.type =
						materialCategory.type
				}
				projectMaterialBomData[index].newSetMaterial.materialId = ''
				projectMaterialBomData[index].newSetMaterial.useDefault = false
				projectMaterialBomData[index].status = MappingStatus.NOT_EXIST
			}
			const disableAnalyzingButton = isDisableToAnalyzing(
				projectMaterialBomData
			)
			const projectMaterialBomDataList = projectMaterialBomData.slice(
				state.paginationData.showingFrom - 1,
				state.paginationData.showingTo
			)
			return {
				...state,
				projectMaterialBomData,
				projectMaterialBomDataList,
				disableAnalyzingButton
			}
		}
		case MATERIAL_CHANGE: {
			const { partBomDataId, value, materials } = action.payload
			const projectMaterialBomData: Record<string, any>[] =
				state.projectMaterialBomData
			const index = projectMaterialBomData.findIndex(
				partBomData => partBomData?.id === partBomDataId
			)

			if (index !== -1) {
				projectMaterialBomData[index].newSetMaterial.materialId = value
				projectMaterialBomData[index].newSetMaterial.useDefault = false
				projectMaterialBomData[index].newSetMaterial.materialChanged = true
				projectMaterialBomData[index].status = MappingStatus.EXIST

				if (!projectMaterialBomData[index].newSetMaterial.category) {
					const material = materials.find(
						(m: Record<string, any>) => m.id === value
					)
					projectMaterialBomData[index].newSetMaterial.type = material.type
					projectMaterialBomData[index].newSetMaterial.category =
						material.category
				}
			}
			const disableAnalyzingButton = isDisableToAnalyzing(
				projectMaterialBomData
			)
			const projectMaterialBomDataList = projectMaterialBomData.slice(
				state.paginationData.showingFrom - 1,
				state.paginationData.showingTo
			)
			return {
				...state,
				projectMaterialBomData,
				projectMaterialBomDataList,
				disableAnalyzingButton
			}
		}
		case RESET_ALL_MATERIAL_SELECTIONS: {
			const { materials } = action.payload
			let projectMaterialBomData: Record<string, any>[] = cloneDeep(
				state.projectMaterialBomInitial
			)

			projectMaterialBomData = projectMaterialBomData.map(materialBom => {
				materialBom.newSetMaterial.useDefault =
					materialBom.newSetMaterial.materialId ===
					materialBom.initialMaterialId
				materialBom.newSetMaterial.materialChanged = false
				materialBom.newSetMaterial.materialId = materialBom.initialMaterialId

				if (!materialBom.newSetMaterial.materialId) {
					materialBom.status = MappingStatus.NOT_EXIST
					materialBom.newSetMaterial.type = null
					materialBom.newSetMaterial.category = null
				} else {
					const material = materials.find(
						(m: Record<string, any>) =>
							m.id == materialBom.newSetMaterial.materialId
					)
					materialBom.newSetMaterial.type = material?.type
					materialBom.newSetMaterial.category = material?.category
				}

				return materialBom
			})

			const disableAnalyzingButton = isDisableToAnalyzing(
				projectMaterialBomData
			)

			const projectMaterialBomDataList = projectMaterialBomData.slice(
				state.paginationData.showingFrom - 1,
				state.paginationData.showingTo
			)
			return {
				...state,
				projectMaterialBomData,
				projectMaterialBomDataList,
				disableAnalyzingButton
			}
		}

		case USE_DEFAULT_MATERIALS_SELECTIONS: {
			const { materials, userMaterialNamesMapping } = action.payload
			let projectMaterialBomData: Record<string, any>[] = cloneDeep(
				state.projectMaterialBomData
			)
			let defaultUserMaterial = userMaterialNamesMapping.filter(
				(materialName: Record<string, any>) => materialName.defaultFormatType
			)
			const defaultBomMaterial =
				defaultUserMaterial[0]?.material || defaultMaterial

			projectMaterialBomData = setNewMaterialToList(
				projectMaterialBomData,
				materials,
				userMaterialNamesMapping,
				defaultBomMaterial
			)

			const disableAnalyzingButton = isDisableToAnalyzing(
				projectMaterialBomData
			)

			const projectMaterialBomDataList = projectMaterialBomData.slice(
				state.paginationData.showingFrom - 1,
				state.paginationData.showingTo
			)
			return {
				...state,
				projectMaterialBomData,
				projectMaterialBomDataList,
				disableAnalyzingButton
			}
		}

		case PROJECT_MATERIAL_BOM_MAPPING_LOADING: {
			return {
				...state,
				loading: action.payload
			}
		}
		case CHANGE_PROJECT_MATERIAL_MAPPING_PAGE: {
			const { page } = action.payload

			const start = (page - 1) * state.paginationData.limit
			const end = start + state.paginationData.limit
			const projectMaterialBomDataList = cloneDeep(
				state.projectMaterialBomData.slice(start, end)
			)

			const paginationData = createPaginationData(
				page,
				state.paginationData.limit,
				state.projectMaterialBomData.length,
				projectMaterialBomDataList.length
			)

			return {
				...state,
				projectMaterialBomDataList,
				paginationData
			}
		}

		default:
			return state
	}
}

export default ProjectMaterialBomMappingReducer
