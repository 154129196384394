import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import {
	IN_HOUSE_PRINTERS_ACTION,
	IN_HOUSE_PRINTERS_AMOUNT,
	IN_HOUSE_PRINTERS_COMPANY,
	IN_HOUSE_PRINTERS_PRINTER_NAME
} from '../../../../Services/Strings.js'
import { getString } from '../../../../Services/Strings/StringService'
import ItemGrid from '../../../Components/thirdParty/CreativeTim/components/Grid/ItemGrid.jsx'
import { Table } from '../../../Components/thirdParty/CreativeTim/components/index.js'
import { removeUserPrinter } from './CustomizeInHousePrintersActions'
import InHousePrinterRow from './InHousePrinterRow'

const CustomizeInHousePrintersTable = ({
	removeUserPrinter,
	userId,
	adminSelectedOrganizationId,
	chosenOrganizationId,
	configurationId,
	userPrinters
}) => {
	const [userPrintersList, setUserPrintersList] = useState([])

	useEffect(() => {
		const userPrintersList = userPrinters.map(printer => {
			printer.editMode = false
			return printer
		})
		setUserPrintersList(userPrintersList)
	}, [userPrinters])

	const onDeleteClick = itemToDelete => {
		removeUserPrinter(
			itemToDelete,
			userId,
			chosenOrganizationId || adminSelectedOrganizationId,
			configurationId
		)
	}

	const onEditClick = index => {
		const newUserPrintersList = [...userPrintersList]
		newUserPrintersList[index].editMode = true
		setUserPrintersList(newUserPrintersList)
	}

	const onCancelClick = index => {
		const newUserPrintersList = [...userPrintersList]
		newUserPrintersList[index].editMode = false
		setUserPrintersList(newUserPrintersList)
	}

	const renderInHousePrinterTblData = () => {
		return userPrintersList?.map((userPrinter, index) => {
			return (
				<InHousePrinterRow
					editMode={userPrinter.editMode}
					printerCompany={userPrinter.company}
					printerName={userPrinter.name}
					printerMaterials={userPrinter.materials}
					printerAmount={userPrinter.amount}
					recordId={userPrinter.recordId}
					onDeleteClick={() => onDeleteClick(userPrinter)}
					onEditClick={() => onEditClick(index)}
					onCancelClick={() => onCancelClick(index)}
				/>
			)
		})
	}

	if (userPrintersList?.length) {
		return (
			<ItemGrid>
				<Table
					editTableStyle={'materialCostTableCell'}
					tableHead={[
						IN_HOUSE_PRINTERS_COMPANY,
						IN_HOUSE_PRINTERS_PRINTER_NAME,
						getString('ADMIN_MATERIALS_PAGE_HEADER'),
						IN_HOUSE_PRINTERS_AMOUNT,
						IN_HOUSE_PRINTERS_ACTION
					]}
					tableData={renderInHousePrinterTblData()}
					qaDataElementName="data-qa-user-printers-table"
					mapCells={false}
				/>
			</ItemGrid>
		)
	}
	return <div />
}

const mapStateToProps = ({
	CustomizeInHousePrintersReducer,
	user,
	CustomizeReducer,
	MainPartAnalysisReducer
}) => {
	const userPrinters = CustomizeInHousePrintersReducer.configurationId
			? MainPartAnalysisReducer.allConfigurationsOrganizationSettings?.[
					CustomizeInHousePrintersReducer.chosenOrganizationId
			  ]?.printers
			: CustomizeInHousePrintersReducer.userId
			? CustomizeInHousePrintersReducer.userPrinters
			: user.printers,
		{ userId, chosenOrganizationId } = CustomizeInHousePrintersReducer,
		{ adminSelectedOrganizationId } = CustomizeReducer
	return {
		userPrinters,
		userId,
		adminSelectedOrganizationId,
		chosenOrganizationId,
		configurationId: CustomizeInHousePrintersReducer.configurationId
	}
}

export default connect(mapStateToProps, { removeUserPrinter })(
	CustomizeInHousePrintersTable
)
