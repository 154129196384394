import {
	CHANGE_CLUSTER_TRAY_SIZE,
	GENERATE_MORE_CHANGE_PRINTER,
	GENERATE_MORE_CHANGE_PRINTER_COMPANY,
	GENERATE_MORE_PRINTER_APPLY,
	GENERATE_MORE_PRINTER_RESET,
	HIDE_ALERT,
	MPIO_REQUEST,
	MPIO_REQUEST_FAIL,
	MPIO_REQUEST_SUCCESS,
	MPIO_SHOW_ALERT,
	SETUP_GENERATE_MORE_RESULTS_ALERT,
	TOGGLE_GENERATE_MORE_RESULTS_ALERT
} from '../../../../global actions/types'

export const emptyOption = '0'

const INITIAL_STATE = {
	error: null,
	loading: false,
	popup: null,
	showGenerateMoreResultsAlert: false,
	trayWidth: '',
	trayHeight: '',
	trayDepth: '',
	printersCompanies: [],
	printers: [],
	selectedPrinterCompany: emptyOption,
	selectedPrinterName: emptyOption,
	selectedPrinter: null
}

const requestMultiplePartsIntoOneReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case MPIO_SHOW_ALERT:
			return { ...state }
		case MPIO_REQUEST:
			return {
				...state,
				popup: null,
				error: null,
				loading: true
			}

		case MPIO_REQUEST_SUCCESS:
			return {
				...INITIAL_STATE,
				error: null,
				loading: false,
				showGenerateMoreResultsAlert: false
			}

		case MPIO_REQUEST_FAIL:
			return { ...state, loading: false }

		case HIDE_ALERT:
			return { ...state, popup: null, error: null }

		case TOGGLE_GENERATE_MORE_RESULTS_ALERT: {
			const { show } = action.payload
			const currentState = show ? state : INITIAL_STATE
			return { ...currentState, showGenerateMoreResultsAlert: show }
		}
		case CHANGE_CLUSTER_TRAY_SIZE: {
			const dimension = action.payload

			return {
				...state,
				...dimension,
				selectedPrinterCompany: emptyOption,
				selectedPrinterName: emptyOption,
				selectedPrinter: null
			}
		}
		case SETUP_GENERATE_MORE_RESULTS_ALERT: {
			const { printersCompanies, printers } = action.payload

			return {
				...state,
				printersCompanies,
				printers
			}
		}
		case GENERATE_MORE_CHANGE_PRINTER_COMPANY: {
			const { printerCompany } = action.payload

			return { ...state, selectedPrinterCompany: printerCompany }
		}
		case GENERATE_MORE_CHANGE_PRINTER: {
			const { printerName } = action.payload

			const selectedPrinter = state.printers.find(
				printer => printer.name === printerName
			)

			return {
				...state,
				selectedPrinter: selectedPrinter,
				selectedPrinterName: printerName
			}
		}
		case GENERATE_MORE_PRINTER_RESET: {
			return {
				...state,
				selectedPrinterCompany: emptyOption,
				selectedPrinterName: emptyOption,
				selectedPrinter: null
			}
		}
		case GENERATE_MORE_PRINTER_APPLY: {
			return {
				...state,
				trayWidth: state.selectedPrinter?.trayX,
				trayHeight: state.selectedPrinter?.trayZ,
				trayDepth: state.selectedPrinter?.trayY
			}
		}
		default:
			return state
	}
}

export default requestMultiplePartsIntoOneReducer
