import { IOrganization } from 'Services/models/IOrganization'
import { getString } from 'Services/Strings/StringService'

export const renderOrganizationValue = (
	availableOrganizations: IOrganization[],
	selected: number,
	emptyOption = false,
	defaultOption = false
): string => {
	if (defaultOption && !selected) {
		return getString('CASTOR_DEFAULT')
	}
	const chosenOrganization = availableOrganizations?.find(
		(organization: IOrganization) => organization.id === selected
	)
	return chosenOrganization
		? `${chosenOrganization?.name}${
				chosenOrganization?.private ? ` (${getString('PRIVATE')})` : ''
		  }`
		: emptyOption
		? getString('CHOOSE_SITE')
		: ''
}
