import { IUserMaterialNamesMapping } from './models/IMaterialNamesMapping'
import { filterUserMaterialMappingExpression } from 'Scenes/Home/Customize/CustomizeMaterialNamesMapping/CastorMaterialNamesMappingService'
import { createPaginationData } from 'Scenes/Home/ProjectAnalysis/ProjectAnalysisService'

export const PAGINATION_DEFAULT_MATERIAL = 'paginationDefaultMaterialData'
export const PAGINATION_ACTIVE_MATERIAL = 'paginationActiveMaterialData'
export const PAGINATION_INACTIVE_MATERIAL = 'paginationInactiveMaterialData'

export const USER_DEFAULT_MATERIAL = 'userMaterialNamesDefaultList'
export const USER_ACTIVE_MATERIAL = 'userMaterialNamesActiveList'
export const USER_INACTIVE_MATERIAL = 'userMaterialNamesInactiveList'

export const initialMaterialPaginationData = {
	page: 1,
	limit: 10,
	totalPartsCount: 0,
	totalPagesCount: 1,
	enableNext: false,
	enablePrev: false,
	showingFrom: 0,
	showingTo: 0
}

export const prepareMaterialAndPagination = (
	materialNameMapping: IUserMaterialNamesMapping[],
	paginationData: Record<string, any>,
	isActive: boolean,
	isDefault?: boolean
) => {
	const filteredNonDefaultMaterial = materialNameMapping?.filter(materialName =>
		isDefault ? materialName.defaultFormatType : !materialName.defaultFormatType
	)

	const filteredMaterial = filterUserMaterialMappingExpression(
		filteredNonDefaultMaterial,
		isActive
	)

	const materialList = filteredMaterial.slice(0, paginationData.limit)

	const paginationMaterialData = createPaginationData(
		paginationData.page,
		paginationData.limit,
		filteredMaterial.length,
		filteredMaterial.length > paginationData.limit
			? paginationData.limit
			: filteredMaterial.length
	)

	return {
		materialList,
		paginationMaterialData
	}
}

export const changedMaterialMappingPage = (
	materialNameMapping: IUserMaterialNamesMapping[],
	paginationData: Record<string, any>,
	page: number,
	isActive: boolean,
	isDefault: boolean
) => {
	const filteredNonDefaultMaterial = materialNameMapping?.filter(materialName =>
		isDefault ? materialName.defaultFormatType : !materialName.defaultFormatType
	)
	const filteredMaterial = filterUserMaterialMappingExpression(
		filteredNonDefaultMaterial,
		isActive
	)
	let changedPage = page
	let start = (changedPage - 1) * paginationData.limit
	let end = start + paginationData.limit
	let materialList = filteredMaterial.slice(start, end)

	if (materialList.length === 0 && changedPage > 1) {
		changedPage = page - 1
		start = (changedPage - 1) * paginationData.limit
		end = start + paginationData.limit
		materialList = filteredMaterial.slice(start, end)
	}

	const paginationMaterialData = createPaginationData(
		changedPage,
		paginationData.limit,
		filteredMaterial.length,
		materialList.length
	)

	return {
		materialList,
		paginationMaterialData
	}
}
