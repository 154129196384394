import { FC, memo } from 'react'

import { MenuItem, Select } from '@material-ui/core'

import { getString } from 'Services/Strings/StringService'

import './DataTable.scss'

const SelectTSX: any = Select
const MenuItemTSX: any = MenuItem

interface IProps {
	text: string | number
	className: string
	data: any
}

const DataTableDropdown: FC<IProps> = ({ text, className, data = {} }) => {
	const isFromStringTable =
		data.isFromStringTable == null ? true : data.isFromStringTable

	return (
		<SelectTSX
			className={`data-table-dropdown ${className} ${data.className}`}
			value={text == null ? '' : text}
			onChange={data.onChange}
			disableUnderline={true}
		>
			{data.options.map((option: any) => {
				return (
					<MenuItemTSX
						key={option.key}
						value={option.value}
						disabled={option.disabled}
					>
						{isFromStringTable ? getString(option.key) : option.key}
					</MenuItemTSX>
				)
			})}
		</SelectTSX>
	)
}

export default memo(DataTableDropdown)
