import { isInteger } from 'lodash'
import Numeral from 'numeral'

/**
 * Convert a long amount to a short amount with a currency mark.
 * @param {string | undefined} num Optional. Amount
 * @param {string} format Format template for converting the amount..
 * @returns Short amount with a currency mark or 0.
 */
export const currencyFormatter = (
	num: number,
	format: string
): number | string => {
	return num ? Numeral(num).format(format).toUpperCase() : 0
}

export const getFixedSize = (size: number, fixed: number): string => {
	return isInteger(size) ? size.toFixed(0) : size.toFixed(fixed)
}

export const eulerToNormalVector = (
	yaw: number = 0,
	pitch: number = 0,
	roll: number = 0
) => {
	const toRadians = (deg: any) => deg * (Math.PI / 180)

	yaw = toRadians(yaw)
	pitch = toRadians(pitch)
	roll = toRadians(roll)

	// Initial vector
	let vector = { x: 0, y: 0, z: 1 }

	const rotateX = (v: any, angle: any) => ({
		x: v.x,
		y: v.y * Math.cos(angle) - v.z * Math.sin(angle),
		z: v.y * Math.sin(angle) + v.z * Math.cos(angle)
	})

	const rotateY = (v: any, angle: any) => ({
		x: v.x * Math.cos(angle) + v.z * Math.sin(angle),
		y: v.y,
		z: -v.x * Math.sin(angle) + v.z * Math.cos(angle)
	})

	const rotateZ = (v: any, angle: any) => ({
		x: v.x * Math.cos(angle) - v.y * Math.sin(angle),
		y: v.x * Math.sin(angle) + v.y * Math.cos(angle),
		z: v.z
	})

	const vectorX = rotateX(vector, roll)
	const vectorY = rotateY(vectorX, pitch)
	const vectorZ = rotateZ(vectorY, yaw)

	return [vectorZ.x, vectorZ.y, vectorZ.z]
}

/**
 *
 * @param number number to round
 * @param minDecimalPlaces min decimal places
 * @param maxDecimalPlaces max decimal places
 * @returns a number rounded to the first non-zero decimal place
 */
export const dynamicRound = (
	number: number,
	minDecimalPlaces = 2,
	maxDecimalPlaces = 10
) => {
	for (let i = minDecimalPlaces; i <= maxDecimalPlaces; i++) {
		const fixedNumber = number.toFixed(i)
		const [, decimal] = fixedNumber.split('.')
		if (decimal?.split('').some(digit => digit !== '0')) {
			return Number(fixedNumber)
		}
	}
	// If we've reached this point, all decimal digits are zeros
	return number
}
