import { cloneDeep, isEqual } from 'lodash'

import { Action } from '../../../../global actions/ActionModels'
import {
	PART_CONSOLIDATION_SETTINGS_CHECKBOX_CHANGED,
	PART_CONSOLIDATION_SETTINGS_FETCHED,
	PART_CONSOLIDATION_SETTINGS_LOADER_CHANGED
} from 'global actions/types'
import { PartConsolidationSettings } from 'Services/models/IPartConsolidationSettings'

interface IState {
	initialPartConsolidationSettings: PartConsolidationSettings
	partConsolidationSettings: PartConsolidationSettings
	changesPerformed: boolean
	settingsUpdating: boolean
}

const initialState: IState = {
	initialPartConsolidationSettings: {} as PartConsolidationSettings,
	partConsolidationSettings: {} as PartConsolidationSettings,
	changesPerformed: false,
	settingsUpdating: false
}

const CustomizePartConsolidationReducer = (
	state = initialState,
	{ type, payload }: Action<any>
) => {
	switch (type) {
		case PART_CONSOLIDATION_SETTINGS_FETCHED: {
			const { partConsolidationSettings } = payload
			return {
				...initialState,
				initialPartConsolidationSettings: partConsolidationSettings,
				partConsolidationSettings
			}
		}

		case PART_CONSOLIDATION_SETTINGS_CHECKBOX_CHANGED: {
			const { key, value } = payload
			const partConsolidationSettings = cloneDeep(
				state.partConsolidationSettings
			)
			partConsolidationSettings[key as keyof PartConsolidationSettings] = value

			const changesPerformed = !isEqual(
				partConsolidationSettings,
				state.initialPartConsolidationSettings
			)

			return {
				...state,
				partConsolidationSettings,
				changesPerformed
			}
		}

		case PART_CONSOLIDATION_SETTINGS_LOADER_CHANGED: {
			const settingsUpdating = payload

			return {
				...state,
				settingsUpdating
			}
		}
		default:
			return state
	}
}

export default CustomizePartConsolidationReducer
