import { capitalize, isEmpty, toLower } from 'lodash'

import { StatusBarColor } from '../../Components/DataTable/DataTableStatusBarForMaterialsMatch'
import { MappingStatus } from './ProjectMaterialBomMappingTypes'
import { generateMaterialCategories } from 'Scenes/Components/MaterialSelector/AmMaterialSelector/AmMaterialSelectorService'
import {
	IMaterial,
	IMaterialCategory,
	Material,
	MaterialTypeEnum
} from 'Services/models/IMaterial'
import { IUserMaterialNamesMapping } from 'Services/models/IMaterialNamesMapping'
import { getAllProjectDataMapping } from 'Services/Network'

export const getStatusColor = (partMapping: Record<string, any>) => {
	let status = StatusBarColor.ORANGE
	const partBomDataStatus = partMapping.status
	switch (partBomDataStatus) {
		case MappingStatus.NOT_EXIST:
			status = StatusBarColor.ORANGE
			break
		case MappingStatus.EXIST:
			status = StatusBarColor.GREEN
			break
		case MappingStatus.DEFAULT_APPLIED:
			status = StatusBarColor.BLUE
			break
		default:
			status = StatusBarColor.ORANGE
			break
	}

	return { color: status }
}

export const getTypesOptions = (
	materialTypes: string[]
): Record<string, string>[] => {
	const options: Record<string, any>[] = []
	options.push({
		key: '',
		value: '',
		disabled: true
	})
	materialTypes?.map(materialType => {
		options.push({
			key: capitalize(materialType),
			value: materialType
		})
	})
	return options
}
export const getCategoriesOptions = (
	materialCategories: IMaterialCategory[],
	materialType: string,
	allowedCategories: String[]
): Record<string, string>[] => {
	const options: Record<string, any>[] = []
	options.push({
		key: '',
		value: '',
		disabled: true
	})

	materialCategories?.map(materialCategory => {
		if (
			(!materialType ||
				materialCategory.type === materialType ||
				toLower(materialCategory.type) === toLower(materialType)) &&
			allowedCategories.includes(toLower(materialCategory.name))
		) {
			options.push({
				key: materialCategory.name,
				value: materialCategory.name.toLowerCase()
			})
		}
	})

	return options
}

export const materialCategoriesList = (materials: IMaterial[]) => {
	const plasticCategories = generateMaterialCategories(
		materials,
		MaterialTypeEnum.plastic
	)

	const metalCategories = generateMaterialCategories(
		materials,
		MaterialTypeEnum.metal
	)

	return {
		allowedCategories: [...plasticCategories, ...metalCategories]
	}
}

export const getMaterialsOptions = (
	materials: Material[],
	materialType: string,
	materialCategory: string
): Record<string, string>[] => {
	const options: Record<string, any>[] = []
	options.push({
		key: '',
		value: '',
		disabled: true
	})

	materials?.map(material => {
		const isMaterialTypeChoose = materialType
		const isMaterialCategoryChoose = materialCategory
		if (
			(isMaterialTypeChoose &&
				!isMaterialCategoryChoose &&
				material.type === materialType) ||
			(isMaterialCategoryChoose &&
				(material.category === materialCategory ||
					toLower(material.category) === toLower(materialCategory))) ||
			(!isMaterialTypeChoose && !isMaterialCategoryChoose)
		) {
			options.push({
				key: material.name,
				value: material.id
			})
		}
	})
	return options
}
export const getAllProjectDataForMapping = async (
	projectId: string,
	isMaterialOnly: boolean = false
): Promise<{
	projectPartBomData: Record<string, any>[]
	projectStatus: string
}> => {
	const response = await getAllProjectDataMapping(projectId, isMaterialOnly)
	return response?.data?.data || {}
}

export const findMaterialByBom = (
	partBomData: Record<string, any>,
	materials: Material[],
	userMaterialNamesMapping: IUserMaterialNamesMapping[]
) => {
	let material
	const materialId = partBomData.materialId
	if (materialId) {
		material = materials.find(m => m.id == materialId)
	}
	if (!material) {
		const userMaterialNameMapping = userMaterialNamesMapping.find(
			m =>
				m.expression.toLowerCase().trim() ===
				partBomData.materialName.toLowerCase().trim()
		)
		if (userMaterialNameMapping?.materialId) {
			material = materials.find(m => m.id == userMaterialNameMapping.materialId)
		}
	}
	return material
}

export const isDisableToAnalyzing = (
	projectMaterialBomData: Record<string, any>[]
) => {
	return projectMaterialBomData?.some((partBomData: Record<string, any>) => {
		return partBomData?.status === MappingStatus.NOT_EXIST
	})
}

export const setStatusToPartBomData = (partBomData: Record<string, any>) => {
	if (partBomData.newSetMaterial?.useDefault) {
		return MappingStatus.DEFAULT_APPLIED
	}
	if (partBomData.newSetMaterial.materialId) {
		return MappingStatus.EXIST
	}
	return MappingStatus.NOT_EXIST
}

export const setNewMaterialSet = (
	materialMapping: Record<string, any>,
	materials: Material[],
	userMaterialNamesMapping: IUserMaterialNamesMapping[]
) => {
	materialMapping.newSetMaterial = materialMapping.newSetMaterial || {}

	if (isEmpty(materialMapping.newSetMaterial)) {
		const material = findMaterialByBom(
			materialMapping,
			materials,
			userMaterialNamesMapping
		)
		if (material) {
			materialMapping.newSetMaterial = {
				type: material.type,
				category: material.category,
				materialId: material.id
			}
		}
	}

	materialMapping.status = setStatusToPartBomData(materialMapping)
	return materialMapping
}

export const setNewMaterialToList = (
	materialBomDataList: Record<string, any>[],
	materials: Material[],
	userMaterialNamesMapping: IUserMaterialNamesMapping[],
	defaultBomMaterial: Material
) =>
	materialBomDataList.map((materialBom: Record<string, any>) => {
		const material = findMaterialByBom(
			materialBom,
			materials,
			userMaterialNamesMapping
		)

		if (!material || !materialBom.newSetMaterial?.materialId) {
			materialBom.newSetMaterial = {
				type: defaultBomMaterial?.type,
				category: defaultBomMaterial?.category,
				materialId: defaultBomMaterial?.id,
				useDefault: true,
				materialChanged: true
			}
			materialBom.status = setStatusToPartBomData(materialBom)
		}
		return materialBom
	})

export const prepareBomDataToSend = (
	projectMaterialBomData: Record<string, any>[]
) =>
	projectMaterialBomData.map(materialBom => {
		materialBom.newSetMaterial.useDefault =
			materialBom.newSetMaterial.materialId === materialBom.initialMaterialId
		materialBom.newSetMaterial.materialChanged =
			materialBom.newSetMaterial.materialId !== materialBom.initialMaterialId
		return materialBom
	})
