import React, {
	ChangeEvent,
	FC,
	memo,
	useCallback,
	useEffect,
	useMemo,
	useState
} from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { FormControlLabel, RadioGroup } from '@material-ui/core'

import { GenerateMoreResultOption } from './GenerateMoreResultsTypes'
import PrinterTraySelector from './PrinterTraySelector'
import TraySizeInputs from './TraySizeInputs'
import {
	sendGenerateMoreClustersRequest,
	setupGenerateMoreResultsAlert,
	toggleGenerateMoreResultsAlert
} from 'global actions'
import CastorAlert from 'Scenes/Components/alerts/CastorAlert'
import CastorRadio from 'Scenes/Components/CastorRadio'
import { Muted } from 'Scenes/Components/thirdParty/CreativeTim/components'
import TransparentButton from 'Scenes/Components/TransparentButton'
import { getString } from 'Services/Strings/StringService'

const GenerateMoreResultsAlert: FC = () => {
	const [chosenOption, setChosenOption] = useState(
		GenerateMoreResultOption.setUnlimitedSize
	)
	const [selectPrinterMode, setSelectPrinterMode] = useState(false)

	const {
		showGenerateMoreResultsAlert,
		loading,
		trayWidth,
		trayHeight,
		trayDepth
	} = useSelector(
		(state: RootStateOrAny) => state.RequestMultiplePartsIntoOneCardReducer
	)
	const { isBundle } = useSelector(
		(state: RootStateOrAny) => state.ProjectPageReducer
	)
	const { projectId: paramsProjectId } = useParams<{ projectId: string }>()
	const dispatch = useDispatch()

	const generatePCResultsOptions =
		getString('PART_CONSOLIDATION_GENERATE_MORE_RESULTS_OPTIONS') || []

	const isConfirmDisabled = useMemo(
		() =>
			chosenOption === GenerateMoreResultOption.setSpecificSize &&
			(!trayWidth || !trayHeight || !trayDepth),
		[chosenOption, trayDepth, trayHeight, trayWidth]
	)

	const closeModal = useCallback(() => {
		dispatch(toggleGenerateMoreResultsAlert(false))
	}, [dispatch])

	const handleConfirm = () => {
		const projectId = isBundle ? null : paramsProjectId
		const bundleId = isBundle ? paramsProjectId : null
		dispatch(
			sendGenerateMoreClustersRequest(
				projectId,
				bundleId,
				chosenOption,
				trayWidth,
				trayHeight,
				trayDepth
			)
		)
	}

	const selectSpecificTraySizeOption = useCallback(() => {
		if (chosenOption !== GenerateMoreResultOption.setSpecificSize) {
			setChosenOption(GenerateMoreResultOption.setSpecificSize)
		}
	}, [chosenOption])

	const onSpecifyPrinterClick = (e: React.MouseEvent) => {
		e.stopPropagation()
		e.preventDefault()
		setSelectPrinterMode(true)
		selectSpecificTraySizeOption()
	}

	useEffect(() => {
		if (showGenerateMoreResultsAlert) {
			dispatch(setupGenerateMoreResultsAlert())
			setChosenOption(GenerateMoreResultOption.setUnlimitedSize)
			setSelectPrinterMode(false)
		}
	}, [dispatch, showGenerateMoreResultsAlert])

	return (
		<CastorAlert
			alertBodyClass="generate-more-pc-results--alert"
			headerTitle={getString('GENERATE_MORE_RESULTS')}
			show={showGenerateMoreResultsAlert}
			onConfirm={handleConfirm}
			showCancel={true}
			onCancel={closeModal}
			cancelOptionalText={getString('CANCEL')}
			onFooterCancel={closeModal}
			loadingCalculation={loading}
			disabled={isConfirmDisabled}
		>
			<h4>{getString('GENERATE_MORE_RESULTS_OPTIONS_LABEL')}:</h4>
			<RadioGroup
				row={false}
				value={chosenOption}
				onChange={(e: ChangeEvent<HTMLInputElement>) =>
					setChosenOption(e.target.value as GenerateMoreResultOption)
				}
				color="primary"
				className="generate-more-pc-results--radio-group"
			>
				{generatePCResultsOptions.map((option: Record<string, any>) => {
					const isSpecificSizeOption =
						option.key === GenerateMoreResultOption.setSpecificSize
					const label =
						isSpecificSizeOption && !selectPrinterMode ? (
							<div>
								{option.name}
								<TransparentButton
									className="generate-more-pc-results--select-printer-btn"
									onClick={onSpecifyPrinterClick}
								>
									{getString('SELECT_PRINTER')}
								</TransparentButton>
							</div>
						) : (
							option.name
						)

					return (
						<>
							<FormControlLabel
								key={option.key}
								className="generate-more-pc-results--radio"
								value={option.key}
								control={
									<CastorRadio color="primary" disabled={option.disabled} />
								}
								label={label}
								disabled={option.disabled}
							/>
							{isSpecificSizeOption &&
								(selectPrinterMode ? (
									<PrinterTraySelector
										onCancel={() => setSelectPrinterMode(false)}
									/>
								) : (
									<TraySizeInputs
										selectSpecificTraySizeOption={selectSpecificTraySizeOption}
									/>
								))}
						</>
					)
				})}
			</RadioGroup>
			<Muted className="generate-more-pc-results--note">
				*{getString('PRINTER_TRAY_SIZE_NOTE')}
			</Muted>
		</CastorAlert>
	)
}

export default memo(GenerateMoreResultsAlert)
