import {
	GET_PROJECT_CLUSTERS,
	GET_PROJECT_CLUSTERS_FAILED,
	GET_PROJECT_CLUSTERS_SUCCESS,
	MPIO_REQUEST_SUCCESS,
	NEW_PAGE_VISITED,
	PROJECT_ANALYSIS_CLUSTER_REMOVED
} from '../../../global actions/types'
import {
	CLUSTER_ANALYSIS_ROUTE,
	USER_HOME_ROUTE
} from '../../../Services/Constants/RoutesConstants'
import {
	ANY_MATERIAL,
	COMBINE_MULTIPLE_SUGGESTION_NUMBER,
	SAME_MATERIAL
} from '../../../Services/Strings'

const INITIAL_STATE = {
	loading: false,
	clusters: [],
	clusterItemsWithPictures: [],
	error: null,
	projectId: null,
	project: null
}

export const getClustersWithImages = (clusters = [], projects) => {
	let sameMaterialCount = 0
	let anyMaterialCount = 0

	//sort
	clusters = clusters?.sort((clusterA, clusterB) => {
		if (clusterA.disregardMaterial === clusterB.disregardMaterial) {
			//sort by number of parts
			return (
				clusterB.compositionSetParts?.length -
				clusterA.compositionSetParts?.length
			)
		}
		// sort by any/same material
		return !clusterA.disregardMaterial ? -1 : 1
	})

	let i = 0
	const clusterItemsWithPictures = []

	if (clusters.length > 0) {
		for (let cluster of clusters) {
			let sameMaterial = false

			if (!cluster.disregardMaterial) {
				sameMaterialCount++
				sameMaterial = true
			} else {
				anyMaterialCount++
			}

			cluster.name = `${COMBINE_MULTIPLE_SUGGESTION_NUMBER}${i + 1}`
			cluster.materialInfo = sameMaterial ? SAME_MATERIAL : ANY_MATERIAL
			cluster.isSameMaterial = !!sameMaterial
			const project =
				projects?.length &&
				projects.find(project => project.id === cluster.projectId)

			i++
			clusterItemsWithPictures.push({
				id: cluster.id,
				image: cluster.imageURL,
				title: cluster.name,
				linkTo: `${USER_HOME_ROUTE}${CLUSTER_ANALYSIS_ROUTE}/${cluster.projectId}/${cluster.id}`,
				materialInfo: cluster.materialInfo,
				isSameMaterial: cluster.isSameMaterial,
				projectName: project?.name || '',
				duplicatesCount: cluster.duplicatesCount,
				clusterPartsAmount: cluster.compositionSetParts.length
			})
		}
	}

	return {
		clusterItemsWithPictures,
		materialCountInfo: {
			sameMaterialCount,
			anyMaterialCount,
			totalMaterialCount: sameMaterialCount + anyMaterialCount
		}
	}
}

const printableClustersOverviewReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case GET_PROJECT_CLUSTERS:
			return { ...state, loading: true, projectId: action.payload }

		case NEW_PAGE_VISITED: {
			return {
				...state,
				clusters: [],
				clusterItemsWithPictures: [],
				materialCountInfo: {}
			}
		}

		case PROJECT_ANALYSIS_CLUSTER_REMOVED: {
			const { externalId } = action.payload
			const { clusters } = state
			const filteredClusters = clusters.filter(
				cluster =>
					!cluster.compositionSetParts.some(part => part.partId === externalId)
			)
			const filteredClusterWithPictures =
				getClustersWithImages(filteredClusters)

			return {
				...state,
				clusters: filteredClusters,
				clusterItemsWithPictures:
					filteredClusterWithPictures.clusterItemsWithPictures,
				materialCountInfo: filteredClusterWithPictures.materialCountInfo
			}
		}

		case MPIO_REQUEST_SUCCESS:
			const { keepClusters } = action.payload
			return keepClusters
				? state
				: {
						...state,
						clusters: [],
						clusterItemsWithPictures: []
				  }

		case GET_PROJECT_CLUSTERS_SUCCESS:
			const { project = null, clusters = [] } = action.payload

			const clustersWithImage = getClustersWithImages(clusters)

			return {
				...state,
				clusters,
				project,
				loading: false,
				clusterItemsWithPictures: clustersWithImage.clusterItemsWithPictures,
				materialCountInfo: clustersWithImage.materialCountInfo
			}

		case GET_PROJECT_CLUSTERS_FAILED:
			return { ...state, loading: false, error: action.payload }

		default:
			return state
	}
}

export default printableClustersOverviewReducer
