import React, { FC, useCallback, useEffect } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import OrganizationPanel from '../OrganizationPanel/OrganizationPanel'
import {
	onPCCheckboxChange,
	onUpdatePartConsolidationSettings,
	setupPartConsolidationSetting
} from './CustomizePartConsolidationActions'
import ButtonWithLoader from 'Scenes/Components/ButtonWithLoader'
import CastorCheckbox from 'Scenes/Components/CastorCheckbox'
import CastorForm from 'Scenes/Components/CastorForm/CastorForm'
import CastorFormHeader from 'Scenes/Components/CastorForm/CastorFormHeader'
import FlexBox from 'Scenes/Components/FlexBox'
import NavBarAndMaterial from 'Scenes/Components/NavBarAndMaterial'
import {
	CUSTOMIZE_ROUTE,
	USER_HOME_ROUTE
} from 'Services/Constants/RoutesConstants'
import { getString } from 'Services/Strings/StringService'

import './index.scss'

const CustomizePartConsolidation: FC = () => {
	const { adminSelectedOrganizationId } = useSelector(
		(state: RootStateOrAny) => state.CustomizeReducer
	)
	const { partConsolidationSettings, changesPerformed, settingsUpdating } =
		useSelector(
			(state: RootStateOrAny) => state.CustomizePartConsolidationReducer
		)
	const history = useHistory()
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(setupPartConsolidationSetting(adminSelectedOrganizationId))
	}, [adminSelectedOrganizationId, dispatch])

	const renderFormHeader = () => {
		return (
			<div className="customize-settings--header">
				<CastorFormHeader
					goBack={() => history.push(USER_HOME_ROUTE + CUSTOMIZE_ROUTE)}
					explanationHeader={getString('CUSTOMIZE_EXPLINATION_1_HEADER')}
					explanationArray={getString('CUSTOMIZE_EXPLANATION_ARRAY')}
				/>
				<OrganizationPanel />
			</div>
		)
	}

	const renderContent = useCallback(() => {
		const partConsolidationSettingsCheckboxes =
			getString('PART_CONSOLIDATION_RESULTS_OPTIONS') || []

		return (
			<FlexBox flexDirection="column">
				<CastorFormHeader
					explanationHeader={getString('CUSTOMIZE_PART_CONSOLIDATION_TITLE')}
					explanationArray={getString(
						'CUSTOMIZE_PART_CONSOLIDATION_EXPLANATION_ARRAY'
					)}
					isInCard={true}
				/>
				{partConsolidationSettingsCheckboxes.map(
					(option: Record<string, any>) => {
						const checked = !!partConsolidationSettings[option.key]
						return (
							<FlexBox key={option.key} alignItems="center">
								<CastorCheckbox
									onClick={() =>
										dispatch(onPCCheckboxChange(option.key, !checked))
									}
									checked={checked}
								/>
								<div>{option.name}</div>
							</FlexBox>
						)
					}
				)}
				<ButtonWithLoader
					loading={settingsUpdating}
					onClick={() =>
						dispatch(
							onUpdatePartConsolidationSettings(
								partConsolidationSettings,
								adminSelectedOrganizationId
							)
						)
					}
					disabled={!changesPerformed}
					className="customize-part-consolidation--button"
				>
					{getString('SET')}
				</ButtonWithLoader>
			</FlexBox>
		)
	}, [
		adminSelectedOrganizationId,
		changesPerformed,
		dispatch,
		partConsolidationSettings,
		settingsUpdating
	])

	return (
		<NavBarAndMaterial title={getString('NAV_TITLE_CUSTOMIZE_USER')}>
			<CastorForm
				formHeader={renderFormHeader()}
				formTitle={getString('CUSTOMIZE_PART_CONSOLIDATION_TITLE')}
				content={renderContent()}
			/>
		</NavBarAndMaterial>
	)
}

export default CustomizePartConsolidation
