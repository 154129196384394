import { Dispatch } from 'react'
import { AnyAction } from 'redux'

import {
	getAllProjectDataForMapping,
	prepareBomDataToSend
} from './ProjectMaterialBomMappingService'
import {
	CHANGE_PROJECT_MATERIAL_MAPPING_PAGE,
	GET_PROJECT_MATERIAL_MAPPING,
	MATERIAL_CATEGORY_CHANGE,
	MATERIAL_CHANGE,
	MATERIAL_TYPE_CHANGE,
	PROJECT_MATERIAL_BOM_MAPPING_LOADING,
	RESET_ALL_MATERIAL_SELECTIONS,
	START_PROJECT_MATERIAL_ERROR,
	START_PROJECT_MATERIAL_MAPPING,
	USE_DEFAULT_MATERIALS_SELECTIONS
} from './ProjectMaterialBomMappingTypes'
import { HANDLE_NOTIFICATION } from 'global actions/types'
import { store } from 'index'
import { Material } from 'Services/models/IMaterial'
import { IUserMaterialNamesMapping } from 'Services/models/IMaterialNamesMapping'
import { ProjectStatus } from 'Services/models/IProject'
import {
	getProjectAnalysisStatus,
	updateProjectMaterialByBom
} from 'Services/Network'
import Poller from 'Services/PollingService/Poller'
import { projectRoute } from 'Services/routeFuncs'
import { SHOW_NOTIFICATION } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

let mappingPoller = new Poller()

export const getProjectPartsMapping = (
	projectOrBundleId: string,
	materials: Material[],
	userMaterialNamesMapping: IUserMaterialNamesMapping[]
) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		try {
			dispatch({
				type: START_PROJECT_MATERIAL_MAPPING
			})
			const { projectPartBomData, projectStatus = null } =
				await getAllProjectDataForMapping(projectOrBundleId, true)

			dispatch({
				type: GET_PROJECT_MATERIAL_MAPPING,
				payload: {
					projectOrBundleId,
					projectStatus,
					projectMaterialBomData: projectPartBomData,
					materials,
					userMaterialNamesMapping
				}
			})
		} catch (err) {
			console.log(err)
			dispatch({
				type: START_PROJECT_MATERIAL_ERROR
			})
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: getString('SOMETHING_WENT_WRONG')
				}
			})
		}
	}
}

export const updateMaterialTypeMapping = (
	partBomDataId: number,
	value: string
) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: MATERIAL_TYPE_CHANGE,
			payload: {
				partBomDataId,
				value
			}
		})
	}
}

export const updateMaterialCategoryMapping = (
	partBomDataId: number,
	value: string,
	materialCategories: Record<string, any>[]
) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: MATERIAL_CATEGORY_CHANGE,
			payload: {
				partBomDataId,
				value,
				materialCategories
			}
		})
	}
}

export const updateMaterialMapping = (
	partBomDataId: number,
	value: string,
	materials: Record<string, any>[]
) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		try {
			dispatch({
				type: MATERIAL_CHANGE,
				payload: {
					partBomDataId,
					value,
					materials
				}
			})

			const { projectMaterialBomData } =
				store.getState().ProjectMaterialBomMappingReducer
			const projectId = projectMaterialBomData[0].projectId

			const index = projectMaterialBomData.findIndex(
				(partBomData: Record<string, any>) => partBomData?.id === partBomDataId
			)

			if (index !== -1 && projectMaterialBomData[index].newSetMaterial) {
				projectMaterialBomData[index].newSetMaterial.materialId = value

				await updateProjectMaterialByBom(
					projectId,
					[projectMaterialBomData[index]],
					true
				)
			}

			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.SUCCESS,
					notificationMessage: getString('DATA_WAS_UPDATED')
				}
			})
		} catch (err) {
			console.error(err)
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: getString('DATA_WAS_NOT_UPDATED')
				}
			})
		}
	}
}
export const usingDefaultForAllMaterialSelections = (
	materials: Material[],
	userMaterialNamesMapping: IUserMaterialNamesMapping[]
) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		try {
			dispatch({
				type: USE_DEFAULT_MATERIALS_SELECTIONS,
				payload: {
					materials,
					userMaterialNamesMapping
				}
			})

			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.SUCCESS,
					notificationMessage: getString('DATA_WAS_UPDATED')
				}
			})
		} catch (err) {
			console.log(err)
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: getString('DATA_WAS_NOT_UPDATED')
				}
			})
		}
	}
}

export const resetAllMaterialSelections = (
	materials: Material[],
	userMaterialNamesMapping: IUserMaterialNamesMapping[]
) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		try {
			dispatch({
				type: RESET_ALL_MATERIAL_SELECTIONS,
				payload: {
					materials,
					userMaterialNamesMapping
				}
			})
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.SUCCESS,
					notificationMessage: getString('DATA_WAS_UPDATED')
				}
			})
		} catch (err) {
			console.log(err)
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: getString('DATA_WAS_NOT_UPDATED')
				}
			})
		}
	}
}

export const clearLoading = () => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: PROJECT_MATERIAL_BOM_MAPPING_LOADING,
			payload: false
		})
	}
}

export const startAnalyzing = (
	projectMaterialBomDataList: Record<string, any>[]
) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		try {
			dispatch({
				type: PROJECT_MATERIAL_BOM_MAPPING_LOADING,
				payload: true
			})
			const projectMaterialBomData = prepareBomDataToSend(
				projectMaterialBomDataList
			)
			const projectId = projectMaterialBomData[0].projectId
			await updateProjectMaterialByBom(projectId, projectMaterialBomData, false)
			store.dispatch(getMappingPoller(projectId))
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.SUCCESS,
					notificationMessage: getString('DATA_WAS_UPDATED')
				}
			})
		} catch (err) {
			console.log(err)
			dispatch({
				type: PROJECT_MATERIAL_BOM_MAPPING_LOADING,
				payload: false
			})
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: getString('DATA_WAS_NOT_UPDATED')
				}
			})
		}
	}
}

export const getMappingPoller = (projectOrBundleId: string) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		let timeOutDateTime = new Date()
		timeOutDateTime.setHours(timeOutDateTime.getHours() + 1)
		mappingPoller = new Poller(
			2000,
			timeOutDateTime,
			() => getProjectAnalysisStatus(projectOrBundleId),
			(res: any) =>
				res.data.projectStatus == ProjectStatus.awaitingAnalysis ||
				res.data.projectStatus == ProjectStatus.awaitingExplode,
			res => {
				if (res) {
					switch (res.data.projectStatus) {
						case ProjectStatus.awaitingAnalysis: // Multi parts case
						case ProjectStatus.awaitingExplode: // Assembly case
							dispatch({
								type: PROJECT_MATERIAL_BOM_MAPPING_LOADING,
								payload: false
							})
							window.location.href = projectRoute(projectOrBundleId)
							break
						default:
							break
					}
				} else {
					throw new Error(getString('SOMETHING_WENT_WRONG'))
				}
			}
		)

		mappingPoller
			.start()
			.then(res => {
				setTimeout(() => {
					dispatch({
						type: PROJECT_MATERIAL_BOM_MAPPING_LOADING,
						payload: false
					})
				}, 2000)
			})
			.catch(error => {
				console.error(error)
			})
	}
}

export const changeMaterialMappingPage = (page: number) => {
	return {
		type: CHANGE_PROJECT_MATERIAL_MAPPING_PAGE,
		payload: { page }
	}
}
