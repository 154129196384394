import React, { ChangeEvent, FC, memo, useCallback } from 'react'
import { useDispatch } from 'react-redux'

import Checkbox from '@material-ui/core/Checkbox'

import { onCombineClick } from '../../MainPartAnalysis/SolutionAnalysis/SolutionAnalysisActions'
import { AlertType } from 'Scenes/Components/alerts/AlertTypes'
import CastorAlert from 'Scenes/Components/alerts/CastorAlert'
import {
	CANCEL,
	COMBINE_AROUND_THIS_PART_INFO,
	SEARCH_WITHIN_SAME_CATEGORY
} from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

interface ConfigurationCombineAlertProps {
	sameMaterial: boolean
	setSameMaterial: Function
	showCombineAlert: boolean
	setShowCombineAlert: Function
	partId: string
	projectId: string
}

const ConfigurationCombineAlert: FC<ConfigurationCombineAlertProps> = ({
	sameMaterial,
	setSameMaterial,
	showCombineAlert,
	setShowCombineAlert,
	partId,
	projectId
}) => {
	const dispatch = useDispatch()

	const onConfirm = useCallback(() => {
		partId && dispatch(onCombineClick(partId, projectId, sameMaterial))
		setShowCombineAlert(false)
		setSameMaterial(true)
	}, [
		dispatch,
		partId,
		projectId,
		sameMaterial,
		setSameMaterial,
		setShowCombineAlert
	])

	const onCancel = useCallback(() => {
		setShowCombineAlert(false)
		setSameMaterial(true)
	}, [setSameMaterial, setShowCombineAlert])

	return (
		<CastorAlert
			headerTitle={getString('PART_CONSOLIDATION')}
			onCancel={onCancel}
			show={showCombineAlert}
			onConfirm={onConfirm}
			cancelOptionalText={CANCEL}
			confirmOptionalText={getString('GENERATE')}
			alertType={AlertType.WARNING}
		>
			{COMBINE_AROUND_THIS_PART_INFO}
			<div>
				<Checkbox
					color="primary"
					checked={sameMaterial}
					onChange={(e: ChangeEvent<HTMLInputElement>) =>
						setSameMaterial(e.target.checked)
					}
				/>
				<span>{SEARCH_WITHIN_SAME_CATEGORY}</span>
			</div>
		</CastorAlert>
	)
}

export default memo(ConfigurationCombineAlert)
