import { ICountry, IState } from './ICountryStateCity'
import Fuse from 'fuse.js'

/* Search string to check any matches in an array */
const searchForMatches = (arrayToCheck: any, checkValue: string) => {
	const options = {
		includeScore: true,
		threshold: 0.25,
		keys: ['n', 'iC']
	}
	const fuseCountry = new Fuse(arrayToCheck, options)

	return fuseCountry?.search(checkValue)[0]?.item
}

/* Make address from receiving user data */
export const makeAddress = (
	currentAddress: any,
	countries: any,
	states: any
) => {
	let currentCountry = currentAddress?.country
	let currentState = currentAddress?.state
	let resCountry: any = null
	let resState: any = null

	if (currentCountry) {
		resCountry = searchForMatches(countries, currentCountry)
	}
	if (currentState) {
		resState = searchForMatches(states, currentState)
	}

	return {
		resCountry,
		resState
	}
}

/* Make list according to selected value */
export const updatedStates = (
	countryCode?: string,
	sortedStates?: IState[]
) => {
	if (countryCode) {
		return sortedStates?.filter((sState: IState) => sState?.cC === countryCode)
	}
	return sortedStates
}

/* Get full info */
export const fullCountry = (countryCode: string, countries: any) =>
	countries?.find((country: ICountry) => country?.iC === countryCode) || {}
export const fullState = (stateCode: string, states: any, countryIc?: string) =>
	states?.find(
		(state: IState) => state?.iC === stateCode && state.cC === countryIc
	) || {}

export const US = 'US'
export const USA = ['usa', 'united states']
