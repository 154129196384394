import React, { FC, useCallback, useEffect, useMemo } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { setupOrganizationDataForWizard } from '../../OnboardingWizardActions'
import OrganizationSelector from 'Scenes/Components/OrganizationSelector'
import { onOrganizationSelect } from 'Scenes/Home/Customize/CustomizeActions'
import { getString } from 'Services/Strings/StringService'
import { renderOrganizationValue } from 'Services/Utils/organizationTools'

const WizardOrganizationSelector: FC = () => {
	const { adminSelectedOrganizationId } = useSelector(
		(state: RootStateOrAny) => state.CustomizeReducer
	)
	const { wizardOrganizationId } = useSelector(
		(state: RootStateOrAny) => state.OnboardingWizardReducer
	)
	const { isAdminByRole, availableOrganizations, organizationDetails } =
		useSelector((state: RootStateOrAny) => state.user)
	const dispatch = useDispatch()

	const title = useMemo(
		() =>
			renderOrganizationValue(
				availableOrganizations,
				adminSelectedOrganizationId
			),
		[adminSelectedOrganizationId, availableOrganizations]
	)

	const handleOrganizationSelect = useCallback(
		(value: number) => {
			dispatch(onOrganizationSelect(value))
			dispatch(setupOrganizationDataForWizard(value))
		},
		[dispatch, wizardOrganizationId]
	)

	const renderValue = useCallback(
		(selected: unknown) =>
			renderOrganizationValue(availableOrganizations, selected as number),
		[availableOrganizations]
	)

	useEffect(() => {
		if (!adminSelectedOrganizationId) {
			dispatch(onOrganizationSelect(organizationDetails?.id))
		}
	}, [adminSelectedOrganizationId, dispatch, organizationDetails?.id])

	if (
		!isAdminByRole ||
		wizardOrganizationId ||
		availableOrganizations.length <= 1
	) {
		return null
	}

	return (
		<div className="start-screen--organization-selector">
			<span>{getString('REGISTER_FORM_ORGANIZATION_LABEL')}:</span>
			<OrganizationSelector
				value={adminSelectedOrganizationId}
				title={title}
				renderValue={renderValue}
				handleOrganizationSelect={handleOrganizationSelect}
			/>
		</div>
	)
}

export default WizardOrganizationSelector
